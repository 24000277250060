<template lang="html">
  <v-container fluid class="pa-0 fill-height">
    <v-card width="100%" flat outlined tile>
      <v-card-title class="pt-0">
        {{ $tc('common.sendManagement', 2) }}
        <v-spacer />
        <v-text-field
          v-if="isUpdate"
          v-model="searchProjectUsers"
          :append-icon="'$iconMagnify'"
          :label="$t('common.search')"
          :persistent-hint="true"
          clearable
          hide-details
          single-line
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="projectUsers"
        :item-class="rowClassesProjectUsers"
        :height="StyleHeightCalc('189px').height"
        :footer-props="{
          'items-per-page-text': itemsPerPageText,
          'page-text': pageText,
          'items-per-page-options': [5, 10, 25, 50, -1],
        }"
        :no-data-text="$t('common.noDataText')"
        :options.sync="options"
        :search="searchProjectUsers"
        dense
        fixed-header
        @click:row="selectProjectUsers($event)"
      >
        <template #[`header.notificationResult`]>
          <tr id="row-1">
            <th colspan="4">
              {{ $t('project.sendNotification.functional') }}
            </th>
            <th colspan="4">
              {{ $t('project.sendNotification.operational') }}
            </th>
            <th v-if="$store.getters['auth/isAuth'](['project_device', 'create'])" colspan="4">
              {{ $t('project.sendNotification.other') }}
            </th>
          </tr>
          <tr id="row-2">
            <th v-for="(v, k) in row2Array" :key="k" colspan="2">
              {{ v }}
            </th>
            <th v-if="isOther" colspan="2">
              {{ $t('project.sendNotification.default') }}
            </th>
            <th v-if="isOther" colspan="2">
              {{ $t('project.sendNotification.error') }}
            </th>
          </tr>
          <tr id="row-3" class="send-notif-send">
            <th v-for="(v, k) in row3Array" :key="k">
              {{ v }}
            </th>
          </tr>
        </template>
        <template #[`item.notificationResult`]="{ item }">
          <span
            v-for="(first, key1) in item.notificationResult"
            :key="key1"
            class="send-notif-key1"
          >
            <span v-for="(second, key2) in first" :key="key2" class="send-notif-key2">
              <span v-for="(last, key3) in second" :key="key3" class="send-notif-key3">
                <v-tooltip left>
                  <template #activator="{ on }">
                    <span v-on="on">
                      <v-checkbox
                        v-model="item.notificationResult[key1][key2][key3]"
                        :disabled="!isUpdate"
                        class="d-inline-block send-notif-checkbox"
                        color="secondary"
                        dense
                        hide-details
                      />
                    </span>
                  </template>
                  <span>
                    {{ getTooltipCheckboxLabel(item, key1, key2, key3) }}
                  </span>
                </v-tooltip>
              </span>
            </span>
          </span>
        </template>
        <template #[`item.notification.report`]="{ item }">
          <v-switch
            v-model="item.notification.report"
            :disabled="!isUpdate"
            :input-value="item.notification.report"
            color="secondary"
          />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
// Remove for a future upgrade from user_project to crm_contact_project:
import {
  GET_USERS_PROJECT_NOTIFICATION,
  PATCH_USER_PROJECT_NOTIFICATION,
} from '@/graphql/UserProjectQueries'
// Keep for a future upgrade from user_project to crm_contact_project:
// import { GET_CRM_CONTACT_PROJECT, UPDATE_CRM_CONTACT_PROJECT_NOTIFICATION } from '@/graphql/CrmContactProjectQueries'
import { CommonMixin } from '@/mixins/CommonMixin'
import merge from 'lodash/merge'

export default {
  name: 'SendNotification',
  mixins: [CommonMixin],
  props: {
    allTag: {
      type: Boolean,
      default: false,
    },
    crmCompanyUuid: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: -1,
    },
  },
  apollo: {
    /*
      // Keep for a future upgrade from user_project to crm_contact_project:
      crm_contact_project: {
        query: GET_CRM_CONTACT_PROJECT,
        variables () {
          const vars = {
            order_by: {
              crm_contact: { lastname: 'asc' },
            },
            where: {
              project_uuid: { _eq: this.value },
            },
          }
          return vars
        },
        skip () {
          return this.value === -1
        },
      },
      */
    // Remove for a future upgrade from user_project to crm_contact_project:
    user_project: {
      query: GET_USERS_PROJECT_NOTIFICATION,
      variables() {
        let vars = {
          order_by: {
            user: { crm_contact: { lastname: 'asc' } },
          },
          where: {
            project_uuid: { _eq: this.value },
          },
        }
        if (this.crmCompanyUuid) {
          const where = {
            where: {
              user: {
                crm_contact: {
                  crm_company: {
                    uuid: {
                      _eq: this.crmCompanyUuid,
                    },
                  },
                },
              },
            },
          }
          vars = merge(vars, where)
        }
        return vars
      },
      skip() {
        return this.value === -1
      },
    },
  },
  data: () => ({
    clickRow: true,
    isTrue: true,
    options: {
      itemsPerPage: 10,
      sortBy: ['lastname'],
      sortDesc: [false],
      filter: {},
    },
    searchProjectUsers: '',
    notificationList: {
      functional: {
        alarm: {
          mail: null,
          sms: null,
        },
        alert: {
          mail: null,
          sms: null,
        },
      },
      operational: {
        alarm: {
          mail: null,
          sms: null,
        },
        alert: {
          mail: null,
          sms: null,
        },
      },
      other: {
        default: {
          mail: null,
          sms: null,
        },
        error: {
          mail: null,
          sms: null,
        },
      },
    },
    notificationResult: {},
  }),
  computed: {
    isUpdate() {
      // Remove for a future upgrade from user_project to crm_contact_project:
      return this.$store.getters['auth/isAuth'](['user_project', 'update'])
      // Keep for a future upgrade from user_project to crm_contact_project:
      // return this.$store.getters['auth/isAuth'](['crm_contact_project', 'update'])
    },
    headers: function () {
      const widthNotification = this.isOther ? '635px' : '435px'
      // Remove for a future upgrade from user_project to crm_contact_project:
      return [
        {
          text: this.$t('contact.lastname'),
          value: 'user.crm_contact.lastname',
          sortable: true,
          cellClass: 'text-uppercase',
        },
        {
          text: this.$t('contact.firstname'),
          value: 'user.crm_contact.firstname',
          sortable: true,
        },
        {
          text: this.$tc('common.company', 1),
          value: 'user.crm_contact.crm_company.name',
          sortable: true,
        },
        {
          text: 'header new notification',
          value: 'notificationResult',
          align: 'center',
          sortable: false,
          width: widthNotification,
        },
        {
          text: this.$tc('common.report', 1),
          value: 'notification.report',
          sortable: false,
        },
      ]
      // Keep for a future upgrade from user_project to crm_contact_project:
      /*
        return [
          { text: this.$t('contact.lastname'), value: 'crm_contact.lastname', sortable: true, cellClass: 'text-uppercase' },
          { text: this.$t('contact.firstname'), value: 'crm_contact.firstname', sortable: true },
          { text: this.$tc('common.company', 1), value: 'crm_contact.crm_company.name', sortable: true },
          { text: 'header new notification', value: 'notificationResult', align: 'center', sortable: false, width: '635px' },
          { text: this.$tc('common.report', 1), value: 'notification.report', sortable: false },
        ]
        */
    },
    isOther() {
      // true if rights for default and error notification
      return this.$store.getters['auth/isAuth'](['project_device', 'create'])
    },
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
    projectUsers() {
      // Remove for a future upgrade from user_project to crm_contact_project:
      return this.getNotification()
      // Keep for a future upgrade from user_project to crm_contact_project:
      // return this.crm_contact_project
    },
    row2Array() {
      const alarm = this.$t('project.sendNotification.alarm')
      const alert = this.$t('project.sendNotification.alert')
      const duplicate = new Array(2).fill([alarm, alert]).flat()
      return duplicate
    },
    row3Array() {
      const mail = this.$t('project.sendNotification.mail')
      const sms = this.$t('project.sendNotification.sms')
      const nbDuplicate = this.isOther ? 6 : 4
      const duplicate = new Array(nbDuplicate).fill([mail, sms]).flat()
      return duplicate
    },
  },
  methods: {
    createSendNotification(event) {
      const deepKeys = this.getDeepKeys(this.notificationList)
      const keysThreeLevels = this.getKeysThreeLevelsDeep(deepKeys)

      const newSend = {
        send_sms: [],
        send_mail: [],
      }
      keysThreeLevels.forEach((el) => {
        const check = this.getKeyByPathArr(event.notificationResult, el)
        if (check) {
          let type = ''
          if (el[0] === 'functional') {
            type = '_F'
          } else if (el[0] === 'operational') {
            type = '_O'
          } else {
            type = ''
          }
          newSend['send_' + el[2]].push(el[1].toUpperCase() + type)
        }
      })

      // Delete empty array
      Object.keys(newSend).forEach((k) => newSend[k].length === 0 && delete newSend[k])

      // Add report
      if (event.notification?.report && event.notification.report) {
        newSend.report = event.notification.report
      }

      return newSend
    },
    /**
     * Return all keys of a deep object
     * @see {@link https://stackoverflow.com/a/42674656}
     * @param {Object} o
     * @return {Array}
     */
    getDeepKeys(o) {
      let keys = []
      for (const key in o) {
        keys.push(key)
        if (typeof o[key] === 'object') {
          const subkeys = this.getDeepKeys(o[key])
          keys = keys.concat(
            subkeys.map((subkey) => {
              return key + '_' + subkey
            })
          )
        }
      }
      return keys
    },
    /**
     * Deep search on an object
     * @see {@link https://stackoverflow.com/a/44063224}
     * @param {Object} o
     * @param {Array} pathArr <array> of key levels
     * @return {Array} o[key] value where key = pathArr.join(.) and value
     * exists, otherwise return null. If pathArr === [] return o
     */
    getKeyByPathArr(o, pathArr) {
      const keyByPathArr = Array.isArray(pathArr)
        ? pathArr.reduce((a, c) => (a[c] !== null ? a[c] : null), o)
        : null
      return keyByPathArr
    },
    /**
     * Return keys with three levels deep
     * @param {Array} keys
     * @return {Array}
     */
    getKeysThreeLevelsDeep(keys) {
      const newKeys = keys.reduce((result, key) => {
        const keySplit = key.split('_')
        if (keySplit.length === 3) {
          result.push([keySplit[0], keySplit[1], keySplit[2]])
        }
        return result
      }, [])
      return newKeys
    },
    getNotification() {
      /**
       * Notification type definition in db (issue_project.notification)
       * typedef {object} notification
       * property {boolean} [notification.report]
       * property {array} [notification.send_mail] each optional value:
       * 'ALARM_O', 'ALERT_O', 'ALARM_F', 'ALERT_F', 'DEFAULT', 'ERROR',
       * property {Array} [notification.send_sms] each optional value:
       * ['ALARM_O', 'ALERT_O', 'ALARM_F', 'ALERT_F', 'DEFAULT', 'ERROR']
       */
      let userProject = []
      userProject = this.user_project

      userProject?.forEach((el) => {
        if (!el.notification?.report) {
          el.notification.report = false
        }

        el.notificationResult = {
          functional: {
            alarm: {
              mail: el.notification?.send_mail?.includes('ALARM_F'),
              sms: el.notification?.send_sms?.includes('ALARM_F'),
            },
            alert: {
              mail: el.notification?.send_mail?.includes('ALERT_F'),
              sms: el.notification?.send_sms?.includes('ALERT_F'),
            },
          },
          operational: {
            alarm: {
              mail: el.notification?.send_mail?.includes('ALARM_O'),
              sms: el.notification?.send_sms?.includes('ALARM_O'),
            },
            alert: {
              mail: el.notification?.send_mail?.includes('ALERT_O'),
              sms: el.notification?.send_sms?.includes('ALERT_O'),
            },
          },
        }

        if (this.isOther) {
          el.notificationResult.other = {
            default: {
              mail: el.notification?.send_mail?.includes('DEFAULT'),
              sms: el.notification?.send_sms?.includes('DEFAULT'),
            },
            error: {
              mail: el.notification?.send_mail?.includes('ERROR'),
              sms: el.notification?.send_sms?.includes('ERROR'),
            },
          }
        } else {
          el.notificationTemporary = {
            other: {
              default: {
                mail: el.notification?.send_mail?.includes('DEFAULT'),
                sms: el.notification?.send_sms?.includes('DEFAULT'),
              },
              error: {
                mail: el.notification?.send_mail?.includes('ERROR'),
                sms: el.notification?.send_sms?.includes('ERROR'),
              },
            },
          }
        }
      })

      return userProject
    },
    getTooltipCheckboxLabel(item, key1, key2, key3) {
      const check = item.notificationResult[key1][key2][key3] || false
      const txtKey1 = this.$t('project.sendNotification.' + key1) + ' '
      const txtKey2 = this.$t('project.sendNotification.' + key2) + ' => '
      const txtKey3 = this.$t('project.sendNotification.' + key3) + ' : '
      const txtCheck = check
        ? this.$t('project.sendNotification.yes')
        : this.$t('project.sendNotification.no')
      return txtKey1 + txtKey2 + txtKey3 + txtCheck
    },
    rowClassesProjectUsers() {
      return this.isTrue ? 'pointer' : null
    },
    async selectProjectUsers(event) {
      if (event.notificationTemporary) {
        event.notificationResult.other = event.notificationTemporary.other
        delete event.notificationTemporary
      }

      const newNotification = this.createSendNotification(event)

      if (this.isUpdate) {
        // Remove for a future upgrade from user_project to crm_contact_project:
        await this.$apollo
          .mutate({
            mutation: PATCH_USER_PROJECT_NOTIFICATION,
            variables: {
              user_uuid: event.user_uuid,
              project_uuid: this.value,
              _set: {
                notification: newNotification,
              },
            },
            /**
             * Update cache Users
             */
            refetchQueries: [
              {
                query: GET_USERS_PROJECT_NOTIFICATION,
                variables: {
                  order_by: {
                    user: { crm_contact: { lastname: 'asc' } },
                  },
                  where: {
                    project_uuid: { _eq: this.value },
                  },
                },
              },
            ],
          })
          .catch((error) => {
            this.$root.$dialogLoader.showSnackbar(
              'update PATCH_USER_PROJECT_NOTIFICATION: ' + error.message,
              { color: 'error' }
            )
            throw error
          })

        // Keep for a future upgrade from user_project to crm_contact_project:
        /*
          await this.$apollo.mutate({
            mutation: UPDATE_CRM_CONTACT_PROJECT_NOTIFICATION,
            variables: {
              contact_uuid: event.contact_uuid,
              project_uuid: this.value,
              _set: {
                notification: newNotification,
              },
            },
            // Update cache
            refetchQueries: [{
              query: GET_CRM_CONTACT_PROJECT,
              variables: {
                order_by: {
                  crm_contact: { lastname: 'asc' },
                },
                where: {
                  project_uuid: { _eq: this.value },
                },
              },
            }],
          }).catch((error) => {
            this.$root.$dialogLoader.showSnackbar('update UPDATE_CRM_CONTACT_PROJECT_NOTIFICATION: ' + error.message, { color: 'error' })
            throw error
          })
          */
      }
    },
  },
}
</script>

<style lang="css">
.send-notif-checkbox {
  margin: 0;
  padding: 0;
  width: 110px;
}
.send-notif-send > th {
  max-width: 50px;
  min-width: 50px;
}
.send-notif-key1 {
  display: inline-block;
  width: 200px;
}
.send-notif-key2 {
  display: inline-block;
  width: 100px;
}
.send-notif-key3 {
  display: inline-block;
  margin: 0 13px;
  width: 24px;
}
</style>
