export default {
  primary: '#6B6B6B',
  secondary: '#64BDEB',
  tertiary: '#F3F3F3',
  quaternary: '#3171BE',
  accent: '#82B1FF',
  error: '#f55a4e',
  info: '#00d3ee',
  success: '#5cb860',
  warning: '#ffa21a',
}
