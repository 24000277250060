<template>
  <helper-crud-list v-bind="AlertDefs">
    <template #[`item.state`]="{ item }">
      <alert-state-chip :state-value="item.state" />
    </template>
    <template #[`item.updated_state`]="{ item }">
      {{ item.updated_state | formatDate }}
    </template>
    <template #[`item.sleep`]="{ item }">
      <v-icon v-if="item.sleep" dense>$iconAlertSleep</v-icon>
    </template>
    <template #expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="pa-2">
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-center">
                  {{ $t('alert.state') }}
                </th>
                <th class="text-right">
                  {{ $t('alert.value') }}
                </th>
                <th class="text-center">
                  {{ $t('common.created_at') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="history in item.alert_alert_histories" :key="history.d">
                <td class="text-center">
                  <alert-state-chip :state-value="history.state" />
                </td>
                <td class="text-right">{{ history.value }}</td>
                <td class="text-right">{{ history.created_at | formatDate }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </td>
    </template>
    <template #preAction="{ item, listparams }">
      <v-icon small @click.stop="Sleep(item, listparams)"> $iconAlertSleep </v-icon>
    </template>
    <template #form="{ editedId, listparams, closeForm }">
      <alert-form
        :value="editedId"
        :device-uuid="deviceUuid"
        :listparams="listparams"
        @closeForm="closeForm"
      />
    </template>
  </helper-crud-list>
</template>

<script>
import AlertForm from '@/views/AlertForm'
import AlertStateChip from '@/components/alert/AlertStateChip'
import { mapGetters } from 'vuex'
import { GET_ALERT_ALERTS, UPDATE_ALERT_ALERT_BY_PK } from '@/graphql/AlertAlertQueries'

export default {
  name: 'DeviceDialogAlert',
  components: {
    AlertForm,
    AlertStateChip,
  },
  props: {
    deviceUuid: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    AlertDefs: {
      titleName: 'alert.alert|plural',
      subtitle: 'alert.subtitle',
      identifier: 'uuid',
      gqlobject: 'alert_alert',
      showExpand: true,
      options: {
        sortBy: ['name'],
        sortDesc: [true],
      },
      headers: [
        { text: 'alert.a', value: 'sleep', sortable: true },
        { text: 'alert.state', value: 'state', sortable: true },
        { text: 'common.name', value: 'name', sortable: true },
        {
          text: 'alert.updated_state',
          value: 'updated_state',
          align: 'center',
        },
        {
          text: 'common.updated_at',
          value: 'updated_at',
          align: 'center',
        },
        {
          text: 'common.created_at',
          value: 'created_at',
          align: 'center',
        },
        {
          text: 'common.action|plural',
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
      filters: {},
    },
  }),
  created() {
    this.AlertDefs.options.where = {
      project_uuid: { _eq: this.currentProjectId },
      device_uuid: { _eq: this.deviceUuid },
    }
  },
  methods: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
    async Sleep(item, listparams) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_ALERT_ALERT_BY_PK,
          variables: {
            uuid: item.uuid,
            alert: {
              sleep: !item.sleep,
            },
          },
          refetchQueries: [
            {
              query: GET_ALERT_ALERTS,
              variables: listparams,
            },
          ],
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'update UPDATE_ALERT_ALERT_BY_PK: ' + error.message,
            {
              color: 'error',
            }
          )
          throw error
        })
    },
  },
}
</script>
