<template>
  <v-form autocomplete="off" class="fill-height">
    <v-toolbar flat width="100%" class="grey lighten-5">
      <helper-breadcrumbs :last-item="choiceListItemTitle" />
      <v-spacer />
      <v-btn class="mb-2 mr-1" @click="saveForm">
        <v-icon class="mr-1" color="primary"> $iconSave </v-icon>{{ $t('common.save') }}
      </v-btn>
      <v-btn class="mb-2 mr-1" @click="closeForm">
        <v-icon class="mr-1" color="primary"> $iconClose </v-icon>{{ $t('common.close') }}
      </v-btn>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="mb-4">
            <v-card-title class="text-body-1 white--text secondary py-1">
              {{ $tc('alert.alert', 1) }}
            </v-card-title>
            <v-card-text class="pa-4">
              <v-row>
                <v-col cols="12">
                  <form-error
                    :validator="$v.alert_by_pk.name"
                    :attribute="$t('common.name')"
                    :messages="messages"
                  >
                    <v-text-field
                      v-bind="attrs"
                      v-model="alert_by_pk.name"
                      slot-scope="{ attrs }"
                      :label="$t('alert.alertfilters.label') + ' *'"
                      :placeholder="$t('alert.alertfilters.placeholder')"
                      @input="$v.alert_by_pk.name.$touch()"
                      @blur="$v.alert_by_pk.name.$touch()"
                    />
                  </form-error>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="auto">
                  <form-error
                    :validator="$v.alert_by_pk.frequency"
                    :attribute="$t('alert.frequency')"
                    :messages="messages"
                  >
                    <v-text-field
                      v-bind="attrs"
                      v-model="alert_by_pk.frequency"
                      slot-scope="{ attrs }"
                      :label="$t('alert.frequency') + ' *'"
                    />
                  </form-error>
                </v-col>
                <v-col cols="12" md="auto">
                  <form-error
                    :validator="$v.alert_by_pk.for"
                    :attribute="$t('alert.for')"
                    :messages="messages"
                  >
                    <v-text-field
                      v-bind="attrs"
                      v-model="alert_by_pk.for"
                      slot-scope="{ attrs }"
                      :label="$t('alert.for') + ' *'"
                    />
                  </form-error>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="mb-4">
            <v-card-title class="text-body-1 white--text secondary py-1">
              {{ $tc('alert.label', 2) }}
            </v-card-title>
            <v-card-text class="pa-4">
              <v-row v-for="(label, index) in alert_by_pk.labels" :key="index" align="center">
                <v-col cols="12" md="auto" class="font-weight-bold">
                  {{ $t('alert.label') }}
                </v-col>
                <v-col cols="12" md="1">
                  <v-text-field v-model="label.label" />
                </v-col>
                <v-col cols="12" md="2">
                  <v-card class="pa-2 align-self-center" outline flat> = </v-card>
                </v-col>
                <v-col cols="12" md="auto">
                  <v-text-field v-model="label.value" />
                </v-col>
                <v-col cols="12" md="auto">
                  <v-btn class="mx-2" fab dark small color="primary" @click="addLabel(index)">
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="index"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="delLabel(index)"
                  >
                    <v-icon dark> mdi-minus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-4">
            <v-card-title class="text-body-1 white--text secondary py-1">
              {{ $tc('alert.condition', 1) }}
            </v-card-title>
            <v-card-text class="pa-4" fill-height fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn-toggle v-model="topLevelCond" mandatory>
                    <v-btn value="all"> {{ $t('rule.all') }} </v-btn>
                    <v-btn value="any"> {{ $t('rule.any') }} </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
              <v-row
                v-for="(condition, index) in Object.values(alert_by_pk.rule.conditions)[0]"
                :key="index"
                align="center"
              >
                <v-col cols="12" md="auto" class="font-weight-bold">
                  {{ $t('alert.metric') }}
                </v-col>
                <v-col cols="12" md="2">
                  <form-error :attribute="$t('alert.operator')" :messages="messages">
                    <v-overflow-btn
                      v-bind="attrs"
                      v-model="condition.operator"
                      slot-scope="{ attrs }"
                      :items="operand_tr"
                      item-value="value"
                      item-text="label"
                      dense
                    ></v-overflow-btn>
                  </form-error>
                </v-col>
                <v-col cols="12" md="auto">
                  <form-error :attribute="$t('alert.value')" :messages="messages">
                    <v-text-field v-bind="attrs" v-model="condition.value" slot-scope="{ attrs }" />
                  </form-error>
                </v-col>
                <v-col cols="12" md="auto">
                  <v-btn class="mx-2" fab dark small color="primary" @click="addCondition(index)">
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="index"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="delCondition(index)"
                  >
                    <v-icon dark> mdi-minus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <p>
        <small>
          <i>
            {{ $t('common.requiredFields') }}
          </i>
        </small>
      </p>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  GET_ALERT_ALERTS,
  GET_ALERT_ALERT,
  INSERT_ALERT_ALERT,
  UPDATE_ALERT_ALERT_BY_PK,
} from '@/graphql/AlertAlertQueries'
import { CommonMixin } from '@/mixins/CommonMixin'
import RulesOperators from '@/components/rules/operator'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'AlertForm',
  mixins: [CommonMixin],
  props: {
    value: {
      type: [String, Number],
      default: -1,
    },
    deviceUuid: {
      type: String,
      default: '',
    },
    listparams: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    alert_by_pk: {
      query: GET_ALERT_ALERT,
      variables() {
        return {
          uuid: this.value,
        }
      },
      update(data) {
        data.alert_alert_by_pk.labels = data.alert_alert_by_pk.labels.length
          ? data.alert_alert_by_pk.labels
          : this.alert_by_pk_default.labels
        return data.alert_alert_by_pk
      },
      skip() {
        return this.value === -1
      },
    },
  },
  data: () => ({
    alert_by_pk: {},
    alert_by_pk_default: {
      name: '',
      type_id: 1,
      state: 'normal',
      frequency: 'onSync',
      for: '5m',
      rule: {
        conditions: {
          all: [
            {
              fact: 'metric',
            },
          ],
        },
      },
      labels: [{ label: '', value: '' }],
    },
    topLevel: 'any',
    RulesOperators,
  }),
  validations: {
    alert_by_pk: {
      name: { minLength: minLength(3), required },
    },
  },
  computed: {
    choiceListItemTitle() {
      const edit = this.$t('common.edit')
      const add = this.$t('common.add')
      return this.value !== -1 ? `${edit} ${this.alert_by_pk.name}` : add
    },
    operand_tr() {
      let operand = []
      this.RulesOperators.number.forEach((obj) => {
        operand = [...operand, { label: this.$t('rule.' + obj), value: obj }]
      })
      return operand
    },
    topLevelCond: {
      get() {
        return this.alert_by_pk ? Object.keys(this.alert_by_pk.rule.conditions)[0] : 'all'
      },
      set(newValue) {
        const oldValue = Object.values(this.alert_by_pk.rule.conditions)[0]
        delete this.alert_by_pk.rule.conditions[Object.keys(this.alert_by_pk.rule.conditions)[0]]

        this.alert_by_pk.rule.conditions[newValue] = oldValue
      },
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
  created() {
    this.alert_by_pk = this.alert_by_pk_default
    this.alert_by_pk.project_uuid = this.currentProjectId()
    this.alert_by_pk.device_uuid = this.deviceUuid
  },
  methods: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
    closeForm() {
      this.$emit('closeForm')
    },
    updateDefaultValue(data) {
      this.alert_by_pk.default_value = data
    },
    updateFieldConfig(data) {
      this.alert_by_pk.field_config = data
    },
    addCondition(index) {
      this.alert_by_pk.rule.conditions[this.topLevelCond].splice(index + 1, 0, {
        fact: 'metric',
      })
    },
    delCondition(index) {
      this.alert_by_pk.rule.conditions[this.topLevelCond].splice(index, 1)
    },
    addLabel(index) {
      this.alert_by_pk.labels.splice(index + 1, 0, { '': '' })
    },
    delLabel(index) {
      this.alert_by_pk.labels.splice(index, 1)
    },
    async saveForm() {
      const errorForm = this.$t('common.errorOnForm')
      const modificationCompleted = this.$t('common.modificationCompleted')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$root.$dialogLoader.showSnackbar(errorForm, { color: 'error' })
        return
      }

      if (this.value === -1) {
        await this.create()
      } else {
        await this.update()
      }

      this.closeForm()
      this.$root.$dialogLoader.showSnackbar(modificationCompleted, {
        color: 'success',
      })
    },
    async create() {
      const Alert = Object.assign({}, this.alert_by_pk)
      Alert.rule = this.convertConditionsToRules(Alert)

      Alert.labels = Alert.labels.filter((label) => {
        return !(!label.label || !label['value'])
      })

      await this.$apollo
        .mutate({
          mutation: INSERT_ALERT_ALERT,
          variables: {
            alerts: Alert,
          },
          refetchQueries: [
            {
              query: GET_ALERT_ALERTS,
              variables: this.listparams,
            },
          ],
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar('create INSERT_ALERT_ALERT_ONE: ' + error.message, {
            color: 'error',
          })
          throw error
        })
    },
    async update() {
      const Alert = Object.keys(this.alert_by_pk_default).reduce(
        (a, c) => ({ ...a, [c]: this.alert_by_pk[c] }),
        {}
      )
      Alert.rule = this.convertConditionsToRules(Alert)

      Alert.labels = Alert.labels.filter((label) => {
        return label.label && label['value']
      })

      await this.$apollo
        .mutate({
          mutation: UPDATE_ALERT_ALERT_BY_PK,
          variables: {
            uuid: this.value,
            alert: Alert,
          },
          refetchQueries: [
            {
              query: GET_ALERT_ALERTS,
              variables: this.listparams,
            },
          ],
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'update UPDATE_ALERT_ALERT_BY_PK: ' + error.message,
            {
              color: 'error',
            }
          )
          throw error
        })
    },
    convertConditionsToRules(alert) {
      return {
        name: alert.name + ' Alert Rules',
        conditions: alert.rule.conditions,
      }
    },
  },
}
</script>

<style>
</style>
