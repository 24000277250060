<template>
  <v-combobox
    v-model="brandSelect"
    v-bind="$attrs"
    :items="brandsItems"
    :label="$t('libDevice.maker')"
    :placeholder="$t('libDevice.filters.maker.placeholder')"
    :search-input.sync="brandSearch"
    item-text="name"
    item-value="id"
    :hide-no-data="!brandSearch"
    clearable
    return-object
    v-on="$listeners"
    @change="selectItem"
  >
    <template #no-data>
      <v-list-item>
        <span class="subheading">{{ $t('common.create') }}</span>
        <span class="ml-2">{{ brandSearch }}</span>
      </v-list-item>
    </template>
    <template #item="{ index, item }">
      <v-list-item v-if="editing === item" dense @click.stop>
        <v-text-field
          v-model="editing.name"
          autofocus
          flat
          background-color="transparent"
          hide-details
          @keyup.enter="edit(index, item)"
        />
      </v-list-item>
      <span v-else>{{ item.name }}</span>
      <v-spacer />
      <v-list-item-action @click.stop>
        <v-btn icon x-small @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? '$iconEdit' : '$iconCheck' }}</v-icon>
        </v-btn>
        <v-btn v-if="editing !== item" icon x-small @click.stop.prevent="deleteBrand(index, item)">
          <v-icon>$iconDelete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>
<script>
import {
  GET_LIB_DEVICE_BRANDS,
  POST_LIB_DEVICE_BRAND,
  PATCH_LIB_DEVICE_BRAND,
  DELETE_LIB_DEVICE_BRAND,
} from '@/graphql/LibDeviceBrandQueries'

export default {
  name: 'BrandSelector',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    brandsItems: [],
    brandSelect: {},
    brandSearch: '',
    editing: null,
    firstload: true,
  }),
  watch: {
    value: {
      handler: 'loadBrand',
      immediate: true,
    },
    brandSelect: {
      handler: 'addBrand',
      immediate: true,
    },
    brandSearch(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch()
      }
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      } else {
        this.editing = null
        this.index = -1
        this.updateBrand(item)
      }
    },
    loadBrand() {
      this.brandSelect = this.value
    },
    async querySearch() {
      this.loading = true
      const rv = await this.$apollo.query({
        query: GET_LIB_DEVICE_BRANDS,
        variables: {
          order_by: { name: 'asc' },
        },
      })
      this.brandsItems = rv.data.lib_device_brand
      this.loading = false
    },
    async addBrand(val) {
      if (typeof val !== 'string') return
      await this.$apollo.mutate({
        mutation: POST_LIB_DEVICE_BRAND,
        variables: {
          objects: { name: val },
        },
        // eslint-disable-next-line
          update: (cache, { data: { insert_lib_device_brand } }) => {
          try {
            const brands = cache.readQuery({
              query: GET_LIB_DEVICE_BRANDS,
              variables: {
                order_by: { name: 'asc' },
              },
            })
            // eslint-disable-next-line
              brands.lib_device_brand.push(insert_lib_device_brand.returning[0])
            brands.lib_device_brand_aggregate.aggregate.totalCount++
            cache.writeQuery({
              query: GET_LIB_DEVICE_BRANDS,
              data: brands,
            })
            // eslint-disable-next-line
              this.$emit('input', insert_lib_device_brand.returning[0])
          } catch (e) {
            console.log(e)
          }
        },
      })
    },
    async updateBrand(item) {
      try {
        await this.$apollo.mutate({
          mutation: PATCH_LIB_DEVICE_BRAND,
          variables: {
            id: parseInt(item.id),
            body: { name: item.name },
          },
          // eslint-disable-next-line
            update: (cache, { data: { update_lib_device_brand } }) => {
            cache.reset()
            // eslint-disable-next-line
              this.brandSelect = update_lib_device_brand.returning[0]
          },
        })
      } catch (e) {
        console.log(e)
      }
    },
    async deleteBrand(index, item) {
      await this.$apollo.mutate({
        mutation: DELETE_LIB_DEVICE_BRAND,
        variables: {
          id: item.id,
        },
        // eslint-disable-next-line
          update: (cache, { data: { delete_project } }) => {
          cache.reset()
        },
      })
      this.querySearch()
    },
    selectItem() {
      if (this.editing) {
        return false
      }

      this.$emit('input', this.brandSelect)
      this.brandSearch = ''
    },
  },
}
</script>

<style>
.subheading::first-letter {
  text-transform: capitalize;
}
</style>
