<template>
  <v-container fluid class="mx-auto pa-0">
    <graph-single :is-dashboard="isTrue" :options-params.sync="optionsParams" />
  </v-container>
</template>

<script>
import GraphSingle from '@/components/project/graphs/GraphSingle'

export default {
  name: 'GraphNotification',
  components: {
    GraphSingle,
  },
  props: {
    device: {
      type: Object,
      default: undefined,
    },
    settings: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    isTrue: true,
    optionsParams: {},
  }),
  watch: {
    'device.id': function (val, oldVal) {
      if (oldVal !== val) {
        this.optionsParams = this.getOptionsParamsDefault()
        delete this.optionsParams.newOptions
      }
    },
  },
  mounted() {
    this.optionsParams = this.getOptionsParamsDefault()
  },
  methods: {
    getOptionsParamsDefault() {
      const options = {
        device_id: this.device.id,
        device_name: this.device.name,
        hub_name: this.device.hub_name,
        annotationsPoints: this.settings.annotationsPoints,
      }
      if (this.settings?.dateTimeFrom && this.settings?.dateTimeFrom) {
        options.dateTimeFrom = this.settings?.dateTimeFrom
        options.dateTimeTo = this.settings?.dateTimeTo
      }
      return options
    },
    updateGraph() {
      this.optionsParams.newOptions = true
    },
  },
}
</script>
