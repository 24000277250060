<template>
  <v-navigation-drawer
    id="project-drawer-calendar"
    v-model="displayDrawer"
    class="mt-8"
    color="tertiary"
    height="calc(100% - (32px * 2))"
    width="292px"
    absolute
    right
    stateless
  >
    <v-container>
      <v-row>
        <div class="text-overline">
          <span class="text-uppercase pl-3">
            {{ $t('calendar.agenda') }}
          </span>
        </div>
        <v-spacer />
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn icon small class="mr-2" @click="goCalendar()" v-on="on">
              <v-icon> $iconOpenInNew </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.openInNewTab') }}</span>
        </v-tooltip>
      </v-row>
      <v-row align="center" class="py-0 px-2">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-btn v-on="on">
              {{ $d(LocalDate, 'short') }}
            </v-btn>
          </template>
          <v-date-picker
            v-model="datePicker"
            :events="eventIndicators"
            :first-day-of-week="dateProps.firstDayOfWeek"
            :locale="dateProps.locale"
            no-title
            scrollable
            color="secondary"
            @change="save"
          />
        </v-menu>
        <v-tooltip v-if="!isNow" top>
          <template #activator="{ on }">
            <v-btn icon small class="ml-3" @click="LocalDate = new Date()" v-on="on">
              <v-icon> $iconCalendarClock </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('calendar.today') }}</span>
        </v-tooltip>
      </v-row>
      <v-row class="pa-0">
        <v-col class="pa-0 pt-2">
          <v-sheet class="pa-0" height="calc(100vh - 220px)">
            <v-calendar
              ref="calendar"
              type="day"
              hide-header
              :events="events"
              :event-color="getEventColor"
              :locale="dateProps.locale"
              :value="datePicker"
              @click:event="showEvent"
            >
              <template #event="{ event }">
                <div class="font-weight-medium px-1">
                  <v-tooltip left>
                    <template #activator="{ on }">
                      <div class="text-no-wrap text-truncate" v-on="on">
                        {{ event.name }}
                      </div>
                    </template>
                    <span>
                      {{ event.name }}
                    </span>
                  </v-tooltip>
                </div>
              </template>
            </v-calendar>
            <calendar-event :selected-options="selectedOptions" />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-toolbar color="tertiary" height="32px" />
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_EVENTS } from '@/graphql/EventQueries'
import CalendarEvent from '@/components/helper/CalendarEvent'
import { dateIso8601ToDate } from '@/locales/formats/dataTimeFormats'
import moment from 'moment'

export default {
  name: 'ProjectDrawerCalendar',
  components: {
    CalendarEvent,
  },
  apollo: {
    events: {
      query: GET_EVENTS,
      variables() {
        return {
          where: {
            project_uuid: {
              _eq: this.currentProjectId,
            },
          },
        }
      },
      update(data) {
        const events = data.event.map((item) => {
          item.start = moment(item.start).format('YYYY-MM-DD HH:mm')
          item.end = moment(item.end).format('YYYY-MM-DD HH:mm')
          return item
        })
        return events
      },
      skip() {
        return !this.currentProjectId
      },
    },
  },
  data() {
    return {
      menu: false,
      LocalDate: new Date(),
      selectedOptions: {
        date: null,
        isDrawer: true,
        position: {
          x: 0,
          y: 0,
        },
        selectedEvent: {},
        selectedOpen: false,
      },
      showForm: false,
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentDrawer: 'project/currentDrawer',
    }),
    datePicker: {
      get() {
        return moment(this.LocalDate).format('YYYY-MM-DD')
      },
      set() {
        this.LocalDate = moment(this.LocalDate).format('YYYY-MM-DD HH:mm')
      },
    },
    dateProps() {
      const obj = {
        firstDayOfWeek: this.userCurrentLang === 'fr' ? 1 : 0,
        locale: this.userCurrentLang,
      }
      return obj
    },
    displayDrawer: {
      get() {
        return this.currentDrawer === 'calendar'
      },
      set(newName) {
        return newName
      },
    },
    isNow() {
      let now = new Date()
      now = dateIso8601ToDate(now)
      const dateSelected = dateIso8601ToDate(this.LocalDate)
      return now === dateSelected
    },
    userCurrentLang() {
      return this.$store.getters['auth/user']?.profile?.lang || 'fr'
    },
  },
  watch: {
    LocalDate: function (val) {
      this.selectedOptions.date = dateIso8601ToDate(val)
    },
    $route: {
      immediate: true,
      handler(to) {
        if (Object.prototype.hasOwnProperty.call(to.params, 'date')) {
          this.LocalDate = to.params.date ? moment(to.params.date)._d : new Date()
        }
      },
    },
  },
  mounted() {
    this.$refs.calendar.scrollToTime('06:55')
  },
  methods: {
    eventIndicators(date) {
      // Get event category color by date and by category
      const mapDate = this.events.map((i) => {
        if (
          (i.event_category.name === 'Alerte - Alarme' && date === i.start.split(' ')[0]) ||
          (i.event_category.name === 'Rapport' &&
            date >= i.start.split(' ')[0] &&
            date <= i.end.split(' ')[0])
        ) {
          return i.event_category.color
        }
        return null
      })

      // Get uniq event category color by date and not null
      const eventColor = mapDate.filter((v, i, a) => a.indexOf(v) === i && v !== null)

      return eventColor.length > 0 ? eventColor : false
    },
    getEventColor(event) {
      return event.event_category.color
    },
    goCalendar() {
      this.$router.push({
        name: 'Calendar',
        params: {
          date: this.selectedOptions.date,
        },
      })
    },
    save(date) {
      this.LocalDate = moment(date, 'YYYY-MM-DD HH:mm').toDate()
      this.$refs.menu.save(this.LocalDate)
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedOptions.selectedEvent = event
        setTimeout(() => {
          this.selectedOptions.position.x = nativeEvent.clientX - 300
          this.selectedOptions.position.y = nativeEvent.clientY + 10
          this.selectedOptions.selectedOpen = true
        }, 10)
      }

      if (this.selectedOptions.selectedOpen) {
        this.selectedOptions.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },
}
</script>
