<template lang="html">
  <v-dialog v-model="show" class="ma-0 pa-0" max-width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t('user.sendInvitationTitle') }}
      </v-card-title>
      <v-card-text class="pa-0">
        <v-stepper v-model="e1" light>
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" editable step="1">
              {{ $tc('common.user', 1) }}
            </v-stepper-step>

            <v-divider />

            <v-stepper-step :complete="e1 > 2" editable step="2">
              {{ $t('user.rights') }}
            </v-stepper-step>

            <v-divider />

            <v-stepper-step editable step="3">
              {{ $tc('common.project', 1) }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <form-error
                      :validator="$v.invitation.firstname"
                      :attribute="$t('contact.firstname')"
                      :messages="messages"
                    >
                      <v-text-field
                        v-model="invitation.firstname"
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        :label="$t('contact.firstname') + ' *'"
                        required
                        @input="$v.invitation.firstname.$touch()"
                        @blur="$v.invitation.firstname.$touch()"
                      />
                    </form-error>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <form-error
                      :validator="$v.invitation.lastname"
                      :attribute="$t('contact.lastname')"
                      :messages="messages"
                    >
                      <v-text-field
                        v-model="invitation.lastname"
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        :label="$t('contact.lastname') + ' *'"
                        required
                        @input="$v.invitation.lastname.$touch()"
                        @blur="$v.invitation.lastname.$touch()"
                      />
                    </form-error>
                  </v-col>
                  <v-col cols="12">
                    <form-error
                      :validator="$v.invitation.options.company"
                      :attribute="$tc('common.company', 1)"
                      :messages="messages"
                    >
                      <company-selector
                        v-model="invitation.options.company"
                        slot-scope="{ attrs }"
                        v-bind="attrs"
                        @input="$v.invitation.options.company.$touch()"
                        @blur="$v.invitation.options.company.$touch()"
                      />
                    </form-error>
                  </v-col>
                  <v-col cols="12">
                    <form-error
                      :validator="$v.invitation.email"
                      :attribute="$t('common.email')"
                      :messages="messages"
                    >
                      <v-text-field
                        v-model="invitation.email"
                        slot-scope="{ attrs }"
                        :label="$t('common.email') + ' *'"
                        v-bind="attrs"
                        required
                        @input="$v.invitation.email.$touch()"
                        @blur="$v.invitation.email.$touch()"
                      />
                    </form-error>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn color="blue darken-1" text @click="nextStep(2)">
                      {{ $t('common.continue') }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="show = false">
                      {{ $t('common.close') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-row>
                <v-col cols="12">
                  <form-error
                    :validator="$v.invitation.options.userRoleDefault"
                    :attribute="$t('user.roleDefaultSelect')"
                    :messages="messages"
                  >
                    <v-select
                      v-model="invitation.options.userRoleDefault"
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      :items="role"
                      item-text="role"
                      item-value="uuid"
                      :label="$t('user.roleDefaultSelect') + ' *'"
                      chips
                      persistent-hint
                      @input="$v.invitation.options.userRoleDefault.$touch()"
                      @blur="$v.invitation.options.userRoleDefault.$touch()"
                    />
                  </form-error>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="invitation.options.userRoles"
                    :items="rolesComplementaryList"
                    item-text="role"
                    item-value="uuid"
                    :label="$t('user.roleComplementarySelect')"
                    chips
                    multiple
                    persistent-hint
                  />
                  <v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn color="blue darken-1" text @click="nextStep(3)">
                        {{ $t('common.continue') }}
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="show = false">
                        {{ $t('common.close') }}
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-row>
                <v-col cols="12">
                  <form-error
                    :validator="$v.invitation.options.projectDefault"
                    :attribute="$t('user.projectDefaultSelect')"
                    :messages="messages"
                  >
                    <v-select
                      v-model="invitation.options.projectDefault"
                      slot-scope="{ attrs }"
                      v-bind="attrs"
                      :items="project"
                      item-text="name"
                      item-value="id"
                      :label="$t('user.projectDefaultSelect') + ' *'"
                      chips
                      persistent-hint
                      @input="$v.invitation.options.projectDefault.$touch()"
                      @blur="$v.invitation.options.projectDefault.$touch()"
                    />
                  </form-error>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="invitation.options.projects"
                    :items="projectsComplementaryList"
                    item-text="name"
                    item-value="id"
                    :label="$t('user.projectComplementarySelect')"
                    chips
                    multiple
                    persistent-hint
                  />
                  <v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn color="blue darken-1" text @click="sendInvitation">
                        {{ $t('common.send') }}
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="show = false">
                        {{ $t('common.close') }}
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CompanySelector from '@/components/helper/CompanySelector'
import { POST_INVITATION } from '@/graphql/AuthQueries'
import { GET_ROLES } from '@/graphql/RoleQueries'
import { GET_PROJECTS } from '@/graphql/ProjectQueries'
import { required, email } from 'vuelidate/lib/validators'
import { alphaExtended } from '@/components/validators'

export default {
  name: 'InvitationDialog',
  components: {
    CompanySelector,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    role: {
      query: GET_ROLES,
      variables: {
        order_by: { role: 'asc' },
      },
    },
    project: {
      query: GET_PROJECTS,
    },
  },
  data: function () {
    return {
      invitationDefault: {
        firstname: '',
        lastname: '',
        email: '',
        options: {
          company: null,
          userRoleDefault: null,
          userRoles: [],
          projectDefault: null,
          projects: [],
        },
      },
      invitation: {
        options: {},
      },
      e1: 1,
      role: [],
      rolesComplementaryList: [],
      project: [],
      projectsComplementaryList: [],
    }
  },
  validations: {
    invitation: {
      email: { required, email },
      firstname: { required, alphaExtended },
      lastname: { required, alphaExtended },
      options: {
        company: { required },
        userRoleDefault: { required },
        projectDefault: { required },
      },
    },
    validationGroup: {
      1: [
        'invitation.firstname',
        'invitation.lastname',
        'invitation.options.company',
        'invitation.email',
      ],
      2: ['invitation.options.userRoleDefault'],
      3: ['invitation.options.projectDefault'],
    },
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.invitation = Object.assign({}, this.invitationDefault)
        this.$v.$reset()
      }
    },
    invitation: {
      deep: true,
      handler: function (newVal) {
        this.rolesComplementaryList = this.role.filter(
          (role) => role.uuid !== newVal.options.userRoleDefault
        )
        this.projectsComplementaryList = this.project.filter(
          (project) => project.id !== newVal.options.projectDefault
        )
      },
    },
  },
  methods: {
    async sendInvitation() {
      // await this.$apollo.mutate({
      //   mutation: INSERT_USER_ONE,
      //   variables: {
      //     object: {
      //       id: '',
      //       email: this.invitation.email,
      //       default_role_uuid: this.invitation.userRoleDefault,
      //       default_project_uuid: this.invitation.projectDefault,
      //       user_projects: { data: this.invitation.projects.map(id => { return { project_uuid: id } }) },
      //       user_roles: { data: this.invitation.userRoles.map(uuid => { return { role_uuid: uuid } }) },
      //       crm_contact: { data: { firstname: this.invitation.firstname, lastname: this.invitation.lastname } },
      //     },
      //   },
      // }).catch((error) => {
      //   this.$root.$dialogLoader.showSnackbar('create POST_USER: ' + error.message, { color: 'error' })
      //   throw error
      // })
      if (this.$v.$invalid) {
        return
      }
      this.finalInvit = JSON.parse(JSON.stringify(this.invitation))
      this.finalInvit.options.company = this.finalInvit.options.company.uuid
      await this.$apollo
        .mutate({
          mutation: POST_INVITATION,
          variables: {
            invitation: this.finalInvit,
          },
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar('create POST_INVITATION: ' + error.message, {
            color: 'error',
          })
          throw error
        })

      this.show = false
    },
    nextStep(step) {
      this.$v.validationGroup[step - 1].$touch()
      if (!this.$v.validationGroup[step - 1].$invalid) {
        this.e1 = step
      }
    },
  },
}
</script>
