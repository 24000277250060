<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" md="6">
        <v-data-table
          :headers="headers"
          :items="fieldConfig.list"
          fixed-header
          height="300px"
          dense
          hide-default-footer
        >
          <template #[`item.actions`]="{ item }">
            <v-icon small @click="deleteValue(item)"> $iconDelete </v-icon>
          </template>
          <template #footer>
            <form-error
              :validator="$v.newvalue"
              :attribute="$t('common.newValue')"
              :messages="messages"
            >
              <v-text-field
                v-model="newvalue"
                slot-scope="{ attrs }"
                v-bind="attrs"
                :label="$t('common.newValue')"
                clearable
                append-outer-icon="mdi-plus"
                @click:append-outer="addNewValue"
                @keydown.enter="addNewValue"
                @input="$v.newvalue.$touch()"
                @blur="$v.newvalue.$touch()"
              />
            </form-error>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="6">
        <v-checkbox
          v-model="localFieldConfig.multiple"
          class="mx-2"
          label="Multiple"
          @change="localDefaultValue.value = []"
        />
        <v-select
          v-model="localDefaultValue.value"
          :items="fieldConfig.list"
          item-text="name"
          item-index="name"
          :label="$t('common.defaultValue')"
          :multiple="fieldConfig.multiple"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const isin = (value, vm) => {
  return !vm.fieldConfig.list.some((item) => item.name === value)
}

export default {
  name: 'SimpleListFieldConfig',
  props: {
    fieldConfig: {
      type: Object,
      default() {
        return {
          list: [{ name: 'item1' }, { name: 'item2' }],
          multiple: false,
        }
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {
          value: ['item1'],
        }
      },
    },
  },
  data() {
    return {
      newvalue: '',
      headers: [
        {
          text: 'Entrées',
          sortable: false,
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      localDefaultValue: this.defaultValue,
      localFieldConfig: this.fieldConfig,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    fieldConfig: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.fieldConfig,
          defaultValue: this.defaultValue,
        })
      },
    },
    defaultValue: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.fieldConfig,
          defaultValue: this.defaultValue,
        })
      },
    },
  },
  validations() {
    return {
      newvalue: {
        isin,
      },
    }
  },
  mounted() {
    this.$emit('input', {
      fieldConfig: this.fieldConfig,
      defaultValue: this.defaultValue,
    })
  },
  methods: {
    addNewValue() {
      if (this.$v.$invalid) return
      this.localFieldConfig.list.push({ name: this.newvalue })
      this.newvalue = ''
    },
    deleteValue(item) {
      this.localFieldConfig.list = this.fieldConfig.list.filter((i) => i.name !== item.name)
    },
  },
}
</script>

<style lang="scss">
.rolloveroff:hover {
  background-color: transparent !important;
}
</style>
