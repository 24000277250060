<template>
  <v-container fluid class="mx-auto pa-0">
    <v-card class="mx-auto pa-0">
      <v-card-text v-if="!isLoading" class="px-0">
        <div v-for="(graph, i) in localeGraphs" :key="i" class="mb-4">
          <apex-charts
            v-if="graph.options.series[0].data"
            height="250"
            width="100%"
            :options="graph.options"
            :series="graph.options.series"
          />
          <span v-else>
            {{ $t('common.noDataText') }}
          </span>
        </div>
      </v-card-text>
      <v-card-text v-else class="px-0">
        {{ $t('common.loadingData') }}
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { SensorMixin } from '@/mixins/SensorMixin'
import VueApexCharts from 'vue-apexcharts'
import en from 'apexcharts/dist/locales/en.json'
import fr from 'apexcharts/dist/locales/fr.json'

export default {
  name: 'GraphReportVersion',
  components: {
    apexCharts: VueApexCharts,
  },
  mixins: [SensorMixin],
  props: {
    graphs: {
      type: Array,
      default: () => {
        return []
      },
    },
    issue: {
      type: [String, Number],
      default: -1,
    },
    versionId: {
      type: [String, Number],
      default: -1,
    },
  },
  data() {
    return {
      isLoading: true,
      localeGraphs: [],
      metrics: [],
      optionsDefault: {
        chart: {
          id: 'chart.id',
          animations: {
            enabled: false,
          },
          defaultLocale: 'fr',
          height: '250px',
          locales: [en, fr],
          selection: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: 0,
        },
        stroke: {
          width: 1,
        },
        subtitle: {
          align: 'right',
          margin: -20,
          offsetX: -30,
          offsetY: 15,
          text: 'subtitle.text',
        },
        title: {
          align: 'left',
          offsetX: -7,
          style: {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 600,
          },
          text: 'title.text',
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          labels: {
            datetimeUTC: false,
          },
          tooltip: {
            enabled: false,
          },
          type: 'datetime',
        },
        yaxis: {
          decimalsInFloat: 4,
          forceNiceScale: false,
          max: 10,
          min: -10,
          tickAmount: 5,
          title: {
            text: 'Unité de mesure : ' + 'yaxis.title.text ch_unit',
          },
        },
      },
    }
  },
  watch: {
    localeGraphs: function (val) {
      if (val.length > 0) this.isLoading = false
    },
    metrics: function (val) {
      if (val.length > 0) this.updateGraphs()
    },
  },
  mounted() {
    if (this.graphs.length > 0) this.getSensorMetrics()
  },
  methods: {
    async updateGraphs() {
      const issueVersionGraphs = this.graphs
      const newGraphs = []

      issueVersionGraphs.forEach((element, index) => {
        const metricsSeries = []
        if (element.optionsParams?.refId) {
          metricsSeries[index] = {
            name: issueVersionGraphs[index].optionsParams.device_name,
            data: this.metrics[index],
          }

          const deviceName = element.optionsParams?.device_name
            ? element.optionsParams.device_name
            : 'deviceName'
          const chartId =
            element.optionsParams?.refId && element.optionsParams?.device_name
              ? element.optionsParams.refId + '_' + deviceName
              : 'chart_' + index
          const subtitleText = element.optionsParams?.subtitleText
            ? element.optionsParams.subtitleText
            : ''
          const titleText = element.optionsParams?.titleText ? element.optionsParams.titleText : ''
          const yaxisMax = element.optionsParams?.yaxisMax ? element.optionsParams.yaxisMax : 500
          const yaxisMin = element.optionsParams?.yaxisMin ? element.optionsParams.yaxisMin : -500
          const yaxisTitleText = element.optionsParams?.yaxisTitleText
            ? element.optionsParams.yaxisTitleText
            : ''
          // Replace space:space by _
          let filename = titleText.replace(/(\s:\s)/gs, '_')
          // Replace space-space by -
          filename = filename.replace(/(\s-\s)/gs, '-')
          // Replace space by _
          filename =
            this.issue + '.' + this.versionId + '.' + index + '-' + filename.replace(/\s/gs, '_')

          newGraphs[index] = {
            issueId: this.issue,
            versionId: this.versionId,
            graph: index,
            options: Object.assign({}, this.optionsDefault, {
              annotations: {
                yaxis: element.optionsParams?.annotationsYaxis || [],
              },
              chart: {
                id: chartId,
                animations: {
                  enabled: false,
                },
                defaultLocale: 'fr',
                height: '250px',
                locales: [en, fr],
                selection: {
                  enabled: true,
                },
                toolbar: {
                  show: true,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: true,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                  },
                  export: {
                    csv: {
                      filename: filename,
                      headerCategory: 'Date',
                    },
                    svg: {
                      filename: filename,
                    },
                    png: {
                      filename: filename,
                    },
                  },
                },
                type: 'line',
                width: '100%',
                zoom: {
                  enabled: true,
                },
              },
              subtitle: {
                align: 'right',
                margin: -20,
                offsetX: -135,
                offsetY: 15,
                text: subtitleText,
              },
              title: {
                align: 'left',
                offsetX: -7,
                style: {
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: 600,
                },
                text: titleText,
              },
              tooltip: {
                enable: true,
                followCursor: true,
                x: {
                  format: 'dd/MM/yyyy HH:mm:ss',
                },
              },
              yaxis: {
                decimalsInFloat: 4,
                forceNiceScale: false,
                tickAmount: 5,
                max: yaxisMax,
                min: yaxisMin,
                title: {
                  text: yaxisTitleText,
                },
              },
              series: [metricsSeries[index]],
            }),
          }
        }
      })

      this.localeGraphs = newGraphs
    },
    async getSensorMetrics() {
      const arr = []
      this.graphs.forEach((el) => {
        arr.push({
          deviceUuid: el.optionsParams?.device_id,
          dateTimeFrom: el.optionsParams?.dateTimeFrom,
          dateTimeTo: el.optionsParams?.dateTimeTo,
          projectUuid: this.$store.getters['project/currentProjectId'],
        })
      })

      const arrLength = arr.length
      for (let i = 0; i < arrLength; i++) {
        const data = await this.GetSensorAllMetrics(arr[i])
        this.metrics.push(data[0])
      }
    },
  },
}
</script>
