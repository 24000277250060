<template>
  <component
    :is="fieldComponent"
    v-if="fieldComponent"
    :field-config="fieldConfig"
    :default-value="defaultValue"
    :field-label="fieldLabel"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'FieldLoader',
  props: {
    type: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default: () => {},
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
    fieldLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    fieldComponent() {
      const type = this.type
      return () => import(`@/components/formfields/${type}Field`)
    },
  },
}
</script>
