<template>
  <v-chip class="ma-2" :color="colors[stateValue]" text-color="white" small>
    {{ $t(`alert.stateEnum.${stateValue}`) }}
  </v-chip>
</template>

<script>
export default {
  name: 'AlertStateChip',
  props: {
    stateValue: {
      type: String,
      default: 'normal',
    },
  },
  data: () => ({
    colors: {
      normal: 'green',
      alerting: 'red',
      pending: 'orange',
      nodata: 'grey',
      error: 'deep-orange',
    },
  }),
}
</script>

<style scoped>
</style>

