<template>
  <component
    :is="fieldComponent"
    v-if="fieldComponent"
    :field-config="fieldConfig"
    :default-value="defaultValue"
    @input="$emit('input', $event)"
  />
</template>
<script>
export default {
  name: 'ConfigFieldLoader',
  props: {
    type: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default: () => {},
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    fieldComponent() {
      if (!this.type) {
        return () => import('@/components/formfields/ConfigDefaultFieldLoader')
      }
      return () => import(`@/components/formfields/${this.type}FieldConfig`)
    },
  },
}
</script>
