<template lang="html">
  <v-data-table
    :headers="headersParamsConfig"
    :items="parametersList"
    dense
    fixed-header
    :footer-props="{
      'items-per-page-text': itemsPerPageText,
      'page-text': pageText,
      'items-per-page-options': [5, 10, 25, 50, -1],
    }"
    :height="StyleHeightCalc('64px + 64px + 48px + 14px').height"
    :no-data-text="$t('common.noDataText')"
  >
    <template #top>
      <v-toolbar flat color="white">
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t('common.settingsDevice') }} ({{ target }})
        </div>
        <v-spacer />
        <config-property-selector
          :lib-device-tags="libDeviceTags"
          :target="target"
          @close="close"
          @selected="addParameter"
        />
      </v-toolbar>
    </template>
    <template #[`item.order`]="{ item }">
      <v-text-field
        v-model="item.order"
        type="number"
        style="width: 70px"
        dense
        @input="updateParameterValue(item.id, 'order', $event)"
      />
    </template>
    <template #[`item.default_value`]="{ item }">
      <field-loader
        :type="item.lib_device_parameter.field_type"
        :default-value="item.default_value"
        :field-config="item.lib_device_parameter.field_config"
        :name="
          $t('common.defaultValueThis', {
            attribute: item.lib_device_parameter.name.toLowerCase(),
          })
        "
        dense
        @input="updateParameterValue(item.id, 'default_value', $event.value)"
      />
    </template>
    <template #[`item.lib_device_parameter.field_type`]="{ item }">
      <span>{{ getConfigParamLabel(item.lib_device_parameter.field_type) }}</span>
    </template>
    <template #[`item.action`]="{ item }">
      <v-icon small @click="deleteParameter(item)">$iconDelete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import ConfigPropertySelector from '@/components/device_library/ConfigPropertySelector'
import FieldLoader from '@/components/formfields/FieldLoader'
import {
  GET_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
  POST_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
  UPDATE_LIB_DEVICES_LIB_DEVICE_PARAMETERS_BY_PK,
  DELETE_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
} from '@/graphql/libDevicesLibDeviceParametersQueries'
import { CommonMixin } from '@/mixins/CommonMixin'

export default {
  name: 'DeviceConfigurator',
  components: {
    ConfigPropertySelector,
    FieldLoader,
  },
  mixins: [CommonMixin],
  props: {
    deviceUuid: {
      type: String,
      default: '',
    },
    libDeviceTags: {
      type: Array,
      default: () => [],
    },
    target: {
      type: String,
      default: 'device',
    },
  },
  apollo: {
    deviceParameters: {
      query: GET_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
      variables() {
        const vars = {
          where: {
            lib_device_uuid: {
              _eq: this.deviceUuid,
            },
            lib_device_parameter: {
              target: {
                _eq: this.target,
              },
            },
          },
          order_by: { order: 'asc' },
        }
        return vars
      },
      skip() {
        return this.deviceUuid === ''
      },
      update(data) {
        return data.lib_devices_lib_device_parameters
      },
      result({ data }) {
        if (data) {
          this.totalCount = data.lib_devices_lib_device_parameters_aggregate.aggregate.totalCount
        }
      },
    },
  },
  data: () => ({
    deviceParameters: [],
    dialog: false,
    options: {
      itemsPerPage: 10,
      sortBy: ['order'],
      sortDesc: [true],
      filter: {},
    },
  }),
  computed: {
    configPropertyType() {
      const componentFields = require.context(
        '@/components/formfields',
        true,
        /^.*FieldConfig\.vue$/
      )

      const formfields = componentFields.keys().map((filename) => {
        const field = {}
        field.component = filename
        field.name = filename.replace('FieldConfig.vue', '').replace('./', '')
        field.label = this.$t(`libDeviceParameter.fieldtype.${field.name.toLowerCase()}`)

        return field
      })

      return formfields
    },
    headersParamsConfig: function () {
      return [
        { text: this.$t('common.order'), value: 'order' },
        { text: 'Param Cfg', value: 'lib_device_parameter.config_label' },
        { text: this.$t('common.name'), value: 'lib_device_parameter.name' },
        {
          text: this.$t('common.description'),
          value: 'lib_device_parameter.description',
        },
        { text: this.$t('common.defaultValue'), value: 'default_value' },
        {
          text: this.$tc('common.action', 2),
          value: 'action',
          sortable: false,
        },
      ]
    },
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
    parametersList() {
      return this.deviceParameters
    },
  },
  methods: {
    close() {
      this.dialog = false
    },
    getConfigParamLabel(name) {
      const index = this.configPropertyType.findIndex(function (item) {
        return item.name === name
      })
      return this.configPropertyType[index].label
    },
    getQueryVariables() {
      const vars = {
        where: { lib_device_uuid: { _eq: this.deviceUuid } },
        order_by: { order: 'asc' },
      }
      return vars
    },
    selectDeviceParameterDefaultValueBoolean(event) {
      if (event.lib_device_parameter.field_type === 'Boolean') {
        const newParameter = {
          id: event.id,
          item: 'default_value',
          order: '',
          default_value: {
            value: event.default_value.value,
          },
        }
        this.updateParameter(newParameter)
      }
    },
    updateParameterValue(itemId, item, value) {
      const order = item === 'order' ? value : ''
      const defaultValue = item === 'default_value' ? value : ''
      const newParameter = {
        id: itemId,
        item: item,
        order: order,
        default_value: {
          value: defaultValue,
        },
      }
      this.updateParameter(newParameter)
    },
    async addParameter(parameter) {
      await this.create(parameter)
    },
    async create(parameter) {
      await this.$apollo
        .mutate({
          mutation: POST_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
          variables: {
            object: {
              lib_device_uuid: this.deviceUuid,
              lib_device_parameter_id: parameter.id,
              default_value: parameter.default_value,
            },
          },
          // eslint-disable-next-line
          update: (cache, { data: { insert_lib_devices_lib_device_parameters_one } }) => {
            const libDevicesLibDeviceDarameter = cache.readQuery({
              query: GET_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
              variables: this.getQueryVariables(),
            })
            libDevicesLibDeviceDarameter.lib_devices_lib_device_parameters.push(
              insert_lib_devices_lib_device_parameters_one
            )
            libDevicesLibDeviceDarameter.lib_devices_lib_device_parameters_aggregate.aggregate
              .totalCount++
            cache.writeQuery({
              query: GET_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
              variables: this.getQueryVariables(),
              data: libDevicesLibDeviceDarameter,
            })
          },
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'update POST_LIB_DEVICES_LIB_DEVICES_PARAMETERS ' + error.message,
            { color: 'error' }
          )
          throw error
        })
    },
    async deleteParameter(item) {
      const removal = this.$t('common.removalFormatting', {
        attribute: item.lib_device_parameter.name,
      })
      const sureToDeleteThis = this.$t('common.sureToDeleteThis', {
        attribute: item.lib_device_parameter.name,
      })
      if (await this.$root.$confirm(removal, sureToDeleteThis, { color: 'red' })) {
        const rv = this.$apollo
          .mutate({
            mutation: DELETE_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
            variables: {
              id: item.id,
            },
            /* eslint-disable */
            update: (cache, { data: { delete_lib_devices_lib_device_parameters } }) => {
              if (delete_lib_devices_lib_device_parameters.affected_rows) {
                const data = cache.readQuery({
                  query: GET_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
                  variables: this.getQueryVariables(),
                })
                data.lib_devices_lib_device_parameters = data.lib_devices_lib_device_parameters.filter(t => {
                  return t.id !== item.id
                })
                cache.writeQuery({
                  query: GET_LIB_DEVICES_LIB_DEVICES_PARAMETERS,
                  variables: this.getQueryVariables(),
                  data,
                })
              }
            },
            /* eslint-enable */
          })
          .then((data) => {
            return data
          })
          .catch((error) => {
            throw error
          })
        if (rv) {
          const removal = this.$t('common.removalFormatting', {
            attribute: item.lib_device_parameter.name,
          })
          this.$root.$dialogLoader.showSnackbar(removal, { color: 'success' })
        }
      }
    },
    async updateParameter(newParameterValue) {
      let newSet = null

      if (newParameterValue.item === 'order') {
        newSet = {
          order: newParameterValue.order,
        }
      }

      if (newParameterValue.item === 'default_value') {
        newSet = {
          default_value: newParameterValue.default_value,
        }
      }

      await this.$apollo
        .mutate({
          mutation: UPDATE_LIB_DEVICES_LIB_DEVICE_PARAMETERS_BY_PK,
          variables: {
            id: newParameterValue.id,
            _set: newSet,
          },
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'update UPDATE_LIB_DEVICES_LIB_DEVICE_PARAMETERS_BY_PK ' + error.message,
            { color: 'error' }
          )
          throw error
        })
    },
  },
}
</script>
