<template>
  <form-error :validator="$v.finalValue" :attribute="name" :messages="messages">
    <v-text-field
      v-model="finalValue"
      slot-scope="{ attrs }"
      v-bind="Object.assign({}, $attrs, attrs)"
      @input="$v.finalValue.$touch()"
      @blur="$v.finalValue.$touch()"
    />
  </form-error>
</template>

<script>
import { url } from 'vuelidate/lib/validators'

export default {
  name: 'UrlField',
  props: {
    name: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data: function () {
    return {
      finalValue: this.defaultValue.value,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    finalValue: {
      deep: true,
      handler() {
        this.$emit('input', { value: this.finalValue })
      },
    },
  },
  validations() {
    return {
      finalValue: { url },
    }
  },
}
</script>
