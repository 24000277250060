var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',_vm._g(_vm._b({attrs:{"items":_vm.eventCategories,"label":_vm.localLabel,"readonly":_vm.isReadonly,"dense":"","item-text":"name","item-value":"id","item-color":"color"},on:{"change":_vm.selectItem},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-avatar',{attrs:{"size":"15","color":data.item.color}}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}},{key:"item",fn:function(data, index){return [(typeof data.item !== 'object')?[_c('v-list-item-content',[_vm._v(" "+_vm._s(data.item)+" ")])]:[_c('v-list-item-avatar',{attrs:{"size":"15","color":data.item.color}}),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1),_c('v-spacer'),_c('v-list-item-action',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, data)}}},[_c('v-icon',[_vm._v("$iconEdit")])],1)],1)]]}},(!_vm.isReadonly)?{key:"append-outer",fn:function(){return [_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$iconPlus")])],1)]}}],null,false,547530636),model:{value:(_vm.dialogCategory),callback:function ($$v) {_vm.dialogCategory=$$v},expression:"dialogCategory"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$tc('common.category', 1))+" :")])]),_c('v-card-text',[_c('v-container',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('form-error',{attrs:{"validator":_vm.$v.category.name,"attribute":_vm.$t('common.name'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"label":_vm.$t('common.name') + ' *'},on:{"input":function($event){return _vm.$v.category.name.$touch()},"blur":function($event){return _vm.$v.category.name.$touch()}},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}},'v-text-field',attrs,false))}}],null,false,1555245119)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('form-error',{attrs:{"validator":_vm.$v.category.color,"attribute":_vm.$t('calendar.color'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"hide-details":"","label":_vm.$t('calendar.color'),"outlined":""},on:{"input":function($event){return _vm.$v.category.color.$touch()},"blur":function($event){return _vm.$v.category.color.$touch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.swatchStyle)},on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.category.color),callback:function ($$v) {_vm.$set(_vm.category, "color", $$v)},expression:"category.color"}})],1)],1)],1)]},proxy:true}],null,false,326573150),model:{value:(_vm.category.color),callback:function ($$v) {_vm.$set(_vm.category, "color", $$v)},expression:"category.color"}},'v-text-field',attrs,false))}}],null,false,2804048139)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.saveForm()}}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])],1)],1)],1)]},proxy:true}:null],null,true),model:{value:(_vm.localId),callback:function ($$v) {_vm.localId=$$v},expression:"localId"}},'v-select',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }