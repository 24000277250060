import { mapGetters } from 'vuex'
import { GET_PROJECT } from '@/graphql/ProjectQueries'
import { GET_PROJECT_DEVICES } from '@/graphql/ProjectDeviceQueries'
import { GET_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS } from '@/graphql/ProjectDevicesProjectDeviceParameters'
import { GET_PROJECT_VIEW, GET_PROJECT_VIEWS } from '@/graphql/ProjectViewQueries'

export const ProjectMixin = {
  apollo: {
    project_by_pk: {
      query: GET_PROJECT,
      variables() {
        return {
          id: this.currentProjectId,
        }
      },
      update(data) {
        return data?.project_by_pk
      },
      skip() {
        return !this.currentProjectId
      },
    },
    project_device: {
      query: GET_PROJECT_DEVICES,
      variables() {
        return {
          where: {
            project_id: {
              _eq: this.currentProjectId,
            },
          },
          order_by: {
            name: 'asc',
          },
        }
      },
      update(data) {
        return data?.project_device
      },
      skip() {
        return !this.currentProjectId
      },
    },
    project_view: {
      query: GET_PROJECT_VIEWS,
      variables() {
        return {
          where: {
            project_uuid: {
              _eq: this.currentProjectId,
            },
          },
          order_by: {
            name: 'asc',
          },
        }
      },
      update(data) {
        return data?.project_view
      },
      skip() {
        return !this.currentProjectId
      },
    },
    project_view_by_pk: {
      query: GET_PROJECT_VIEW,
      variables() {
        return {
          uuid: this.currentView.uuid,
        }
      },
      update(data) {
        return data?.project_view_by_pk
      },
      skip() {
        return !this.currentView.uuid
      },
    },
  },
  data() {
    return {
      ProjectDevices: [],
      ProjectDevicesUpdate: false,
      ProjectDevicesParameters: [],
      ProjectName: '?',
      ProjectView: null,
      ProjectViews: [],
      VarsDeviceParameters: {},
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentView: 'project/currentView',
    }),
  },
  created() {
    this.GetProjectDevicesParameters()
  },
  watch: {
    project_by_pk: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.ProjectName = val.name
        }
      },
      deep: true,
    },
    project_device: function (val, oldVal) {
      if (val?.length !== oldVal?.length) {
        this.ProjectDevices = val
        this.ProjectDevicesUpdate = false
      }
    },
    ProjectDevicesUpdate: function (val) {
      if (val) {
        setTimeout(() => {
          this.$apollo.queries.project_device.refresh()
        }, 500)
      }
    },
    project_view: {
      handler: function (val, oldVal) {
        if (val !== oldVal) this.ProjectViews = val
      },
      deep: true,
    },
    project_view_by_pk: {
      handler: function (val, oldVal) {
        if (val !== oldVal) this.ProjectView = val
      },
      deep: true,
    },
    VarsDeviceParameters: {
      handler: function (val, oldVal) {
        if (val !== oldVal) this.GetProjectDevicesParameters(val)
      },
      deep: true,
    },
  },
  methods: {
    /**
     * Returns the list of sensor or hub configured for a control that
     * provides a list of options (select)
     * @param {string} device sensor || hub
     * @param {array} [projectDevices] project devices
     * @return {array}
     */
    GetDeviceList(device, projectDevices) {
      const listTemp = []
      let list = []
      let listSensor = []

      projectDevices = projectDevices || this.ProjectDevices

      projectDevices?.filter((key) => {
        if (key.device_type === device) {
          listTemp.push({
            uuid: key.id,
            name: key.name,
            device_type: key.device_type,
            hub_ref:
              key.project_device2_links.length > 0
                ? key.project_device2_links[0].project_device1
                : key.name,
            crm_company_uuid: key.config_fields?.crm_company_uuid,
          })
        }
        return null
      })

      if (device !== 'hub' && listTemp.length > 0) {
        listSensor = listTemp.map((key) => ({
          uuid: key.uuid,
          name: key.name,
          device_type: key.device_type,
          device_select: key.name + ' (' + key.hub_ref.name + ')',
          hub_ref_name: key.hub_ref.name,
          hub_ref_uuid: key.hub_ref.id,
        }))
      }

      list = device === 'hub' ? listTemp : listSensor

      return list
    },
    /**
     * Returns the value of project device parameter
     * @param {string} deviceUuid
     * @param {string} param name of parameter from config_label
     * @return {*}
     */
    GetProjectDeviceParameter(deviceUuid, param) {
      const search = this.ProjectDevicesParameters?.find(
        (i) =>
          i.project_device_uuid === deviceUuid && i.project_device_parameter.config_label === param
      )
      return search ? search.parameter_value.value : null
    },
    async GetProjectDevicesParameters(vars) {
      if (this.currentProjectId) {
        if (!vars) {
          vars = {}
        }

        let result = []

        await this.$apollo
          .query({
            query: GET_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS,
            variables: vars,
          })
          .then((res) => {
            result = res
          })
          .catch((err) => {
            throw err
          })

        this.ProjectDevicesParameters = result?.data?.project_devices_project_device_parameters
      }
    },
  },
}
