<template>
  <v-navigation-drawer
    id="project-drawer-crm-contacts"
    v-model="displayDrawer"
    class="mt-8"
    color="tertiary"
    height="calc(100% - (32px * 2))"
    width="292px"
    absolute
    right
    stateless
  >
    <v-container>
      <v-row v-if="!search" class="align-center">
        <div class="text-overline">
          <span class="text-uppercase pl-3">
            {{ $tc('contact.contact', 2) }}
          </span>
          <span v-if="displayList">({{ totalContacts }})</span>
        </div>
        <v-spacer />
        <v-tooltip v-if="!displayList" left>
          <template #activator="{ on }">
            <v-btn icon small class="mr-2" @click="displayList = true" v-on="on">
              <v-icon>$iconArrowLeft2</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.back') }}</span>
        </v-tooltip>
        <div v-if="displayList">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon small @click="displaySearch()" v-on="on">
                <v-icon>$iconMagnify</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('common.search') }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn icon small class="mr-2" @click="$router.push('/crm-contact')" v-on="on">
                <v-icon>$iconOpenInNew</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('common.openInNewTab') }}</span>
          </v-tooltip>
        </div>
      </v-row>
      <v-row v-if="search" class="px-2 pt-1">
        <v-text-field
          ref="searchInput"
          v-model="searchString"
          dense
          flat
          hide-details
          single-line
          solo
          :label="$t('common.search')"
        >
          <template #prepend-inner>
            <v-icon>$iconMagnify</v-icon>
          </template>
          <template #append-outer>
            <v-btn
              icon
              small
              @click="
                search = false
                searchString = ''
              "
            >
              <v-icon>$iconCloseCircle</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-row>
      <v-row v-if="displayList">
        <v-list
          color="#fff"
          dense
          two-line
          style="overflow-y: scroll"
          :max-height="calcHeight()"
          width="100%"
        >
          <v-list-item-group>
            <template v-for="(contact, k) in contacts">
              <v-list-item :key="k" ripple @click="display(contact)">
                <v-list-item-avatar color="secondary">
                  <span class="white--text text-h5">{{ contact.lastname.charAt(0) }}</span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    contact.lastname.toUpperCase() + ' ' + contact.firstname
                  }}</v-list-item-title>
                  <v-list-item-subtitle v-if="contact.crm_emails[0]">{{
                    contact.crm_emails[0].email
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-row>
      <v-row v-if="!displayList" class="mt-4" no-gutters>
        <div class="d-inline-flex align-center">
          <v-avatar color="secondary" size="36">
            <span class="white--text text-h5">{{ selectContact.lastname.charAt(0) }}</span>
          </v-avatar>
          <h4 class="pl-2">
            {{ selectContact.lastname | uppercase }}
            {{ selectContact.firstname }}
          </h4>
        </div>
      </v-row>
      <v-row v-if="!displayList" class="d-inline-block mt-2" no-gutters>
        <p v-if="selectContact.crm_company" class="mb-0">
          <span v-if="selectContact.crm_company.name.length < 25">
            {{ selectContact.crm_company.name }}
          </span>
          <span v-else>
            {{ selectContact.crm_company.other_name }}
          </span>
        </p>
        <p class="mb-0">
          <span class="text-caption">{{ selectContact.job_title }}</span>
        </p>
      </v-row>
      <v-row v-if="!displayList">
        <v-toolbar color="primary" dark height="32px">
          <v-tooltip
            v-if="
              $store.state.auth.user.crm_contact_uuid === selectContact.uuid ||
              $store.getters['auth/isAuth'](['crm_contact', 'create'])
            "
            top
          >
            <template #activator="{ on }">
              <v-btn
                icon
                small
                dark
                v-on="on"
                @click="$router.push('/crm-contact/edit/' + selectContact.uuid)"
              >
                <v-icon>$iconEdit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('common.edit') }}</span>
          </v-tooltip>
          <v-tooltip v-else top>
            <template #activator="{ on }">
              <v-btn
                icon
                small
                dark
                v-on="on"
                @click="$router.push('/crm-contact/edit/' + selectContact.uuid)"
              >
                <v-icon>$iconEye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('contact.see') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-if="!selectContact.user" #activator="{ on }">
              <v-btn
                v-if="$store.getters['auth/isAuth'](['crm_contact', 'delete'])"
                icon
                small
                dark
                v-on="on"
                @click="deleteItem(selectContact)"
              >
                <v-icon>$iconDelete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('contact.deleteContact') }}</span>
          </v-tooltip>
        </v-toolbar>
        <v-card outlined tile width="348px" flat>
          <v-card-text class="pa-1">
            {{ $t('contact.contact_info') }}
            <div v-for="(email, k) in selectContact.crm_emails" :key="'email' + k" class="my-4">
              <v-icon class="mr-3">$iconSendEmail</v-icon>
              <a :href="`mailto:${email.email}`">{{ email.email }}</a>
              <span class="mx-1">•</span>{{ email.type }}
            </div>
            <div v-for="(phone, k) in selectContact.crm_phones" :key="'phone' + k" class="my-4">
              <v-icon class="mr-3">$iconPhone</v-icon>
              <a :href="`tel:${phone.phone}`">{{ phone.phone }}</a>
              <span class="mx-1">•</span>{{ phone.type }}
            </div>
            <div v-if="selectContact.address" class="my-4">
              <v-icon class="mr-3 float-left">$iconAddress</v-icon>
              <p>
                {{ selectContact.address }}<br />
                {{ selectContact.zip_code + ' ' + selectContact.city }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row v-if="displayList">
        <v-toolbar color="tertiary" height="32px">
          <v-spacer />
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn
                v-if="$store.getters['auth/isAuth'](['crm_contact', 'create'])"
                icon
                small
                color="primary"
                @click="$router.push('/crm-contact/add')"
              >
                <v-icon v-on="on">$iconPlus</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('contact.add') }}</span>
          </v-tooltip>
        </v-toolbar>
      </v-row>
    </v-container>
    <crm-contact-form v-if="showForm" :value="editedId" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_CRM_CONTACTS, DELETE_CRM_CONTACT } from '@/graphql/CrmContactQueries'
import merge from 'lodash/merge'

export default {
  name: 'ProjectDrawerCrmContacts',
  apollo: {
    contacts: {
      query: GET_CRM_CONTACTS,
      variables() {
        return this.searchQuery
      },
      update(data) {
        this.totalContacts = data.crm_contact_aggregate.aggregate.totalCount
        return data.crm_contact
      },
      skip() {
        return !this.currentProjectId
      },
    },
  },
  data() {
    return {
      displayList: true,
      editedId: -1,
      search: false,
      searchString: '',
      selectContact: {},
      showForm: false,
      totalContacts: 0,
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentDrawer: 'project/currentDrawer',
    }),
    displayDrawer: {
      get() {
        return this.currentDrawer === 'contacts'
      },
      set(newName) {
        return newName
      },
    },
    searchQuery: function () {
      let vars = {
        where: {
          crm_contact_project: {
            project_uuid: {
              _eq: this.currentProjectId,
            },
            visible: {
              _eq: true,
            },
          },
        },
        order_by: {
          lastname: 'asc',
        },
      }

      if (this.searchString) {
        const where = {
          where: {
            _and: [
              {
                _or: [
                  { firstname: { _ilike: `%${this.searchString}%` } },
                  { lastname: { _ilike: `%${this.searchString}%` } },
                  {
                    crm_company: {
                      name: { _ilike: `%${this.searchString}%` },
                    },
                  },
                ],
              },
            ],
          },
        }
        vars = merge(vars, where)
      }

      return vars
    },
  },
  methods: {
    calcHeight() {
      return this.search ? 'calc(100vh - 187px)' : 'calc(100vh - 176px)'
    },
    display(contact) {
      this.displayList = false
      this.search = false
      this.searchString = ''
      this.selectContact = contact
    },
    displaySearch() {
      this.search = true
      setTimeout(() => {
        this.$refs.searchInput.focus()
      })
    },
    async deleteItem(record) {
      const removal = this.$t('common.removal')
      const sureToDelete = this.$t('common.sureToDelete')
      if (await this.$root.$confirm(removal, sureToDelete, { color: 'red' })) {
        this.$root.$dialogLoader.start(
          removal + '...',
          {},
          () => {
            return new Promise((resolve, reject) => {
              try {
                this.$apollo.mutate({
                  mutation: DELETE_CRM_CONTACT,
                  variables: {
                    uuid: record.uuid,
                  },
                  update: (cache) => {
                    try {
                      const data = cache.readQuery({
                        query: GET_CRM_CONTACTS,
                        variables: this.searchQuery,
                      })

                      data.crm_contact = data.crm_contact.filter((item) => {
                        return item.uuid !== record.uuid
                      })
                      data.crm_contact_aggregate.aggregate.totalCount -= 1

                      cache.writeQuery({
                        query: GET_CRM_CONTACTS,
                        data,
                        variables: this.searchQuery,
                      })
                      this.displayList = true
                      resolve()
                    } catch (e) {
                      reject(e.toString())
                    }
                  },
                })
              } catch (e) {
                console.log('myerror', e)
                reject(e.toString())
              }
            })
          },
          true
        )
      }
    },
  },
}
</script>
