<template lang="html">
  <v-autocomplete
    v-model="guests"
    :items="contacts"
    :label="localLabel"
    :readonly="isReadonly"
    :search-input.sync="search"
    chips
    hide-no-data
    hide-selected
    color="blue-grey lighten-2"
    multiple
    return-object
    item-text="lastname"
    item-value="uuid"
    :filter="customFilter"
    v-bind="$attrs"
    v-on="$listeners"
    @change="selectItem"
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        :close="!isReadonly"
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left color="secondary">
          <span class="white--text text-h6">
            {{ data.item.lastname.charAt(0) }}
          </span>
        </v-avatar>
        {{ data.item.firstname }} {{ data.item.lastname | uppercase }}
      </v-chip>
    </template>
    <template #item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content>
          {{ data.item }}
        </v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar color="secondary">
          <span class="white--text text-h6">
            {{ data.item.lastname.charAt(0) }}
          </span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.firstname + ' ' + data.item.lastname.toUpperCase() }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="data.item.crm_emails[0]">
            {{ data.item.crm_emails[0].email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_CRM_CONTACTS } from '@/graphql/CrmContactQueries'

export default {
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String],
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    contacts: [],
    guests: [],
    loading: false,
    search: null,
  }),
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
    localLabel() {
      return this.label ?? this.$tc('contact.contact', 2)
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.value) {
          this.querySelections()
          this.guests = this.value.map((v) => v.crm_contact)
        }
      },
      immediate: true,
    },
    async search(val) {
      val && (await this.querySelections(val))
    },
  },
  methods: {
    customFilter(item, queryText) {
      if (item.header) return false

      const data = item.firstname.toLowerCase() + item.lastname.toLowerCase()
      const searchText = queryText.toLowerCase()
      return data.indexOf(searchText) > -1
    },
    remove(item) {
      const index = this.guests.findIndex((guest) => guest.uuid === item.uuid)
      if (index >= 0) this.guests.splice(index, 1)
      this.selectItem()
    },
    selectItem() {
      const list = []
      this.guests.forEach(function (item) {
        list.push({ crm_contact: item })
      })
      this.$emit('input', list)
      this.search = ''
    },
    async querySelections() {
      this.loading = true
      const rv = await this.$apollo.query({
        query: GET_CRM_CONTACTS,
        variables() {
          return {
            where: {
              project: { project_uuid: { _eq: this.currentProjectId } },
            },
            order_by: { name: 'asc' },
          }
        },
      })
      this.contacts = rv.data.crm_contact
      this.loading = false
    },
  },
}
</script>
