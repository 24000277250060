<template>
  <v-container class="pa-0">
    <v-select
      v-model="companyModel"
      :items="companyList"
      :label="labelText"
      :readonly="isReadonly"
      :menu-props="{ top: false, offsetY: true }"
      :no-data-text="$t('common.noDataText')"
      :placeholder="$t('common.select')"
      height="46px"
      item-text="name"
      item-value="uuid"
      :clearable="!isReadonly"
      dense
      return-object
      @change="selectItem"
    />
  </v-container>
</template>
<script>
import { GET_CRM_COMPANIES } from '@/graphql/CrmCompanyQueries'

export default {
  name: 'CompanySelector',
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: undefined,
    },
  },
  apollo: {
    crm_company: {
      query: GET_CRM_COMPANIES,
      variables() {
        const vars = {
          order_by: { name: 'asc' },
        }
        return vars
      },
      result({ data }) {
        if (data) {
          this.crm_company = data.crm_company
        }
      },
      skip() {
        return !this.$store.getters['auth/isAuthenticated']
      },
    },
  },
  data: () => ({
    companySelected: [],
  }),
  computed: {
    companyList() {
      return this.getCompanyList()
    },
    companyModel: {
      get() {
        return this.getCompanySelected()
      },
      set(val) {
        this.companySelected = val
      },
    },
    labelText() {
      return this.label !== '' ? this.label : this.$tc('crmCompany.company', 1) + ' *'
    },
  },
  watch: {
    companyModel: function (val) {
      this.companySelected = val
    },
    companySelected: function (val) {
      this.companyModel = val
    },
  },
  methods: {
    getCompanyList() {
      const list = []
      if (typeof this.crm_company !== 'undefined') {
        this.crm_company.forEach((i) => {
          list.push({
            name: i.name,
            uuid: i.uuid,
          })
        })
      }
      return list
    },
    getCompanySelected() {
      let result = []
      if (typeof this.value !== 'undefined') {
        result = this.value
      }
      return result
    },
    selectItem() {
      this.$emit('input', this.companySelected)
    },
  },
}
</script>
