import gql from 'graphql-tag'

// queries
export const GET_PROJECT_DEVICE = gql`
  query ($id: uuid!) {
    project_device_by_pk(id: $id) {
      id
      name
      model
      description
      device_type
      config_fields
      lib_device_id
      project_id
      project_view_device_coordinates {
        x
        y
      }
      project_device2_links {
        link_type
        project_device1 {
          id
          name
        }
      }
      created_at
      updated_at
    }
  }
`

export const GET_PROJECT_DEVICES = gql`
  query project_device(
    $where: project_device_bool_exp
    $order_by: [project_device_order_by!]
    $limit: Int
    $offset: Int
  ) {
    project_device(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
      id
      project_id
      name
      model
      description
      device_type
      config_fields
      created_at
      updated_at
      project_device2_links {
        link_type
        project_device2 {
          id
        }
        project_device1 {
          id
          name
          device_type
        }
      }
      project_view_device_coordinates {
        project_view_uuid
        project_view_coordinate_system_id
        x
        y
      }
    }
  }
`

export const POST_PROJECT_DEVICES = gql`
  mutation ($objects: [project_device_insert_input!]!) {
    insert_project_device(objects: $objects) {
      returning {
        id
        name
        model
        description
        device_type
        config_fields
        created_at
        updated_at
      }
    }
  }
`

export const PATCH_PROJECT_DEVICE = gql`
  mutation ($id: uuid!, $body: project_device_set_input!) {
    update_project_device(where: { id: { _eq: $id } }, _set: $body) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`

export const DELETE_PROJECT_DEVICE = gql`
  mutation ($id: [uuid!]!) {
    delete_project_device(where: { id: { _in: $id } }) {
      returning {
        id
      }
    }
  }
`
