<template>
  <v-card>
    <v-card-title class="text-body-1 white--text secondary py-1">
      {{ $tc('common.contact', 1) }}
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12" class="py-0">
          <form-error
            :validator="$v.crmContactLocal.lastname"
            :attribute="$t('contact.lastname')"
            :messages="messages"
          >
            <v-text-field
              v-model="crmContactLocal.lastname"
              slot-scope="{ attrs }"
              v-bind="attrs"
              :label="$t('contact.lastname') + ' *'"
              :placeholder="$t('contact.placeholder.lastname')"
              :readonly="readonly"
              @input="$v.crmContactLocal.lastname.$touch()"
              @blur="$v.crmContactLocal.lastname.$touch()"
            />
          </form-error>
        </v-col>
        <v-col cols="12" class="py-0">
          <form-error
            :validator="$v.crmContactLocal.firstname"
            :attribute="$t('contact.firstname')"
            :messages="messages"
          >
            <v-text-field
              v-model="crmContactLocal.firstname"
              slot-scope="{ attrs }"
              v-bind="attrs"
              :label="$t('contact.firstname') + ' *'"
              :placeholder="$t('contact.placeholder.firstname')"
              :readonly="readonly"
              @input="$v.crmContactLocal.firstname.$touch()"
              @blur="$v.crmContactLocal.firstname.$touch()"
            />
          </form-error>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CrmContactEditor',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {
          firstname: '',
          lastname: '',
        }
      },
    },
  },
  validations: {
    crmContactLocal: {
      firstname: { required },
      lastname: { required },
    },
  },
  computed: {
    crmContactLocal: {
      get() {
        return this.value
      },
      set(val) {
        if (val.lastname.length > 0 && val.firstname.length > 0) {
          this.$emit('input', val)
        }
      },
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
}
</script>