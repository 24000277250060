import gql from 'graphql-tag'

const logFragment = gql`
  fragment logFragment on log {
    uuid
    project_uuid
    hub_uuid
    device_uuid
    message
    log_time
    log_type
    type
    created_at
    updated_at
  }
`

export const GET_ENUM_TYPE_LOG = gql`
  query enum_type_log {
    enum_type_log {
      text
      value
    }
  }
`

export const GET_LOGS = gql`
  query log($limit: Int, $offset: Int, $order_by: [log_order_by!], $where: log_bool_exp) {
    log(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      ...logFragment
    }
    log_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  ${logFragment}
`

export const GET_LOGS_COUNT = gql`
  query ($project_uuid: uuid!) {
    log_aggregate(
      where: {
        project_uuid: { _eq: $project_uuid }
        type: { _eq: warnings }
        _not: { message: { _has_key: "state" } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LOGS_AGGREGATE = gql`
  query log_aggregate($limit: Int, $where: log_bool_exp) {
    log_aggregate(limit: $limit, where: $where) {
      aggregate {
        count
      }
    }
  }
`

// mutations
export const UPDATE_LOG_MESSAGE = gql`
  mutation update_log($where: log_bool_exp!, $message: jsonb!) {
    update_log(where: $where, _append: { message: $message }) {
      affected_rows
      returning {
        ...logFragment
      }
    }
  }
  ${logFragment}
`

// message format:
// {
//  "hub_data_send_time": "",
//  "hub_filename_logs": "",
//  "log_type": "",
//  "message_type": "",
//  "message_text": "",
//  "message_value": "",
//  "version": "",
//  "state": ""
// }

// subscriptions
export const SUBSCRIPTION_LOGS = gql`
  subscription log($limit: Int, $offset: Int, $order_by: [log_order_by!], $where: log_bool_exp) {
    log(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
      ...logFragment
    }
  }
  ${logFragment}
`
