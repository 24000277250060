import gql from 'graphql-tag'

export const GET_UNITS = gql`
  query unit($where: unit_bool_exp, $order_by: [unit_order_by!], $limit: Int, $offset: Int) {
    unit_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    unit(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      id
      symbol
    }
  }
`

export const POST_UNIT = gql`
  mutation ($objects: [unit_insert_input!]!) {
    insert_unit(objects: $objects) {
      returning {
        id
        symbol
      }
    }
  }
`

export const PATCH_UNIT = gql`
  mutation ($id: Int!, $body: unit_set_input!) {
    update_unit(where: { id: { _eq: $id } }, _set: $body) {
      affected_rows
      returning {
        id
        symbol
      }
    }
  }
`

export const DELETE_UNIT = gql`
  mutation ($id: Int!) {
    delete_unit(where: { id: { _eq: $id } }) {
      returning {
        id
        symbol
      }
    }
  }
`
