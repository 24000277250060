<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" md="12">
        <v-alert type="info">
          {{ $t('common.noParams') }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ConfigDefaultFieldLoader',
  data: () => ({}),
}
</script>
<style lang="scss" scoped></style>
