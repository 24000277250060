import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { dateTimeFormats } from '@/locales/formats/dataTimeFormats'

// @link https://github.com/preetishhs/vue-localization-techniques/blob/master/src/plugins/i18n.js

Vue.use(VueI18n)

/**
 * loadLocaleMessages
 * Load locales languages messages used
 * @return array messages
 */
export function loadLocaleMessages() {
  // New by folder and files lang
  const localesFolder = require.context('@/locales', true, /^.*\.json$/)

  const messages = {}

  // Set deep2 messages from files by lang
  localesFolder.keys().forEach((key) => {
    const keySlash = key.split('/')
    const rootKey = keySlash[2].replace('.json', '').replace(`${keySlash[1]}_`, '')
    const messageTmp = {}
    messageTmp[rootKey] = localesFolder(key)
    messages[keySlash[1]] = Object.assign({}, messages[keySlash[1]], messageTmp)
  })

  return messages
}

/**
 * checkDefaultLanguage
 * Check default language
 * @return array matched
 */
export function checkDefaultLanguage() {
  let matched = null
  const languages = Object.getOwnPropertyNames(loadLocaleMessages())
  languages.forEach((lang) => {
    if (lang === navigator.language) {
      matched = lang
    }
  })
  if (!matched) {
    languages.forEach((lang) => {
      const languagePartials = navigator.language.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    })
  }
  if (!matched) {
    languages.forEach((lang) => {
      const languagePartials = navigator.language.split('-')[0]
      if (lang.split('-')[0] === languagePartials) {
        matched = lang
      }
    })
  }
  return matched
}

export const languages = Object.getOwnPropertyNames(loadLocaleMessages())

export const selectedLocale =
  checkDefaultLanguage() ||
  (process.env?.VUE_APP_I18N_LOCALE.length === 2 ? process.env.VUE_APP_I18N_LOCALE : 'fr')

const i18n = new VueI18n({
  dateTimeFormats,
  locale: selectedLocale,
  fallbackLocale:
    process.env?.VUE_APP_I18N_FALLBACK_LOCALE.length === 2
      ? process.env.VUE_APP_I18N_FALLBACK_LOCALE
      : 'fr',
  messages: loadLocaleMessages(),
})

export default i18n
