import gql from 'graphql-tag'

// queries
export const GET_CRM_PHONES = gql`
  query crm_phone($where: crm_phone_bool_exp = {}) {
    crm_phone_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    crm_phone(where: $where) {
      uuid
      phone
      type
      priority
      crm_company_uuid
      crm_company {
        uuid
        name
      }
      crm_contact_uuid
      crm_contact {
        uuid
        lastname
      }
      created_at
      updated_at
    }
  }
`

export const POST_CRM_PHONE = gql`
  mutation ($objects: [crm_phone_insert_input!]!) {
    insert_crm_phone(objects: $objects) {
      returning {
        uuid
        phone
        type
        priority
        crm_company_uuid
        crm_company {
          uuid
          name
        }
        crm_contact_uuid
        crm_contact {
          uuid
          lastname
        }
        created_at
        updated_at
      }
    }
  }
`

export const DELETE_CRM_PHONE = gql`
  mutation ($uuid: uuid!) {
    delete_crm_phone(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
      }
    }
  }
`
