var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-0 pt-2",attrs:{"flat":"","outlined":""}},[_c('v-card-subtitle',{staticClass:"pa-0"},[_c('v-row',{attrs:{"align":"baseline","justify":"space-between","no-gutters":_vm.isDrawer}},[_c('v-col',{class:_vm.isDrawer ? '' : 'pr-0',attrs:{"cols":_vm.isDrawer ? 12 : 6,"md":_vm.isDrawer ? 12 : 2}},[_c('v-text-field',{staticClass:"px-2",attrs:{"append-icon":'$iconMagnify',"label":_vm.$t('common.title'),"clearable":"","dense":"","hide-details":!_vm.isDrawer},model:{value:(_vm.searchDocument),callback:function ($$v) {_vm.searchDocument=$$v},expression:"searchDocument"}})],1),_c('v-col',{class:_vm.isDrawer ? '' : 'pr-0',attrs:{"cols":_vm.isDrawer ? 12 : 6,"md":_vm.isDrawer ? 12 : 2}},[_c('v-select',{staticClass:"px-2",attrs:{"items":_vm.categoryList,"label":_vm.$tc('common.category', 1),"menu-props":{ top: false, offsetY: true },"no-data-text":_vm.$t('common.noDataText'),"placeholder":_vm.$t('common.select'),"item-text":"name","item-value":"id","clearable":"","dense":"","hide-details":!_vm.isDrawer,"small-chips":""},model:{value:(_vm.categorySelected),callback:function ($$v) {_vm.categorySelected=$$v},expression:"categorySelected"}})],1)],1)],1),_c('v-card-text',{staticClass:"pa-0",style:(_vm.styleCalcHeight)},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documents,"item-class":_vm.rowClasses,"disable-pagination":_vm.source === 'drawer',"footer-props":{
          'items-per-page-text': _vm.itemsPerPageText,
          'page-text': _vm.pageText,
          'items-per-page-options': [5, 10, 25, 50, 100],
        },"hide-default-footer":_vm.source === 'drawer',"no-data-text":_vm.$t('common.noDataText'),"options":_vm.options,"search":_vm.searchDocument,"item-key":"project_device_document_uuid","dense":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
        var item = ref.item;
return [_c('thumbnail',{attrs:{"doc":{
              datetime: item.project_device_document.created_at,
              key: item.project_device_document.file.key,
              title: item.project_device_document.title,
              token: _vm.getToken(item),
            },"is-hover":_vm.isTrue,"size":_vm.sizeThumbnail()}})]}}],null,true)})],1)],1),_c('image-viewer',{attrs:{"image":_vm.imageViewerSrc},model:{value:(_vm.imageViewer),callback:function ($$v) {_vm.imageViewer=$$v},expression:"imageViewer"}}),_c('pdf-viewer',{attrs:{"pdf-file":_vm.pdfViewerSrc},model:{value:(_vm.pdfViewer),callback:function ($$v) {_vm.pdfViewer=$$v},expression:"pdfViewer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }