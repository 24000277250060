export default [
  {
    name: 'wire',
    label: 'Filaire',
  },
  {
    name: 'modbus',
    label: 'ModBus',
  },
  {
    name: 'wifi',
    label: 'WiFi',
  },
  {
    name: 'nb-iot',
    label: 'NB-IoT',
  },
]
