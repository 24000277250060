import gql from 'graphql-tag'

const crmContactFragment = gql`
  fragment CrmContact on crm_contact {
    uuid
    firstname
    lastname
    address
    zip_code
    city
    job_title
    crm_company {
      uuid
      name
      other_name
    }
    crm_phones {
      phone
      type
      priority
    }
    crm_emails {
      email
      type
    }
    crm_contact_project {
      contact_uuid
      project_uuid
      notification
      visible
    }
  }
`

export const GET_CRM_CONTACT = gql`
  query ($uuid: uuid!) {
    crm_contact_by_pk(uuid: $uuid) {
      ...CrmContact
    }
  }
  ${crmContactFragment}
`

export const GET_CRM_CONTACTS = gql`
  query contact(
    $where: crm_contact_bool_exp
    $order_by: [crm_contact_order_by!]
    $limit: Int
    $offset: Int
  ) {
    crm_contact_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    crm_contact(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...CrmContact
      user {
        active
      }
    }
  }
  ${crmContactFragment}
`

export const INSERT_CRM_CONTACT_ONE = gql`
  mutation insert_crm_contact_one($object: crm_contact_insert_input!) {
    insert_crm_contact_one(object: $object) {
      ...CrmContact
    }
  }
  ${crmContactFragment}
`

export const UPDATE_CRM_CONTACT_BY_PK = gql`
  mutation update_crm_contact_by_pk($uuid: uuid!, $object: crm_contact_set_input!) {
    update_crm_contact_by_pk(pk_columns: { uuid: $uuid }, _set: $object) {
      ...CrmContact
    }
  }
  ${crmContactFragment}
`

export const DELETE_CRM_CONTACT = gql`
  mutation ($uuid: uuid!) {
    delete_crm_contact(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
        created_at
        updated_at
      }
    }
  }
`
