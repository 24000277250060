var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('form-error',{attrs:{"validator":_vm.$v.localfieldConfig.min,"attribute":_vm.$t('common.minimum'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"type":"number","label":_vm.$t('common.minimum')},on:{"input":function($event){return _vm.$v.localfieldConfig.min.$touch()},"blur":function($event){return _vm.$v.localfieldConfig.min.$touch()}},model:{value:(_vm.localfieldConfig.min),callback:function ($$v) {_vm.$set(_vm.localfieldConfig, "min", $$v)},expression:"localfieldConfig.min"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('form-error',{attrs:{"validator":_vm.$v.localfieldConfig.max,"attribute":_vm.$t('common.maximum'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"type":"number","label":_vm.$t('common.maximum')},on:{"input":function($event){return _vm.$v.localfieldConfig.max.$touch()},"blur":function($event){return _vm.$v.localfieldConfig.max.$touch()}},model:{value:(_vm.localfieldConfig.max),callback:function ($$v) {_vm.$set(_vm.localfieldConfig, "max", $$v)},expression:"localfieldConfig.max"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('form-error',{attrs:{"validator":_vm.$v.localfieldConfig.decimal,"attribute":_vm.$t('common.decimal'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"type":"number","label":_vm.$t('common.decimal')},on:{"input":function($event){return _vm.$v.localfieldConfig.max.$touch()},"blur":function($event){return _vm.$v.localfieldConfig.max.$touch()}},model:{value:(_vm.localfieldConfig.decimal),callback:function ($$v) {_vm.$set(_vm.localfieldConfig, "decimal", $$v)},expression:"localfieldConfig.decimal"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('unit-selector',{attrs:{"label":_vm.$t('common.unit')},on:{"input":function($event){return _vm.$v.localfieldConfig.unit.$touch()},"blur":function($event){return _vm.$v.localfieldConfig.unit.$touch()}},model:{value:(_vm.localfieldConfig.unit),callback:function ($$v) {_vm.$set(_vm.localfieldConfig, "unit", $$v)},expression:"localfieldConfig.unit"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('form-error',{attrs:{"validator":_vm.$v.localDefaultValue.value,"attribute":_vm.$t('common.defaultValue'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"label":_vm.$t('common.defaultValue'),"type":"number"},on:{"input":function($event){return _vm.$v.localDefaultValue.value.$touch()},"blur":function($event){return _vm.$v.localDefaultValue.value.$touch()}},model:{value:(_vm.localDefaultValue.value),callback:function ($$v) {_vm.$set(_vm.localDefaultValue, "value", $$v)},expression:"localDefaultValue.value"}},'v-text-field',attrs,false))}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }