<template>
  <helper-crud-list
    v-if="dashboardDefs.gqlobject"
    v-bind="dashboardDefs"
    @viewItem="viewItem($event)"
  >
    <template #[`item.user_uuid`]="{ item }">
      <span v-if="!isDrawerLocal">
        {{ UsersFullname[item.user_uuid] }}
      </span>
    </template>
    <template v-if="!isDrawerLocal" #[`item.action`]="{ item }">
      <v-icon class="mr-4" small @click.stop="viewItem(item)"> $iconEye </v-icon>
      <v-tooltip v-if="!isAuthor(item)" left>
        <template #activator="{ on }">
          <v-icon v-if="!isAuthor(item)" class="mr-4" small v-on="on" @click.stop="subscribe(item)">
            {{ isSubscribe(item) ? '$iconStar' : '$iconStarOff' }}
          </v-icon>
        </template>
        <span>
          {{ isSubscribe(item) ? $t('dashboard.bookmarkDelete') : $t('dashboard.bookmarkAdd') }}
        </span>
      </v-tooltip>
      <v-icon v-if="isAuthor(item) && !IsMobile()" class="mr-4" small @click.stop="editItem(item)">
        $iconEdit
      </v-icon>
      <v-icon v-if="isAuthor(item) && !IsMobile()" small @click.stop="deleteItem(item)">
        $iconDelete
      </v-icon>
    </template>
    <template v-else #[`item.action`]="{ item }">
      <v-icon v-if="!isAuthor(item)" small>
        {{ isSubscribe(item) ? '$iconStar' : '' }}
      </v-icon>
    </template>
    <template #form="{ editedId, listparams, closeForm }">
      <dashboard-form :value="editedId" :listparams="listparams" @closeForm="closeForm" />
    </template>
  </helper-crud-list>
</template>

<script>
import { GET_DASHBOARDS } from '@/graphql/DashboardQueries'
import DashboardForm from '@/views/DashboardForm'
import { mapGetters } from 'vuex'
import { CommonMixin } from '@/mixins/CommonMixin'
import { DashboardMixin } from '@/mixins/DashboardMixin'
import { UserMixin } from '@/mixins/UserMixin'

export default {
  name: 'ViewDashboard',
  components: {
    DashboardForm,
  },
  mixins: [CommonMixin, DashboardMixin, UserMixin],
  props: {
    isDrawer: {
      type: Boolean,
      default: false,
    },
    isView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dashboardDefs: {},
    isDrawerLocal: false,
  }),
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
    dashboardDefsComputed() {
      const defs = {
        identifier: 'uuid',
        gqlobject: 'dashboard',
        homeButton: true,
        showToolbar: !this.isDrawerLocal,
        heightDataTable: this.isDrawerLocal
          ? this.StyleHeightCalc('192px', true).height
          : this.StyleHeightCalc('64px + 64px + 48px + 16px').height,
        query: GET_DASHBOARDS,
        clickRow: this.isDrawerLocal,
        options: {
          itemsPerPage: this.isDrawerLocal ? -1 : 10,
          hideDefaultFooter: this.isDrawerLocal,
          sortBy: ['name'],
          sortDesc: [true],
          where: {
            project_uuid: {
              _eq: this.currentProjectId,
            },
          },
        },
        headers: [
          {
            text: 'common.name',
            value: 'name',
            sortable: true,
            cellClass: this.isDrawerLocal ? 'px-2 pt-2' : '',
            class: this.isDrawerLocal ? 'px-2' : '',
          },
          {
            text: this.isDrawerLocal ? '' : 'common.owner',
            value: 'user_uuid',
            sortable: true,
          },
          {
            text: this.isDrawerLocal ? '' : 'common.action|plural',
            value: 'action',
            sortable: false,
            width: this.isDrawerLocal ? '30px' : '120px',
          },
        ],
        filters: {
          name: {
            type: 'v-text-field',
            col: { cols: 12 },
            options: {
              label: 'common.name',
              placeholder: 'common.name',
              clearable: true,
            },
            gql: (value) => ({ name: { _ilike: `%${value}%` } }),
            value: '',
          },
        },
      }
      if (this.isDrawerLocal) {
        defs.options.where = {
          project_uuid: {
            _eq: this.currentProjectId,
          },
          _or: [
            {
              user_uuid: {
                _eq: this.$store.getters['auth/user'].id,
              },
            },
            {
              dashboard_users: {
                user_uuid: {
                  _eq: this.$store.getters['auth/user'].id,
                },
              },
            },
          ],
        }
      }
      return defs
    },
  },
  watch: {
    currentProjectId: function (val, oldVal) {
      if (val !== oldVal) {
        this.dashboardDefs = {}
        this.dashboardDefs = this.dashboardDefsComputed
      }
    },
  },
  mounted() {
    this.isDrawerLocal = this.isDrawer
    this.dashboardDefs = this.dashboardDefsComputed
  },
  methods: {
    editItem(item) {
      this.$router.push({
        name: 'DashboardEdit',
        path: '/dashboard/edit/' + item.uuid,
        params: {
          value: item.uuid,
          isDrawer: this.isDrawerLocal,
        },
      })
    },
    isAuthor(item) {
      return item.user_uuid === this.$store.getters['auth/user'].id
    },
    isSubscribe(item) {
      return item?.dashboard_users.find((i) => i.user_uuid === this.$store.getters['auth/user'].id)
    },
    subscribe(item) {
      const dashboardUuid = item.uuid
      const userUuid = this.$store.getters['auth/user'].id
      if (this.isSubscribe(item)) {
        this.UnsubscribeDashboard(dashboardUuid, userUuid)
      } else {
        this.SubscribeDashboard(dashboardUuid, userUuid)
      }
      this.updateList()
    },
    updateList() {
      // Tip to update the data list
      this.dashboardDefs.filters.name.value = 'up'
      setTimeout(() => {
        this.dashboardDefs.filters.name.value = ''
      }, 300)
    },
    viewItem(item) {
      this.$router.push({
        name: 'DashboardView',
        path: '/dashboard/view/' + item.uuid,
        params: {
          value: item.uuid,
          isDrawer: this.isDrawerLocal,
        },
      })
    },
    async deleteItem(item) {
      const removal = this.$t('common.removal')
      const sureToDeleteThis = this.$t('common.sureToDeleteThis', {
        attribute: item.name,
      })
      const res = await this.$root.$confirm(removal, sureToDeleteThis, {
        color: 'red',
      })
      if (res) {
        this.DeleteDashboard(item)
        this.updateList()
      }
    },
  },
}
</script>
