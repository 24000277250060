<template>
  <v-container fluid class="mx-auto pa-0">
    <v-card v-if="isLoadingLocal" class="pa-2">
      <v-progress-linear
        v-model="timer"
        :buffer-value="bufferValue"
        background-color="secondary"
        background-opacity="0.2"
        color="secondary"
        height="25"
      >
        <template #default="{ value }">
          <span class="text-caption">
            {{ $t('common.loadingData') }}
            <span v-if="value !== 0">
              {{ value }}
            </span>
            <span>&nbsp;... {{ $t('common.finalizationInProgress') }}</span>
          </span>
        </template>
      </v-progress-linear>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ProgressLinearTimer',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      bufferValue: 100,
      interval: null,
      isLoadingLocal: false,
      timer: 0,
      timerBegan: null,
      timerRemember: '00:00.000',
    }
  },
  watch: {
    isLoading: function (val) {
      this.isLoadingLocal = val
    },
    isLoadingLocal: function (val) {
      if (!val) this.timerReset()
      if (val) this.timerStart()
    },
  },
  mounted() {
    this.isLoadingLocal = this.isLoading
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    timerReset() {
      clearInterval(this.interval)
      this.timerBegan = null
      this.timerRemember = this.timer
      this.$emit('input', this.timerRemember)
      this.timer = '00:00.000'
    },
    timerRunning() {
      const currentTime = new Date()
      const timeElapsed = new Date(currentTime - this.timerBegan)
      let min = timeElapsed.getUTCMinutes()
      let sec = timeElapsed.getUTCSeconds()
      let ms = timeElapsed.getUTCMilliseconds()

      min = min > 9 ? min : '0' + min
      sec = sec > 9 ? sec : '0' + sec
      ms = ms > 99 ? ms : ms > 9 ? '0' + ms : '00'

      this.bufferValue = sec * 10
      if (this.bufferValue >= 110) {
        this.bufferValue = (sec * 15) / 10
      }

      this.timer = min + ':' + sec + '.' + ms
    },
    timerStart() {
      this.timerBegan = new Date()
      this.interval = setInterval(this.timerRunning, 10)
    },
  },
}
</script>
