export const CommonMixin = {
  data() {
    return {
      countDebug: 0,
      DebugCsv: 'id, timestamp, datetime, I/O, component, method\r\n',
      WindowSize: [],
    }
  },
  created() {
    this.displayWindowSize()
    this.TraceDebug()
  },
  methods: {
    displayWindowSize() {
      this.WindowSize = [window.innerWidth, window.innerHeight]
    },
    /**
     * Return the first letter uppercase of a string
     * @param {string} str
     * @return {string}
     */
    GetFirstLetterUppercase(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : '?'
    },
    /**
     * Return the element part of the string
     * @param {string} str
     * @param {string} [separator]
     * @param {number} [split] array position of the desired element or the last one if omitted
     * @return {string}
     */
    GetStringSplit(str, separator, split) {
      let result = ''
      if (separator) {
        const strSplit = str.split(separator)
        result = split || split === 0 ? strSplit[split] : strSplit[strSplit.length - 1]
      } else {
        result = str
      }
      return result
    },
    IsMediumDown() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    IsMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    /**
     * Return string normalize (use for path file name)
     * @param {string} str
     * @return {string}
     */
    NormalizeString(str) {
      // Returns the Unicode Normalization Form of the string
      str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      // Replace space by underscore
      str = str.replaceAll(' ', '_')
      // Replace quote by underscore
      str = str.replaceAll("'", '_')
      // Replace apostrophe by underscore
      str = str.replaceAll('’', '_')
      // Replace anti-slash by underscore
      str = str.replaceAll('\\', '_')
      // Replace coma by underscore
      str = str.replaceAll(',', '_')
      // Replace semicolon by underscore
      str = str.replaceAll(';', '_')
      // Get extension
      const ext = this.GetStringSplit(str, '.')
      // Remove extension from str
      let strNoExt = str.replace('.' + ext, '')
      // Replace dot by underscore
      strNoExt = strNoExt.replaceAll('.', '_')
      // Add extnstion to str
      str = strNoExt + '.' + ext
      return str
    },
    /**
     * Return string with html breakline <br /> or operating
     * system \n\r or \n
     * \n is Line Feed
     * \r is Carriage Return
     * @param {string} str Character string to replace
     * @param {boolean} nrToBr If true convert \n\r to <br />
     * @param {boolean} [nr] If true convert to \n\r else to \n
     * @returns {string}
     */
    ReplaceBreakline(str, nrToBr, nr) {
      const nrFinal = !nrToBr && nr ? '\n\r' : '\n'
      return nrToBr ? str.replace(/\n\r?/g, '<br />') : str.replace(/(?:<br \/>)/g, nrFinal)
    },
    ShowSnackbarError(msg) {
      const errorForm = msg || this.$t('common.errorOnForm')
      this.$root.$dialogLoader.showSnackbar(errorForm, { color: 'error' })
    },
    ShowSnackbarSuccess(msg) {
      const modificationCompleted = msg || this.$t('common.modificationCompleted')
      this.$root.$dialogLoader.showSnackbar(modificationCompleted, {
        color: 'success',
      })
    },
    /**
     * Return style height calc
     * @param {string} [morePixel] Is eg. height toolbar: 64px or other in pixel
     * @param {boolean} [overflowYScroll] True if need scroll
     * @return {string}
     */
    StyleHeightCalc(morePixel, overflowYScroll) {
      const appBar = this.IsMobile() ? '34px' : '48px'
      const substract = morePixel ? '(' + appBar + ' + ' + morePixel + ')' : appBar
      const calc = 'calc(100vh - ' + substract + ') !important'
      const style = {
        height: calc,
      }
      if (overflowYScroll) {
        style.overflowY = 'scroll'
      }
      return style
    },
    /**
     * Return in the console a table in csv format to be
     * copied into a spreadsheet.
     *
     * Allow to trace the execution time between two points
     * by placing a TraceDebug in input and another in
     * output.
     * @param {string} InOut I || O
     * @param {string} component componement name
     * @param {string} method To identify what needs to be analyzed
     */
    TraceDebug(InOut, component, method) {
      if (component) {
        const dateNow = Date.now()
        const datetime = new Date(dateNow)
        this.countDebug += 1
        // id, timestamp, datetime, In/Out, component, method
        this.DebugCsv +=
          this.countDebug +
          ', ' +
          dateNow +
          ', ' +
          datetime.toISOString() +
          ', ' +
          InOut +
          ', ' +
          component +
          ', ' +
          method +
          '\r\n'
        console.log(this.DebugCsv)
      }
    },
  },
}
