<template>
  <v-switch
    v-model="finalValue"
    :label="`${fieldLabelFinal}: ${TransformBoolValue(finalValue)}`"
    color="secondary"
    true-value
  />
</template>

<script>
export default {
  name: 'BooleanField',
  props: {
    fieldConfig: {
      type: Object,
      default() {
        return {
          valueTrue: true,
          valueFalse: false,
        }
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {}
      },
    },
    fieldLabel: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      finalValue: this.defaultValue.value,
    }
  },
  computed: {
    fieldLabelFinal() {
      return this.fieldLabel !== '' ? this.fieldLabel : this.$t('common.defaultValue')
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    finalValue: {
      deep: true,
      handler() {
        this.$emit('input', { value: this.finalValue })
      },
    },
  },
  methods: {
    TransformBoolValue: function (value) {
      return value ? this.fieldConfig.valueTrue : this.fieldConfig.valueFalse
    },
  },
}
</script>
