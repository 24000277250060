<template lang="html">
  <v-card class="ma-0" flat outlined>
    <v-data-table
      :headers="headersParamsConfig"
      :items="deviceParameters"
      item-key="uuid"
      :items-per-page="100"
      :footer-props="{
        'items-per-page-text': itemsPerPageText,
        'page-text': pageText,
        'items-per-page-options': [20, 30, 40, 50, 100],
      }"
      :no-data-text="$t('common.noDataText')"
      dense
      @click:row="selectParameterValue($event)"
    >
      <template #top>
        <v-toolbar flat color="white">
          <div class="text-subtitle-1 font-weight-bold">
            {{ $t('common.settingsDevice') }} ({{ target }})
          </div>
          <v-spacer />
          <v-btn v-if="checkChange" class="mb-2 mr-1" @click="saveForm">
            <v-icon class="mr-1" color="primary"> $iconSave </v-icon>{{ $t('common.save') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`item.order`]="{ item }">
        <v-text-field v-model="item.order" type="number" style="width: 70px" dense />
      </template>
      <template #[`item.parameter_value`]="{ item }">
        <span v-if="item.project_device_parameter.constant">
          {{ item.parameter_value.value }}
        </span>
        <span v-else>
          <field-loader
            :type="item.project_device_parameter.field_type"
            :default-value="item.parameter_value"
            :field-config="item.project_device_parameter.field_config"
            :field-label="$t('common.value')"
            dense
            @input="updateParameterValue(item.uuid, $event.value)"
          />
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  GET_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS,
  UPDATE_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS,
} from '@/graphql/ProjectDevicesProjectDeviceParameters'
import FieldLoader from '@/components/formfields/FieldLoader'
import { CommonMixin } from '@/mixins/CommonMixin'

export default {
  name: 'DeviceDialogConfigParameters',
  components: {
    FieldLoader,
  },
  mixins: [CommonMixin],
  props: {
    deviceUuid: {
      type: String,
      default: '',
    },
    isDeviceEnable: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: 'device',
    },
  },
  apollo: {
    deviceParameters: {
      query: GET_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS,
      variables() {
        return {
          where: {
            project_device_uuid: {
              _eq: this.deviceUuid,
            },
            project_device_parameter: {
              target: {
                _eq: this.target,
              },
            },
          },
          order_by: { order: 'asc' },
        }
      },
      result({ data }) {
        if (data) {
          this.deviceParameters = data.project_devices_project_device_parameters
        }
      },
      skip() {
        return this.deviceUuid === ''
      },
      update(data) {
        this.deviceParametersNew = data.project_devices_project_device_parameters
        return data.project_devices_project_device_parameters
      },
    },
  },
  data: () => ({
    checkChange: false,
    deviceParameters: [],
    deviceParametersNew: [],
  }),
  computed: {
    headersParamsConfig() {
      return [
        { text: this.$t('common.order'), value: 'order' },
        { text: 'Param Cfg', value: 'project_device_parameter.config_label' },
        {
          text: this.$t('common.name'),
          value: 'project_device_parameter.name',
        },
        {
          text: this.$t('common.description'),
          value: 'project_device_parameter.description',
        },
        { text: this.$t('project.valueParameter'), value: 'parameter_value' },
      ]
    },
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
  },
  methods: {
    selectParameterValue() {
      this.checkChange = true
    },
    updateParameterValue(parameterUuid, event) {
      this.deviceParametersNew.find((i) => {
        if (i.uuid === parameterUuid) {
          i.parameter_value.value = event
        }
        return null
      })
    },
    async saveForm() {
      const headerMessage = this.$t('common.settingsDevice')
      const message = this.$t('project.updateConfigurationSettings')
      if (this.checkChange) {
        if (await this.$root.$confirm(headerMessage, message, { color: 'red' })) {
          await this.deviceParametersNew.forEach((a) => {
            this.$apollo
              .mutate({
                mutation: UPDATE_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS,
                variables: {
                  where: {
                    uuid: { _eq: a.uuid },
                  },
                  _set: {
                    order: Number(a.order),
                    parameter_value: a.parameter_value,
                  },
                },
              })
              .catch((error) => {
                this.ShowSnackbarError(
                  'update UPDATE_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS: ' +
                    a?.project_device_parameter?.config_label +
                    ' ' +
                    error.message
                )
                throw error
              })
            if (a?.project_device_parameter?.config_label === 'enable') {
              this.$emit('update:isDeviceEnable', a.parameter_value.value)
            }
          })
          this.checkChange = false
        }
      }
    },
  },
}
</script>
