var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('v-dialog',{attrs:{"width":"500","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.widgetLocal),callback:function ($$v) {_vm.widgetLocal=$$v},expression:"widgetLocal"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"tertiary","dense":"","flat":""}},[_c('v-toolbar-title',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.settingsWidget'))+" ")])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.isTitle(_vm.widgetLocal.title),"elevation":"0","x-small":""},on:{"click":function($event){return _vm.closeDialog('save')}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$iconSave")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0",attrs:{"elevation":"0","x-small":""},on:{"click":function($event){return _vm.closeDialog('close')}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$iconClose")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.GetFirstLetterUppercase(_vm.$t('common.close')))+" ")])])],1)],1),_c('v-card-text',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"lg":_vm.widgetLocal.type === 'graph' ? 5 : 12,"md":"12"}},[_c('v-select',{attrs:{"items":_vm.widgetType,"label":_vm.$t('dashboard.selectWidgetType'),"placeholder":_vm.$t('common.select'),"dense":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('dashboard.type.' + item))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('dashboard.type.' + item))+" ")]}}],null,true),model:{value:(_vm.widgetLocal.type),callback:function ($$v) {_vm.$set(_vm.widgetLocal, "type", $$v)},expression:"widgetLocal.type"}})],1),_c('v-col',{attrs:{"lg":_vm.widgetLocal.type === 'graph' ? 7 : 12,"md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('common.title') + ' *',"placeholder":_vm.$t('common.title'),"clearable":"","dense":"","hide-details":""},model:{value:(_vm.widgetLocal.title),callback:function ($$v) {_vm.$set(_vm.widgetLocal, "title", $$v)},expression:"widgetLocal.title"}})],1)],1),(_vm.widgetLocal.type === 'graph')?_c('dashboard-widget-settings-graph',{attrs:{"settings":_vm.widgetLocal.settings},on:{"update:settings":function($event){return _vm.$set(_vm.widgetLocal, "settings", $event)},"changeTitle":_vm.changeTitle}}):_vm._e(),(_vm.widgetLocal.type === 'text')?_c('dashboard-widget-settings-text',{attrs:{"settings":_vm.widgetLocal.settings},on:{"update:settings":function($event){return _vm.$set(_vm.widgetLocal, "settings", $event)}}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }