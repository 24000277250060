<template>
  <v-tooltip
    v-if="currentPositionLocal && currentDevice.hasOwnProperty('device_type')"
    v-model="currentPositionLocal"
    :min-width="infoDevice.tooltipMinWidth"
    :nudge-top="isNudge.top"
    :nudge-right="isNudge.right"
    :nudge-bottom="isNudge.bottom"
    :nudge-left="isNudge.left"
    :open-delay="1500"
    :position-x="currentPositionLocal[0]"
    :position-y="currentPositionLocal[1]"
    :top="isPosition.top"
    :right="isPosition.right"
    :left="isPosition.left"
    offset-overflow
  >
    <v-row no-gutters>
      <v-col class="text-decoration-underline">
        {{ infoDevice.type }}
      </v-col>
      <v-spacer />
      <v-col v-if="infoDevice.otherType !== ''" class="text-decoration-underline">
        {{ infoDevice.otherType }}
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-card color="transparent" outlined>
          <v-card-text class="pa-0 white--text">
            {{ infoDevice.name }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
      <v-col>
        <span v-if="infoDevice.otherName !== ''">
          {{ infoDevice.otherName }}
        </span>
        <span v-if="currentDevice.device_type === 'hub' && listCurrentDeviceAssociate.length > 0">
          <span v-for="(name, i) in listCurrentDeviceAssociate" :key="i" class="d-block">
            <span class="d-block">
              {{ name }}
            </span>
          </span>
        </span>
      </v-col>
    </v-row>
    <v-sheet
      v-if="currentDevice.device_type === 'sensor' && sparklineValue.length > 0"
      color="rgba(0, 0, 0, .12)"
    >
      <v-card class="mx-auto mt-2 px-2 py-0 text-center" dark outlined tile>
        <div class="text-overline">
          {{ dateTimeFromTo }}
        </div>
      </v-card>
      <v-sparkline
        :value="sparklineValue"
        color="white"
        height="100"
        label-size="4"
        line-width="1"
        padding="16"
        width="350"
        auto-draw
        auto-line-width
        show-labels
      />
      <v-card class="d-flex px-2 py-0 mb-4 justify-space-between" dark outlined tile>
        <div class="text-overline">Min. {{ metricMin }}</div>
        <v-divider class="mx-4" vertical />
        <div class="pt-1">
          {{ sparklineUnit }}
        </div>
        <v-divider class="mx-4" vertical />
        <div class="text-overline">Max. {{ metricMax }}</div>
      </v-card>
    </v-sheet>
  </v-tooltip>
</template>

<script>
import { GET_PROJECT_DEVICE_LINKS } from '@/graphql/ProjectDeviceLinkQueries'
import { OlView } from '@/components/project/views/OlViewMixin'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import { SensorMixin } from '@/mixins/SensorMixin'
import { dateTimeHumanFormatFromTo, formatDateTimeIso8601 } from '@/locales/formats/dataTimeFormats'
import moment from 'moment'

export default {
  name: 'TooltipView',
  mixins: [OlView, ProjectMixin, SensorMixin],
  props: {
    currentDevice: {
      type: Object,
      default: undefined,
    },
    currentPosition: {
      type: Array,
      default: undefined,
    },
    sizeMap: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      currentPositionLocal: undefined,
      dateTimeFromTo: null,
      hubName: '',
      infoDevice: {},
      infoDeviceDefault: {
        name: '',
        tooltipMinWidth: 260,
        type: '',
        otherName: '',
        otherType: '',
      },
      isPosition: {
        top: true,
        right: false,
        left: false,
      },
      isNudge: {
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      listCurrentDeviceAssociate: [],
      metricMax: 0,
      metricMin: 0,
      metrics: [],
      sparklineValue: [],
      sparklineUnit: '',
    }
  },
  watch: {
    currentDevice: function (val, oldVal) {
      if (val || val !== oldVal) {
        this.getListCurrentDeviceAssociate()
        this.getSensorMetrics()
        this.getInfoDevice() // Put last
      }
    },
    currentPosition: function (val) {
      this.currentPositionLocal = undefined
      this.currentPositionLocal = val
    },
    sparklineValue: function (val) {
      if (val.length > 0) this.getInfoDevice()
    },
  },
  async mounted() {
    this.infoDevice = Object.assign({}, this.infoDeviceDefault)
  },
  methods: {
    getInfoDevice() {
      this.infoDevice.otherName = ''
      if (this.currentDevice?.device_name) {
        this.infoDevice.name = this.currentDevice.device_name
        const type = this.getDeviceTypeData(this.currentDevice.device_type)?.name
        this.infoDevice.type = type ? this.$tc('project.' + type, 1) : ''
        if (this.currentDevice.device_type === 'hub') {
          this.infoDevice.otherType = this.$tc('project.sensor', 2)
          this.infoDevice.tooltipMinWidth = this.infoDeviceDefault.tooltipMinWidth
        } else {
          this.infoDevice.otherName = this.hubName
          this.infoDevice.otherType = this.hubName !== '' ? this.$tc('project.hub', 1) : ''
          this.infoDevice.tooltipMinWidth =
            this.sparklineValue.length > 0 ? 350 : this.infoDeviceDefault.tooltipMinWidth
        }
        setTimeout(() => {
          this.setPositionTooltip()
        }, 1000)
      }
    },
    getLangAndTranslate() {
      return {
        lang: this.$store.getters['auth/user']?.profile?.lang || 'fr',
        to: this.$t('common.to'),
      }
    },
    setPositionTooltip() {
      if (
        this.currentPositionLocal &&
        !Number.isNaN(this.currentPositionLocal[0]) &&
        !Number.isNaN(this.currentPositionLocal[1])
      ) {
        const size = this.sizeMap || [1, 1]
        const screenLeft = this.currentPositionLocal[0] / size[0] <= 0.5
        const screenTop = this.currentPositionLocal[1] / size[1] <= 0.5

        this.isPosition = {
          top: true,
          right: false,
          left: false,
        }
        this.isNudge = {
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        }
        if (screenLeft) {
          this.isPosition.top = false
          this.isPosition.right = true
          this.isNudge.right = '20px'
        } else {
          this.isPosition.top = false
          this.isPosition.left = true
          this.isNudge.left = '20px'
        }
        if (screenTop) {
          this.isNudge.top = '15px'
        } else {
          this.isNudge.bottom = '15px'
        }
      }
    },
    async getDevicesByHub(hubUuid) {
      const devicesName = []
      const vars = {
        where: {
          project_device1_id: {
            _eq: hubUuid,
          },
          project_device2: {
            project_view_device_coordinates: {
              project_uuid: {
                _eq: this.currentView.project_uuid,
              },
              project_view_uuid: {
                _eq: this.currentView.uuid,
              },
            },
          },
        },
        order_by: {
          project_device2: {
            name: 'asc',
          },
        },
      }
      const req = await this.$apollo.mutate({
        mutation: GET_PROJECT_DEVICE_LINKS,
        variables: vars,
      })
      req.data?.project_device_link.forEach((i) => {
        devicesName.push(i.project_device2.name)
      })
      return devicesName
    },
    async getListCurrentDeviceAssociate() {
      this.hubName = ''
      this.listCurrentDeviceAssociate = []
      if (this.currentDevice?.device_type === 'hub') {
        const listDevicesAssociate = await this.getDevicesByHub(this.currentDevice.device_uuid)
        this.listCurrentDeviceAssociate = listDevicesAssociate
      } else {
        const search = this.ProjectDevices.find((i) => i.id === this.currentDevice?.device_uuid)
        if (search && search?.project_device2_links[0]?.project_device1?.name) {
          this.hubName = search.project_device2_links[0].project_device1.name
        }
      }
    },
    async getSensorMetrics() {
      this.metrics = []
      this.sparklineValue = []
      if (this.currentDevice?.device_type === 'sensor') {
        const dateTimeFrom = moment().subtract(4, 'days').format(formatDateTimeIso8601)
        const dateNow = moment().format(formatDateTimeIso8601)

        this.dateTimeFromTo =
          this.$t('common.from') +
          ' ' +
          dateTimeHumanFormatFromTo(dateTimeFrom, dateNow, this.getLangAndTranslate())

        const arr = [
          {
            bucket: '3600 s',
            device_id: this.currentDevice.device_uuid,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateNow,
          },
        ]
        this.metrics = await this.GetMetrics(arr)

        const appRoundMetrics = this.GetProjectDeviceParameter(
          this.currentDevice?.device_uuid,
          'app_round_metrics'
        )

        const minMax = this.GetMinMaxData(this.metrics[0])
        this.metricMax = minMax[1]
        this.metricMin = minMax[0]

        if (appRoundMetrics) {
          this.metricMax = parseFloat(this.metricMax).toFixed(appRoundMetrics)
          this.metricMin = parseFloat(this.metricMin).toFixed(appRoundMetrics)
        }

        this.sparklineUnit = this.GetProjectDeviceParameter(
          this.currentDevice?.device_uuid,
          'ch_unit'
        )

        this.metrics.forEach((i) => {
          i.forEach((m) => {
            if (m[1]) {
              this.sparklineValue.push(m[1])
            }
          })
        })
      }
    },
  },
}
</script>
