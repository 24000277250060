import gql from 'graphql-tag'

// queries
export const GET_PROJECT_DEVICE_LINK = gql`
  query ($project_id: uuid!, $project_device1_id: uuid!, $project_device2_id: uuid!) {
    project_device_link_by_pk(
      project_id: $project_id
      project_device1_id: $project_device1_id
      project_device2_id: $project_device2_id
    ) {
      project_id
      link_type
      project_device1_id
      project_device2_id
      updated_at
      created_at
    }
  }
`

export const GET_PROJECT_DEVICE_LINKS = gql`
  query project_device_link(
    $where: project_device_link_bool_exp
    $order_by: [project_device_link_order_by!]
    $limit: Int
    $offset: Int
  ) {
    project_device_link_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    project_device_link(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      link_type
      project_device1_id
      project_device2_id
      updated_at
      created_at
      project_device2 {
        id
        lib_device_id
        model
        name
      }
    }
  }
`

export const GET_PROJECT_DEVICE_BY_HUB = gql`
  query project_device_link(
    $project_uuid: uuid!
    $hub_uuid: uuid!
    $order_by: [project_device_link_order_by!] = {}
  ) {
    project_device_link_aggregate(
      where: { project_id: { _eq: $project_uuid }, project_device1_id: { _eq: $hub_uuid } }
      order_by: $order_by
    ) {
      aggregate {
        count
      }
    }
    project_device_link(
      where: { project_id: { _eq: $project_uuid }, project_device1_id: { _eq: $hub_uuid } }
      order_by: $order_by
    ) {
      project_device2 {
        id
        lib_device_id
        model
        name
      }
    }
  }
`

export const PATCH_PROJECT_DEVICE_LINK = gql`
  mutation upsert_project_device_link(
    $objects: [project_device_link_insert_input!]!
    $update_columns: [project_device_link_update_column!] = [link_type, project_device1_id]
  ) {
    insert_project_device_link(
      objects: $objects
      on_conflict: {
        constraint: project_device_link_pkey
        update_columns: [link_type, project_device1_id]
      }
    ) {
      returning {
        project_id
        project_device1_id
        project_device2_id
        link_type
        updated_at
        created_at
      }
    }
  }
`

export const DELETE_PROJECT_DEVICE_LINK_BY_PK = gql`
  mutation delete_project_device_link_by_pk(
    $project_id: uuid!
    $project_device1_id: uuid!
    $project_device2_id: uuid!
  ) {
    delete_project_device_link_by_pk(
      project_id: $project_id
      project_device1_id: $project_device1_id
      project_device2_id: $project_device2_id
    ) {
      project_id
      project_device1_id
      project_device2_id
      link_type
      created_at
      updated_at
    }
  }
`
