<template>
  <v-main class="ma-0">
    <v-fade-transition mode="in-out" class="ma-0">
      <router-view />
    </v-fade-transition>
  </v-main>
</template>

<script>
export default {
  name: 'CoreView',
  metaInfo() {
    return {
      title: 'Vision',
    }
  },
}
</script>
