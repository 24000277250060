<template lang="html">
  <v-container>
    <v-dialog v-model="dialogDoc" max-width="500px">
      <v-card>
        <v-container class="tertiary d-flex justify-space-between py-0 px-2">
          <v-card-title v-if="localDoc.uuid && ShowDoc(localDoc.contentType)" class="pa-8">
            <v-btn fab @click="viewDocument(localDoc)">
              <thumbnail :doc="getThumbnail(localDoc)" size="100" />
            </v-btn>
          </v-card-title>
          <v-card-title v-else class="py-3">
            <thumbnail :doc="getThumbnail(localDoc)" size="100" />
          </v-card-title>
          <v-card-actions>
            <v-btn icon @click="saveDoc">
              <v-icon>$iconSave</v-icon>
            </v-btn>
            <v-btn icon @click="dialogDoc = false">
              <v-icon>$iconClose</v-icon>
            </v-btn>
          </v-card-actions>
        </v-container>
        <v-card-text v-if="updateValidations">
          <v-container>
            <v-row>
              <v-col class="pa-0" cols="12">
                <form-error :validator="$v.localDoc.title" attribute="model" :messages="messages">
                  <v-text-field
                    v-model="localDoc.title"
                    slot-scope="{ attrs }"
                    :label="$t('common.title')"
                    v-bind="attrs"
                    @input="$v.localDoc.title.$touch()"
                    @blur="$v.localDoc.title.$touch()"
                  />
                </form-error>
              </v-col>
              <v-col v-if="localDoc.project_document_category" class="pa-0" cols="12">
                <form-error
                  :validator="$v.localDoc.project_document_category.id"
                  :attribute="$tc('common.category', 1)"
                  :messages="messages"
                >
                  <project-document-category-selector
                    v-model="localDoc.project_document_category"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    @input="$v.localDoc.project_document_category.id.$touch()"
                    @blur="$v.localDoc.project_document_category.id.$touch()"
                  />
                </form-error>
              </v-col>
              <v-col v-if="localDoc.lib_document_category" class="pa-0" cols="12">
                <form-error
                  :validator="$v.localDoc.lib_document_category.id"
                  :attribute="$tc('common.category', 1)"
                  :messages="messages"
                >
                  <document-category-selector
                    v-model="localDoc.lib_document_category"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    @input="$v.localDoc.lib_document_category.id.$touch()"
                    @blur="$v.localDoc.lib_document_category.id.$touch()"
                  />
                </form-error>
              </v-col>
              <v-col v-if="localDoc.project_document_category" class="pa-0" cols="12">
                <v-textarea
                  v-model="localDoc.description"
                  :label="$t('common.description')"
                  :rows="1"
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <image-viewer v-model="imageViewer" :image="imageViewerSrc" />
    <pdf-viewer v-model="pdfViewer" :pdf-file="pdfViewerSrc" />
  </v-container>
</template>

<script>
import { CommonMixin } from '@/mixins/CommonMixin'
import { DocumentMixin } from '@/mixins/DocumentMixin'
import ImageViewer from '@/components/helper/ImageViewer'
import PdfViewer from '@/components/helper/PdfViewer'
import Thumbnail from '@/components/helper/Thumbnail'
import DocumentCategorySelector from '@/components/device_library/DocumentCategorySelector'
import ProjectDocumentCategorySelector from '@/components/project/devices/helper/ProjectDocumentCategorySelector'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'DocumentEdit',
  components: {
    DocumentCategorySelector,
    ImageViewer,
    PdfViewer,
    ProjectDocumentCategorySelector,
    Thumbnail,
  },
  mixins: [CommonMixin, DocumentMixin],
  props: {
    document: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogDoc: false,
      imageViewer: false,
      imageViewerSrc: undefined,
      localDoc: {
        categoryName: '',
        contentType: '',
        datetime: '',
        description: '',
        key: '',
        lib_document_category: {
          id: null,
          name: '',
        },
        project_document_category: {
          id: null,
          name: '',
        },
        title: '',
        token: '',
        uuid: null,
      },
      pdfViewer: false,
      pdfViewerSrc: undefined,
      updateValidations: false,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    document: function (val, oldVal) {
      if (val.uuid || val.uuid !== oldVal.uuid) {
        this.dialogDoc = true
        this.localDoc = val
        this.updateValidations = true
        this.getThumbnail(val)
      }
    },
  },
  validations() {
    if (this.updateValidations) {
      let toValidate = {
        localDoc: {
          title: {
            required,
            minLength: minLength(2),
          },
        },
      }
      if (this.localDoc?.lib_document_category?.id) {
        toValidate.localDoc.lib_document_category = {
          id: { required },
        }
      }
      if (this.localDoc?.project_document_category?.id) {
        toValidate.localDoc.project_document_category = {
          id: { required },
        }
      }
      return toValidate
    }
  },
  methods: {
    getThumbnail(doc) {
      return {
        contentType: doc.contentType,
        datetime: doc.datetime,
        key: doc.key,
        title: doc.title,
        token: doc.token,
        uuid: doc.uuid,
      }
    },
    saveDoc() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.ShowSnackbarError()
        return
      }
      this.$emit('edit-doc', this.localDoc)
      this.dialogDoc = false
    },
    viewDocument(doc) {
      if (this.ImageType.includes(doc.contentType)) {
        this.imageViewerSrc = {
          categoryName: doc?.project_document_category?.name || doc?.lib_document_category?.name,
          key: doc.key,
          title: doc.title,
          token: doc.token,
          uuid: doc.uuid,
        }
        this.imageViewer = true
      } else {
        this.pdfViewerSrc = {
          key: doc.key,
          token: doc.token,
        }
        this.pdfViewer = true
      }
    },
  },
}
</script>