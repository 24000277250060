<template lang="html">
  <v-dialog v-model="display" max-width="70%" @click:outside="$emit('input', false)">
    <v-card>
      <v-container class="d-flex justify-space-between py-1 px-3">
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-container>
      <v-card-text>
        <v-sheet class="px-6 py-2" outlined>
          <v-row>
            <v-col cols="12" sm="6" style="padding: 2px 4px">
              <helper-crud-list-filter
                v-model="options.filter"
                :filters.sync="filters"
                :style-class="styleClassCrudListFilter"
                :style-outlined="styleOutlinedCrudListFilter"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="deviceTypeSelected"
                :items="deviceTypeList"
                :label="$t('common.type')"
                :placeholder="$t('common.typeDevice')"
                height="46px"
                item-text="label"
                item-value="name"
                clearable
                deletable-chips
                dense
                multiple
                small-chips
              />
            </v-col>
          </v-row>
        </v-sheet>
        <v-data-table
          :headers="headers"
          :items="devices"
          :options.sync="options"
          :server-items-length="totalCount"
          dense
          :footer-props="{
            'items-per-page-text': itemsPerPageText,
            'page-text': pageText,
            'items-per-page-options': [20, 30, 40, 50],
          }"
          :no-data-text="$t('common.noDataText')"
          @click:row="selectConfig($event)"
        >
          <template #[`item.device_type`]="{ item }">
            <span>{{ getDeviceTypeLabel(item.device_type) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { GET_LIB_DEVICES, GET_LIB_DEVICE } from '@/graphql/LibDeviceQueries'
import DeviceType from '@/components/device_library/DeviceType'

export default {
  props: {
    filterType: {
      type: String,
      default: null,
    },
    styleClass: {
      type: String,
      default: '',
    },
    styleOutlined: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    devices: {
      query: GET_LIB_DEVICES,
      variables() {
        this.convertOptionsToGQL()
        return this.params
      },
      update(data) {
        return data.lib_device
      },
      result({ data }) {
        if (data) {
          this.totalCount = data.lib_device_aggregate.aggregate.totalCount
        }
      },
      skip() {
        return (
          !this.$store.getters['auth/isAuth'](['lib_device', 'read']) &&
          !this.$store.getters['auth/isAuth'](['lib_device_tag', 'read']) &&
          !this.$store.getters['auth/isAuth'](['lib_device_brand', 'read'])
        )
      },
    },
  },
  data() {
    return {
      DeviceType: DeviceType,
      dialog: false,
      filters: {},
      newFilterIn: [],
      options: {
        sortBy: ['model'],
        sortDesc: [false],
        filter: {},
      },
      styleClassCrudListFilter: 'my-0 pa-0',
      styleOutlinedCrudListFilter: 'off',
      totalCount: 0,
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('common.model'), value: 'model' },
        { text: this.$t('common.type'), value: 'device_type' },
        { text: this.$t('common.description'), value: 'description' },
      ]
    },
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    deviceTypeList() {
      return DeviceType
    },
    deviceTypeSelected: {
      get: function () {
        const device = []
        device.push(this.getDeviceTypeSelected())
        return device
      },
      set: function (value) {
        this.DeviceType = value
        this.newFilterIn = value
      },
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
    title() {
      return this.$t('common.addDevice')
    },
    typeDevice() {
      const device = []
      device.push({
        name: this.filterType,
        label: this.getDeviceTypeLabel(this.filterType),
      })
      return device
    },
    display() {
      return this.value
    },
  },
  watch: {
    filterType: {
      handler() {
        this.filters = {
          model: {
            type: 'v-text-field',
            col: { cols: 12 },
            options: {
              label: this.$t('common.model'),
              placeholder: this.$t('common.modelDevice'),
              clearable: true,
            },
            gql: (value) => ({ model: { _ilike: `%${value}%` } }),
            value: '',
          },
          ...(!this.filterType && {
            device_type: {
              type: 'v-select',
              col: { cols: 12 },
              options: {
                label: this.$t('common.type'),
                placeholder: this.$t('common.typeDevice'),
                clearable: true,
                items: DeviceType,
                'item-text': 'label',
                'item-value': 'name',
              },
              gql: (value) => ({ device_type: { _eq: value } }),
              value: '',
            },
          }),
        }
      },
    },
    newFilterIn: 'convertOptionsToGQL',
    options: {
      handler() {
        this.newFilterIn = []
        const name = this.getDeviceTypeSelected()
        if (!this.newFilterIn.includes(name)) {
          this.newFilterIn.push(name)
        }
        this.convertOptionsToGQL()
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
      this.$emit('input', false)
    },
    convertOptionsToGQL() {
      const { sortBy, sortDesc, page, itemsPerPage, filter } = this.options
      let newFilter = {}

      if (this.newFilterIn.length > 0) {
        newFilter = { device_type: { _in: this.newFilterIn } }
      }

      if (typeof filter._and !== 'undefined') {
        Object.assign(newFilter, filter)
      }

      this.params = {
        where: newFilter,
        order_by: sortBy.length ? { [sortBy]: sortDesc[0] ? 'asc' : 'desc' } : {},
        limit: itemsPerPage,
        offset: page - 1,
      }
    },
    getDeviceTypeLabel(name) {
      const index = DeviceType.findIndex(function (item) {
        return item.name === name
      })
      if (index !== -1) {
        return DeviceType[index].label
      }
    },
    getDeviceTypeSelected() {
      return this.typeDevice[0].name
    },
    async selectConfig(event) {
      try {
        const rv = await this.$apollo.query({
          query: GET_LIB_DEVICE,
          variables: { id: event.id },
        })
        rv.data.lib_device_by_pk.id = event.id
        this.$emit('selected', rv.data.lib_device_by_pk)
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="css" scoped>
>>> tbody tr {
  cursor: pointer;
}
.headline::first-letter {
  text-transform: capitalize;
}
</style>
