var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headersParamsConfig,"items":_vm.parametersList,"dense":"","fixed-header":"","footer-props":{
    'items-per-page-text': _vm.itemsPerPageText,
    'page-text': _vm.pageText,
    'items-per-page-options': [5, 10, 25, 50, -1],
  },"height":_vm.StyleHeightCalc('64px + 64px + 48px + 14px').height,"no-data-text":_vm.$t('common.noDataText')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('common.settingsDevice'))+" ("+_vm._s(_vm.target)+") ")]),_c('v-spacer'),_c('config-property-selector',{attrs:{"lib-device-tags":_vm.libDeviceTags,"target":_vm.target},on:{"close":_vm.close,"selected":_vm.addParameter}})],1)]},proxy:true},{key:"item.order",fn:function(ref){
  var item = ref.item;
return [_c('v-text-field',{staticStyle:{"width":"70px"},attrs:{"type":"number","dense":""},on:{"input":function($event){return _vm.updateParameterValue(item.id, 'order', $event)}},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]}},{key:"item.default_value",fn:function(ref){
  var item = ref.item;
return [_c('field-loader',{attrs:{"type":item.lib_device_parameter.field_type,"default-value":item.default_value,"field-config":item.lib_device_parameter.field_config,"name":_vm.$t('common.defaultValueThis', {
          attribute: item.lib_device_parameter.name.toLowerCase(),
        }),"dense":""},on:{"input":function($event){return _vm.updateParameterValue(item.id, 'default_value', $event.value)}}})]}},{key:"item.lib_device_parameter.field_type",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getConfigParamLabel(item.lib_device_parameter.field_type)))])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteParameter(item)}}},[_vm._v("$iconDelete")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }