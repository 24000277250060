<template>
  <v-container class="mx-0 pa-0">
    <v-card elevation="0" max-width="550" tile>
      <v-row align="center" no-gutters>
        <v-col>
          <v-card elevation="0" min-width="255px">
            <v-row justify="space-around" no-gutters>
              <v-col cols="6">
                <form-error
                  :validator="$v.dateLocaleFrom"
                  :attribute="$t('common.from')"
                  :messages="messages"
                >
                  <v-text-field
                    v-model="dateLocaleFrom"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :hint="dateFormatManual"
                    :label="$t('common.from')"
                    :placeholder="dateFormatManual"
                    :readonly="isReadonly"
                    class="input-manual-date"
                    dense
                    single-line
                    @focus="resetTimer()"
                    @input="$v.dateLocaleFrom.$touch()"
                    @blur="updateInterval()"
                  >
                    <template #prepend>
                      <span class="text-caption">
                        {{ $t('common.from') }}
                      </span>
                    </template>
                    <template #append>
                      <v-menu
                        ref="menuDateFrom"
                        v-model="menuDateFrom"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template #activator="{ on }">
                          <v-icon tabindex="-1" v-on="on" @focus="resetTimer()">
                            $iconEvent
                          </v-icon>
                        </template>
                        <v-date-picker
                          v-if="!isReadonly"
                          v-model="dateFrom"
                          :first-day-of-week="dateProps.firstDayOfWeek"
                          :max="dateProps.max"
                          color="secondary"
                          no-title
                          show-current
                          @input="menuDateFrom = false"
                        />
                      </v-menu>
                    </template>
                  </v-text-field>
                </form-error>
              </v-col>
              <v-col cols="4">
                <form-error
                  :validator="$v.timeFrom"
                  :attribute="$t('common.hour')"
                  :messages="messages"
                >
                  <v-text-field
                    v-model="timeFrom"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :label="$t('common.hour')"
                    :readonly="isReadonly"
                    hint="hh:mm"
                    class="input-manual-hour"
                    dense
                    single-line
                    @focus="resetTimer()"
                    @input="$v.timeFrom.$touch()"
                    @blur="updateInterval()"
                  >
                    <template #append>
                      <v-menu
                        ref="menuTimeFrom"
                        v-model="menuTimeFrom"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template #activator="{ on }">
                          <v-icon tabindex="-1" v-on="on" @focus="resetTimer()">
                            $iconCalendarClock
                          </v-icon>
                        </template>
                        <v-time-picker
                          v-if="!isReadonly"
                          v-model="timeFrom"
                          color="secondary"
                          format="24hr"
                          @click:minute="$refs.menuTimeFrom.save(menuTimeFrom)"
                        />
                      </v-menu>
                    </template>
                  </v-text-field>
                </form-error>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
          <v-card elevation="0" min-width="255px">
            <v-row justify="space-around" no-gutters>
              <v-col cols="6">
                <form-error
                  :validator="$v.dateLocaleTo"
                  :attribute="$t('common.to')"
                  :messages="messages"
                >
                  <v-text-field
                    v-model="dateLocaleTo"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :hint="dateFormatManual"
                    :label="$t('common.to')"
                    :placeholder="dateFormatManual"
                    :readonly="isReadonly"
                    class="input-manual-date"
                    dense
                    single-line
                    @focus="resetTimer()"
                    @input="$v.dateLocaleTo.$touch()"
                    @blur="updateInterval()"
                  >
                    <template #prepend>
                      <span class="text-caption">
                        {{ $t('common.to') }}
                      </span>
                    </template>
                    <template #append>
                      <v-menu
                        ref="menuDateTo"
                        v-model="menuDateTo"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template #activator="{ on }">
                          <v-icon tabindex="-1" v-on="on" @focus="resetTimer()">
                            $iconEvent
                          </v-icon>
                        </template>
                        <v-date-picker
                          v-if="!isReadonly"
                          v-model="dateTo"
                          :first-day-of-week="dateProps.firstDayOfWeek"
                          :max="dateProps.max"
                          color="secondary"
                          no-title
                          show-current
                          @input="menuDateTo = false"
                        />
                      </v-menu>
                    </template>
                  </v-text-field>
                </form-error>
              </v-col>
              <v-col cols="4">
                <form-error
                  :validator="$v.timeTo"
                  :attribute="$t('common.hour')"
                  :messages="messages"
                >
                  <v-text-field
                    v-model="timeTo"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :label="$t('common.hour')"
                    :readonly="isReadonly"
                    hint="hh:mm"
                    class="input-manual-hour"
                    dense
                    single-line
                    @focus="resetTimer()"
                    @input="$v.timeTo.$touch()"
                    @blur="updateInterval()"
                  >
                    <template #append>
                      <v-menu
                        ref="menuTimeTo"
                        v-model="menuTimeTo"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template #activator="{ on }">
                          <v-icon tabindex="-1" v-on="on" @focus="resetTimer()">
                            $iconCalendarClock
                          </v-icon>
                        </template>
                        <v-time-picker
                          v-if="!isReadonly"
                          v-model="timeTo"
                          color="secondary"
                          format="24hr"
                          @click:minute="$refs.menuTimeTo.save(menuTimeTo)"
                        />
                      </v-menu>
                    </template>
                  </v-text-field>
                </form-error>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {
  convertDateTime,
  dateIso8601ToDate,
  dateIso8601ToHour,
  dateTimeToIso8601,
  formatDate,
  formatDateTimeIso8601,
} from '@/locales/formats/dataTimeFormats'
import { date, hour } from '@/components/validators'
import * as moment from 'moment/moment'

export default {
  name: 'TimeInterval',
  props: {
    isDatePropsMax: {
      type: Boolean,
      default: true,
    },
    isInProgress: {
      type: Boolean,
      default: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    dateFrom: null,
    dateTo: null,
    dateLocaleFrom: null,
    dateLocaleTo: null,
    downloadTimer: 0,
    menuDateFrom: false,
    menuDateTo: false,
    menuTimeFrom: false,
    menuTimeTo: false,
    newValue: {},
    openInterval: false,
    timeFrom: '00:00',
    timeleft: -1,
    timeTo: '00:00',
  }),
  validations: {
    dateLocaleFrom: {
      date: date(),
    },
    dateLocaleTo: {
      date: date(),
    },
    timeFrom: { hour },
    timeTo: { hour },
  },
  computed: {
    dateMomentFormat() {
      return formatDate[this.userCurrentLang].momentjs
    },
    datetimePickerFormat() {
      return formatDate[this.userCurrentLang].picker
    },
    dateFormatManual() {
      return formatDate[this.userCurrentLang].manual
    },
    dateProps() {
      const obj = {
        firstDayOfWeek: this.userCurrentLang === 'fr' ? 1 : 0,
        max: this.isDatePropsMax ? new Date().toISOString() : undefined,
      }
      return obj
    },
    messages() {
      return this.$t('validationMessage')
    },
    userCurrentLang() {
      return this.$store.getters['auth/user']?.profile?.lang || 'fr'
    },
  },
  watch: {
    dateLocaleFrom: function (val) {
      this.assistedEntryDate(val, 'dateLocaleFrom')
      if (val && !this.$v.dateLocaleFrom.$invalid) {
        this.dateFrom = this.localeToDate(val)
      }
    },
    dateLocaleTo: function (val) {
      this.assistedEntryDate(val, 'dateLocaleTo')
      if (val && !this.$v.dateLocaleTo.$invalid) {
        this.dateTo = this.localeToDate(val)
      }
    },
    dateFrom: function (val) {
      this.dateLocaleFrom = val ? this.dateToLocale(val) : null
    },
    dateTo: function (val) {
      this.dateLocaleTo = val ? this.dateToLocale(val) : null
    },
    menuDateFrom: function (val) {
      if (!val) {
        this.updateInterval(25)
      }
    },
    menuDateTo: function (val) {
      if (!val) {
        this.updateInterval(25)
      }
    },
    menuTimeFrom: function (val) {
      if (!val) {
        this.updateInterval(25)
      }
    },
    menuTimeTo: function (val) {
      if (!val) {
        this.updateInterval(25)
      }
    },
    timeFrom: function (val) {
      this.assistedEntryHour(val, 'timeFrom')
    },
    timeleft: function (val) {
      if (val === 0) {
        this.$emit('input', this.newValue)
      }
      if (val === 10 && this.isInProgress) {
        const wip = this.$t('project.enum.stateLog.in_progress')
        this.$root.$dialogLoader.showSnackbar(wip, { color: 'primary' })
      }
    },
    timeTo: function (val) {
      this.assistedEntryHour(val, 'timeTo')
    },
    value: {
      handler: 'loadValue',
      immediate: true,
    },
  },
  methods: {
    assistedEntryDate(value, event) {
      if (value.length === 2 || value.length === 5) {
        this[event] = this.userCurrentLang === 'fr' ? value + '/' : value + '-'
      }
    },
    assistedEntryHour(value, event) {
      if (value.length === 2) {
        this[event] = value + ':'
      }
    },
    dateToLocale(date) {
      return moment(date).format(this.dateMomentFormat)
    },
    loadValue() {
      this.newValue = {}

      /**
       * date now
       * @type {Object}
       */
      const dateNow = moment()

      /**
       * date maximum
       * @type {String} Eg: 2020-12-14T14:14:14+01:00
       */
      const dateMax = convertDateTime(dateNow.clone())

      /**
       * date mininum
       * default: dateMax - 14 days
       * @type {String} Eg: 2020-10-14T14:14:14+01:00
       */
      const dateMin = dateNow.clone().subtract(14, 'days').format(formatDateTimeIso8601)

      if (this.value?.dateTimeFrom) {
        this.dateLocaleFrom = this.dateToLocale(dateIso8601ToDate(this.value.dateTimeFrom))
        this.timeFrom = dateIso8601ToHour(this.value.dateTimeFrom)
      } else {
        this.dateLocaleFrom = this.dateToLocale(dateIso8601ToDate(dateMin))
        this.timeFrom = dateIso8601ToHour(dateMin)
        this.newValue.dateTimeFrom = dateMin
      }

      if (this.value?.dateTimeTo) {
        this.dateLocaleTo = this.dateToLocale(dateIso8601ToDate(this.value.dateTimeTo))
        this.timeTo = dateIso8601ToHour(this.value.dateTimeTo)
      } else {
        this.dateLocaleTo = this.dateToLocale(dateIso8601ToDate(dateMax))
        this.timeTo = dateIso8601ToHour(dateMax)
        this.newValue.dateTimeTo = dateMax
        this.$emit('input', this.newValue)
      }
    },
    localeToDate(date) {
      return moment(date, this.dateMomentFormat).format(formatDate.en.momentjs)
    },
    resetTimer() {
      this.$root.$dialogLoader.hideSnackbar()
      clearInterval(this.downloadTimer)
    },
    startTimer(timeleft) {
      this.timeleft = timeleft || 20
      this.downloadTimer = setInterval(() => {
        if (this.timeleft <= 0) {
          this.resetTimer()
        } else {
          this.timeleft -= 1
        }
      }, 100)
    },
    updateInterval(timeleft) {
      if (
        this.menuDateFrom ||
        this.menuDateTo ||
        this.menuTimeFrom ||
        this.menuTimeTo ||
        this.$v.dateLocaleFrom.$invalid ||
        this.$v.dateLocaleTo.$invalid ||
        this.$v.timeFrom.$invalid ||
        this.$v.timeTo.$invalid
      ) {
        this.resetTimer()
        return
      }

      const newValue = Object.assign({}, this.value)
      this.newValue = {}

      newValue.dateTimeFrom = dateTimeToIso8601(
        this.localeToDate(this.dateLocaleFrom) + 'T' + this.timeFrom
      )
      newValue.dateTimeTo = dateTimeToIso8601(
        this.localeToDate(this.dateLocaleTo) + 'T' + this.timeTo
      )

      const diff = newValue.dateTimeFrom >= newValue.dateTimeTo

      if (diff && !this.isReadonly) {
        clearInterval(this.downloadTimer)
        this.$root.$dialogLoader.showSnackbar(this.$t('common.invalidPeriod'), {
          color: 'red',
        })
      } else {
        this.startTimer(timeleft)
        this.newValue = newValue
      }
    },
  },
}
</script>

<style>
.input-manual-date {
  min-width: 150px;
}
.input-manual-hour {
  width: 75px;
}
</style>
