import gql from 'graphql-tag'

const alertContactPointFragment = gql`
  fragment AlertContactPoint on alert_contact_point {
    uuid
    name
    type
    options
    alert_contact_point_crm_contacts {
      crm_contact {
        uuid
        firstname
        lastname
      }
    }
    updated_at
    created_at
  }
`

export const GET_ALERT_CONTACT_POINT = gql`
  query ($uuid: uuid!) {
    alert_contact_point_by_pk(uuid: $uuid) {
      ...AlertContactPoint
    }
  }
  ${alertContactPointFragment}
`

export const GET_ALERT_CONTACT_POINTS = gql`
  query contact(
    $where: alert_contact_point_bool_exp
    $order_by: [alert_contact_point_order_by!]
    $limit: Int
    $offset: Int
  ) {
    alert_contact_point_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    alert_contact_point(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...AlertContactPoint
    }
  }
  ${alertContactPointFragment}
`

export const INSERT_ALERT_CONTACT_POINT_ONE = gql`
  mutation insert_alert_contact_point_one($object: alert_contact_point_insert_input!) {
    insert_alert_contact_point_one(object: $object) {
      ...AlertContactPoint
    }
  }
  ${alertContactPointFragment}
`

export const UPDATE_ALERT_CONTACT_POINT_BY_PK = gql`
  mutation update_alert_contact_point_by_pk(
    $uuid: uuid!
    $object: alert_contact_point_set_input!
    $crm_contacts: [alert_contact_point_crm_contact_insert_input!]!
  ) {
    delete_alert_contact_point_crm_contact(where: { alert_contact_point_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_alert_contact_point_crm_contact(objects: $crm_contacts) {
      affected_rows
    }
    update_alert_contact_point_by_pk(pk_columns: { uuid: $uuid }, _set: $object) {
      ...AlertContactPoint
    }
  }
  ${alertContactPointFragment}
`

export const DELETE_ALERT_CONTACT_POINT = gql`
  mutation ($uuid: uuid!) {
    delete_alert_contact_point(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
        created_at
        updated_at
      }
    }
  }
`
