import gql from 'graphql-tag'

export const GET_LIB_DEVICE_BRANDS = gql`
  query lib_device_brand(
    $where: lib_device_brand_bool_exp
    $order_by: [lib_device_brand_order_by!]
    $limit: Int
    $offset: Int
  ) {
    lib_device_brand_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    lib_device_brand(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      id
      name
      updated_at
      created_at
    }
  }
`

export const POST_LIB_DEVICE_BRAND = gql`
  mutation ($objects: [lib_device_brand_insert_input!]!) {
    insert_lib_device_brand(objects: $objects) {
      returning {
        id
        name
        updated_at
        created_at
      }
    }
  }
`

export const PATCH_LIB_DEVICE_BRAND = gql`
  mutation ($id: Int!, $body: lib_device_brand_set_input!) {
    update_lib_device_brand(where: { id: { _eq: $id } }, _set: $body) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`

export const DELETE_LIB_DEVICE_BRAND = gql`
  mutation ($id: Int!) {
    delete_lib_device_brand(where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`
