var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dashboardDefs.gqlobject)?_c('helper-crud-list',_vm._b({on:{"viewItem":function($event){return _vm.viewItem($event)}},scopedSlots:_vm._u([{key:"item.user_uuid",fn:function(ref){
var item = ref.item;
return [(!_vm.isDrawerLocal)?_c('span',[_vm._v(" "+_vm._s(_vm.UsersFullname[item.user_uuid])+" ")]):_vm._e()]}},(!_vm.isDrawerLocal)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.viewItem(item)}}},[_vm._v(" $iconEye ")]),(!_vm.isAuthor(item))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.isAuthor(item))?_c('v-icon',_vm._g({staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.subscribe(item)}}},on),[_vm._v(" "+_vm._s(_vm.isSubscribe(item) ? '$iconStar' : '$iconStarOff')+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.isSubscribe(item) ? _vm.$t('dashboard.bookmarkDelete') : _vm.$t('dashboard.bookmarkAdd'))+" ")])]):_vm._e(),(_vm.isAuthor(item) && !_vm.IsMobile())?_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" $iconEdit ")]):_vm._e(),(_vm.isAuthor(item) && !_vm.IsMobile())?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" $iconDelete ")]):_vm._e()]}}:{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.isAuthor(item))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.isSubscribe(item) ? '$iconStar' : '')+" ")]):_vm._e()]}},{key:"form",fn:function(ref){
var editedId = ref.editedId;
var listparams = ref.listparams;
var closeForm = ref.closeForm;
return [_c('dashboard-form',{attrs:{"value":editedId,"listparams":listparams},on:{"closeForm":closeForm}})]}}],null,true)},'helper-crud-list',_vm.dashboardDefs,false)):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }