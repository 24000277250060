import gql from 'graphql-tag'

// queries
export const GET_USERS_PROJECTS = gql`
  query user_project(
    $where: user_project_bool_exp
    $order_by: [user_project_order_by!]
    $limit: Int
    $offset: Int
  ) {
    user_project_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    user_project(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      user_uuid
      project_uuid
      notification
    }
  }
`

export const GET_USERS_PROJECT_NOTIFICATION = gql`
  query user_project(
    $where: user_project_bool_exp
    $order_by: [user_project_order_by!]
    $limit: Int
    $offset: Int
  ) {
    user_project(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      user_uuid
      project_uuid
      notification
      user {
        active
        crm_contact {
          lastname
          firstname
          crm_company {
            name
            other_name
          }
          crm_emails {
            email
            type
          }
        }
      }
    }
  }
`

// mutations
export const UPSERT_USER_PROJECTS = gql`
  mutation (
    $objects: [user_project_insert_input!]!
    $update_columns: [user_project_update_column!]! = []
  ) {
    insert_user_project(
      objects: $objects
      on_conflict: { constraint: user_project_pkey, update_columns: $update_columns }
    ) {
      returning {
        user_uuid
        project_uuid
      }
    }
  }
`

export const PATCH_USER_PROJECT_NOTIFICATION = gql`
  mutation ($user_uuid: uuid!, $project_uuid: uuid!, $_set: user_project_set_input = {}) {
    update_user_project(
      where: { project_uuid: { _eq: $project_uuid }, user_uuid: { _eq: $user_uuid } }
      _set: $_set
    ) {
      affected_rows
      returning {
        user_uuid
        project_uuid
        notification
      }
    }
  }
`

export const DELETE_USERS_PROJECTS = gql`
  mutation ($user_uuid: uuid_comparison_exp = {}, $project_uuid: uuid_comparison_exp = {}) {
    delete_user_project(where: { user_uuid: $user_uuid, project_uuid: $project_uuid }) {
      affected_rows
    }
  }
`
