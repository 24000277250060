<template lang="html">
  <form ref="fileform" class="fill-height ma-0 pa-0">
    <v-card>
      <v-tabs background-color="grey lighten-5" centered color="quaternary" slider-size="2">
        <v-tab>
          <v-icon left>$iconViewList</v-icon>
        </v-tab>
        <v-tab>
          <v-icon left>$iconApps</v-icon>
        </v-tab>
        <v-tab v-if="$store.getters['auth/isAuth'](['project_device_document', 'create'])">
          <v-icon left>$iconPaperclip</v-icon>
        </v-tab>

        <v-tab-item>
          <v-card class="drop-zone" flat>
            <v-card-subtitle>
              <v-row align="baseline" justify="space-between">
                <v-col class="pr-0" cols="6" md="2">
                  <v-select
                    v-model="categorySelected"
                    :items="categoryList"
                    :label="$tc('common.category', 1)"
                    :menu-props="{ top: false, offsetY: true }"
                    :no-data-text="$t('common.noDataText')"
                    :placeholder="$t('common.select')"
                    item-text="name"
                    item-value="id"
                    clearable
                    dense
                    hide-details
                    small-chips
                  />
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="documents"
                :expanded.sync="expanded"
                :no-data-text="$t('common.noDataText')"
                :options.sync="options"
                :single-expand="isTrue"
                :footer-props="{
                  'items-per-page-text': itemsPerPageText,
                  'page-text': pageText,
                  'items-per-page-options': [5, 10, 25, 50, 100],
                }"
                item-key="project_device_document_uuid"
                dense
                show-expand
              >
                <template #[`item.thumbnail`]="{ item }">
                  <thumbnail :doc="getThumbnail(item)" :is-hover="isTrue" size="36" />
                </template>

                <template #[`item.data-table-expand`]="{ item }">
                  <v-btn
                    v-if="item.project_device_document.description"
                    icon
                    @click="isExpand(item)"
                  >
                    <v-icon>
                      {{
                        !compareExpand([...item], expanded) ? '$iconArrowRight' : '$iconArrowDown'
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <template #expanded-item="{ item }">
                  <td :colspan="headers.length" class="py-4">
                    {{ item.project_device_document.description }}
                  </td>
                </template>

                <template #[`item.type`]="{ item }">
                  {{ GetStringSplit(item.project_device_document.file.contentType, '/') }}
                </template>
                <template #[`item.size`]="{ item }">
                  <!-- transform bytes size to Mo -->
                  {{
                    parseFloat(item.project_device_document.file.size / (1024 * 1024)).toFixed(2)
                  }}
                  Mo
                </template>
                <template #[`item.project_device_document.user_uuid`]="{ item }">
                  {{ UsersFullname[item.project_device_document.user_uuid] }}
                </template>
                <template #[`item.project_device_document.updated_at`]="{ item }">
                  {{ item.project_device_document.updated_at | formatDate }}
                </template>
                <template #[`item.action`]="{ item }">
                  <v-tooltip v-if="ShowDoc(item.project_device_document.file.contentType)" left>
                    <template #activator="{ on }">
                      <v-btn icon x-small @click="viewDocument(item)" v-on="on">
                        <v-icon>$iconEye</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('common.see') }}
                      {{ item.project_device_document.title }}
                    </span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on }">
                      <v-btn
                        class="mx-2"
                        icon
                        x-small
                        v-on="on"
                        @click="
                          Download(
                            item.project_device_document.file.key,
                            getToken(item),
                            item.project_device_document.title
                          )
                        "
                      >
                        <v-icon>$iconDownload</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.upload') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="$store.getters['auth/isAuth'](['project_device_document', 'update'])"
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-btn class="mr-2" icon x-small v-on="on" @click="editDoc(item)">
                        <v-icon>$iconEdit</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.edit') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="$store.getters['auth/isAuth'](['project_device_document', 'delete'])"
                    bottom
                  >
                    <template #activator="{ on }">
                      <v-btn icon x-small v-on="on" @click="deleteDoc(item)">
                        <v-icon>$iconDelete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.delete') }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card class="drop-zone" flat>
            <v-card-text>
              <v-row>
                <v-col v-if="documentsGallery.length === 0">
                  <span class="d-block text-center text--disabled">
                    {{ $t('common.noDataText') }}
                  </span>
                </v-col>
              </v-row>
              <gallery
                :documents="documentsGallery"
                :is-delete="$store.getters['auth/isAuth'](['project_device_document', 'delete'])"
                :is-edit="$store.getters['auth/isAuth'](['project_device_document', 'update'])"
                size="150"
                @delete-doc="deleteDoc"
                @edit-doc="editDocFromGallery"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="$store.getters['auth/isAuth'](['project_device_document', 'create'])">
          <v-card flat>
            <v-card-text class="drop-zone">
              <file-input :button-save-form="isTrue" @click-save-form="saveFileInput" />
              <span>
                {{ $t('common.dragAndDropDoc') }}
              </span>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <document-edit
      v-model="documentEdit"
      :document="documentEditSrc"
      @edit-doc="updateDocumentEdit"
    />
    <image-viewer v-model="imageViewer" :image="imageViewerSrc" />
    <pdf-viewer v-model="pdfViewer" :pdf-file="pdfViewerSrc" />
  </form>
</template>

<script>
import {
  GET_PROJECT_DEVICE_DOCUMENT,
  UPDATE_PROJECT_DEVICE_DOCUMENT_BY_PK,
  DELETE_PROJECT_DEVICE_DOCUMENT_BY_PK,
} from '@/graphql/ProjectDeviceDocumentQueries'
import {
  POST_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_ONE,
  PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_AGGREGATE,
  SUBSCRIPTION_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS,
  DELETE_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_BY_PK,
} from '@/graphql/ProjectDevicesProjectDeviceDocumentsQueries'
import DocumentEdit from '@/components/helper/DocumentEdit'
import FileInput from '@/components/helper/FileInput'
import Gallery from '@/components/helper/Gallery'
import ImageViewer from '@/components/helper/ImageViewer'
import PdfViewer from '@/components/helper/PdfViewer'
import Thumbnail from '@/components/helper/Thumbnail'
import { CommonMixin } from '@/mixins/CommonMixin'
import { DocumentMixin } from '@/mixins/DocumentMixin'
import { UserMixin } from '@/mixins/UserMixin'
import { storageClient } from '@/plugins/storage'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import merge from 'lodash/merge'
import uniqWith from 'lodash/uniqWith'

export default {
  name: 'DeviceDialogTabDocument',
  components: {
    DocumentEdit,
    FileInput,
    Gallery,
    ImageViewer,
    PdfViewer,
    Thumbnail,
  },
  mixins: [CommonMixin, DocumentMixin, UserMixin],
  props: {
    deviceUuid: {
      type: String,
      default: '',
    },
  },
  apollo: {
    $subscribe: {
      documents: {
        query: SUBSCRIPTION_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS,
        variables() {
          let vars = {
            where: {
              project_device_uuid: { _eq: this.deviceUuid },
            },
            order_by: {
              project_device_document: {
                updated_at: 'desc',
              },
            },
          }

          if (this.categorySelected) {
            const where = {
              where: {
                project_device_document: {
                  project_device_document_category_id: {
                    _eq: this.categorySelected,
                  },
                },
              },
            }
            vars = merge(vars, where)
          }

          return vars
        },
        result({ data }) {
          if (data) {
            this.documents = data.project_devices_project_device_documents
          }
        },
      },
    },
  },
  data: () => ({
    categorySelected: null,
    documentEdit: false,
    documentEditSrc: undefined,
    documents: [],
    expanded: [],
    expandedCurrent: [],
    imageViewer: false,
    imageViewerSrc: undefined,
    isTrue: true,
    loading: false,
    options: {
      itemsPerPage: 50,
      sortBy: ['project_device_document.updated_at'],
      sortDesc: [true],
      filter: {},
    },
    pdfViewer: false,
    pdfViewerSrc: undefined,
    users: [],
  }),
  computed: {
    categoryList() {
      return this.getCategory()
    },
    documentsGallery() {
      return this.getDocumentsGallery()
    },
    headers: function () {
      return [
        {
          text: this.$t('project.thumbnail'),
          value: 'thumbnail',
          sortable: false,
          cellClass: 'pl-1 py-2',
          class: 'pl-1',
        },
        {
          text: this.$t('common.title'),
          value: 'project_device_document.title',
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: 'px-0',
          class: 'px-0',
          align: 'left',
          sortable: false,
          width: '36px',
        },
        {
          text: this.$tc('common.category', 1),
          value: 'project_device_document.project_device_document_category.name',
        },
        { text: this.$t('common.type'), value: 'type' },
        { text: this.$t('project.size'), value: 'size' },
        {
          text: this.$t('common.owner'),
          value: 'project_device_document.user_uuid',
        },
        {
          text: this.$t('common.updated_at'),
          value: 'project_device_document.updated_at',
          align: 'center',
          class: 'text-center',
        },
        {
          text: this.$tc('common.action', 1),
          value: 'action',
          cellClass: 'pl-0 pr-1',
          class: 'pl-0 pr-1',
          align: 'right',
          sortable: false,
          width: '120px',
        },
      ]
    },
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
  },
  mounted() {
    if (this.$store.getters['auth/isAuth'](['project_device_document', 'create'])) {
      ;['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
        function (evt) {
          /**
           * For each event add an event listener that prevents the default
           * action (opening the file in the browser) and stop the propagation
           * of the event (so no other elements open the file in the browser)
           */
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault()
              e.stopPropagation()
            },
            false
          )
        }.bind(this)
      )

      // Add an event listener for drop to the form
      this.$refs.fileform.addEventListener('drop', (e) => this.uploadDoc(e))
    }
  },
  methods: {
    compareExpand(expanded, expandedCurrent) {
      return isEqual(expanded, expandedCurrent)
    },
    editDoc(doc) {
      this.documentEditSrc = {
        contentType: doc.project_device_document.file.contentType,
        description: doc.project_device_document.description,
        datetime: doc.project_device_document.created_at,
        key: doc.project_device_document.file.key,
        project_document_category: {
          id: doc.project_device_document.project_device_document_category.id,
          name: doc.project_device_document.project_device_document_category.name,
        },
        title: doc.project_device_document.title,
        token: doc.project_device_document.file.userMetadata['X-Amz-Meta-Token'],
        uuid: doc.project_device_document.uuid,
      }
      this.documentEdit = true
    },
    editDocFromGallery(doc) {
      const docGallery = this.documents.find(
        (i) =>
          i.project_device_document.file.key === doc.key &&
          i.project_device_document.file.userMetadata['X-Amz-Meta-Token'] === doc.token
      )
      this.editDoc(docGallery)
    },
    getCategory() {
      const list = []
      this.documents?.filter((i) =>
        list.push(i.project_device_document.project_device_document_category)
      )
      const uniq = uniqWith(list, isEqual)
      const orderByUniq = orderBy(uniq, ['name'], ['asc'])
      return orderByUniq
    },
    getDocumentsGallery() {
      const list = []
      this.documents?.forEach((doc) => {
        const token = doc.project_device_document.file.userMetadata['X-Amz-Meta-Token']
        list.push({
          categoryName: doc.project_device_document.project_device_document_category.name,
          contentType: doc.project_device_document.file.contentType,
          datetime: doc.project_device_document.created_at,
          key: doc.project_device_document.file.key,
          project_device_uuid: doc.project_device_uuid,
          project_device_document_uuid: doc.project_device_document_uuid,
          title: doc.project_device_document.title,
          token: token,
          uuid: doc.project_device_document_uuid,
        })
      })
      return list
    },
    getToken(item) {
      return item.project_device_document.file.userMetadata['X-Amz-Meta-Token']
    },
    getThumbnail(doc) {
      return {
        datetime: doc.project_device_document.created_at,
        key: doc.project_device_document.file.key,
        title: doc.project_device_document.title,
        token: this.getToken(doc),
      }
    },
    isExpand(item) {
      this.expandedCurrent = []
      this.expandedCurrent.push(item)
      if (this.compareExpand(this.expanded, this.expandedCurrent)) {
        this.expanded = []
      } else {
        this.expanded = []
        this.expanded.push(item)
      }
    },
    saveFileInput(val) {
      this.uploadDoc(val, true)
    },
    viewDocument(doc) {
      const key = doc.project_device_document.file.key
      const token = doc.project_device_document.file.userMetadata['X-Amz-Meta-Token']
      if (this.ImageType.includes(doc.project_device_document.file.contentType)) {
        this.imageViewerSrc = {
          categoryName: doc.project_device_document.project_device_document_category.name,
          key: key,
          title: doc.project_device_document.title,
          token: token,
          uuid: doc.project_device_document_uuid,
        }
        this.imageViewer = true
      } else {
        this.pdfViewerSrc = {
          key: key,
          token: token,
        }
        this.pdfViewer = true
      }
    },
    async associateDoc(name, path) {
      const msgTitle =
        this.GetFirstLetterUppercase(this.$tc('common.info', 1)) +
        ' ' +
        this.$tc('common.document', 1)
      const msgBody = this.$t('common.docSavedAssociate', { name: name })
      const msgProcess = this.$t('common.finalizationInProgress')

      const projectDeviceDocumentUuid = await this.getProjectDeviceDocumentUuid(path)

      const search = this.documents.find(
        (i) => i.project_device_document_uuid === projectDeviceDocumentUuid
      )

      if (search) {
        const errorForm = this.$t('common.fileExistsRenameIt', { name: name })
        this.ShowSnackbarError(errorForm)
      } else {
        if (await this.$root.$confirm(msgTitle, msgBody, { color: 'red' })) {
          await this.$root.$dialogLoader.start(msgProcess, {}, () => {
            try {
              return new Promise(() => {
                try {
                  this.$apollo.mutate({
                    mutation: POST_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_ONE,
                    variables: {
                      project_device_uuid: this.deviceUuid,
                      project_device_document_uuid: projectDeviceDocumentUuid,
                    },
                  })
                } catch (e) {
                  console.log('POST_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_ONE', e)
                }
              })
            } catch (e) {
              console.log('associateDoc try', e)
            }
          })
        }
      }
    },
    async deleteDoc(doc) {
      const projectDeviceUuid = doc.project_device_uuid
      const projectDeviceDocumentUuid = doc.project_device_document_uuid
      const docTitle = doc.title || doc.project_device_document.title
      const removal = this.$t('common.removal')
      const removalFormatting = this.$t('common.removalFormatting', {
        attribute: docTitle,
      })
      const sureToDeleteThis = this.$t('common.sureToDeleteThis', {
        attribute: docTitle,
      })

      if (await this.$root.$confirm(removal, sureToDeleteThis, { color: 'red' })) {
        await this.$root.$dialogLoader.start(removalFormatting + '...', {}, () => {
          try {
            return new Promise(() => {
              try {
                this.$apollo.mutate({
                  mutation: DELETE_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_BY_PK,
                  variables: {
                    project_device_uuid: projectDeviceUuid,
                    project_device_document_uuid: projectDeviceDocumentUuid,
                  },
                })
                this.deleteProjectDeviceDocument(projectDeviceDocumentUuid)
              } catch (e) {
                console.log('DELETE_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_BY_PK', e)
              }
            })
          } catch (e) {
            console.log('deleteDoc try', e)
          }
        })
      }
    },
    async deleteProjectDeviceDocument(projectDeviceDocumentUuid) {
      const totalCountDocument = await this.getTotalProjectDocument(projectDeviceDocumentUuid)
      if (totalCountDocument <= 1) {
        await this.$apollo
          .mutate({
            mutation: DELETE_PROJECT_DEVICE_DOCUMENT_BY_PK,
            variables: {
              uuid: projectDeviceDocumentUuid,
            },
          })
          .catch((error) => {
            this.ShowSnackbarError('DELETE_PROJECT_DEVICE_DOCUMENT_BY_PK: ' + error.message)
            throw error
          })
      }
    },
    async getProjectDeviceDocumentUuid(key) {
      const keyEncode = encodeURIComponent(key)
      const resp = await this.$apollo
        .mutate({
          mutation: GET_PROJECT_DEVICE_DOCUMENT,
          variables: {
            where: {
              file: {
                _contains: {
                  key: keyEncode,
                },
              },
            },
            limit: 1,
          },
        })
        .catch((error) => {
          this.ShowSnackbarError('GET_PROJECT_DEVICE_DOCUMENT: ' + error.message)
          throw error
        })
      return resp?.data?.project_device_document[0]?.uuid
    },
    async getTotalProjectDocument(projectDeviceDocumentUuid) {
      let totalCountDocument = null
      await this.$apollo
        .mutate({
          mutation: PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_AGGREGATE,
          variables: {
            where: {
              project_device_document_uuid: {
                _eq: projectDeviceDocumentUuid,
              },
            },
          },
          // eslint-disable-next-line
          update: (
            cache,
            { data: { project_devices_project_device_documents_aggregate } }
          ) => {
            // eslint-disable-next-line
            totalCountDocument =
              project_devices_project_device_documents_aggregate.aggregate
                .count;
          },
        })
        .catch((error) => {
          this.ShowSnackbarError(
            'PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_AGGREGATE: ' + error.message
          )
          throw error
        })
      return totalCountDocument
    },
    async isFileExist(name, path) {
      const isInDB = await this.getProjectDeviceDocumentUuid(path)
      const isInStoreDb = await this.IsDocExist(name, path, isInDB)
      if (isInStoreDb) {
        await this.associateDoc(name, path)
      }
      return isInStoreDb
    },
    async updateDocumentEdit(doc) {
      const description = doc.description
      await this.$apollo
        .mutate({
          mutation: UPDATE_PROJECT_DEVICE_DOCUMENT_BY_PK,
          variables: {
            uuid: doc.uuid,
            _set: {
              project_device_document_category_id: doc.project_document_category.id,
              title: doc.title,
              description: description ? description.trim() : description,
            },
          },
        })
        .then((data) => {
          if (data) this.ShowSnackbarSuccess()
        })
        .catch((error) => {
          this.ShowSnackbarError('UPDATE_PROJECT_DEVICE_DOCUMENT_BY_PK: ' + error.message)
          throw error
        })
    },
    async uploadDoc(event, noDrop) {
      const files = noDrop ? event : event.dataTransfer.files
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const name = this.NormalizeString(file.name)
        const path =
          'project/' + this.$store.getters['project/currentProjectId'] + '/doc/original/' + name

        // Check if file is stored and in db
        const isInStoreDb = await this.isFileExist(name, path)

        if (!isInStoreDb) {
          try {
            this.loading = true
            const categoryId = this.categorySelected ? this.categorySelected.toString() : '1'
            await storageClient.put(path, file, {
              originalname: name,
              project_device_document_category_id: categoryId,
              project_device_uuid: this.deviceUuid,
              user_uuid: this.$store.getters['auth/user'].id,
            })
            this.loading = false
            this.ShowSnackbarSuccess()
          } catch (e) {
            console.log('uploadDoc', e)
          }
        }
      }
    },
  },
}
</script>

<style>
.drop-zone {
  border: 1px dashed #bdbdbd;
  border-radius: 5px;
  color: #bdbdbd;
  min-height: calc(
    100vh - (64px + 112px + 12px)
  ); /* appBar: 64px + header: 112px + margin/padding: 12px*/
  text-align: center;
}
</style>
