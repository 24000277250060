<template>
  <v-container fluid class="pa-0">
    <v-card flat outlined class="px-0 pt-2">
      <v-card-subtitle class="pa-0">
        <v-row align="baseline" justify="space-between" :no-gutters="isDrawer">
          <v-col :class="isDrawer ? '' : 'pr-0'" :cols="isDrawer ? 12 : 6" :md="isDrawer ? 12 : 2">
            <v-text-field
              v-model="searchDocument"
              :append-icon="'$iconMagnify'"
              :label="$t('common.title')"
              class="px-2"
              clearable
              dense
              :hide-details="!isDrawer"
            />
          </v-col>
          <v-col :class="isDrawer ? '' : 'pr-0'" :cols="isDrawer ? 12 : 6" :md="isDrawer ? 12 : 2">
            <v-select
              v-model="categorySelected"
              :items="categoryList"
              :label="$tc('common.category', 1)"
              :menu-props="{ top: false, offsetY: true }"
              :no-data-text="$t('common.noDataText')"
              :placeholder="$t('common.select')"
              item-text="name"
              item-value="id"
              class="px-2"
              clearable
              dense
              :hide-details="!isDrawer"
              small-chips
            />
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text class="pa-0" :style="styleCalcHeight">
        <v-data-table
          :headers="headers"
          :items="documents"
          :item-class="rowClasses"
          :disable-pagination="source === 'drawer'"
          :footer-props="{
            'items-per-page-text': itemsPerPageText,
            'page-text': pageText,
            'items-per-page-options': [5, 10, 25, 50, 100],
          }"
          :hide-default-footer="source === 'drawer'"
          :no-data-text="$t('common.noDataText')"
          :options.sync="options"
          :search="searchDocument"
          item-key="project_device_document_uuid"
          dense
          @click:row="rowClick"
        >
          <template #[`item.thumbnail`]="{ item }">
            <thumbnail
              :doc="{
                datetime: item.project_device_document.created_at,
                key: item.project_device_document.file.key,
                title: item.project_device_document.title,
                token: getToken(item),
              }"
              :is-hover="isTrue"
              :size="sizeThumbnail()"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <image-viewer v-model="imageViewer" :image="imageViewerSrc" />
    <pdf-viewer v-model="pdfViewer" :pdf-file="pdfViewerSrc" />
  </v-container>
</template>

<script>
import {
  PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS,
  SUBSCRIPTION_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS,
} from '@/graphql/ProjectDevicesProjectDeviceDocumentsQueries'
import { CommonMixin } from '@/mixins/CommonMixin'
import { DocumentMixin } from '@/mixins/DocumentMixin'
import { mapGetters } from 'vuex'
import ImageViewer from '@/components/helper/ImageViewer'
import PdfViewer from '@/components/helper/PdfViewer'
import Thumbnail from '@/components/helper/Thumbnail'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import orderBy from 'lodash/orderBy'
import uniqWith from 'lodash/uniqWith'

export default {
  name: 'ProjectDocumentList',
  components: {
    ImageViewer,
    PdfViewer,
    Thumbnail,
  },
  mixins: [CommonMixin, DocumentMixin],
  props: {
    isView: {
      type: Boolean,
      default: true,
    },
    // Where is the component called
    source: {
      type: String,
      default: '', // 'drawer' || ...
    },
  },
  apollo: {
    project_devices_project_device_documents: {
      query: PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS,
      pollInterval: 300000, // every 5 mn
      variables() {
        return this.queryVarsDocument()
      },
      result({ data }) {
        if (data) {
          this.documentsCategories = data.project_devices_project_device_documents
        }
      },
    },
    $subscribe: {
      project_devices_project_device_documents: {
        query: SUBSCRIPTION_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS,
        variables() {
          return this.varsDocument
        },
        result({ data }) {
          if (data) {
            this.documents = data.project_devices_project_device_documents
          }
        },
      },
    },
  },
  data() {
    return {
      categorySelected: null,
      documents: [],
      documentsCategories: [],
      imageViewer: false,
      imageViewerSrc: undefined,
      isTrue: true,
      options: {
        itemsPerPage: 50,
        sortBy: ['project_device_document.created_at'],
        sortDesc: [true],
      },
      pdfViewer: false,
      pdfViewerSrc: undefined,
      searchDocument: '',
      varsDocument: {},
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
    categoryList() {
      return this.getCategory()
    },
    clickRow() {
      return this.isDrawer && this.isView
    },
    headers: function () {
      // note: space before d-none for not display column
      return [
        {
          text: this.isDrawer ? '' : this.$t('project.thumbnail'),
          value: 'thumbnail',
          filterable: false,
          sortable: false,
          cellClass: 'pl-1 py-2',
          class: 'pl-1',
        },
        {
          text: this.$t('common.title'),
          value: 'project_device_document.title',
        },
        {
          text: this.$tc('common.category', 1),
          value: 'project_device_document.project_device_document_category.name',
          align: this.isDrawer ? ' d-none' : '',
          filterable: false,
        },
      ]
    },
    isDrawer() {
      return this.source === 'drawer'
    },
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
    styleCalcHeight() {
      let style = {}
      if (this.isDrawer) {
        /* Search category: 48px */
        style = this.StyleHeightCalc('186px + 48px', true)
      } else {
        /* Toolbar: 64px + Search: 44px + padding: 8px */
        style = this.StyleHeightCalc('64px + 44px + 26px', true)
      }
      return style
    },
  },
  watch: {
    categorySelected: function (val) {
      if (val) {
        const vars = {
          where: {
            project_device_document: {
              project_device_document_category_id: {
                _eq: val,
              },
            },
          },
        }
        this.varsDocument = this.queryVarsDocument(vars)
      } else {
        this.varsDocument = this.queryVarsDocument()
      }
    },
    currentProjectId: function (val, oldVal) {
      if (oldVal !== val) {
        this.varsDocument = this.queryVarsDocument()
      }
    },
  },
  mounted() {
    this.varsDocument = this.queryVarsDocument()
  },
  methods: {
    getCategory() {
      const list = []
      this.documentsCategories?.filter((i) =>
        list.push(i.project_device_document.project_device_document_category)
      )
      const uniq = uniqWith(list, isEqual)
      const orderByUniq = orderBy(uniq, ['name'], ['asc'])
      return orderByUniq
    },
    getToken(item) {
      return item.project_device_document.file.userMetadata['X-Amz-Meta-Token']
    },
    queryVarsDocument(options) {
      let vars = {
        where: {
          project_device: {
            project_id: {
              _eq: this.currentProjectId,
            },
          },
        },
        order_by: {
          project_device_document: {
            created_at: 'desc',
          },
        },
      }

      if (options) {
        vars = merge(vars, options)
      }

      return vars
    },
    rowClasses() {
      return this.clickRow ? 'pointer' : null
    },
    sizeThumbnail() {
      let size = '36'
      if (this.isDrawer) {
        size = '24'
      }
      return size
    },
    viewDocument(doc) {
      const contentType = doc.project_device_document.file.contentType
      const key = doc.project_device_document.file.key
      const token = doc.project_device_document.file.userMetadata['X-Amz-Meta-Token']
      if (this.ShowDoc(contentType)) {
        if (this.ImageType.includes(contentType)) {
          this.imageViewerSrc = {
            categoryName: doc.project_device_document.project_device_document_category.name,
            key: key,
            title: doc.project_device_document.title,
            token: token,
            uuid: doc.project_device_document_uuid,
          }
          this.imageViewer = true
        } else {
          this.pdfViewerSrc = {
            key: key,
            token: token,
          }
          this.pdfViewer = true
        }
      } else {
        const url = this.GetUrlDoc(key, token)
        window.open(url, '_blank').focus()
      }
      /*
      const imageType = ['image/jpeg', 'image/png', 'image/gif']
      if (imageType.includes(doc.project_device_document.file.contentType)) {
        this.imageViewer = true
        this.imageViewerSrc = {
          categoryName: doc.project_device_document.project_device_document_category.name,
          key: doc.project_device_document.file.key,
          title: doc.project_device_document.title,
          token: doc.project_device_document.file.userMetadata['X-Amz-Meta-Token'],
          uuid: doc.project_device_document_uuid,
        }
      } else {
        const url = this.GetUrlDoc(doc.project_device_document.file.key, this.getToken(doc))
        window.open(url, '_blank').focus()
      }
      */
    },
    rowClick(item) {
      if (this.isDrawer && this.isView) {
        this.viewDocument(item)
      }
    },
  },
}
</script>
