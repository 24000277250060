import gql from 'graphql-tag'

const dashboardFragment = gql`
  fragment dashboardFragment on dashboard {
    uuid
    name
    layout
    widget
    project_uuid
    user_uuid
    created_at
    updated_at
    dashboard_users {
      user_uuid
      dashboard_uuid
    }
  }
`

export const GET_DASHBOARDS = gql`
  query dashboard(
    $where: dashboard_bool_exp
    $order_by: [dashboard_order_by!]
    $limit: Int
    $offset: Int
  ) {
    dashboard_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    dashboard(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...dashboardFragment
    }
  }
  ${dashboardFragment}
`

export const INSERT_DASHBOARD_ONE = gql`
  mutation insert_dashboard_one($object: dashboard_insert_input!) {
    insert_dashboard_one(object: $object) {
      ...dashboardFragment
    }
  }
  ${dashboardFragment}
`

export const INSERT_DASHBOARD_USER_ONE = gql`
  mutation insert_dashboard_user_one($dashboard_uuid: uuid!, $user_uuid: uuid!) {
    insert_dashboard_user_one(object: { dashboard_uuid: $dashboard_uuid, user_uuid: $user_uuid }) {
      dashboard_uuid
      user_uuid
    }
  }
`

export const UPDATE_DASHBOARD_BY_PK = gql`
  mutation update_dashboard_by_pk($uuid: uuid!, $_set: dashboard_set_input!) {
    update_dashboard_by_pk(pk_columns: { uuid: $uuid }, _set: $_set) {
      ...dashboardFragment
    }
  }
  ${dashboardFragment}
`

export const DELETE_DASHBOARD_BY_PK = gql`
  mutation delete_dashboard_by_pk($uuid: uuid!) {
    delete_dashboard_by_pk(uuid: $uuid) {
      ...dashboardFragment
    }
  }
  ${dashboardFragment}
`

export const DELETE_DASHBOARD_USER_BY_PK = gql`
  mutation delete_dashboard_user_by_pk($dashboard_uuid: uuid!, $user_uuid: uuid!) {
    delete_dashboard_user_by_pk(dashboard_uuid: $dashboard_uuid, user_uuid: $user_uuid) {
      dashboard_uuid
      user_uuid
    }
  }
`
