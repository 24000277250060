var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"75%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2"},on),[_vm._v(" "+_vm._s(_vm.buttonTitle)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-space-between py-1 pl-3 pr-4"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.close}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("$iconClose")]),_vm._v(_vm._s(_vm.$t('common.close'))+" ")],1)],1)],1),_c('v-card-text',[_c('v-sheet',{staticClass:"pa-3 mb-4",attrs:{"outlined":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('helper-crud-list-filter',{attrs:{"filters":_vm.filters,"style-class":_vm.styleClassCrudListFilter,"style-outlined":_vm.styleOutlinedCrudListFilter},on:{"update:filters":function($event){_vm.filters=$event}},model:{value:(_vm.options.filter),callback:function ($$v) {_vm.$set(_vm.options, "filter", $$v)},expression:"options.filter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('helper-tags-selector',{attrs:{"edit-item":_vm.itemEdit},model:{value:(_vm.tagsLibDevice),callback:function ($$v) {_vm.tagsLibDevice=$$v},expression:"tagsLibDevice"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.libDeviceParameters,"options":_vm.options,"dense":"","fixed-header":"","footer-props":{
          'items-per-page-text': _vm.itemsPerPageText,
          'page-text': _vm.pageText,
          'items-per-page-options': [5, 10, 25, 50, -1],
        },"height":_vm.StyleHeightCalc('64px + 64px + 72px + 86px + 14px').height,"no-data-text":_vm.$t('common.noDataText'),"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.selectConfig($event)}},scopedSlots:_vm._u([{key:"item.field_type",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getConfigParamLabel(item.field_type)))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }