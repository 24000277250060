var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"ma-0 pa-0",attrs:{"fullscreen":"","hide-overlay":"","scrollable":"","transition":"dialog-bottom-transition"},model:{value:(_vm.displayDialog),callback:function ($$v) {_vm.displayDialog=$$v},expression:"displayDialog"}},[_c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-card-text',{staticClass:"overflow-y-hidden pa-0",staticStyle:{"position":"relative"}},[(_vm.localImage)?_c('div',{staticClass:"cropper-content cropper-info pa-2 primary"},[_c('span',[_vm._v(_vm._s(_vm.localImage.title))]),(_vm.localImage.categoryName)?_c('span',[_vm._v(" ("+_vm._s(_vm.localImage.categoryName)+")")]):_vm._e()]):_vm._e(),(_vm.displayCropper)?_c('cropper',{ref:"cropper",staticClass:"cropper",style:(_vm.styleCropper()),attrs:{"auto-zoom":true,"default-size":_vm.defaultSize,"src":_vm.optionsCropper.img,"transitions":true,"foreground-class":"cropper-foreground","image-restriction":"fit-area"}}):_vm._e(),_c('div',{staticClass:"cropper-content cropper-vertical-buttons primary"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.displayDialog = false}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconClose")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.close')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.maximize()}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconHome")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.original')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.center()}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconCenterFocus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.center')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.resize(2, 2)}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconArrowExpandAll")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.resize'))+" (x2)")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.resize(1, 2)}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconArrowUpDown")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.resizeHeight'))+" (x2)")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.resize(2, 1)}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconArrowLeftRight")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.resizeWidth'))+" (x2)")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.resize(0.5, 0.5)}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconArrowCollapseAll")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.resize'))+" (x1/2)")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.flip(true, false)}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconFlipHorizontal")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.flipHorizontal')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.flip(false, true)}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconFlipVertical")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.flipVertical')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.rotate(90)}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconReload")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.rotateClockwise'))+" (90°)")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.rotate(-90)}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconRestore")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('imageViewer.rotateCounterClockwise'))+" (-90°)")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }