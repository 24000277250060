<template>
  <div v-if="fieldConfig.offset == 0">
    <form-error :validator="$v.finalValue" :attribute="name" :messages="messages">
      <v-text-field
        v-model="finalValue"
        slot-scope="{ attrs }"
        type="number"
        v-bind="Object.assign({}, $attrs, attrs)"
        :suffix="fieldConfig.unit ? fieldConfig.unit.symbol : undefined"
        @input="$v.finalValue.$touch()"
        @blur="$v.finalValue.$touch()"
      />
    </form-error>
  </div>
  <form-error v-else :validator="$v.finalValue" :attribute="name" :messages="messages">
    <v-slider
      v-model="finalValue"
      :max="fieldConfig.max"
      :min="fieldConfig.min"
      :step="fieldConfig.offset"
      color="secondary"
      ticks="always"
      tick-size="4"
      thumb-color="secondary"
      track-fill-color="secondary"
    >
      <template #append>
        <v-text-field
          v-model="finalValue"
          class="mt-0 pt-0"
          hide-details
          single-line
          type="number"
          style="width: 60px"
        />
      </template>
    </v-slider>
  </form-error>
</template>

<script>
import { integer, between } from 'vuelidate/lib/validators'

export default {
  name: 'IntegerField',
  props: {
    name: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data: function () {
    return {
      finalValue: this.defaultValue.value,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    finalValue: {
      deep: true,
      handler() {
        this.$emit('input', { value: this.finalValue })
      },
    },
  },
  validations() {
    return {
      finalValue: {
        integer,
        between: between(this.fieldConfig.min, this.fieldConfig.max),
      },
    }
  },
}
</script>
