import gql from 'graphql-tag'

// queries
export const GET_PROJECT_VIEW_LAYERS = gql`
  query project_view_layer(
    $where: project_view_layer_bool_exp
    $order_by: [project_view_layer_order_by!]
    $limit: Int
    $offset: Int
  ) {
    project_view_layer_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    project_view_layer(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      uuid
      project_uuid
      project_view_uuid
      name
      type
      options
      vorder
      updated_at
      created_at
    }
  }
`

export const POST_PROJECT_VIEW_LAYER = gql`
  mutation ($layers: [project_view_layer_insert_input!]!) {
    insert_project_view_layer(objects: $layers) {
      returning {
        uuid
        project_uuid
        project_view_uuid
        name
        type
        options
        vorder
        updated_at
        created_at
      }
    }
  }
`

export const PATCH_PROJECT_VIEW_LAYER = gql`
  mutation ($uuid: uuid!, $body: project_view_layer_set_input!) {
    update_project_view_layer(where: { uuid: { _eq: $uuid } }, _set: $body) {
      affected_rows
      returning {
        uuid
        project_uuid
        project_view_uuid
        name
        type
        options
        vorder
        updated_at
        created_at
      }
    }
  }
`
export const PATCH_PROJECT_VIEW_LAYERS_OPTIONS = gql`
  mutation ($where: project_view_layer_bool_exp!, $options: jsonb) {
    update_project_view_layer(where: $where, _append: { options: $options }) {
      affected_rows
      returning {
        uuid
        project_uuid
        project_view_uuid
        name
        type
        options
        vorder
        updated_at
        created_at
      }
    }
  }
`

export const DELETE_PROJECT_VIEW_LAYER = gql`
  mutation ($uuid: uuid!) {
    delete_project_view_layer(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
        created_at
        updated_at
      }
    }
  }
`

export const UPDATE_ORDER_UP_PROJECT_VIEW_LAYER = gql`
  mutation ($project_view_uuid: uuid!, $new_positon: Int!, $old_positon: Int!) {
    update_project_view_layer(
      where: {
        project_view_uuid: { _eq: $project_view_uuid }
        vorder: { _gte: $new_positon }
        _and: { vorder: { _lt: $old_positon } }
      }
      _inc: { vorder: 1 }
    ) {
      affected_rows
      returning {
        uuid
        project_uuid
        project_view_uuid
        name
        type
        options
        vorder
        updated_at
        created_at
      }
    }
  }
`

export const UPDATE_ORDER_DOWN_PROJECT_VIEW_LAYER = gql`
  mutation ($project_view_uuid: uuid!, $new_positon: Int!, $old_positon: Int!) {
    update_project_view_layer(
      where: {
        project_view_uuid: { _eq: $project_view_uuid }
        vorder: { _gt: $old_positon }
        _and: { vorder: { _lte: $new_positon } }
      }
      _inc: { vorder: -1 }
    ) {
      affected_rows
      returning {
        uuid
        project_uuid
        project_view_uuid
        name
        type
        options
        vorder
        updated_at
        created_at
      }
    }
  }
`
