var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.IsMediumDown())?_c('div',[_c('v-app-bar',{attrs:{"app":"","color":"tertiary","clipped-left":_vm.IsMediumDown(),"height":"48"}},[(_vm.isAuthenticated)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.navigationDrawer = true}}}):_vm._e()],1),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":"","width":"auto"},model:{value:(_vm.navigationDrawer),callback:function ($$v) {_vm.navigationDrawer=$$v},expression:"navigationDrawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"secondary--text"},model:{value:(_vm.navigationDrawerGroup),callback:function ($$v) {_vm.navigationDrawerGroup=$$v},expression:"navigationDrawerGroup"}},[_c('v-list-item',{attrs:{"to":"/project"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({attrs:{"color":"tranparent","height":"36","min-width":"36","width":"36"},on:{"click":_vm.openLink}},on),[_c('v-img',_vm._b({attrs:{"contain":""}},'v-img',_vm.logo,false))],1)]}}],null,false,1772038226)},[_c('span',[_vm._v("Vision - "+_vm._s(_vm.$tc('common.project', 1)))])])],1),_c('app-bar-menu'),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-auto",attrs:{"icon":""},on:{"click":_vm.logout}},on),[_c('v-icon',[_vm._v("$iconLogout")])],1)]}}],null,false,3201080471)},[_c('span',[_vm._v(_vm._s(_vm.$t('authentication.logout')))])])],1)],1)],1)],1)],1):_c('v-app-bar',{attrs:{"id":"app-bar","app":"","color":"tertiary","clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"max-width":_vm.$vuetify.breakpoint.width,"height":"48"}},[_c('v-avatar',{attrs:{"color":"tranparent","height":"36","min-width":"36","width":"36"},on:{"click":_vm.openLink}},[_c('v-img',_vm._b({attrs:{"contain":""}},'v-img',_vm.logo,false))],1),(!_vm.$vuetify.breakpoint.mobile)?_c('span',{staticClass:"text-h6 mr-4",staticStyle:{"color":"#003e96"}},[_vm._v("VISION")]):_vm._e(),_c('v-spacer'),(_vm.isAuthenticated && _vm.UserCurrent.user_projects)?_c('v-toolbar-items',[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[(_vm.UserCurrent.user_projects.length > 1)?_c('v-card',{attrs:{"id":"intro-home-1-project","elevation":"0","width":_vm.$vuetify.breakpoint.mobile ? '100px' : '340px'}},[_c('v-container',{staticClass:"tertiary justify-space-between py-0"},[(!_vm.$vuetify.breakpoint.mobile)?_c('span',{staticClass:"text-body-2 pr-2"},[_vm._v(" "+_vm._s(_vm.$tc('common.projectColon', 2))+" ")]):_vm._e(),_c('v-autocomplete',{staticClass:"d-inline-block",attrs:{"items":_vm.project,"item-text":"name","item-value":"id","value":_vm.currentProjectId,"height":_vm.$vuetify.breakpoint.mobile ? 16 : 26,"dense":"","hide-details":""},on:{"input":_vm.updateCurrentProject}})],1)],1):_vm._e(),_c('app-bar-menu'),_c('bar-project-report'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"intro-home-4-userprofile","to":"/user-profile","icon":""}},on),[_c('v-icon',[_vm._v("$iconUser")])],1)]}}],null,false,1501264120)},[_c('span',[_vm._v(_vm._s(_vm.currentUser))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"intro-home-0-introjs","disabled":!_vm.isInfo,"icon":""},on:{"click":function($event){_vm.StartIntro(_vm.startIntroParam(true))}}},on),[_c('v-icon',[_vm._v("$iconInfo")])],1)]}}],null,false,2021696025)},[_c('span',[_vm._v(_vm._s(_vm.$tc('common.info', 2)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"intro-home-5-logout","icon":""},on:{"click":_vm.logout}},on),[_c('v-icon',[_vm._v("$iconLogout")])],1)]}}],null,false,725220456)},[_c('span',[_vm._v(_vm._s(_vm.$t('authentication.logout')))])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }