<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="localConfig.valueTrue"
              :label="$t('libDeviceParameter.valueTrue')"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="localConfig.valueFalse"
              :label="$t('libDeviceParameter.valueFalse')"
            />
          </v-col>
        </v-row>
        <v-switch
          v-model="localDefaultValue.value"
          :label="`${$t('common.defaultValue')}: ${TransformBoolValue(defaultValue.value)}`"
          color="secondary"
          true-value
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'BooleanFieldConfig',
  props: {
    fieldConfig: {
      type: Object,
      default() {
        return {
          valueTrue: true,
          valueFalse: false,
        }
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {
          value: true,
        }
      },
    },
    fieldLabel: {
      type: String,
      default: '',
    },
  },
  data: function () {
    return {
      localConfig: this.fieldConfig,
      localDefaultValue: this.defaultValue,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    localConfig: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
    localDefaultValue: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
  },
  validations() {
    return {
      fieldConfig: {
        valueTrue: {
          required,
        },
        valueFalse: {
          required,
        },
      },
      defaultValue: {
        value: {
          required,
        },
      },
    }
  },
  mounted() {
    this.$emit('input', {
      fieldConfig: this.localConfig,
      defaultValue: this.localDefaultValue,
    })
  },
  methods: {
    TransformBoolValue: function (value) {
      return value ? this.localConfig.valueTrue : this.localConfig.valueFalse
    },
  },
}
</script>
