<template lang="html">
  <v-menu
    v-if="selectedOptions.selectedEvent.name"
    v-model="localeSelectedOpen"
    :close-on-content-click="false"
    :position-x="selectedOptions.position.x"
    :position-y="selectedOptions.position.y"
    absolute
    max-width="450px"
    offset-x
  >
    <v-card color="grey lighten-4" min-width="350px" flat>
      <v-toolbar
        :color="selectedOptions.selectedEvent.event_category.color"
        class="px-3"
        dark
        dense
      >
        <v-btn
          v-if="$store.getters['auth/isAuth'](['event', 'update'])"
          icon
          small
          @click="goRoute('edit', selectedOptions)"
        >
          <v-icon> $iconEdit </v-icon>
        </v-btn>
        <v-btn v-else icon small @click="goRoute('view', selectedOptions)">
          <v-icon> $iconEye </v-icon>
        </v-btn>
        <v-btn
          v-if="$store.getters['auth/isAuth'](['event', 'delete'])"
          icon
          @click="deleteEvent(selectedOptions.selectedEvent.uuid)"
        >
          <v-icon> $iconDelete </v-icon>
        </v-btn>
        <v-toolbar-title v-if="selectedOptions.selectedEvent.name.length > 34">
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">
                {{ selectedOptions.selectedEvent.name }}
              </span>
            </template>
            <span>
              {{ selectedOptions.selectedEvent.name }}
            </span>
          </v-tooltip>
        </v-toolbar-title>
        <v-toolbar-title v-else>
          {{ selectedOptions.selectedEvent.name }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        {{ selectedOptions.selectedEvent.description }}
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { GET_EVENTS, DELETE_EVENT_BY_PK } from '@/graphql/EventQueries'
import { CommonMixin } from '@/mixins/CommonMixin'

export default {
  name: 'CalendarEvent',
  mixins: [CommonMixin],
  props: {
    selectedOptions: {
      type: Object,
      default: () => {
        return {
          date: null,
          isDraweer: false,
          position: {
            x: 0,
            y: 0,
          },
          selectedEvent: {},
          selectedOpen: false,
          type: 'month',
        }
      },
    },
  },
  computed: {
    localeSelectedOpen: {
      get() {
        return this.selectedOptions.selectedOpen
      },
      set(val) {
        this.$emit('update:selectedOptions', { selectedOpen: val })
      },
    },
  },
  methods: {
    goRoute(type, selectedOptions) {
      const routeName = type === 'edit' ? 'CalendarEventEdit' : 'CalendarEventView'
      this.$router.push({
        name: routeName,
        path: '/calendar-event/' + type + '/' + selectedOptions.selectedEvent.uuid,
        params: {
          date: selectedOptions.date,
          isDrawer: selectedOptions.isDrawer,
          value: selectedOptions.selectedEvent.uuid,
          type: selectedOptions.type,
        },
      })
    },
    async deleteEvent(uuid) {
      const removal = this.$t('common.removal')
      const sureToDelete = this.$t('common.sureToDelete')
      const res = await this.$root.$confirm(removal, sureToDelete, {
        color: 'red',
      })

      if (res) {
        await this.$apollo
          .mutate({
            mutation: DELETE_EVENT_BY_PK,
            variables: {
              uuid: uuid,
            },
            refetchQueries: [
              {
                query: GET_EVENTS,
                variables: {
                  where: {
                    project_uuid: {
                      _eq: this.$store.getters['project/currentProjectId'],
                    },
                  },
                },
              },
            ],
          })
          .catch((error) => {
            this.ShowSnackbarError('DELETE_EVENT_BY_PK: ' + error.message)
            throw error
          })
      }
    },
  },
}
</script>
