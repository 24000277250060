import Vue from 'vue'
import VueRx from 'vue-rx'

import './components'
import './plugins'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/router'
import Vuelidate from 'vuelidate'
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor'
import FormError from './components/helper/FormError'
import Filters from './global.filters.js'
import DialogLoader from './components/helper/DialogLoader'
import HelperConfirm from './components/helper/Confirm'
import i18n from '@/plugins/i18n.js'
import store from '@/store'
import { apolloProvider } from '@/apollo-provider'
import VueLayers from 'vuelayers'

// Load globals filters
for (const key in Filters) {
  Vue.filter(key, Filters[key])
}

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.config.debug = true

Vue.prototype.$log = console.log
Vue.use(VueRx)
Vue.use(Vuelidate)

Vue.use(VuelidateErrorExtractor)

Vue.use(VueLayers, {})
/*
Vue.use(VuelidateErrorExtractor, {
  i18n: 'validation',
})
*/
Vue.component('FormWrapper', templates.FormWrapper)
Vue.component('FormError', FormError)

new Vue({
  router,
  vuetify,
  HelperConfirm,
  DialogLoader,
  store,
  i18n,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app')
