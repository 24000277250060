<template>
  <v-card>
    <v-card-title class="text-body-1 white--text secondary py-1">
      {{ $tc('common.address', 1) }}
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="crmContactLocal.address"
            :label="$tc('common.address', 1)"
            :placeholder="$tc('common.address', 1)"
            :readonly="readonly"
          />
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <form-error
            :validator="$v.crmContactLocal.zip_code"
            :attribute="$t('common.zipCode')"
            :messages="messages"
          >
            <v-text-field
              v-model="crmContactLocal.zip_code"
              slot-scope="{ attrs }"
              v-bind="attrs"
              :label="$t('common.zipCode')"
              :placeholder="$t('common.zipCode')"
              :readonly="readonly"
              @input="$v.crmContactLocal.zip_code.$touch()"
              @blur="$v.crmContactLocal.zip_code.$touch()"
            />
          </form-error>
        </v-col>
        <v-col cols="12" md="8" class="py-0">
          <v-text-field
            v-model="crmContactLocal.city"
            :label="$t('common.city')"
            :placeholder="$t('common.city')"
            :readonly="readonly"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { minLength } from 'vuelidate/lib/validators'

export default {
  name: 'CrmAddressEditor',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {
          address: '',
          city: '',
          zip_code: '',
        }
      },
    },
  },
  validations: {
    crmContactLocal: {
      zip_code: { minLength: minLength(5) },
    },
  },
  computed: {
    crmContactLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
}
</script>