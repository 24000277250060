/**
 * List of icons used in a constant
 *
 * @link  https://vuetifyjs.com/en/customization/icons/#reusable-custom-icons
 * @example unique usage:
 *  <v-icon>$iconPlus</v-icon>
 *  <v-icon v-text="'$iconPlus'"></v-icon>
 *  <v-icon v-html="'$iconPlus'"></v-icon>
 *
 * @example check all icons used:
<v-icon
  v-for="icon in $vuetify.icons.values"
  v-bind:key="icon.id"
>
  {{ icon }}
</v-icon>
 */
export const ICONS = {
  icon3D: 'mdi-rotate-3d', // instead of mdi-axis-arrow
  iconAddress: 'mdi-map-marker',
  iconAlert: 'mdi-alert-decagram',
  iconAlertSleep: 'mdi-bell-sleep',
  iconApps: 'mdi-apps',
  iconArrowCollapseAll: 'mdi-arrow-collapse-all',
  iconArrowDown: 'mdi-menu-down',
  iconArrowExpandAll: 'mdi-arrow-expand-all',
  iconArrowHigh: 'mdi-arrow-collapse-up',
  iconArrowLeft: 'mdi-menu-left',
  iconArrowLeft2: 'mdi-arrow-left',
  iconArrowLeftRight: 'mdi-arrow-left-right',
  iconArrowLow: 'mdi-arrow-collapse-down',
  iconArrowRight: 'mdi-menu-right',
  iconArrowTopRight: 'mdi-arrow-top-right',
  iconArrowUp: 'mdi-menu-up',
  iconArrowUpDown: 'mdi-arrow-up-down',
  iconBell: 'mdi-bell',
  iconBook: 'mdi-bookmark-multiple',
  iconCalendar: 'mdi-calendar',
  iconCalendarClock: 'mdi-calendar-clock',
  iconCamera: 'mdi-camera',
  iconCenterFocus: 'mdi-image-filter-center-focus',
  iconChartBar: 'mdi-chart-bar',
  iconChartCopy: 'mdi-chart-box-plus-outline',
  iconChartLine: 'mdi-chart-timeline-variant',
  iconChartLineAll: 'mdi-chart-timeline-variant-shimmer',
  iconCheck: 'mdi-check',
  iconClipboardText: 'mdi-clipboard-text-outline',
  iconClockStartReverse: 'mdi-clock-start mdi-flip-h',
  iconClose: 'mdi-close', // Replaces also 'clear'
  iconCloseCircle: 'mdi-close-circle',
  iconContact: 'mdi-contacts-outline',
  iconDashboard: 'mdi-view-dashboard',
  iconDelete: 'mdi-delete', // 'mdi-delete' or 'delete'; Replaces 'mdi-trash-can'
  iconDeleteDevice: 'mdi-delete-forever',
  iconDeviceParameter: 'mdi-router-wireless-settings',
  iconDirection: 'mdi-sign-direction',
  iconDocument: 'mdi-file-document-outline',
  iconDotsVertical: 'mdi-dots-vertical',
  iconDownload: 'mdi-download',
  iconEdit: 'edit', // Replaces 'mdi-pen', 'mdi-pencil'
  iconEffector: 'mdi-alarm-light',
  iconEvent: 'event',
  iconEye: 'mdi-eye',
  iconEyeOff: 'mdi-eye-off',
  iconFile: 'mdi-file',
  iconFlipHorizontal: 'mdi-flip-horizontal',
  iconFlipVertical: 'mdi-flip-vertical',
  iconFolder: 'mdi-folder',
  iconGeojsonAdd: 'mdi-layers-plus',
  iconGeojsonExport: 'mdi-code-json',
  iconHeart: 'mdi-heart',
  iconHeartBroken: 'mdi-heart-broken',
  iconHeartOff: 'mdi-heart-off',
  iconHome: 'mdi-home',
  iconInfo: 'mdi-information',
  iconLayerDel: 'mdi-layers-remove',
  iconLayers: 'mdi-layers-triple',
  iconLayerSystem: 'mdi-layers-outline',
  iconLibrary: 'mdi-library',
  iconLink: 'mdi-link-variant',
  iconLinkOff: 'mdi-link-variant-off',
  iconLock: 'mdi-lock',
  iconLockOpen: 'mdi-lock-open',
  iconLogout: 'mdi-logout',
  iconMagnify: 'mdi-magnify',
  iconMap: 'mdi-map',
  iconMapLegend: 'mdi-map-legend',
  iconMapOutline: 'mdi-map-outline',
  iconMapMarkerRemove: 'mdi-map-marker-remove',
  iconMessages: 'messages',
  iconMove: 'mdi-cursor-move',
  iconNotificationPolicy: 'mdi-lan',
  iconOne: 'mdi-numeric-1-box-multiple-outline',
  iconOpenInNew: 'mdi-open-in-new',
  iconOther: 'mdi-cable-data',
  iconPaperclip: 'mdi-paperclip', // or 'attach_file' ?
  iconPdf: 'mdi-file-pdf-outline',
  iconPhone: 'mdi-phone',
  iconPin: 'mdi-pin',
  iconPlot: 'mdi-nail',
  iconPlus: 'mdi-plus',
  iconPointsCloud: 'mdi-graph-outline', // or 'mdi-grain' ? - Name is ok ?
  iconProjects: 'mdi-folder-multiple', // replaces 'mdi-file-document-box-outline' who doesn't work
  iconReload: 'mdi-reload',
  iconReportList: 'mdi-playlist-edit',
  iconRestore: 'mdi-restore',
  iconRisk: 'mdi-alert-outline',
  iconSave: 'mdi-content-save', // 'mdi-content-save' or 'save'
  iconSatellite: 'mdi-satellite',
  iconSatelliteDown: 'mdi-satellite-variant',
  iconSatelliteUp: 'mdi-satellite-uplink', // Replaces also 'fa-satellite-dish'
  iconSend: 'mdi-mail',
  iconSendEmail: 'mdi-email-send-outline',
  iconSendEmailOff: 'mdi-email-off-outline',
  iconSensor: 'mdi-radio-tower',
  iconSensor2: 'mdi-access-point', // icon like a point wifi
  iconSensorType: 'mdi-shape',
  iconSettings: 'mdi-cog',
  iconSettingsAppli: 'mdi-application-cog',
  iconSettingsFile: 'mdi-file-cog',
  iconSms: 'mdi-cellphone-message',
  iconSmsOff: 'mdi-cellphone-message-off',
  iconSociety: 'mdi-domain',
  iconStar: 'mdi-star',
  iconStarOff: 'mdi-star-outline',
  iconStation: 'mdi-router-wireless', // Replaces 'station'
  iconSubMenu: 'mdi-dots-vertical',
  iconSupervisor: 'supervisor_account',
  iconSystem: 'mdi-exclamation-thick',
  iconTarget: 'mdi-target',
  iconTools: 'mdi-tools',
  iconTranslate: 'mdi-translate',
  iconTransmitter: 'mdi-rss ',
  iconUpdate: 'mdi-update',
  iconUser: 'mdi-account',
  iconUserOff: 'mdi-account-off',
  iconUserPlus: 'mdi-account-multiple-plus',
  iconUserPlusOutline: 'mdi-account-multiple-plus-outline',
  iconUsers: 'mdi-account-multiple',
  iconUsersCircle: 'supervised_user_circle',
  iconViewList: 'mdi-view-list',
  iconWorkflow: 'mdi-transit-connection-variant',
}
