import DeviceType from '@/components/device_library/DeviceType'
import { mapActions, mapGetters } from 'vuex'

import { GET_PROJECT_VIEW_LAYERS } from '@/graphql/ProjectViewLayerQueries'
import {
  SUBSCRIPTION_PROJECT_VIEW_DEVICE_COORDINATES,
  GET_PROJECT_VIEW_DEVICE_COORDINATE,
  GET_PROJECT_VIEW_DEVICES_COORDINATE,
} from '@/graphql/ProjectViewDeviceCoordinateQueries'
import { SUBSCRIPTION_LOGS } from '@/graphql/LogQueries'

import { createStyle } from 'vuelayers/dist/ol-ext'
import { getVectorContext } from 'ol/render'
import { unByKey } from 'ol/Observable'
import { fromLonLat } from 'ol/proj'
import { boundingExtent } from 'ol/extent'

const easeInOut = (t) => 1 - Math.pow(1 - t, 3)

export const OlView = {
  apollo: {
    $subscribe: {
      Features: {
        query: SUBSCRIPTION_PROJECT_VIEW_DEVICE_COORDINATES,
        variables() {
          return this.DeviceCoordinatesQueryVars
        },
        result({ data }) {
          if (data) {
            this.Features = []
            this.Features = data.project_view_device_coordinate.reduce((acc, curr) => {
              if (!acc[curr.project_device.device_type]) {
                acc[curr.project_device.device_type] = []
              }

              acc[curr.project_device.device_type].push({
                type: 'Feature',
                id: curr.project_device_uuid,
                properties: {
                  device_uuid: curr.project_device_uuid,
                  device_type: curr.project_device.device_type,
                  device_name: curr.project_device.name,
                  selected: false,
                  color: 'grey',
                },
                geometry: {
                  type: 'Point',
                  coordinates: [curr.x, curr.y],
                },
              })
              return acc
            }, {})
            this.updateLayerFeatures()
          }
        },
        skip() {
          return !this.Layers
        },
      },
      DevicesWarning: {
        query: SUBSCRIPTION_LOGS,
        variables() {
          const vars = {
            where: {
              project_uuid: {
                _eq: this.currentProjectId,
              },
              type: {
                _eq: 'warnings',
              },
              _and: {
                _not: {
                  message: { _has_key: 'state' },
                },
              },
            },
          }
          return vars
        },
        result({ data }) {
          this.DevicesWarning = data.log
          this.updateLayerFeatures()
        },
        skip() {
          return !this.Layers
        },
      },
    },
    Layers: {
      query: GET_PROJECT_VIEW_LAYERS,
      deep: true,
      variables() {
        return {
          where: { project_view_uuid: { _eq: this.currentView.uuid } },
          order_by: { vorder: 'asc' },
        }
      },
      result({ data }) {
        if (data) {
          this.updateLayer()
        }
      },
      update(data) {
        return data.project_view_layer
      },
      skip() {
        return !this.currentView.uuid
      },
    },
  },
  data() {
    return {
      defaultMapOptions: {
        zoom: 5,
        center: [3.409318162800443, 46.927844620885054],
        rotation: 0,
        geolocPosition: undefined,
      },
      flashDuration: 1000,
      flyingAnimation: true,
      localMouseCursor: 'default',
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentView: 'project/currentView',
      viewTool: 'project/viewTool',
      targetDevices: 'project/targetDevices',
    }),
    center: {
      get() {
        return this.currentView.options?.center || this.defaultMapOptions.center
      },
      set(value) {
        this.updateCurrentViewOptions({ center: value })
      },
    },
    coordinateSystemId() {
      return this.currentView.type === 'map' ? 2 : 1
    },
    DeviceCoordinatesQueryVars: {
      get() {
        return this.DeviceCoordinatesQuery()
      },
    },
    MouseCursor: {
      get() {
        if (this.viewTool === 'device-pos') {
          return 'crosshair'
        }
        if (this.viewTool === 'device-del') {
          return 'pointer'
        }
        return this.localMouseCursor
      },
    },
    rotation: {
      get() {
        return this.currentView.options?.rotation || this.defaultMapOptions.rotation
      },
      set(value) {
        this.updateCurrentViewOptions({ rotation: value })
      },
    },
    zoom: {
      get() {
        return this.currentView.options?.zoom || this.defaultMapOptions.zoom
      },
      set(value) {
        this.updateCurrentViewOptions({ zoom: value })
      },
    },
  },
  watch: {
    targetDevices: function (val) {
      if (val && val.length > 0) this.updateViewFocus(val)
    },
  },
  methods: {
    ...mapActions({
      setTargetDevice: 'project/setTargetDevice',
      setViewTool: 'project/setViewTool',
      updateCurrentViewOptions: 'project/updateCurrentViewOptions',
    }),
    DeviceCoordinatesQuery() {
      const vars = {
        where: {
          project_uuid: {
            _eq: this.currentProjectId,
          },
          project_view_coordinate_system_id: {
            _eq: this.coordinateSystemId,
          },
        },
      }
      if (this.coordinateSystemId === 1) {
        vars.where.project_view_uuid = {
          _eq: this.currentView.uuid,
        }
      }
      return vars
    },
    DeviceStyleFunc() {
      return (feature, resolution) => {
        let style = {}
        const color = this.hex2rgba(feature.get('selected') ? '#FF0000' : feature.get('color'))
        const bgcolor = this.hex2rgba(feature.get('bgcolor') ? feature.get('bgcolor') : '#FFFFFF')

        const search = DeviceType.find((i) => i.name === feature.get('device_type'))
        if (!search) {
          style = createStyle({
            strokeColor: color,
            fillColor: bgcolor,
            ...(feature.get('showlabel') && {
              text: feature.get('name'),
              textFillColor: color,
            }),
          })
        } else if (search.name === 'sensor') {
          style = createStyle({
            imageSrc: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(search.icon),
            imageScale: 0.7 / Math.pow(resolution, 1 / 3),
            imageColor: color,
          })
        } else {
          style = createStyle({
            imageSrc: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(search.icon),
            imageScale: 1 / Math.pow(resolution, 1 / 3),
            imageColor: color,
          })
        }

        const searchWarning = this.DevicesWarning?.find((d) => d.device_uuid === feature.getId())
        if (searchWarning) {
          if (!feature.listenerKey) {
            this.FlashingAnimation(feature)
          }
        } else if (feature.listenerKey) {
          unByKey(feature.listenerKey)
          delete feature.listenerKey
        }

        return style
      }
    },
    FlashingAnimation(feature) {
      let start = new Date().getTime()
      feature.listenerKey = this.GetLayerbyUuid(feature.get('layerUuid')).on('postrender', animate)
      const _this = this

      function animate(event) {
        const vectorContext = getVectorContext(event)
        const frameState = event.frameState
        const flashGeom = feature.getGeometry().clone()
        const elapsed = frameState.time - start
        const elapsedRatio = elapsed / _this.flashDuration
        // radius will be 5 at start and 30 at end.
        const radius = easeInOut(elapsedRatio) * 35 + 5
        const opacity = easeInOut(0.5 - elapsedRatio)

        const style = createStyle({
          imageRadius: Math.abs(radius),
          imageFillColor: [255, 0, 0, opacity],
          imageStrokeColor: [255, 0, 0],
          strokeWidth: 2 + opacity,
        })

        vectorContext.setStyle(style)
        vectorContext.drawGeometry(flashGeom)
        if (elapsed > _this.flashDuration) {
          start = new Date().getTime()
        }

        _this.$refs.map.$map.render()
      }
    },
    FlyingAnimation(view, done) {
      const duration = 1000
      const zoom =
        view && view.getZoom() < 22 ? view.getZoom() : this.coordinateSystemId === 2 ? 22 : 5
      let parts = 2
      let called = false

      function callback(complete) {
        --parts
        if (called) {
          return
        }
        if (parts === 0 || !complete) {
          called = true
          done(complete)
        }
      }

      view &&
        view.animate(
          {
            zoom: zoom - 1,
            duration: duration / 2,
          },
          {
            zoom: zoom,
            duration: duration / 2,
          },
          callback
        )
    },
    getDeviceTypeData(deviceType) {
      return DeviceType.find((x) => x.name === deviceType)
    },
    GetLayerbyUuid(uuid) {
      return this.$refs.map.getLayers().find((layer) => layer.get('id') === uuid)
    },
    hex2rgba(hex) {
      if (!hex) return
      const [r, g, b, a] = hex
        .match(hex.length <= 4 ? /\w/g : /\w\w/g)
        .map((x) => parseInt(x.length < 2 ? `${x}${x}` : x, 16))
      const alpha = !a ? 1 : a / 255
      return `rgba(${r},${g},${b},${alpha})`
    },
    onMapPointerMove({ pixel }) {
      if (this.viewTool !== 'select') return

      const hitFeature = this.$refs.map.$map.forEachFeatureAtPixel(pixel, (feature) => feature, {
        hitTolerance: 5,
      })
      if (hitFeature) {
        if (hitFeature.get('features.type') === 'secteur') {
          return
        }
        this.localMouseCursor = 'pointer'
        this.currentPosition = this.$refs.map.$map.getPixelFromCoordinate(
          hitFeature.getGeometry().getCoordinates()
        )
        this.currentDevice = hitFeature.values_
      } else {
        this.localMouseCursor = 'default'
        this.currentPosition = this.currentDevice = undefined
      }
    },
    updateLayer() {
      this.updateLayerFeatures()
    },
    updateLayerFeatures() {
      if (!this.Layers || !this.Features) return
      this.Layers = this.Layers.map((layer) => {
        if (layer.type === 'system') {
          layer = Object.assign({}, layer, {
            features: this.Features[layer.options.type],
          })
          layer.features?.map((f) => {
            f.properties.color = layer.options.color
            f.properties.layerUuid = layer.uuid
            return f
          })
        }
        return layer
      })
    },
    async getDeviceCoordinate(deviceUuid) {
      const query = await this.$apollo.query({
        query: GET_PROJECT_VIEW_DEVICE_COORDINATE,
        variables: {
          project_uuid: this.currentProjectId,
          project_view_uuid: this.currentView.uuid,
          project_device_uuid: deviceUuid,
          coordinate_system_id: this.coordinateSystemId,
        },
      })
      return [
        query.data.project_view_device_coordinate[0].x,
        query.data.project_view_device_coordinate[0].y,
      ]
    },
    async getDevicesCoordinate(deviceUuids) {
      const query = await this.$apollo.query({
        query: GET_PROJECT_VIEW_DEVICES_COORDINATE,
        variables: {
          project_uuid: this.currentProjectId,
          project_view_uuid: this.currentView.uuid,
          project_devices_uuids: JSON.parse(JSON.stringify(deviceUuids)),
          coordinate_system_id: this.coordinateSystemId,
        },
      })
      return query.data.project_view_device_coordinate.reduce((acc, curr) => {
        acc.push(this.coordinateSystemId === 2 ? fromLonLat([curr.x, curr.y]) : [curr.x, curr.y])
        return acc
      }, [])
    },
    async onRenderComplete() {
      const isTargetDevices = this.targetDevices && this.targetDevices.length > 0
      if ((isTargetDevices || this.$route.name === 'ViewFocus') && this.flyingAnimation) {
        let deviceUuids = []
        if (this.$route.params?.deviceUuid) {
          deviceUuids =
            this.targetDevices && this.targetDevices.includes(this.$route.params.deviceUuid)
              ? this.targetDevices
              : [this.$route.params.deviceUuid]
        } else {
          deviceUuids = this.targetDevices
        }
        this.updateViewFocus(deviceUuids)
      }
    },
    async updateViewFocus(deviceUuids) {
      const targetLocations = await this.getDevicesCoordinate(deviceUuids)
      const extent = boundingExtent(targetLocations)
      this.$refs?.map?.getView().fit(extent, { padding: [150, 150, 150, 150] })
      if (deviceUuids.length === 1) this.zoom = 22
      this.FlyingAnimation(this.$refs?.map?.$map?.getView(), () => {})
      this.flyingAnimation = false

      this.setTargetDevice([])
    },
  },
}
