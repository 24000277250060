<template>
  <div id="project-drawer-view-configuration" class="pb-4">
    <v-text-field
      v-if="$store.getters['auth/isAuth'](['project_view', 'update'])"
      v-model="viewName"
      :label="$t('common.name')"
      dense
    />
    <v-file-input
      v-if="currentView.type === '2d' && $store.getters['auth/isAuth'](['project_view', 'update'])"
      v-model="imageUpload"
      :label="$t('project.imageFile')"
      prepend-icon="$iconCamera"
      accept="image/*"
      dense
    />
    <v-text-field
      v-model.number="zoom"
      type="number"
      :label="$t('project.zoom')"
      class="mb-4"
      min="0"
      step="0.1"
      dense
    />
    <v-text-field
      v-model.number="centerX"
      :label="currentView.type === '2d' ? 'X' : $t('project.latitude')"
      dense
    />
    <v-text-field
      v-model.number="centerY"
      :label="currentView.type === '2d' ? 'Y' : $t('project.longitude')"
      dense
    />
    <v-text-field
      v-model.number="rotation"
      type="number"
      step="0.1"
      :label="$t('project.rotation')"
      dense
    />
    <v-btn
      v-if="$store.getters['auth/isAuth'](['project_view', 'update'])"
      x-small
      block
      color="tertiary"
      @click="updateView"
    >
      <span class="text-uppercase">
        {{ $t('common.update') }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GET_PROJECT_VIEWS, PATCH_PROJECT_VIEW } from '@/graphql/ProjectViewQueries'
import { CommonMixin } from '@/mixins/CommonMixin'
import { storageClient } from '@/plugins/storage'
import { getCenter } from 'ol/extent'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ProjectDrawerViewConfiguration',
  mixins: [CommonMixin],
  data: () => ({
    imageUpload: null,
    imgExtent: [0, 0, 2550, 1250],
  }),
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentView: 'project/currentView',
      currentViewDefaultOptions: 'project/currentViewDefaultOptions',
    }),
    centerX: {
      get() {
        return this.currentView.options.center[0]
      },
      set(value) {
        const y = this.currentView.options.center[1]
        this.updateCurrentViewOptions({ center: [value, y] })
      },
    },
    centerY: {
      get() {
        return this.currentView.options.center[1]
      },
      set(value) {
        const x = this.currentView.options.center[0]
        this.updateCurrentViewOptions({ center: [x, value] })
      },
    },
    rotation: {
      get() {
        return this.currentView.options?.rotation || 0
      },
      set(value) {
        this.updateCurrentViewOptions({ rotation: value })
      },
    },
    zoom: {
      get() {
        return this.checkZoom(this.currentView.options.zoom)
      },
      set(value) {
        const newZoom = this.checkZoom(value)
        this.updateCurrentViewOptions({ zoom: newZoom })
      },
    },
    viewName: {
      get() {
        return this.currentView.name
      },
      set(value) {
        this.updateCurrentView({ name: value })
      },
    },
  },
  watch: {
    currentViewDefaultOptions: function (val) {
      if (!val) this.updateView()
    },
  },
  methods: {
    ...mapActions({
      setCurrentViewDefaultOptions: 'project/setCurrentViewDefaultOptions',
      updateCurrentView: 'project/updateCurrentView',
      updateCurrentViewOptions: 'project/updateCurrentViewOptions',
    }),
    checkZoom(val) {
      return Number.isNaN(val) ? 0.0000000001 : val
    },
    async updateView() {
      if (this.imageUpload) {
        const options = await storageClient.put(`view2d/${this.imageUpload.name}`, this.imageUpload)
        this.updateCurrentViewOptions({
          ...options,
          center: getCenter(this.imgExtent),
          rotation: this.rotation,
        })
        this.imageUpload = null
      }
      const projectView = cloneDeep(this.currentView)
      delete projectView.__typename
      delete projectView.uuid

      await this.$apollo
        .mutate({
          mutation: PATCH_PROJECT_VIEW,
          variables: {
            uuid: this.currentView.uuid,
            body: projectView,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_VIEWS,
              variables: {
                where: { project_uuid: { _eq: this.currentProjectId } },
              },
            },
          ],
        })
        .then(() => {
          const options = JSON.parse(JSON.stringify(projectView.options))
          this.setCurrentViewDefaultOptions(options)
          this.ShowSnackbarSuccess()
        })
        .catch((error) => {
          const errorChangeView = this.$t('project.errorChangeView')
          this.ShowSnackbarError(errorChangeView + error.message)
          throw error
        })
    },
  },
}
</script>
