import axios from 'axios'
import Configuration from '@/configuration'
import { CommonMixin } from '@/mixins/CommonMixin'

class Storage {
  constructor(config, inMemory = {}) {
    this.inMemory = inMemory
    this.endpoint = config.endpoint
  }

  async put(path, file, metadata = null, onUploadProgress = false) {
    const formData = new FormData()
    formData.append('file', file)

    path = CommonMixin.methods.NormalizeString(path)

    const headers = {
      'Content-Type': 'multipart/form-data',
      'x-path': path,
      metadata: JSON.stringify(metadata),
    }

    const uploadRes = await axios.post(`${this.endpoint}/storage/upload`, formData, {
      headers,
      onUploadProgress,
    })

    return uploadRes.data
  }

  async delete(file) {
    const res = await axios.delete(
      `${this.endpoint}/storage/file/${file.key}?token=${file.token}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-path': `/${file.key}`,
        },
      }
    )

    return res.data
  }

  async deleteFile(key, token) {
    const res = await axios.delete(`${this.endpoint}/storage/file/${key}?token=${token}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'x-path': `/${key}`,
      },
    })

    return res.data
  }

  getDownloadURL(key, token) {
    return `${this.endpoint}/storage/download/${decodeURIComponent(key).replace(
      '+',
      ' '
    )}?token=${token}`
  }

  getUrl(key, token) {
    return `${this.endpoint}/storage/file/${decodeURIComponent(key).replace(
      '+',
      ' '
    )}?token=${token}`
  }

  async isFileExist(url) {
    try {
      await axios.get(url)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async isStorageFileExist(key) {
    key = CommonMixin.methods.NormalizeString(key)
    const url = `${this.endpoint}/storage/isfile/${key}`
    const urlGet = await axios.get(url)
    return urlGet.data === 'fileTrue'
  }
}

export const storageClient = new Storage({
  endpoint: Configuration.value('apiMediasHttp'),
})
