import gql from 'graphql-tag'

// queries
export const GET_ROLE = gql`
  query ($uuid: uuid!) {
    role_by_pk(uuid: $uuid) {
      uuid
      role
      created_at
      updated_at
    }
  }
`

export const GET_ROLES = gql`
  query role($where: role_bool_exp, $order_by: [role_order_by!], $limit: Int, $offset: Int) {
    role_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    role(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      uuid
      role
      created_at
      updated_at
    }
  }
`
