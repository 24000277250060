<template>
  <v-dialog v-model="show" persistent max-width="700">
    <v-card>
      <v-card-title class="text-h5"> {{ $t('project.systemLayerAdd') }} : </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <form-error
                :validator="$v.newLayer.name"
                :attribute="$t('project.layerName')"
                :messages="messages"
              >
                <v-text-field
                  v-model="newLayer.name"
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  :label="$t('project.layerName')"
                  required
                />
              </form-error>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <form-error
                :validator="$v.newLayer.type"
                :attribute="$t('libDevice.type')"
                :messages="messages"
              >
                <v-select
                  v-model="newLayer.type"
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  :items="DeviceType"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('libDevice.type') + ' *'"
                  :placeholder="$t('libDevice.filters.type.placeholder')"
                  item-text="label"
                  item-value="name"
                />
              </form-error>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="newLayer.color"
                hide-details
                :label="$t('project.colorOutline')"
                outlined
              >
                <template #append>
                  <v-menu
                    v-model="menu"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on }">
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="newLayer.color" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-slider
                v-model="newLayer.opacity"
                :label="$t('project.transparency')"
                class="align-center"
                max="100"
                min="0"
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn color="primary darken-1" text @click="AddSystemLayer">
          {{ $t('common.add') }}
        </v-btn>
        <v-btn color="grey" text @click="UndoLayerDialog">
          {{ $t('common.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { required, minLength } from 'vuelidate/lib/validators'
import DeviceType from '@/components/device_library/DeviceType'
import { GET_PROJECT_VIEW_LAYERS, POST_PROJECT_VIEW_LAYER } from '@/graphql/ProjectViewLayerQueries'

export default {
  name: 'AddSystemLayerDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    DeviceType: DeviceType,
    newLayerDefault: {
      opacity: 100,
      color: '#333',
    },
    newLayer: {
      opacity: 100,
      color: '#FF9600FF',
    },
    menu: false,
  }),
  validations: {
    newLayer: {
      name: { required, minLength: minLength(4) },
      type: { required },
    },
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    messages() {
      return this.$t('validationMessage')
    },
    swatchStyle() {
      const { menu } = this
      return {
        backgroundColor: this.newLayer.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
  },
  methods: {
    UndoLayerDialog() {
      this.newLayer = this.newLayerDefault
      this.$v.$reset()
      this.show = false
    },
    async AddSystemLayer() {
      const errorForm = this.$t('common.errorOnForm')

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$root.$dialogLoader.showSnackbar(errorForm, { color: 'error' })
        return
      }

      const SystemLayer = {
        name: this.newLayer.name,
        type: 'system',
        project_uuid: this.$store.getters['project/currentProjectId'],
        project_view_uuid: this.$store.getters['project/currentView'].uuid,
        options: {
          type: this.newLayer.type,
          color: this.newLayer.color,
          opacity: this.newLayer.opacity,
          visibility: true,
        },
      }

      await this.$apollo.mutate({
        mutation: POST_PROJECT_VIEW_LAYER,
        variables: {
          layers: SystemLayer,
        },
        refetchQueries: [
          {
            query: GET_PROJECT_VIEW_LAYERS,
            variables: {
              where: {
                project_view_uuid: {
                  _eq: this.$store.getters['project/currentView'].uuid,
                },
              },
              order_by: { vorder: 'asc' },
            },
          },
        ],
      })
      this.UndoLayerDialog()
    },
  },
}
</script>
<style lang="css">
.iconstyle svg {
  fill: #27ae60;
}
</style>
