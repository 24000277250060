import gql from 'graphql-tag'

// queries
export const GET_PROJECT_VIEW_DEVICE_COORDINATE = gql`
  query project_view_device_coordinate(
    $project_uuid: uuid
    $project_view_uuid: uuid
    $project_device_uuid: uuid
    $coordinate_system_id: Int
  ) {
    project_view_device_coordinate(
      where: {
        project_uuid: { _eq: $project_uuid }
        project_view_uuid: { _eq: $project_view_uuid }
        project_device_uuid: { _eq: $project_device_uuid }
        project_view_coordinate_system_id: { _eq: $coordinate_system_id }
      }
    ) {
      project_device_uuid
      x
      y
      z
      created_at
      updated_at
    }
  }
`

export const GET_PROJECT_VIEW_DEVICE_COORDINATE_WITHOUT_VIEW = gql`
  query project_view_device_coordinate(
    $project_uuid: uuid
    $project_device_uuid: uuid
    $coordinate_system_id: Int
  ) {
    project_view_device_coordinate(
      where: {
        project_uuid: { _eq: $project_uuid }
        project_device_uuid: { _eq: $project_device_uuid }
        project_view_coordinate_system_id: { _eq: $coordinate_system_id }
      }
    ) {
      project_device_uuid
      x
      y
      z
      created_at
      updated_at
    }
  }
`

export const GET_PROJECT_VIEW_DEVICES_COORDINATE = gql`
  query project_view_device_coordinate(
    $project_uuid: uuid
    $project_view_uuid: uuid
    $project_devices_uuids: [uuid!]!
    $coordinate_system_id: Int
  ) {
    project_view_device_coordinate(
      where: {
        project_uuid: { _eq: $project_uuid }
        project_view_uuid: { _eq: $project_view_uuid }
        project_device_uuid: { _in: $project_devices_uuids }
        project_view_coordinate_system_id: { _eq: $coordinate_system_id }
      }
    ) {
      project_device_uuid
      x
      y
      z
      created_at
      updated_at
    }
  }
`

export const GET_PROJECT_VIEW_DEVICE_COORDINATES = gql`
  query project_view_device_coordinate(
    $project_uuid: uuid
    $project_view_uuid: uuid
    $coordinate_system_id: Int
  ) {
    project_view_device_coordinate_aggregate(
      where: {
        project_uuid: { _eq: $project_uuid }
        project_view_uuid: { _eq: $project_view_uuid }
        project_view_coordinate_system_id: { _eq: $coordinate_system_id }
      }
    ) {
      aggregate {
        totalCount: count
      }
    }
    project_view_device_coordinate(
      where: {
        project_uuid: { _eq: $project_uuid }
        project_view_uuid: { _eq: $project_view_uuid }
        project_view_coordinate_system_id: { _eq: $coordinate_system_id }
      }
    ) {
      project_device_uuid
      project_device {
        id
        name
      }
      x
      y
      z
      created_at
      updated_at
    }
  }
`

export const POST_PROJECT_VIEW_DEVICE_COORDINATE = gql`
  mutation ($coordinate: [project_view_device_coordinate_insert_input!]!) {
    insert_project_view_device_coordinate(
      objects: $coordinate
      on_conflict: { constraint: project_view_device_coordinate_pkey, update_columns: [x, y, z] }
    ) {
      returning {
        x
        y
        z
      }
    }
  }
`

export const DELETE_PROJECT_VIEW_DEVICE_COORDINATE = gql`
  mutation DELETE_PROJECT_VIEW_DEVICE_COORDINATE($where: project_view_device_coordinate_bool_exp!) {
    delete_project_view_device_coordinate(where: $where) {
      returning {
        project_uuid
        project_device_uuid
        project_view_uuid
        project_view_coordinate_system_id
        x
        y
        z
      }
    }
  }
`

// subscriptions
export const SUBSCRIPTION_PROJECT_VIEW_DEVICE_COORDINATES = gql`
  subscription project_view_device_coordinate_by_view(
    $where: project_view_device_coordinate_bool_exp = {}
  ) {
    project_view_device_coordinate(where: $where) {
      project_uuid
      project_view_uuid
      project_view_coordinate_system_id
      project_device_uuid
      project_device {
        id
        name
        device_type
      }
      x
      y
      z
      created_at
      updated_at
    }
  }
`
