<template>
  <v-container fluid class="pt-2">
    <v-form v-if="hasRights">
      <v-row align="center" justify="center" class="px-1">
        <v-col>
          <v-combobox
            v-model="newType"
            :items="emailTypeDefault"
            :search-input.sync="search"
            :error-messages="emailTypeErrorMessage"
            :hint="$t('common.typeChoice')"
            :label="$t('common.selectOrAdd')"
            hide-selected
            persistent-hint
            small-chips
            dense
            @update:search-input="$v.newType.$touch()"
            @blur="$v.newType.$touch()"
          >
            <template #no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('common.noResultsMatching') }} "<strong>{{ search }}</strong
                    >". {{ $t('common.toCreateNewPress') }}
                    <kbd>{{ $t('common.enter') }}</kbd>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
        <v-col>
          <form-error :validator="$v.newEmail" :attribute="$t('common.email')" :messages="messages">
            <v-text-field
              v-model="newEmail"
              slot-scope="{ attrs }"
              v-bind="attrs"
              :label="$t('common.email')"
              :placeholder="$t('common.email')"
              :error-messages="emailErrorMessage"
              class="mt-0 pt-1"
              @input="$v.newEmail.$touch()"
              @blur="$v.newEmail.$touch()"
            />
          </form-error>
        </v-col>
        <v-col md="auto">
          <button v-if="hasRights" @click.prevent="addNewMail()">
            <v-icon :title="$t('common.add')">$iconPlus</v-icon>
          </button>
        </v-col>
      </v-row>
    </v-form>
    <v-list>
      <template v-for="(item, index) in emailsList">
        <v-list-item :key="item.uuid" dense class="px-1">
          <v-list-item-content>
            <v-list-item-title>{{ item.type }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>{{ item.email }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn v-if="hasRights" icon x-small @click.stop.prevent="deleteEmail(index, item)">
              <v-icon :title="$t('common.delete')" @click="deleteEmail(index, item)">
                $iconDelete
              </v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-divider :key="index" />
      </template>
    </v-list>
  </v-container>
</template>

<script>
import { GET_CRM_EMAILS, POST_CRM_EMAIL, DELETE_CRM_EMAIL } from '@/graphql/CrmEmailQueries'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import upperFirst from 'lodash/upperFirst'

export default {
  name: 'EmailSelector',
  mixins: [validationMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    crmName: {
      type: String,
      default: '', // contact || company
      required: true,
    },
  },
  data() {
    return {
      crmLocal: this.value,
      newEmail: '',
      newType: '',
      emailTypeDefault: [
        this.$t('common.typeWork'),
        this.$t('common.typeNotification'),
        this.$t('common.typePersonal'),
      ],
      emailsList: [],
      search: null,
    }
  },
  validations: {
    newEmail: { required, email },
    newType: { required },
    validationGroup: ['newEmail', 'newType'],
  },
  computed: {
    emailErrorMessage() {
      let msg = ''
      if (this.newType !== '' && (this.newEmail === '' || email(this.newEmail) === false)) {
        const attr = this.$t('common.email')
        if (this.newEmail === '') {
          msg = this.$t('validationMessage.required', { attribute: attr })
        }
        if (email(this.newEmail) === false) {
          msg = this.$t('validationMessage.email', { attribute: attr })
        }
      }
      return msg
    },
    emailTypeErrorMessage() {
      let msg = ''
      if (this.newEmail !== '' && this.$v.validationGroup.newType.$invalid) {
        const type = this.$t('common.type')
        msg = this.$t('validationMessage.required', { attribute: type })
      }
      return msg
    },
    hasRights() {
      return (
        this.$store.getters['auth/isAuth'](['crm_contact', 'create']) ||
        this.$store.getters['auth/user'].crm_contact_uuid === this.crmLocal.uuid
      )
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    value: {
      handler: function (val) {
        if (val.uuid !== '') {
          this.crmLocal = val
          this.getEmail()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEmail()
  },
  methods: {
    addNewMail: function () {
      const newEmailType = this.newType
      const newEmail = this.newEmail

      // Display message if email field failed
      this.$v.$touch()
      if (this.$v.validationGroup.$invalid || newEmail === '' || email(newEmail) === false) {
        const emailTxt = this.$t('common.email')
        let errorForm = upperFirst(this.$t('common.errorOnForm')) + ' ' + emailTxt + '.'
        if (
          this.$v.validationGroup.newType.$invalid &&
          (newEmail === '' || email(newEmail) === false)
        ) {
          const attr = this.$t('common.type') + ' + ' + emailTxt
          const msg = this.$t('validationMessage.required', {
            attribute: attr,
          })
          errorForm = errorForm + ' ' + msg
        }
        this.$root.$dialogLoader.showSnackbar(errorForm, { color: 'error' })
        return
      } else {
        if (this.crmLocal.uuid !== '') {
          // Add new email to db with crmUuid
          this.addEmailWithCrmUuid(newEmail, newEmailType)
        }
      }

      // Clear
      this.newType = ''
      this.newEmail = ''
    },
    getQueryVariables() {
      // Condition where for db to get, post query
      let vars = {}
      if (this.crmName === 'contact') {
        vars = {
          where: { crm_contact_uuid: { _eq: this.crmLocal.uuid } },
        }
      }
      if (this.crmName === 'company') {
        vars = {
          where: { crm_company_uuid: { _eq: this.crmLocal.uuid } },
        }
      }
      return vars
    },
    async addEmailWithCrmUuid(newEmail, newType) {
      let email = {}

      // Create object email by crm to post query
      if (this.crmName === 'contact') {
        email = {
          crm_contact_uuid: this.crmLocal.uuid,
          email: newEmail,
          type: newType,
        }
      }
      if (this.crmName === 'company') {
        email = {
          crm_company_uuid: this.crmLocal.uuid,
          email: newEmail,
          type: newType,
        }
      }

      await this.$apollo
        .mutate({
          mutation: POST_CRM_EMAIL,
          variables: {
            objects: email,
          },
          // eslint-disable-next-line
          update: (cache, { data: { insert_crm_email } }) => {
            const data = cache.readQuery({
              query: GET_CRM_EMAILS,
              variables: this.getQueryVariables(),
            })
            // eslint-disable-next-line
            data.crm_email.push(insert_crm_email.returning[0])
            data.crm_email_aggregate.aggregate.totalCount++

            // Update the list for display
            this.emailsList = data.crm_email

            cache.writeQuery({
              query: GET_CRM_EMAILS,
              data: data,
              variables: this.getQueryVariables(),
            })
          },
        })
        .catch((error) => {
          throw error
        })
    },
    async getEmail() {
      // Get list email by user
      if (this.crmLocal.uuid !== '') {
        const result = await this.$apollo.query({
          query: GET_CRM_EMAILS,
          variables: this.getQueryVariables(),
        })
        this.emailsList = result?.data?.crm_email || []
      }
    },
    async deleteEmail(index, item) {
      const removal = this.$t('common.removal')
      const sureToDelete = this.$t('common.sureToDelete')
      const res = await this.$root.$confirm(removal, sureToDelete, {
        color: 'red',
      })

      if (res) {
        // Delete on DB if user exist in DB
        if (typeof item.uuid !== 'undefined') {
          await this.$apollo
            .mutate({
              mutation: DELETE_CRM_EMAIL,
              variables: {
                uuid: item.uuid,
              },
              refetchQueries: [
                {
                  // Update cache Emails
                  query: GET_CRM_EMAILS,
                  variables: this.getQueryVariables(),
                },
              ],
            })
            .then((res) => {
              // Delete email to emailsList (only for display)
              if (res) this.emailsList.splice(index, 1)
            })
            .catch((error) => {
              throw error
            })
        }
      }
    },
  },
}
</script>
