import gql from 'graphql-tag'

// queries
export const GET_PROJECT_GEOJSON_OBJECTS = gql`
  query project_geojson_object(
    $where: project_geojson_object_bool_exp
    $order_by: [project_geojson_object_order_by!]
    $limit: Int
    $offset: Int
  ) {
    project_geojson_object_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    project_geojson_object(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      uuid
      data
      created_at
      updated_at
    }
  }
`

export const POST_PROJECT_GEOJSON_OBJECT = gql`
  mutation ($objects: [project_geojson_object_insert_input!]!) {
    insert_project_geojson_object(objects: $objects) {
      returning {
        uuid
        data
        created_at
        updated_at
      }
    }
  }
`

export const DELETE_PROJECT_GEOJSON_OBJECT = gql`
  mutation ($uuid: uuid!) {
    delete_project_geojson_object(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
        created_at
        updated_at
      }
    }
  }
`
