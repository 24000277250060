import { storageClient } from '@/plugins/storage'
import { CommonMixin } from '@/mixins/CommonMixin'

export const DocumentMixin = {
  mixins: [CommonMixin],
  data() {
    return {
      ImageType: ['image/jpeg', 'image/png', 'image/gif'],
    }
  },
  methods: {
    /**
     * Get stokage document url
     * @param {string} key
     * @param {string} token
     */
    GetUrlDoc(key, token) {
      if (key && token) {
        return storageClient.getUrl(key, token)
      }
    },
    /**
     * Return true if document mime type is PDF or image
     * @param {string} contentType
     * @return {boolean}
     */
    ShowDoc(contentType) {
      return (
        contentType &&
        (contentType === 'application/pdf' || this.GetStringSplit(contentType, '/', 0) === 'image')
      )
    },
    /**
     * Download document
     * @param {string} key
     * @param {string} token
     * @param {string} title
     */
    async Download(key, token, title) {
      if (key && token) {
        const url = await storageClient.getDownloadURL(key, token)
        const extKey = this.GetStringSplit(key, '.').toLowerCase()
        const extTitle = this.GetStringSplit(title, '.').toLowerCase()
        const ext = extKey !== extTitle ? '.' + extKey : ''
        const docName = (title || 'download') + ext
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', docName)
        document.body.appendChild(link)
        link.click()
      }
    },
    /**
     * Return true if doc is storaged and in DB
     * @param {string} name File name
     * @param {string} path
     * @param {boolean} inDB
     * @return {boolean}
     */
    async IsDocExist(name, path, inDB) {
      let result = false
      const isStoraged = await storageClient.isStorageFileExist(path)
      if (isStoraged && inDB) {
        const errorForm = this.$t('common.fileExistsRenameIt', { name: name })
        this.ShowSnackbarError(errorForm)
        result = true
      }
      return result
    },
  },
}
