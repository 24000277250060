<template>
  <v-container class="pa-0">
    <v-card
      id="intro-reportgraph-2-thresholds"
      class="d-inline-flex text-caption"
      elevation="0"
      height="36"
      width="auto"
      tile
    >
      <span v-for="(value, key) in thresholdLocal" :key="key">
        <v-list-item
          v-if="Object.keys(value).length > 0"
          :class="
            isDashboard
              ? 'd-inline-flex threshold-list-item py-2 px-1'
              : 'd-inline-flex threshold-list-item py-2'
          "
        >
          <v-list-item-content v-if="!isDashboard" class="py-0 pr-2">
            <v-list-item-subtitle>{{ $t('common.threshold.' + key) }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon
            v-for="(val, k) in value"
            :key="val"
            :class="isDashboard ? 'ma-0' : 'my-0'"
            style="min-width: 55px"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon
                  class="mr-1"
                  :color="key === 'alarm' ? '#FF4560' : '#FEB019'"
                  left
                  small
                  v-on="on"
                >
                  {{ k === 'low' ? `$iconArrowLow` : `$iconArrowHigh` }}
                </v-icon>
                {{ val }}
              </template>
              <span v-if="key === 'trigger'">
                {{ $t('common.threshold.' + key) }}
                {{ $tc('common.threshold.high', 1) }}
              </span>
              <span v-else>
                {{ $t('common.threshold.' + key) }}
                {{ $tc('common.threshold.' + k, 2) }}
              </span>
            </v-tooltip>
          </v-list-item-icon>
        </v-list-item>
      </span>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'GraphThreshold',
  props: {
    threshold: {
      type: Object,
      default() {
        return {
          alarm: {},
          alert: {},
          trigger: {},
        }
      },
    },
    isDashboard: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    thresholdLocal() {
      return this.threshold
    },
  },
}
</script>

<style>
.threshold-list-item {
  min-height: 20px;
}
</style>
