<template>
  <v-container class="pa-0">
    <v-dialog v-model="widgetLocal" width="500" persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="tertiary" dense flat>
          <v-toolbar-title>
            <span>
              {{ $t('dashboard.settingsWidget') }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  :disabled="!isTitle(widgetLocal.title)"
                  elevation="0"
                  x-small
                  v-on="on"
                  @click="closeDialog('save')"
                >
                  <v-icon color="primary">$iconSave</v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('common.save') }}
              </span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn class="px-0" elevation="0" x-small v-on="on" @click="closeDialog('close')">
                  <v-icon color="primary">$iconClose</v-icon>
                </v-btn>
              </template>
              <span>
                {{ GetFirstLetterUppercase($t('common.close')) }}
              </span>
            </v-tooltip>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pa-4">
          <v-row>
            <v-col :lg="widgetLocal.type === 'graph' ? 5 : 12" md="12">
              <v-select
                v-model="widgetLocal.type"
                :items="widgetType"
                :label="$t('dashboard.selectWidgetType')"
                :placeholder="$t('common.select')"
                dense
                hide-details
              >
                <template #[`selection`]="{ item }">
                  {{ $t('dashboard.type.' + item) }}
                </template>
                <template #[`item`]="{ item }">
                  {{ $t('dashboard.type.' + item) }}
                </template>
              </v-select>
            </v-col>
            <v-col :lg="widgetLocal.type === 'graph' ? 7 : 12" md="12">
              <v-text-field
                v-model="widgetLocal.title"
                :label="$t('common.title') + ' *'"
                :placeholder="$t('common.title')"
                clearable
                dense
                hide-details
              />
            </v-col>
          </v-row>
          <dashboard-widget-settings-graph
            v-if="widgetLocal.type === 'graph'"
            :settings.sync="widgetLocal.settings"
            @changeTitle="changeTitle"
          />
          <dashboard-widget-settings-text
            v-if="widgetLocal.type === 'text'"
            :settings.sync="widgetLocal.settings"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { CommonMixin } from '@/mixins/CommonMixin'
import DashboardWidgetSettingsGraph from '@/components/project/dashboard/helper/DashboardWidgetSettingsGraph'
import DashboardWidgetSettingsText from '@/components/project/dashboard/helper/DashboardWidgetSettingsText'

export default {
  name: 'DashboardWidgetConfig',
  components: {
    DashboardWidgetSettingsGraph,
    DashboardWidgetSettingsText,
  },
  mixins: [CommonMixin],
  props: {
    openWidget: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    settingsGraph: {
      device_id: '',
      dateTimeFrom: null,
      dateTimeTo: null,
      isTimeIntervalToNow: false, // if true && isTimeInterval is true, dateTimeTo is now at each consultation
      isTimeInterval: false, // if true timeInterval is selected by user
      timeBefore: 2,
      timeBeforeUnit: 'days',
    },
    settingsText: {
      description: '',
    },
    widgetType: ['graph', 'text'],
  }),
  computed: {
    widgetLocal() {
      return this.widget
    },
  },
  watch: {
    'widgetLocal.type': function (val, oldVal) {
      if (val !== oldVal) {
        this.initWidget()
      }
    },
  },
  methods: {
    changeTitle(evt) {
      this.widgetLocal.title = evt
    },
    closeDialog(type) {
      if (!this.isTitle(this.widgetLocal.title)) {
        const msg = this.$t('common.errorOnForm') + '. ' + this.$t('dashboard.addTitle')
        this.ShowSnackbarError(msg)
      } else {
        if (type === 'save') {
          const time = this.widgetLocal.type === 'graph' ? 2000 : 10
          setTimeout(() => {
            this.$emit('update:openWidget', false)
          }, time)
        } else {
          this.$emit('update:openWidget', false)
        }
      }
    },
    initWidget() {
      delete this.widgetLocal.settings
      this.widgetLocal.settings = {}
      switch (this.widgetLocal.type) {
        case 'text':
          this.widgetLocal.settings = this.settingsText
          break
        case 'graph':
        default:
          this.widgetLocal.settings = this.settingsGraph
          break
      }
    },
    isTitle(widgetTitle) {
      let result = false
      if (widgetTitle) {
        result = true
      }
      return result
    },
  },
}
</script>
