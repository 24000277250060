import introJs from 'intro.js'

export const IntroMixin = {
  data() {
    return {
      Intro: undefined,
    }
  },
  created() {
    this.Intro = introJs()
  },
  methods: {
    formatIntro(param) {
      const list = this.$i18n.messages[this.$i18n.fallbackLocale]?.intro
      let intro = []

      if (list) {
        const listParam = list[param]
        const ordered = Object.keys(listParam)
          .sort((a, b) => {
            a = a.split('-')[0]
            b = b.split('-')[0]
            return a - b
          })
          .reduce((obj, key) => {
            obj[key] = listParam[key]
            return obj
          }, {})

        for (const [index, [key]] of Object.entries(Object.entries(ordered))) {
          intro.push({
            element: '#intro-' + param + '-' + key,
            intro: key !== 'title' ? this.$t('intro.' + param + '.' + key) : null,
            title: key !== 'title' ? this.$t('intro.' + param + '.title.' + index) : null,
          })
        }
        intro = intro.filter((el) => {
          return el.element !== '#intro-' + param + '-title'
        })
      }
      return intro
    },
    StartIntro(param) {
      if (param) {
        this.Intro.setOptions({
          nextLabel: '>',
          prevLabel: '<',
          doneLabel: 'ok',
          scrollTo: true,
          showProgress: true,
          showStepNumbers: true,
          steps: this.steps(param).filter(function (obj) {
            return document.querySelector(obj.element) !== null
          }),
        })
        this.Intro.start()
      }
    },
    steps(param) {
      let steps = {
        drawercalendar: [],
        drawercontacts: [],
        drawerdashboard: [],
        drawerdevice: [],
        drawerdocuments: [],
        drawerview: [],
        home: [],
        reportform: [],
        reportgraph: [],
      }
      if (param) {
        const format = this.formatIntro(param)
        steps[param] = format
      }
      return steps[param] || []
    },
  },
}
