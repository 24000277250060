<template>
  <v-combobox
    v-model="unitSelect"
    v-bind="$attrs"
    :items="unitsItems"
    :label="$t('common.unit')"
    :placeholder="$t('common.unitplaceholder')"
    :search-input.sync="unitSearch"
    item-text="symbol"
    item-value="id"
    :hide-no-data="!unitSearch"
    return-object
    v-on="$listeners"
    @change="selectItem"
  >
    <template #no-data>
      <v-list-item>
        <span class="subheading">{{ $t('common.create') }}</span>
        <span class="ml-2">{{ unitSearch }}</span>
      </v-list-item>
    </template>
    <template #item="{ index, item }">
      <v-list-item v-if="editing === item" dense @click.stop>
        <v-text-field
          v-model="editing.symbol"
          autofocus
          flat
          background-color="transparent"
          hide-details
          @keyup.enter="edit(index, item)"
        />
      </v-list-item>
      <span v-else>{{ item.symbol }}</span>
      <v-spacer />
      <v-list-item-action @click.stop>
        <v-btn icon x-small @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? '$iconEdit' : '$iconCheck' }}</v-icon>
        </v-btn>
        <v-btn v-if="editing !== item" icon x-small @click.stop.prevent="deleteUnit(index, item)">
          <v-icon>$iconDelete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>
<script>
import { GET_UNITS, POST_UNIT, PATCH_UNIT, DELETE_UNIT } from '@/graphql/UnitQueries'

export default {
  name: 'UnitSelector',
  props: {
    value: {
      type: [Object, String],
      default: () => {},
    },
  },
  data: () => ({
    unitsItems: [],
    unitSelect: {},
    unitSearch: '',
    editing: null,
    firstload: true,
  }),
  watch: {
    value: {
      handler: 'loadUnit',
      immediate: true,
    },
    unitSelect: {
      handler: 'addUnit',
      immediate: true,
    },
    unitSearch(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch()
      }
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      } else {
        this.editing = null
        this.index = -1
        this.updateUnit(item)
      }
    },
    loadUnit() {
      this.unitSelect = this.value
    },
    async querySearch() {
      this.loading = true
      const rv = await this.$apollo.query({
        query: GET_UNITS,
        variables: {
          order_by: { symbol: 'asc' },
        },
      })
      this.unitsItems = rv.data.unit
      this.loading = false
    },
    async addUnit(val) {
      if (typeof val !== 'string' || val === '') return
      await this.$apollo.mutate({
        mutation: POST_UNIT,
        variables: {
          objects: { symbol: val },
        },
        // eslint-disable-next-line
          update: (cache, { data: { insert_unit } }) => {
          try {
            const units = cache.readQuery({
              query: GET_UNITS,
              variables: {
                order_by: { symbol: 'asc' },
              },
            })
            // eslint-disable-next-line
              units.unit.push(insert_unit.returning[0])
            units.unit_aggregate.aggregate.totalCount++
            cache.writeQuery({
              query: GET_UNITS,
              data: units,
            })
            // eslint-disable-next-line
              this.$emit('input', insert_unit.returning[0])
          } catch (e) {
            console.log(e)
          }
        },
      })
    },
    async updateUnit(item) {
      try {
        await this.$apollo.mutate({
          mutation: PATCH_UNIT,
          variables: {
            id: parseInt(item.id),
            body: { symbol: item.symbol },
          },
          // eslint-disable-next-line
            update: (cache, { data: { update_unit } }) => {
            cache.reset()
            // eslint-disable-next-line
              this.unitSelect = update_unit.returning[0]
          },
        })
      } catch (e) {
        console.log(e)
      }
    },
    async deleteUnit(index, item) {
      await this.$apollo.mutate({
        mutation: DELETE_UNIT,
        variables: {
          id: item.id,
        },
        // eslint-disable-next-line
          update: (cache, { data: { delete_project } }) => {
          cache.reset()
        },
      })
      this.querySearch()
    },
    selectItem() {
      if (this.editing) {
        return false
      }

      this.$emit('input', this.unitSelect)
      this.unitSearch = ''
    },
  },
}
</script>

<style>
.subheading::first-letter {
  text-transform: capitalize;
}
</style>
