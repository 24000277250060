import gql from 'graphql-tag'

const alertFragment = gql`
  fragment Alert on alert_alert {
    uuid
    project_uuid
    device_uuid
    name
    description
    type_id
    alert_alert_type {
      id
      name
      editable
    }
    alert_alert_histories(limit: 10, order_by: { created_at: desc }) {
      state
      value
      created_at
    }
    state
    frequency
    for
    rule
    labels
    sleep
    state_changes
    updated_state
    updated_at
    created_at
  }
`

export const GET_ALERT_ALERT = gql`
  query ($uuid: uuid!) {
    alert_alert_by_pk(uuid: $uuid) {
      ...Alert
    }
  }
  ${alertFragment}
`

export const GET_ALERT_ALERTS_COUNT = gql`
  query ($project_uuid: uuid!) {
    alert_alert_aggregate(
      where: { project_uuid: { _eq: $project_uuid }, state: { _eq: alerting } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_TOTAL_ALERTS_COUNT = gql`
  query ($project_uuid: uuid!) {
    alert_alert_aggregate(
      where: { project_uuid: { _eq: $project_uuid }, state: { _eq: alerting } }
    ) {
      aggregate {
        count
      }
    }
    log_aggregate(
      where: {
        project_uuid: { _eq: $project_uuid }
        type: { _eq: warnings }
        _not: { message: { _has_key: "state" } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_ALERT_ALERTS = gql`
  query contact(
    $where: alert_alert_bool_exp
    $order_by: [alert_alert_order_by!]
    $limit: Int
    $offset: Int
  ) {
    alert_alert_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    alert_alert(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...Alert
    }
  }
  ${alertFragment}
`

export const INSERT_ALERT_ALERT = gql`
  mutation insert_alert_alert($alerts: alert_alert_insert_input!) {
    insert_alert_alert(objects: [$alerts]) {
      returning {
        uuid
        updated_at
        created_at
      }
    }
  }
  ${alertFragment}
`

export const INSERT_ALERT_ALERT_ONE = gql`
  mutation insert_alert_alert_one($object: alert_alert_insert_input!) {
    insert_alert_alert_one(object: $object) {
      ...Alert
    }
  }
  ${alertFragment}
`

export const UPDATE_ALERT_ALERT_BY_PK = gql`
  mutation update_alert_alert_by_pk($uuid: uuid!, $alert: alert_alert_set_input!) {
    update_alert_alert_by_pk(pk_columns: { uuid: $uuid }, _set: $alert) {
      ...Alert
    }
  }
  ${alertFragment}
`
export const DELETE_ALERT_ALERT = gql`
  mutation ($uuid: uuid!) {
    delete_alert_alert(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
        created_at
        updated_at
      }
    }
  }
`
