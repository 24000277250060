import {
  GET_DASHBOARDS,
  INSERT_DASHBOARD_USER_ONE,
  DELETE_DASHBOARD_BY_PK,
  DELETE_DASHBOARD_USER_BY_PK,
} from '@/graphql/DashboardQueries'
import { mapGetters } from 'vuex'
import merge from 'lodash/merge'

export const DashboardMixin = {
  apollo: {
    dashboard: {
      query: GET_DASHBOARDS,
      // pollInterval: 60000, // every 1 mn
      deep: true,
      variables() {
        const vars = this.VarsDashboard()
        return vars
      },
      result({ data }) {
        this.DashboardList = data?.dashboard
      },
      skip() {
        return !this.currentProjectId
      },
    },
  },
  data() {
    return {
      DashboardDefault: {
        name: '',
        project_uuid: this.$store.getters['project/currentProjectId'], // keep this
        user_uuid: this.$store.getters['auth/user'].id,
        dashboard_users: [],
        layout: [
          {
            i: '0',
            x: 0,
            y: 0,
            w: 4,
            h: 1,
          },
        ],
        widget: [
          {
            layout_i: '0',
            title: '',
            type: 'graph',
            settings: {
              device_id: '',
              dateTimeFrom: null,
              dateTimeTo: null,
              isTimeIntervalToNow: false, // if true && isTimeInterval is true, dateTimeTo is now at each consultation
              isTimeInterval: false, // if true timeInterval is selected by user
              timeBefore: 2,
              timeBeforeUnit: 'days',
            },
          },
        ],
      },
      DashboardList: [],
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
  },
  watch: {
    currentProjectId: function (val, oldVal) {
      if (oldVal !== val) this.VarsDashboard()
    },
  },
  methods: {
    /**
     * Returns the variables to query table dashboard
     * @param {string} [type] 'list': display on drawer or not
     * @param {boolean} [param] if isDrawer or not
     * @return {Object}
     */
    VarsDashboard(type, param) {
      let vars = {
        where: {
          project_uuid: {
            _eq: this.currentProjectId,
          },
        },
        order_by: {
          name: 'asc',
        },
      }

      if (type === 'list' && param) {
        const userUuid = this.$store.getters['auth/user'].id
        const varsDrawer = {
          where: {
            _or: [
              {
                user_uuid: {
                  _eq: userUuid,
                },
              },
              {
                dashboard_users: {
                  user_uuid: {
                    _eq: userUuid,
                  },
                },
              },
            ],
          },
        }
        vars = merge(vars, varsDrawer)
      }
      return vars
    },
    async DeleteDashboard(dashboard) {
      const resp = await this.$apollo
        .mutate({
          mutation: DELETE_DASHBOARD_BY_PK,
          variables: {
            uuid: dashboard.uuid,
          },
          refetchQueries: [
            {
              query: GET_DASHBOARDS,
            },
          ],
        })
        .catch((error) => {
          this.ShowSnackbarError('DELETE_DASHBOARD_BY_PK: ' + error.message)
          throw error
        })
      if (resp?.data) {
        this.ShowSnackbarSuccess()
        this.$apollo.queries.dashboard.refresh()
      }
    },
    async SubscribeDashboard(dashboardUuid, userUuid) {
      const resp = await this.$apollo
        .mutate({
          mutation: INSERT_DASHBOARD_USER_ONE,
          variables: {
            dashboard_uuid: dashboardUuid,
            user_uuid: userUuid,
          },
          refetchQueries: [
            {
              query: GET_DASHBOARDS,
            },
          ],
        })
        .catch((error) => {
          this.ShowSnackbarError('INSERT_DASHBOARD_USER_ONE: ' + error.message)
          throw error
        })
      if (resp?.data) {
        this.ShowSnackbarSuccess()
        this.$apollo.queries.dashboard.refresh()
      }
    },
    async UnsubscribeDashboard(dashboardUuid, UserUuid) {
      const resp = await this.$apollo
        .mutate({
          mutation: DELETE_DASHBOARD_USER_BY_PK,
          variables: {
            dashboard_uuid: dashboardUuid,
            user_uuid: UserUuid,
          },
          refetchQueries: [
            {
              query: GET_DASHBOARDS,
            },
          ],
        })
        .catch((error) => {
          this.ShowSnackbarError('DELETE_DASHBOARD_USER_BY_PK: ' + error.message)
          throw error
        })
      if (resp?.data) {
        this.ShowSnackbarSuccess()
        this.$apollo.queries.dashboard.refresh()
      }
    },
  },
}
