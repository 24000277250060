var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-2",attrs:{"outlined":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('common.name'),"clearable":"","dense":""},model:{value:(_vm.queryDeviceOptions.filter.filteredName),callback:function ($$v) {_vm.$set(_vm.queryDeviceOptions.filter, "filteredName", $$v)},expression:"queryDeviceOptions.filter.filteredName"}})],1),_c('v-data-table',{staticClass:"deviceDataTable pa-0 ma-0",staticStyle:{"overflow-y":"scroll"},attrs:{"id":"project-drawer-device-hub-calc-height","headers":_vm.headers,"items":_vm.projectDevicesFinal,"no-data-text":_vm.$t('common.noDataText'),"single-select":_vm.singleSelected,"dense":"","disable-filtering":"","disable-pagination":"","fixed-header":"","hide-default-footer":"","must-sort":""},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({class:[
              _vm.getPresent(item) ? 'text-decoration-none' : 'text-decoration-underline',
              'd-inline-block',
              'text-truncate' ],staticStyle:{"max-width":"260px"}},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),(!_vm.getPresent(item))?_c('span',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.$t('project.deviceNotPresentInView'))+" ")]):_vm._e()])]}}],null,true),model:{value:(_vm.selectedDevices),callback:function ($$v) {_vm.selectedDevices=$$v},expression:"selectedDevices"}}),_c('v-toolbar',{attrs:{"color":"tertiary","height":"32px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",attrs:{"color":_vm.isSelectedToOpenDevice ? 'secondary' : 'primary',"small":""},on:{"click":function($event){return _vm.clickIconToolbar('openDevice')}}},on),[_vm._v(" $iconSettings ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('project.deviceInfo')))])]),(_vm.$store.getters['auth/isAuth'](['project_view_device_coordinate', 'update']))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",attrs:{"color":_vm.isSelectedToAddDeviceMap ? 'secondary' : 'primary',"small":""},on:{"click":function($event){return _vm.clickIconToolbar('addDeviceMap')}}},on),[_vm._v(" $iconTarget ")])]}}],null,false,3191714839)},[_c('span',[_vm._v(_vm._s(_vm.$t('project.addDeviceMap')))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":_vm.isSelectedToZoomOn ? 'secondary' : 'primary'},on:{"click":function($event){return _vm.clickIconToolbar('zoomOn')}}},on),[_vm._v(" $iconEye ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('project.zoomOnDevice')))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.$store.getters['auth/isAuth'](['project_device', 'create']))?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.addDevice}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconPlus")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('project.addDevice')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(_vm.$store.getters['auth/isAuth'](['project_device', 'delete']))?_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.selectedDevices.length < 1},on:{"click":_vm.multiDeleteDevice}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconDelete")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('project.deleteDevice')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }