<template>
  <v-navigation-drawer
    id="project-drawer"
    v-model="displayDrawer"
    absolute
    right
    :mini-variant="displayDrawer"
    mini-variant-width="40px"
    permanent
    height="80%"
    width="340px"
    class="mr-2 mt-8"
    color="transparent"
  >
    <v-list dense class="ma-0 pa-0">
      <v-list-item
        v-for="(item, i) in drawerList"
        :key="i"
        :class="
          currentDrawer === item.name
            ? 'elevation-3 current-drawer-active-class'
            : 'elevation-3 tertiary'
        "
        @click="updateCurrentDrawer(item.name)"
      >
        <v-list-item-icon class="pa-0">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon
                v-if="item.name !== 'documents'"
                :id="item.id"
                :color="currentDrawer === item.name ? 'quaternary' : ''"
                v-on="on"
              >
                {{ item.icon }}
              </v-icon>
              <span v-else>
                <v-badge
                  v-if="newDocumentsTotal"
                  color="green"
                  :content="newDocumentsTotal"
                  overlap
                >
                  <v-icon
                    :id="item.id"
                    :color="currentDrawer === item.name ? 'quaternary' : ''"
                    v-on="on"
                  >
                    {{ item.icon }}
                  </v-icon>
                </v-badge>
                <v-icon
                  v-else
                  :id="item.id"
                  :color="currentDrawer === item.name ? 'quaternary' : ''"
                  v-on="on"
                >
                  {{ item.icon }}
                </v-icon>
              </span>
            </template>
            <span>
              {{ $tc(item.translate, 2) }}
            </span>
          </v-tooltip>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_AGGREGATE } from '@/graphql/ProjectDevicesProjectDeviceDocumentsQueries'
import { mapGetters, mapActions } from 'vuex'
import { formatDateTimeIso8601 } from '@/locales/formats/dataTimeFormats'
import * as moment from 'moment/moment'

export default {
  name: 'ProjectDrawer',
  apollo: {
    project_devices_project_device_documents_aggregate: {
      query: PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_AGGREGATE,
      pollInterval: 60000, // every 1 mn
      variables() {
        const nowMinus10days = moment().subtract(10, 'days').format(formatDateTimeIso8601)
        return {
          where: {
            project_device: {
              project_id: {
                _eq: this.currentProjectId,
              },
            },
            project_device_document: {
              created_at: {
                _gte: nowMinus10days,
              },
            },
          },
        }
      },
      result({ data }) {
        if (data) {
          this.newDocumentsTotal =
            data.project_devices_project_device_documents_aggregate.aggregate.count
        }
      },
    },
  },
  data: function () {
    return {
      // important: keep this order
      drawerList: [
        {
          id: 'intro-home-6-device',
          name: 'device',
          icon: '$iconSensor',
          translate: 'project.device',
        },
        {
          id: 'intro-home-7-view',
          name: 'view',
          icon: '$iconEye',
          translate: 'project.view',
        },
        {
          id: 'intro-home-8-dashboard',
          name: 'dashboard',
          icon: '$iconDashboard',
          translate: 'dashboard.dashboard',
        },
        {
          id: 'intro-home-9-contacts',
          name: 'contacts',
          icon: '$iconContact',
          translate: 'project.contact',
        },
        {
          id: 'intro-home-10-documents',
          name: 'documents',
          icon: '$iconFile',
          translate: 'common.document',
        },
        {
          id: 'intro-home-11-calendar',
          name: 'calendar',
          icon: '$iconCalendar',
          translate: 'calendar.agenda',
        },
      ],
      newDocumentsTotal: 0,
      ProjectViews: [],
    }
  },
  computed: {
    ...mapGetters({
      currentDrawer: 'project/currentDrawer',
      currentProjectId: 'project/currentProjectId',
    }),
    displayDrawer: {
      get() {
        return this.currentDrawer === 'none'
      },
      set(newName) {
        return newName
      },
    },
  },
  methods: {
    ...mapActions({
      updateCurrentDrawer: 'project/updateCurrentDrawer',
    }),
  },
}
</script>

<style lang="css">
div.v-navigation-drawer__border {
  width: 0 !important;
}
.current-drawer-active-class {
  background-color: #f3f3f3;
  border-left: 4px solid #3171be;
  padding-left: 12px;
}
</style>
