import {
  GET_METRICS_BY_SENSOR_TIME_BUCKET_GAPFILL,
  GET_DEVICES_SENSORS_LAST_VALUE,
  GET_SENSOR,
} from '@/graphql/ProjectGraphQuery'
import { mapGetters } from 'vuex'
import * as moment from 'moment/moment'
import merge from 'lodash/merge'

export const SensorMixin = {
  data() {
    return {
      SensorsLastValue: [],
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
  },
  created: function () {
    this.GetSensorLastValue('sensor')
  },
  methods: {
    /**
     * Returns the minimum and maximum of the metric depending on its type
     * @param {Object[]} datasMetrics arr[metric_time, metric]
     * @return {Array} [min, max]
     */
    GetMinMaxData(datasMetrics) {
      let metrics = []
      let result = []
      if (datasMetrics?.length > 0) {
        const filtered = datasMetrics.filter((i) => {
          return i[1] !== null
        })
        metrics = Array.from(filtered, (i) => i[1])
        // @link https://stackoverflow.com/a/42623277
        const arrayMinMax = (arr) =>
          arr.reduce(
            ([min, max], val) => [Math.min(min, val), Math.max(max, val)],
            [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
          )
        result = arrayMinMax(metrics)
      }
      return result
    },
    /**
     * Return the color or the message according to the time of metric_time:
     * <24h || >= 24h && < 48h || > 48H
     * @param {Object} item
     * @param {string} type color || msg
     * @return {string} color || message translation
     */
    GetSensorLastValueColorMsg(item, type) {
      let color = null
      let msg = null
      if (!item?.metric_time) {
        color = 'text--disabled'
        msg = '?'
      } else {
        /**
         * 24h = 86400000 ms
         * 72h = 259200000 ms
         */
        const dateNow = moment()
        const metricTime = moment(item.metric_time)
        const diff = dateNow.diff(metricTime)
        if (diff < 86400000) {
          color = 'text--black'
          msg = '< 24h'
        } else if (diff >= 86400000 && diff < 259200000) {
          color = 'orange--text'
          msg = '>= 24h & < 48h'
        } else {
          color = 'red--text'
          msg = '> 48h'
        }
      }
      return type === 'color' ? color : this.$tc('common.lastMeasurements', 1) + ' ' + msg
    },
    /**
     * Return sensor metrics
     * @param {Object[]} arr
     * @param {string} arr[].bucket
     * @param {string} arr[].device_id - Device uuid.
     * @param {string} arr[].dateTimeFrom
     * @param {string} arr[].dateTimeTo
     * @return {Array}
     */
    async GetMetrics(arr) {
      const datasMetrics = []

      for (let i = 0; i < arr.length; i++) {
        const bucket = arr[i]?.bucket || '300 s'
        const check = arr[i]?.device_id && arr[i]?.dateTimeFrom && arr[i]?.dateTimeTo

        if (check) {
          const vars = {
            args: {
              f_project_uuid: this.$store.getters['project/currentProjectId'],
              f_sensor_uuid: arr[i].device_id,
              f_bucket_width: bucket,
              f_date_low: arr[i].dateTimeFrom,
              f_date_high: arr[i].dateTimeTo,
            },
          }

          let resp = null

          await this.$apollo
            .query({
              query: GET_METRICS_BY_SENSOR_TIME_BUCKET_GAPFILL,
              variables: vars,
            })
            .then((res) => {
              resp = res
            })
            .catch((err) => {
              throw err
            })

          if (resp) {
            datasMetrics[i] = resp.data.get_metrics_by_sensor_time_bucket_gapfill.map((metric) => [
              metric.metric_time,
              metric.metric,
            ])
          }
        }
      }

      return datasMetrics
    },
    /**
     * Return one sensor all metrics
     * @param {Object[]} arr
     * @param {string} arr[].device_id - Device uuid.
     * @param {string} arr[].dateTimeFrom
     * @param {string} arr[].dateTimeTo
     * @return {Array}
     */
    async GetSensorAllMetrics(arr) {
      let datasMetrics = []
      if (arr?.deviceUuid && arr?.dateTimeFrom && arr?.dateTimeTo && arr?.projectUuid) {
        const vars = {
          where: {
            project_uuid: {
              _eq: arr.projectUuid,
            },
            device_uuid: {
              _eq: arr.deviceUuid,
            },
            _and: [
              {
                metric_time: {
                  _gte: arr.dateTimeFrom,
                },
                _and: {
                  metric_time: {
                    _lte: arr.dateTimeTo,
                  },
                },
              },
            ],
          },
          order_by: {
            metric_time: 'asc',
          },
        }

        let resp = null

        await this.$apollo
          .query({
            query: GET_SENSOR,
            variables: vars,
          })
          .then((res) => {
            resp = res
          })
          .catch((err) => {
            throw err
          })

        if (resp) {
          datasMetrics[0] = resp.data.sensor.map((metric) => [metric.metric_time, metric.metric])
        }
      }
      return datasMetrics
    },
    /**
     * Get sensor(s) last value and set it in SensorLastValue
     * @param {string} [deviceType]
     * @param {string} [deviceUuid]
     */
    async GetSensorLastValue(deviceType, deviceUuid) {
      let vars = {
        where: {
          project_id: {
            _eq: this.currentProjectId,
          },
        },
        order_by: {
          name: 'asc',
        },
      }

      if (deviceType) {
        const where = {
          where: {
            device_type: {
              _eq: deviceType,
            },
          },
        }
        vars = merge(vars, where)
      }

      if (deviceUuid) {
        const where = {
          where: {
            id: {
              _eq: deviceUuid,
            },
          },
        }
        vars = merge(vars, where)
      }

      let result = []

      await this.$apollo
        .query({
          query: GET_DEVICES_SENSORS_LAST_VALUE,
          variables: vars,
          skip: !this.currentProjectId,
        })
        .then((res) => {
          result = res
        })
        .catch((err) => {
          throw err
        })

      this.SensorsLastValue = result?.data?.project_device
    },
  },
}
