import { regex } from 'vuelidate/lib/validators/common'

/**
 * include all characters :
 * a-z
 * A-Z
 * àâäéèêëïîôöùûüÿçæœÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒa
 * space - '
 */

const alphaExtendedRegex = /^['àâäéèêëïîôöùûüÿçæœÀÂÄÇÉÈÊËÎÏÔÖÙÛÜŸÆŒa-zA-Z\-\s]*$/

export default regex('alphaExtended', alphaExtendedRegex)
