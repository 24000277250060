/**
 * Define all of your application routes here for more information on routes,
 * see the official documentation https://router.vuejs.org/en/
 */
export default [
  {
    name: 'AdministrationMenu',
    path: '/administration',
    slug: 'administration',
    view: 'EmptyRouterView',
    icon: '$iconUsersCircle',
    i18n: 'common.administration',
    acl: { project: 'create' },
    meta: { requiresAuth: true },
    menu: true,
    children: [
      {
        name: 'UsersList',
        path: 'users',
        slug: 'users',
        view: 'User',
        icon: '$iconUsers',
        i18n: 'common.user',
        acl: { project: 'create' },
        meta: { requiresAuth: true },
      },
      {
        name: 'CompanyList',
        path: 'companies',
        slug: 'companies',
        view: 'Company',
        icon: '$iconSociety',
        i18n: 'crmCompany.company',
        acl: { project: 'create' },
        meta: { requiresAuth: true },
      },
      {
        name: 'ProjetList',
        path: 'projects',
        slug: 'projects',
        view: 'Project',
        icon: '$iconProjects',
        i18n: 'common.project',
        acl: { project: 'create' },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    name: 'LibraryMenu',
    path: '/library',
    slug: 'library',
    view: 'EmptyRouterView',
    icon: '$iconLibrary',
    i18n: 'common.library',
    menu: true,
    acl: { project: 'create' },
    meta: { requiresAuth: true },
    children: [
      {
        name: 'LibDeviceList',
        path: 'lib_device', // @todo change to lib-device ?
        slug: 'lib_device', // @todo change to lib-device ?
        view: 'LibDevice',
        icon: '$iconSensor',
        i18n: 'libDevice.device',
        acl: { project: 'create' },
        meta: { requiresAuth: true },
      },
      {
        name: 'LibDeviceParameter',
        path: 'device_parameter', // @todo change to device-parameter
        slug: 'device_parameter', // @todo change to device-parameter
        view: 'LibDeviceParameter',
        icon: '$iconSettingsFile',
        i18n: 'libDeviceParameter.titleBarMenu',
        acl: { project: 'create' },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    name: 'AlertMenu',
    path: '/alert',
    slug: 'alert',
    view: 'EmptyRouterView',
    icon: '$iconBell',
    badge: {
      query: 'GET_TOTAL_ALERTS_COUNT',
      graphqlFile: 'AlertAlertQueries',
    },
    i18n: 'alert.alert',
    onMobile: true,
    menu: true,
    meta: { requiresAuth: true },
    children: [
      {
        name: 'Notification',
        path: 'notification',
        slug: 'notification',
        view: 'Notification',
        icon: '$iconBell',
        badge: {
          color: 'error',
          query: 'GET_LOGS_COUNT',
          graphqlFile: 'LogQueries',
        },
        i18n: 'common.notification',
        onMobile: true,
        meta: { requiresAuth: true },
        props: true,
      },
      {
        name: 'AlertList',
        path: 'alert',
        slug: 'alert',
        view: 'Alert',
        icon: '$iconAlert',
        badge: {
          query: 'GET_ALERT_ALERTS_COUNT',
          graphqlFile: 'AlertAlertQueries',
        },
        i18n: 'alert.alert',
        acl: { alert_alert: 'update' },
        meta: { requiresAuth: true },
      },
      {
        name: 'AlertContactPointList',
        path: 'contactpoint',
        slug: 'contactpoint',
        view: 'AlertContactPoint',
        icon: '$iconMessages',
        i18n: 'alert.contactpoint',
        acl: { alert_alert: 'update' },
        meta: { requiresAuth: true },
      },
      {
        name: 'AlertNotificationPolicytList',
        path: 'notification_policy',
        slug: 'notification_policy',
        view: 'AlertNotificationPolicy',
        icon: '$iconNotificationPolicy',
        i18n: 'alert.notificationpolicy',
        acl: { alert_alert: 'update' },
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    name: 'Calendar',
    path: '/calendar',
    slug: 'calendar',
    view: 'Calendar',
    icon: null,
    meta: { requiresAuth: true },
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    name: 'CalendarEventAdd',
    path: '/calendar-event/add/:startTime?',
    slug: 'calendar-event-add',
    view: 'CalendarEventForm',
    icon: null,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'CalendarEventEdit',
    path: '/calendar-event/edit/:value',
    slug: 'calendar-event-edit',
    view: 'CalendarEventForm',
    icon: null,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'CalendarEventView',
    path: '/calendar-event/view/:value',
    slug: 'calendar-event-view',
    view: 'CalendarEventForm',
    icon: null,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'CrmContact',
    path: '/crm-contact',
    slug: 'crm-contact',
    view: 'CrmContact',
    icon: null,
    meta: { requiresAuth: true },
  },
  {
    name: 'CrmContactAdd',
    path: '/crm-contact/add',
    slug: 'crm-contact-add',
    view: 'CrmContactForm',
    icon: null,
    meta: { requiresAuth: true },
  },
  {
    name: 'CrmContactEdit',
    path: '/crm-contact/edit/:value',
    slug: 'crm-contact-edit',
    view: 'CrmContactForm',
    props: true,
    icon: null,
    meta: { requiresAuth: true },
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    slug: 'dashboard',
    view: 'Dashboard',
    icon: null,
    meta: {
      requiresAuth: true,
      title: 'dashboard',
    },
  },
  {
    name: 'DashboardAdd',
    path: '/dashboard/add',
    slug: 'dashboard-add',
    view: 'DashboardForm',
    icon: null,
    meta: { requiresAuth: true },
  },
  {
    name: 'DashboardEdit',
    path: '/dashboard/edit/:value',
    slug: 'dashboard-edit',
    view: 'DashboardForm',
    icon: null,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'DashboardView',
    path: '/dashboard/view/:value',
    slug: 'dashboard-view',
    view: 'DashboardForm',
    icon: null,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'home',
    path: '/project',
    slug: 'project',
    view: 'ProjectHome',
    icon: null,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    name: 'projectDevice',
    path: '/project/device/:uuid/:tab/:logTime?',
    slug: 'project',
    view: 'ProjectHome',
    icon: null,
    meta: {
      isProjectDrawerDevice: true,
      isProjectDrawerDeviceDialog: false,
      requiresAuth: true,
    },
    props: true,
  },
  {
    name: 'LastMetrics',
    path: '/last-metrics',
    slug: 'last-metrics',
    view: 'LastMetrics',
    icon: null,
    meta: { requiresAuth: true },
  },
  {
    name: 'Login',
    path: '/login',
    slug: 'login',
    view: 'Login',
    icon: null,
  },
  {
    name: 'Projets',
    path: '/projects_root',
    slug: 'projects-root',
    // view: null,
    icon: '$iconProjects',
    meta: { requiresAuth: true },
    children: [
      {
        name: 'Vue 3D',
        path: '3d-vue',
        slug: '3d-vue',
        view: 'Tree',
        icon: '$icon3D',
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    name: 'Register',
    path: '/register',
    slug: 'register',
    view: 'AuthRegister',
    icon: null,
  },
  {
    name: 'Report',
    path: '/report',
    slug: 'report',
    view: 'Report',
    icon: null,
    meta: { requiresAuth: true },
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    name: 'ReportEdit',
    path: '/report/edit/:value',
    slug: 'report-edit',
    view: 'ReportForm',
    props: true,
    icon: null,
    meta: { requiresAuth: true },
  },
  {
    name: 'User Profile',
    path: '/user-profile',
    slug: 'user-profile',
    view: 'UserProfile',
    icon: null,
    meta: { requiresAuth: true },
  },
  {
    name: 'ViewFocus',
    path: '/viewfocus/:viewUuid/:deviceUuid?/:currentDrawer?',
    slug: 'viewfocus',
    view: 'ProjectHome',
    icon: null,
    props: true,
    meta: { requiresAuth: true },
  },
]
