<template>
  <v-container fluid class="mx-auto pa-0">
    <progress-linear-timer v-model="timerRemember" :is-loading="isLoading" />
    <div v-if="!isLoading">
      <v-card v-if="graph.series && graph.series[0].data.length > 0" class="mx-auto pa-0">
        <v-banner id="graph-single-banner" class="grey lighten-5" single-line>
          <div class="d-inline-flex">
            <graph-scale v-model="optionsScale" :is-dashboard="isDashboard" />
            <graph-threshold :threshold="thresholdListDisplay" :is-dashboard="isDashboard" />
          </div>
          <template #actions>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn icon small v-on="on" @click="allMetrics = !allMetrics">
                  <v-icon>
                    {{ allMetrics ? '$iconChartLineAll' : '$iconChartLine' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ allMetrics ? $t('common.seeMetricsByInterval') : $t('common.seeAllMetrics') }}
              </span>
            </v-tooltip>
          </template>
        </v-banner>
        <v-card-text :class="isDashboard ? 'pa-0' : ''">
          <apex-charts
            :ref="localRefId"
            height="250"
            width="99.9%"
            type="line"
            :options="graph.optionsApexChart"
            :series="graph.series"
          />
          <div v-if="!isDashboard" class="text-caption text-right pr-6">
            {{ $t('common.duration') }} {{ timerRemember }} -
            {{ $t('common.pointInterval') }} (bucket) : {{ bucketInfo }} -
            {{ $t('project.numbersMeasures') }}&nbsp;{{ graph.series[0].data.length }}
          </div>
        </v-card-text>
      </v-card>
      <v-card v-else class="mx-auto pa-4 text-center">
        <v-card-text>
          {{ $t('common.noDataText') }}
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { GET_PROJECT } from '@/graphql/ProjectQueries'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import { SensorMixin } from '@/mixins/SensorMixin'
import {
  calcBelowXDays,
  calcBucket,
  getTimeInterval,
} from '@/components/project/graphs/GraphHelper'
import {
  dateTimeHumanFormatFromTo,
  formatDate,
  formatDateTimeIso8601,
} from '@/locales/formats/dataTimeFormats'
import GraphScale from '@/components/project/graphs/GraphScale'
import GraphThreshold from '@/components/project/graphs/GraphThreshold'
import ProgressLinearTimer from '@/components/helper/ProgressLinearTimer'
import VueApexCharts from 'vue-apexcharts'
import en from 'apexcharts/dist/locales/en.json'
import fr from 'apexcharts/dist/locales/fr.json'
import * as moment from 'moment/moment'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'GraphSingle',
  components: {
    apexCharts: VueApexCharts,
    GraphScale,
    GraphThreshold,
    ProgressLinearTimer,
  },
  mixins: [ProjectMixin, SensorMixin],
  props: {
    // Used from dashboard and notification
    isDashboard: {
      type: Boolean,
      default: false,
    },
    optionsParams: {
      type: Object,
      default: () => {},
    },
    refId: {
      type: [Number, String],
      default: 0,
    },
  },
  apollo: {
    project_by_pk: {
      query: GET_PROJECT,
      variables() {
        return {
          id: this.$store.getters['project/currentProjectId'],
        }
      },
      result({ data }) {
        if (typeof data !== 'undefined') {
          this.projectName = data.project_by_pk.name
        }
      },
    },
  },
  data() {
    return {
      allMetrics: true,
      bucketInfo: '',
      bufferValue: 100,
      configLabel: [
        'app_round_metrics',
        'app_round_ordinate',
        'ch_alarm_low',
        'ch_alarm_high',
        'ch_alert_low',
        'ch_alert_high',
        /**
         * For seismic sensors, the alert threshold is
         * programmed in the variable ch_seuil_trigger
         */
        'ch_seuil_trigger',
        'ch_unit',
      ],
      datasMetricsTmp: null,
      dateMinMax: {
        max: undefined,
        min: undefined,
      },
      deviceParameters: null,
      deviceParametersList: {},
      graph: {},
      graphDefault: {
        optionsApexChart: {},
        series: [
          {
            data: [],
          },
        ],
      },
      isLoading: true,
      localOptionsParams: this.optionsParams,
      localRefId: this.refId,
      optionsScale: {},
      projectName: '?',
      timerRemember: '00:00.000',
      thresholdListDisplay: {
        alarm: {},
        alert: {},
        trigger: {},
      },
    }
  },
  computed: {
    userCurrentLang() {
      return this.$store.getters['auth/user']?.profile?.lang || 'fr'
    },
  },
  watch: {
    allMetrics: function () {
      this.updateGraph()
    },
    datasMetricsTmp: function (val) {
      if (val.length > 0) {
        const optionsApexChart = this.updateOptionsCharts()
        this.createGraph(val, optionsApexChart)
      }
    },
    optionsParams: function (val) {
      this.localOptionsParams = val
      this.VarsDeviceParameters = this.deviceParametersVars()
    },
    localOptionsParams: function (val, oldVal) {
      const newDevice = oldVal?.device_id !== val?.device_id
      const newOptions = val?.newOptions

      this.optionsScale = {
        yaxisMax: val?.yaxisMax,
        yaxisMin: val?.yaxisMin,
        newScale: val?.newScale,
      }

      if (newDevice || newOptions) {
        this.graph = this.graphDefault
        const allMetricsTmp = calcBelowXDays(val.dateTimeFrom, val.dateTimeTo)
        if (this.allMetrics === allMetricsTmp) {
          this.updateGraph()
        }
        this.allMetrics = allMetricsTmp
      }
    },
    optionsScale: function (val) {
      if (val.newScale) {
        this.updateGraph()
      }
    },
    ProjectDevicesParameters: {
      handler: function (val, oldVal) {
        if (val !== oldVal && val?.length > 0 && val?.length <= 8) {
          this.deviceParameters = val
          this.parameters()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (isEmpty(this.localOptionsParams)) {
      this.graph = this.graphDefault
    }
  },
  methods: {
    calcScale(value, scale) {
      let result = 0
      if (value < 0 && scale === 'low') {
        result = value * 1.15
      }
      if (value < 0 && scale === 'high') {
        result = value * 0.85
      }
      if (value > 0 && scale === 'low') {
        result = value * 0.85
      }
      if (value > 0 && scale === 'high') {
        result = value * 1.15
      }
      return parseFloat(result.toFixed(4))
    },
    createGraph(metrics, optionsApexChart) {
      this.updateOptionsParams(optionsApexChart)
      this.graph = {
        optionsApexChart: optionsApexChart,
        series: [
          {
            name: this.localOptionsParams.device_name,
            data: metrics,
          },
        ],
      }
    },
    datasMetricsScale(datasMetrics) {
      const minMax = this.GetMinMaxData(datasMetrics)
      this.optionsScale.yaxisMax = this.calcScale(minMax[1], 'high')
      this.optionsScale.yaxisMin = this.calcScale(minMax[0], 'low')
    },
    deviceParametersVars() {
      const vars = {
        where: {
          project_device_uuid: {
            _eq: this.localOptionsParams.device_id,
          },
          project_device_parameter: {
            config_label: {
              _in: this.configLabel,
            },
          },
        },
        order_by: {
          project_device_parameter: {
            config_label: 'asc',
          },
        },
      }
      return vars
    },
    getLangAndTranslate() {
      return {
        lang: this.userCurrentLang,
        to: this.$t('common.to'),
      }
    },
    parameters() {
      if (this.deviceParameters) {
        Object.entries(this.deviceParameters).forEach(([, value]) => {
          this.deviceParametersList[value.project_device_parameter.config_label] = isNaN(
            value.parameter_value.value
          )
            ? value.parameter_value.value
            : parseFloat(value.parameter_value.value)

          const configLabel = value.project_device_parameter.config_label.split('_')
          if (configLabel[1] === 'alarm') {
            this.thresholdListDisplay[configLabel[1]][configLabel[2]] = parseFloat(
              value.parameter_value.value
            )
          } else if (configLabel[1] === 'alert') {
            this.thresholdListDisplay[configLabel[1]][configLabel[2]] = parseFloat(
              value.parameter_value.value
            )
          } else if (configLabel[2] === 'trigger') {
            this.thresholdListDisplay[configLabel[2]][configLabel[2]] = parseFloat(
              value.parameter_value.value
            )
          } else {
            // do nothing
          }
        })
      }
    },
    updateAnnotation(ot) {
      const op = this.localOptionsParams
      const newOptions = {
        annotations: {
          points: [],
          xaxis: [],
          yaxis: [],
        },
      }

      // Used from notification
      if (op.annotationsPoints) {
        newOptions.annotations.points = [
          {
            x: new Date(op.annotationsPoints.log_time).getTime(),
            y: op.annotationsPoints.value,
            marker: {
              size: 6,
              fillColor: '#fff',
              strokeColor: op.annotationsPoints.color,
              radius: 2,
              cssClass: 'apexcharts-custom-class',
            },
            label: {
              borderColor: op.annotationsPoints.color,
              offsetY: 0,
              style: {
                color: '#fff',
                background: op.annotationsPoints.color,
              },
              text: op.annotationsPoints.type,
            },
          },
        ]
      }

      if (ot.ch_alert_low || ot.ch_alert_low === 0) {
        newOptions.annotations.yaxis.push({
          y: ot.ch_alert_low,
          borderColor: '#FEB019',
          strokeDashArray: 0,
        })
      }

      if (ot.ch_alert_high || ot.ch_alert_high === 0) {
        newOptions.annotations.yaxis.push({
          y: ot.ch_alert_high,
          borderColor: '#FEB019',
          strokeDashArray: 0,
        })
      }

      if (ot.ch_alarm_low || ot.ch_alarm_low === 0) {
        newOptions.annotations.yaxis.push({
          y: ot.ch_alarm_low,
          borderColor: '#FF4560',
          strokeDashArray: 0,
        })
      }

      if (ot.ch_alarm_high || ot.ch_alarm_high === 0) {
        newOptions.annotations.yaxis.push({
          y: ot.ch_alarm_high,
          borderColor: '#FF4560',
          strokeDashArray: 0,
        })
      }

      if (ot.ch_seuil_trigger || ot.ch_seuil_trigger === 0) {
        newOptions.annotations.yaxis.push({
          y: ot.ch_seuil_trigger,
          borderColor: '#FEB019',
          strokeDashArray: 0,
        })
      }
      return newOptions
    },
    updateOptionsCharts() {
      const op = this.localOptionsParams
      const os = this.optionsScale
      const dpl = this.deviceParametersList
      const annotations = this.updateAnnotation(dpl).annotations
      const appRoundOrdinate = dpl.app_round_ordinate
      const appRoundMetrics = dpl.app_round_metrics
      const filename = this.projectName + '_' + op.hub_name + '_' + op.device_name
      const lang = this.userCurrentLang
      const subtitleText = this.isDashboard
        ? dateTimeHumanFormatFromTo(
            this.localOptionsParams.dateTimeFrom,
            this.localOptionsParams.dateTimeTo,
            this.getLangAndTranslate()
          )
        : this.$t('common.periodColon') +
          ' ' +
          dateTimeHumanFormatFromTo(
            this.localOptionsParams.dateTimeFrom,
            this.localOptionsParams.dateTimeTo,
            this.getLangAndTranslate()
          )
      const titleText = this.isDashboard
        ? ''
        : this.$t('common.siteColon') +
          this.projectName +
          ' - ' +
          this.$t('common.hubColon') +
          op.hub_name +
          ' - ' +
          this.$t('project.sensorColon') +
          ' ' +
          op.device_name
      const unit = dpl.ch_unit
      const yaxisTitleText = this.$t('common.unitColon') + unit

      const newOptions = {
        annotations: annotations,
        chart: {
          id: this.localRefId + '_' + op.device_name,
          animations: {
            enabled: false,
          },
          defaultLocale: lang,
          events: {
            zoomed: function (_chartContext, { xaxis }) {
              return xaxis
            },
          },
          locales: [en, fr],
          toolbar: {
            export: {
              csv: {
                dateFormatter(timestamp) {
                  return moment(timestamp).format(formatDateTimeIso8601)
                },
                filename: filename,
                headerCategory: this.allMetrics ? 'Datetime' : 'Datetime by interval',
              },
              png: {
                filename: filename,
              },
              svg: {
                filename: filename,
              },
            },
            tools: {
              download: !this.isDashboard,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: 0,
        },
        stroke: {
          width: 1,
        },
        subtitle: {
          align: this.isDashboard ? 'left' : 'right',
          margin: this.isDashboard ? 0 : -20,
          offsetX: this.isDashboard ? -5 : -180,
          offsetY: this.isDashboard ? 6 : 15,
          style: {
            fontSize: this.isDashboard ? '9px' : '12px',
          },
          text: subtitleText,
        },
        title: {
          align: 'left',
          offsetY: 15,
          style: {
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 600,
          },
          text: titleText,
        },
        tooltip: {
          followCursor: true,
          x: {
            format: formatDate[lang].picker + ' HH:mm:ss',
          },
          y: {
            formatter: (value) => {
              return appRoundMetrics
                ? Number.parseFloat(value).toFixed(parseInt(appRoundMetrics, 10))
                : value
            },
          },
        },
        xaxis: {
          labels: {
            datetimeUTC: false,
          },
          min: this.dateMinMax.min ? new Date(this.dateMinMax.min).getTime() : undefined,
          max: this.dateMinMax.max ? new Date(this.dateMinMax.max).getTime() : undefined,
          tooltip: {
            enabled: false,
          },
          type: 'datetime',
        },
        yaxis: {
          decimalsInFloat: Number.parseInt(appRoundOrdinate, 10) || 4,
          forceNiceScale: false,
          max: os.yaxisMax,
          min: os.yaxisMin,
          tickAmount: 5,
          title: {
            text: yaxisTitleText,
          },
        },
      }

      return newOptions
    },
    updateOptionsParams(optionsApexChart) {
      this.localOptionsParams.refId = this.localRefId
      this.localOptionsParams.annotationsYaxis = optionsApexChart.annotations?.yaxis
      this.localOptionsParams.bucket = this.bucketInfo
      this.localOptionsParams.titleText = optionsApexChart.title.text
      this.localOptionsParams.subtitleText = optionsApexChart.subtitle.text
      this.localOptionsParams.yaxisTitleText = optionsApexChart.yaxis.title.text
      this.localOptionsParams.yaxisMax = this.optionsScale.yaxisMax
      this.localOptionsParams.yaxisMin = this.optionsScale.yaxisMin
      if (this.optionsScale.newScale) {
        this.localOptionsParams.newScale = this.optionsScale.newScale
      }
      this.$emit('update:options-params', this.localOptionsParams)
    },
    async updateGraph() {
      this.isLoading = true

      if (this.localOptionsParams?.device_id) {
        const dateMin = getTimeInterval(
          this.localOptionsParams.dateTimeFrom,
          this.localOptionsParams.dateTimeTo
        ).dateMin
        const dateMax = getTimeInterval(
          this.localOptionsParams.dateTimeFrom,
          this.localOptionsParams.dateTimeTo
        ).dateMax
        const bucket = calcBucket(dateMin, dateMax)
        this.bucketInfo = bucket
        this.dateMinMax = {
          max: dateMax,
          min: dateMin,
        }

        let datasMetrics = []

        if (this.allMetrics) {
          this.bucketInfo = this.$t('common.no')
          const arr = {
            deviceUuid: this.localOptionsParams.device_id,
            dateTimeFrom: dateMin,
            dateTimeTo: dateMax,
            projectUuid: this.$store.getters['project/currentProjectId'],
          }
          datasMetrics = await this.GetSensorAllMetrics(arr)
        } else {
          const arr = [
            {
              bucket: bucket,
              device_id: this.localOptionsParams.device_id,
              dateTimeFrom: dateMin,
              dateTimeTo: dateMax,
            },
          ]
          datasMetrics = await this.GetMetrics(arr)
        }

        if (datasMetrics[0].length > 0) {
          if (!this.optionsScale.newScale) {
            this.datasMetricsScale(datasMetrics[0])
          }
          this.datasMetricsTmp = datasMetrics[0]
        }
      }

      this.isLoading = false
    },
  },
}
</script>

<style>
#graph-single-banner > .v-banner__wrapper {
  padding: 0 8px;
}
.ch-alarm {
  color: #ff4560;
}
.ch-alert {
  color: #feb019;
}
</style>
