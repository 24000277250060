var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{staticClass:"tags-selector pt-1",attrs:{"filter":_vm.filter,"hide-no-data":!_vm.tagsSearch,"items":_vm.tagsItems,"label":_vm.label,"menu-props":{ closeOnContentClick: true, offsetY: true, top: false },"placeholder":_vm.placeholder,"search-input":_vm.tagsSearch,"item-text":"tag_value","item-value":"id","chips":"","clearable":"","dense":"","hide-selected":"","multiple":"","return-object":""},on:{"update:searchInput":function($event){_vm.tagsSearch=$event},"update:search-input":function($event){_vm.tagsSearch=$event},"paste":_vm.tagsPasted,"change":_vm.selectItem},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t('common.create'))+" ")]),_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.tagsSearch)+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.tag_value)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("$iconClose")])],1):_vm._e()]}},(_vm.itemEdit === 'on')?{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(_vm.editing === item)?_c('v-text-field',{attrs:{"autofocus":"","flat":"","background-color":"transparent","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.edit(index, item)}},model:{value:(_vm.editing.tag_value),callback:function ($$v) {_vm.$set(_vm.editing, "tag_value", $$v)},expression:"editing.tag_value"}}):_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(item.tag_value)+" ")]),_c('v-spacer'),_c('v-list-item-action',{staticClass:"d-flex flex-row",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(index, item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.editing !== item ? '$iconEdit' : '$iconCheck'))])],1),(_vm.editing !== item)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteTag(item)}}},[_c('v-icon',[_vm._v("$iconDelete")])],1):_vm._e()],1)]}}:null],null,true),model:{value:(_vm.tagsSelect),callback:function ($$v) {_vm.tagsSelect=$$v},expression:"tagsSelect"}})}
var staticRenderFns = []

export { render, staticRenderFns }