<template>
  <v-container fluid class="mx-auto px-0 pt-0">
    <v-card>
      <v-toolbar flat color="white">
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t('project.stationConfiguration') }}
        </div>
        <v-spacer />
        <v-btn
          v-if="$store.getters['auth/isAuth'](['project_device', 'update'])"
          class="mb-2 mr-1"
          @click="saveConfig"
        >
          <v-icon class="mr-1" color="primary"> $iconSave </v-icon>{{ $t('common.save') }}
        </v-btn>
      </v-toolbar>
      <v-row class="px-4">
        <v-col cols="6">
          <company-selector v-model="crmCompany" :label="$t('project.companyProjectManager')" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="deviceLocal.config_fields.area_name"
            :label="$t('project.areaName')"
            :placeholder="$t('project.areaName')"
          />
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col cols="6">
          <v-text-field
            v-model="deviceLocal.config_fields.hub_phone"
            :label="$t('project.stationPhone')"
            :placeholder="$t('project.stationPhone')"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="deviceLocal.config_fields.hub_password"
            :label="$t('project.stationPassword')"
            :placeholder="$t('project.stationPassword')"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { GET_PROJECT_DEVICES, PATCH_PROJECT_DEVICE } from '@/graphql/ProjectDeviceQueries'
import CompanySelector from '@/components/helper/CompanySelector'

export default {
  name: 'DeviceDialogTabSensorConfHub',
  components: {
    CompanySelector,
  },
  props: {
    device: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    config_fields_default: {
      area_name: '',
      crm_company: {
        uuid: '',
        name: '',
      },
      hub_phone: '',
      hub_password: '',
    },
    crmCompanyNew: {
      uuid: '',
      name: '',
    },
  }),
  computed: {
    crmCompany: {
      get() {
        return this.getCrmCompany()
      },
      set(val) {
        this.crmCompanyNew = val
      },
    },
    deviceLocal() {
      return this.device
    },
  },
  watch: {
    crmCompanyNew: function (val) {
      this.crmCompany = val
    },
  },
  methods: {
    configFields() {
      if (typeof this.device.config_fields.crm_company === 'undefined') {
        this.deviceLocal.config_fields = this.config_fields_default
      }
      return typeof this.device.config_fields.crm_company !== 'undefined'
    },
    getCrmCompany() {
      const result = {
        uuid: '',
        name: '',
      }
      if (typeof this.device.config_fields.crm_company_uuid !== 'undefined') {
        this.crmCompanyNew = result
        result.uuid = this.device.config_fields.crm_company_uuid
      }
      return result
    },
    async saveConfig() {
      const modificationCompleted = this.$t('common.modificationCompleted')

      /**
       * Patch to delete old data
       * Date: 2021-01-20
       */
      if (typeof this.device.config_fields.crm_company !== 'undefined') {
        delete this.device.config_fields.crm_company
      }

      const projectDeviceConfigFields = {
        config_fields: this.device.config_fields,
      }

      projectDeviceConfigFields.config_fields.crm_company_uuid = this.crmCompanyNew.uuid

      const result = await this.$apollo
        .mutate({
          mutation: PATCH_PROJECT_DEVICE,
          variables: {
            id: this.device.id,
            body: projectDeviceConfigFields,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_DEVICES,
              variables: {
                where: {
                  project_id: {
                    _eq: this.$store.getters['project/currentProjectId'],
                  },
                },
              },
            },
          ],
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar('update PATCH_PROJECT_DEVICE ' + error.message, {
            color: 'error',
          })
          throw error
        })

      if (result.data.update_project_device.returning.length === 1) {
        this.$root.$dialogLoader.showSnackbar(modificationCompleted, {
          color: 'success',
        })
      }
    },
  },
}
</script>
