<template>
  <v-container fluid class="ma-0 pa-0">
    <v-toolbar flat width="100%" class="grey lighten-5">
      <helper-breadcrumbs :last-item="author + ' : ' + choiceListItemTitle" />
      <v-spacer />
      <v-btn v-if="isView && !isAuthor" class="mr-1" :x-small="IsMobile()" @click="subscribe">
        <v-icon class="mr-1" color="primary">
          {{ isSubscribe() ? '$iconStarOff' : '$iconStar' }}
        </v-icon>
        <span v-if="!IsMobile()">
          {{ isSubscribe() ? $t('dashboard.bookmarkDelete') : $t('dashboard.bookmarkAdd') }}
        </span>
      </v-btn>
      <v-btn class="mr-1" :x-small="IsMobile()" @click="closeForm">
        <v-icon class="mr-1" color="primary">$iconClose</v-icon>
        <span v-if="!IsMobile()">
          {{ $t('common.close') }}
        </span>
      </v-btn>
    </v-toolbar>
    <v-container fluid class="pa-0">
      <v-card elevation="0" width="100%" tile>
        <v-card-actions v-if="!isView && !IsMobile()" class="pl-6 py-0">
          <form-error
            :validator="$v.dashboardOne.name"
            :attribute="$t('common.name')"
            :messages="messages"
            style="width: 320px"
          >
            <v-text-field
              v-model="dashboardOne.name"
              slot-scope="{ attrs }"
              v-bind="attrs"
              :label="$t('common.name') + ' ' + $t('dashboard.dashboard') + ' *'"
              :placeholder="$t('common.name') + ' ' + $t('dashboard.dashboard')"
              :validate-on-blur="isTrue"
              @input="$v.dashboardOne.name.$touch()"
              @blur="$v.dashboardOne.name.$touch()"
            >
              <template #append-outer>
                <v-btn icon small :disabled="$v.$invalid" @click="addDashboard()">
                  <v-icon>$iconSave</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </form-error>
          <v-tooltip v-if="isDashboard" top>
            <template #activator="{ on }">
              <v-btn class="ml-3" small v-on="on" @click="addWidget">
                <v-icon class="mr-1" color="primary">$iconPlus</v-icon>
                <span>widget</span>
              </v-btn>
            </template>
            <span>
              {{ $t('dashboard.addWidget') }}
            </span>
          </v-tooltip>
        </v-card-actions>
        <v-progress-linear
          v-show="isDashboard && loading"
          class="text-caption"
          color="secondary"
          height="15"
          indeterminate
        >
          <span>{{ $t('common.finalizationInProgress') }}</span>
        </v-progress-linear>
        <v-card-text
          v-if="isDashboard"
          :class="IsMobile() ? 'px-3 py-0' : 'pa-0'"
          :style="styleWrapDashboard()"
        >
          <grid-layout
            :layout.sync="dashboardOne.layout"
            :col-num="colNum"
            :is-draggable="isAuthor && !IsMobile() && !isView"
            :is-resizable="isAuthor && !IsMobile() && !isView"
            :prevent-collision="!isTrue"
            :responsive="isTrue"
            :row-height="rowHeight"
            :vertical-compact="isTrue"
            :use-css-transforms="isTrue"
            :margin="[10, 10]"
            @layout-ready="layoutReadyEvent"
          >
            <grid-item
              v-for="(item, key) in dashboardOne.layout"
              :key="key"
              :static="item.static"
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :max-h="thresholdGridItem(item.i).maxH"
              :min-h="thresholdGridItem(item.i).minH"
              :min-w="thresholdGridItem(item.i).minW"
              class="tertiary"
              drag-allow-from=".vue-draggable-handle"
              drag-ignore-from=".no-drag"
              @move="moveEvent"
            >
              <v-row class="vue-draggable-handle" no-gutters>
                <v-col>
                  <v-toolbar class="tertiary" height="34px" dense flat>
                    {{ dashboardOne.widget[key].title }}
                    <v-spacer />
                    <v-tooltip v-if="!isView && !IsMobile()" top>
                      <template #activator="{ on }">
                        <v-btn
                          class="mr-4"
                          icon
                          x-small
                          v-on="on"
                          @click="openWidgetKey(dashboardOne.widget, item.i)"
                        >
                          <v-icon>
                            {{ widgetSelected.layout_i === item.i ? '$iconSave' : '$iconEdit' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{
                          widgetSelected.layout_i === item.i
                            ? $t('common.save')
                            : $t('dashboard.settingsWidget')
                        }}
                      </span>
                    </v-tooltip>
                    <v-tooltip v-if="!isView && !IsMobile()" top>
                      <template #activator="{ on }">
                        <v-btn icon x-small v-on="on" @click="removeWidget(item.i)">
                          <v-icon>$iconClose</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t('dashboard.deleteWidget') }}
                      </span>
                    </v-tooltip>
                  </v-toolbar>
                </v-col>
              </v-row>
              <v-row class="no-drag" no-gutters>
                <v-col class="pa-1">
                  <v-card>
                    <v-card-text v-if="dashboardOne.widget[key].type === 'graph'" class="pa-0">
                      <dashboard-graph
                        v-if="
                          dashboardOne.widget[key].settings.device_id &&
                          widgetSelected.layout_i !== dashboardOne.widget[key].layout_i
                        "
                        :device="getDevice(dashboardOne.widget[key].settings.device_id)"
                        :settings="dashboardOne.widget[key].settings"
                      />
                      <span v-else>
                        {{ $t('common.noDataText') }}
                      </span>
                    </v-card-text>
                    <v-card-text
                      v-if="dashboardOne.widget[key].type === 'text'"
                      class="text-pre-line pa-3"
                    >
                      {{ dashboardOne.widget[key].settings.description }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </grid-item>
          </grid-layout>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <span v-if="$route.name === 'DashboardView'">
            {{ $t('common.noDataText') }}
          </span>
        </v-card-text>
      </v-card>
      <div v-if="openWidget">
        <dashboard-widget-config :open-widget.sync="openWidget" :widget.sync="widgetSelected" />
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { GridItem, GridLayout } from 'vue-grid-layout'
import { CommonMixin } from '@/mixins/CommonMixin'
import { DashboardMixin } from '@/mixins/DashboardMixin'
import { UserMixin } from '@/mixins/UserMixin'
import { INSERT_DASHBOARD_ONE, UPDATE_DASHBOARD_BY_PK } from '@/graphql/DashboardQueries'
import DashboardGraph from '@/components/project/dashboard/helper/DashboardGraph'
import DashboardWidgetConfig from '@/components/project/dashboard/helper/DashboardWidgetConfig'
import { formatDateTimeIso8601 } from '@/locales/formats/dataTimeFormats'
import { minLength, required } from 'vuelidate/lib/validators'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

export default {
  name: 'DashboardForm',
  components: {
    DashboardGraph,
    DashboardWidgetConfig,
    GridItem,
    GridLayout,
  },
  mixins: [CommonMixin, DashboardMixin, UserMixin],
  props: {
    value: {
      type: [String, Number],
      default: -1,
    },
  },
  data() {
    return {
      colNum: 12,
      dashboardOne: null,
      firstOpening: 0,
      layoutRef: [],
      layoutMoved: {},
      listIndex: [],
      loading: true,
      isDashboard: false,
      isSave: false,
      isTrue: true,
      isUpdateNow: false,
      openWidget: false,
      rowHeight: 42, // row height in pixel
      /**
       * stepHeight
       * Interacts with rowHeight.
       * For a widget graph it's the min and max,
       * when rowHeigth = 4 and stepHeight = 7 so widget heigth = 354px
       */
      stepHeight: 7,
      widgetSelected: {},
      widgetMinWidth: 4, // min width for a widget graph
    }
  },
  validations: {
    dashboardOne: {
      name: {
        isUnique(value) {
          if (value === '') return true
          return !this.checkDashboardName(value)
        },
        minLength: minLength(3),
        required,
      },
    },
  },
  computed: {
    author() {
      return this.GetUserFullname(this.dashboardOne.user_uuid)
    },
    choiceListItemTitle() {
      const edit = this.$t('common.edit')
      const add = this.$t('common.add')
      const dashboardName = this.dashboardOne?.name
      let title = dashboardName.toUpperCase()
      if (!this.isView) {
        const dashboardAdd = this.$route.name === 'DashboardAdd' ? add : null
        const dashboardEdit = this.$route.name === 'DashboardEdit' ? `${edit} ${title}` : null
        title = !this.IsMobile() ? dashboardAdd || dashboardEdit : title
      }
      return title
    },
    isAuthor() {
      return this.dashboardOne?.user_uuid === this.$store.getters['auth/user'].id
    },
    isView() {
      return this.$route.name === 'DashboardView'
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    DashboardList: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.dashboardOne = cloneDeep(this.getDashboardLocal(val))
        }
      },
      deep: true,
    },
    dashboardOne: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.index = val?.layout.length || 0
          this.isDashboard = val?.uuid
          this.isUpdateNow = val?.uuid
        }
      },
      deep: true,
    },
    'dashboardOne.layout': {
      handler: function (val, oldVal) {
        if (this.isDashboard && val !== oldVal && !this.isView && this.isAuthor) {
          this.firstOpening += 1
          // start = 0, +1 default, +2 firstOpening, so update after 2 and more
          if (this.firstOpening > 2) {
            this.update()
          }
        }
      },
      deep: true,
    },
    isUpdateNow: function (val) {
      if (val) {
        this.setDashboardGraph(this.dashboardOne)
      }
    },
    openWidget: function (val) {
      if (!val && this.widgetSelected) {
        const time = this.widgetSelected.type === 'graph' ? 500 : 10
        setTimeout(() => {
          this.update()
          this.widgetSelected = {}
        }, time)
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === 'Dashboard') {
          this.$router.push('/dashboard/add')
        }
        setTimeout(() => {
          if (this.dashboardOne?.name) {
            document.title = this.dashboardOne.name + ' - Vision'
          }
        }, 500)
      },
    },
  },
  created() {
    this.dashboardOne = this.DashboardDefault
  },
  mounted() {
    this.listIndex = []
  },
  methods: {
    addDashboard() {
      if (!this.$v.$invalid) {
        if (this.isDashboard) {
          this.update()
        } else {
          this.create()
        }
      }
    },
    addWidget() {
      // Add new layouts
      const layoutPropertyLength = this.dashboardOne.layout.length
      const x = (layoutPropertyLength * this.widgetMinWidth) % (this.colNum || 12)
      const y = layoutPropertyLength + (this.colNum || 12)

      // Increment the counter to ensure key is always unique.
      const index = this.getLastIndex() + 1

      this.dashboardOne.layout.push({
        i: index.toString(),
        x: x,
        y: y, // puts it at the bottom
        h: this.stepHeight,
        w: this.widgetMinWidth,
      })

      // Add new widget
      const widgetNew = cloneDeep(this.DashboardDefault.widget[0])
      widgetNew.layout_i = index.toString()
      this.dashboardOne.widget.push(widgetNew)
      this.widgetSelected = widgetNew
      this.openWidget = true
    },
    checkDashboardName(name) {
      // Note: this.dashboard comes from DashboardMixin
      const search = this.dashboard.find((i) => i.name.toLowerCase() === name.toLowerCase())
      return search && search.uuid !== this.dashboardOne.uuid ? search : null
    },
    closeForm() {
      if (this.$route?.params?.isDrawer) {
        this.$router.push('/project')
      } else {
        this.$router.push('/dashboard')
      }
    },
    getDashboardLocal(list) {
      const search = list?.find((i) => i.uuid === this.value)
      let result = {}
      if (search) {
        result = search
      } else {
        result = this.DashboardDefault
      }
      return result
    },
    getDevice(deviceUuid) {
      return {
        id: deviceUuid,
        name: '',
        hub_name: '',
      }
    },
    getLastIndex() {
      this.dashboardOne.layout?.forEach((key) => {
        this.listIndex.push(parseInt(key.i, 10))
      })
      const uniq = [...new Set(this.listIndex)]
      uniq.sort((a, b) => {
        return a - b
      })
      const reversed = uniq.reverse()
      const lastIndex = reversed[0]
      return reversed.length > 0 ? lastIndex : 0
    },
    isSubscribe() {
      return this.dashboardOne?.dashboard_users.find(
        (i) => i.user_uuid === this.$store.getters['auth/user'].id
      )
    },
    layoutReadyEvent(newLayout) {
      this.loading = this.isDashboard ? this.dashboardOne.layout.length !== newLayout.length : false
    },
    openWidgetKey(widget, itemI) {
      const search = widget.find((i) => i.layout_i === itemI)
      if (search) {
        this.widgetSelected = search
        this.openWidget = true
      }
    },
    moveEvent(i, newX, newY) {
      const search = this.dashboardOne.layout.find((item) => item.i === i)
      if (search && this.layoutMoved?.i !== i) {
        this.layoutMoved = {}
        this.layoutRef = []
        this.layoutMoved = cloneDeep(search)
        this.layoutRef = cloneDeep(this.dashboardOne.layout)
      }
      /**
       * Inspired by:
       * @link https://github.com/jbaysolutions/vue-grid-layout/issues/604
       * @mink https://codesandbox.io/s/quirky-ardinghelli-6ni7f?file=/src/App.vue
       */
      let p = 0
      for (p = 0; p < this.layoutRef.length; p++) {
        if (
          this.layoutRef[p].i !== i &&
          this.layoutRef[p].x === newX &&
          this.layoutRef[p].y === newY
        ) {
          this.dashboardOne.layout.filter((item) => {
            if (item.i === this.layoutRef[p].i) {
              item.x = this.layoutMoved.x
              item.y = this.layoutMoved.y
            }
            if (item.i === this.layoutMoved.i) {
              item.x = newX
              item.y = newY
            }
            return item
          })
          this.layoutMoved = {}
        }
      }
    },
    setDashboardGraph(dashboardCurrent) {
      const dateNow = moment().format(formatDateTimeIso8601)
      dashboardCurrent.widget.forEach((i) => {
        if (i.type === 'graph') {
          if (!i.settings.isTimeInterval) {
            i.settings.dateTimeFrom = moment()
              .subtract(i.settings.timeBefore, i.settings.timeBeforeUnit)
              .format(formatDateTimeIso8601)
            i.settings.dateTimeTo = dateNow
          }
          if (i.settings.isTimeIntervalToNow) {
            i.settings.dateTimeTo = dateNow
          }
        }
      })
      this.isUpdateNow = false
      return dashboardCurrent
    },
    styleWrapDashboard() {
      const pixel = this.isView ? '64px' : '64px + 70px'
      let style = this.StyleHeightCalc(pixel, true)
      style.visibility = this.loading ? 'hidden' : 'visible'
      return style
    },
    subscribe() {
      const dashboardUuid = this.dashboardOne.uuid
      const userUuid = this.$store.getters['auth/user'].id
      this.isSubscribe()
        ? this.UnsubscribeDashboard(dashboardUuid, userUuid)
        : this.SubscribeDashboard(dashboardUuid, userUuid)
    },
    thresholdGridItem(itemI) {
      let threshold = {}
      let type = null

      this.dashboardOne.widget.forEach((i) => {
        if (i.layout_i === itemI) {
          type = i.type
        }
      })

      switch (type) {
        case 'text':
          threshold = {
            maxH: this.stepHeight * 1.5,
            minH: 2,
            minW: 2,
          }
          break
        case 'graph':
        default:
          threshold = {
            maxH: this.stepHeight,
            minH: this.stepHeight,
            minW: this.widgetMinWidth,
          }
          break
      }

      return threshold
    },
    async create() {
      const obj = {
        name: this.dashboardOne.name,
        project_uuid: this.dashboardOne.project_uuid,
        user_uuid: this.dashboardOne.user_uuid,
      }
      const resp = await this.$apollo
        .mutate({
          mutation: INSERT_DASHBOARD_ONE,
          variables: {
            object: obj,
          },
        })
        .catch((error) => {
          this.ShowSnackbarError('create INSERT_DASHBOARD_ONE: ' + error.message)
          throw error
        })
      if (resp?.data) {
        this.ShowSnackbarSuccess()
        this.dashboardOne = resp.data.insert_dashboard_one
        this.$router.push({
          name: 'DashboardEdit',
          path: '/dashboard/edit/' + resp.data.insert_dashboard_one.uuid,
          params: {
            value: resp.data.insert_dashboard_one.uuid,
            isDrawer: false,
          },
        })
      }
    },
    async removeWidget(val) {
      const removal = this.$t('common.removal')
      const sureToDelete = this.$t('common.sureToDelete')
      const res = await this.$root.$confirm(removal, sureToDelete, {
        color: 'red',
      })

      if (res) {
        // Remove this layout
        const indexLayout = this.dashboardOne.layout.map((item) => item.i).indexOf(val)
        this.dashboardOne.layout.splice(indexLayout, 1)

        // Remove widget
        const indexWidget = this.dashboardOne.widget.map((item) => item.layout_i).indexOf(val)
        this.dashboardOne.widget.splice(indexWidget, 1)

        // Update db
        this.update()
      }
    },
    async update() {
      const resp = await this.$apollo
        .mutate({
          mutation: UPDATE_DASHBOARD_BY_PK,
          variables: {
            uuid: this.dashboardOne.uuid,
            _set: {
              name: this.dashboardOne.name,
              layout: this.dashboardOne.layout,
              widget: this.dashboardOne.widget,
            },
          },
        })
        .catch((error) => {
          this.ShowSnackbarError('update UPDATE_DASHBOARD_BY_PK: ' + error.message)
          throw error
        })
      if (resp?.data) {
        this.ShowSnackbarSuccess()
      }
    },
  },
}
</script>

<style lang="css" scoped>
.vue-grid-layout {
  background: rgba(243, 243, 243, 0.3);
}
.vue-grid-item {
  touch-action: none;
}
.vue-grid-item.static {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
</style>
