var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('helper-crud-list',_vm._b({scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('alert-state-chip',{attrs:{"state-value":item.state}})]}},{key:"item.updated_state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.updated_state))+" ")]}},{key:"item.sleep",fn:function(ref){
var item = ref.item;
return [(item.sleep)?_c('v-icon',{attrs:{"dense":""}},[_vm._v("$iconAlertSleep")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-2",attrs:{"colspan":headers.length}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('alert.state'))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('alert.value'))+" ")]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('common.created_at'))+" ")])])]),_c('tbody',_vm._l((item.alert_alert_histories),function(history){return _c('tr',{key:history.d},[_c('td',{staticClass:"text-center"},[_c('alert-state-chip',{attrs:{"state-value":history.state}})],1),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(history.value))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("formatDate")(history.created_at)))])])}),0)]},proxy:true}],null,true)})],1)]}},{key:"preAction",fn:function(ref){
var item = ref.item;
var listparams = ref.listparams;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.Sleep(item, listparams)}}},[_vm._v(" $iconAlertSleep ")])]}},{key:"form",fn:function(ref){
var editedId = ref.editedId;
var listparams = ref.listparams;
var closeForm = ref.closeForm;
return [_c('alert-form',{attrs:{"value":editedId,"device-uuid":_vm.deviceUuid,"listparams":listparams},on:{"closeForm":closeForm}})]}}],null,true)},'helper-crud-list',_vm.AlertDefs,false))}
var staticRenderFns = []

export { render, staticRenderFns }