var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"mt-8",attrs:{"id":"project-drawer-calendar","color":"tertiary","height":"calc(100% - (32px * 2))","width":"292px","absolute":"","right":"","stateless":""},model:{value:(_vm.displayDrawer),callback:function ($$v) {_vm.displayDrawer=$$v},expression:"displayDrawer"}},[_c('v-container',[_c('v-row',[_c('div',{staticClass:"text-overline"},[_c('span',{staticClass:"text-uppercase pl-3"},[_vm._v(" "+_vm._s(_vm.$t('calendar.agenda'))+" ")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.goCalendar()}}},on),[_c('v-icon',[_vm._v(" $iconOpenInNew ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.openInNewTab')))])])],1),_c('v-row',{staticClass:"py-0 px-2",attrs:{"align":"center"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$d(_vm.LocalDate, 'short'))+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"events":_vm.eventIndicators,"first-day-of-week":_vm.dateProps.firstDayOfWeek,"locale":_vm.dateProps.locale,"no-title":"","scrollable":"","color":"secondary"},on:{"change":_vm.save},model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}})],1),(!_vm.isNow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.LocalDate = new Date()}}},on),[_c('v-icon',[_vm._v(" $iconCalendarClock ")])],1)]}}],null,false,3211812396)},[_c('span',[_vm._v(_vm._s(_vm.$t('calendar.today')))])]):_vm._e()],1),_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0 pt-2"},[_c('v-sheet',{staticClass:"pa-0",attrs:{"height":"calc(100vh - 220px)"}},[_c('v-calendar',{ref:"calendar",attrs:{"type":"day","hide-header":"","events":_vm.events,"event-color":_vm.getEventColor,"locale":_vm.dateProps.locale,"value":_vm.datePicker},on:{"click:event":_vm.showEvent},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{staticClass:"font-weight-medium px-1"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-no-wrap text-truncate"},on),[_vm._v(" "+_vm._s(event.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(event.name)+" ")])])],1)]}}])}),_c('calendar-event',{attrs:{"selected-options":_vm.selectedOptions}})],1)],1)],1),_c('v-row',[_c('v-toolbar',{attrs:{"color":"tertiary","height":"32px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }