import gql from 'graphql-tag'

export const GET_LIB_DEVICE_DOCUMENT_CATEGORIES = gql`
  query lib_device_document_category(
    $where: lib_device_document_category_bool_exp
    $order_by: [lib_device_document_category_order_by!]
    $limit: Int
    $offset: Int
  ) {
    lib_device_document_category_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    lib_device_document_category(
      where: $where
      offset: $offset
      limit: $limit
      order_by: $order_by
    ) {
      id
      name
    }
  }
`

export const POST_LIB_DEVICE_DOCUMENT_CATEGORY = gql`
  mutation ($objects: [lib_device_document_category_insert_input!]!) {
    insert_lib_device_document_category(objects: $objects) {
      returning {
        id
        name
      }
    }
  }
`

export const PATCH_LIB_DEVICE_DOCUMENT_CATEGORY = gql`
  mutation ($id: Int!, $body: lib_device_document_category_set_input!) {
    update_lib_device_document_category(where: { id: { _eq: $id } }, _set: $body) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`

export const DELETE_LIB_DEVICE_DOCUMENT_CATEGORY = gql`
  mutation ($id: Int!) {
    delete_lib_device_document_category(where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`
