import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import theme from './theme'
import { ICONS } from '@/constants/icons.js'
import { en, fr } from 'vuetify/es5/locale/'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
    values: ICONS,
  },
  lang: {
    current: 'fr',
    locales: { en, fr },
  },
  theme: {
    themes: {
      light: theme,
    },
  },
})
