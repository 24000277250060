<template>
  <div
    v-if="activeMenu.length > 0"
    :class="$vuetify.breakpoint.mobile ? 'v-list-item v-list-item--link' : 'd-flex'"
  >
    <template v-for="itemMenu in activeMenu">
      <div v-if="checkAcl(itemMenu.acl) && checkMobile(itemMenu.onMobile)" :key="itemMenu.slug">
        <v-tooltip v-if="!itemMenu.children" bottom>
          <template #activator="{ on: tooltip }">
            <v-btn icon :to="{ path: itemMenu.path }" v-on="{ ...tooltip }">
              <v-icon>
                {{ itemMenu.icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $tc(itemMenu.i18n, 2) }}</span>
        </v-tooltip>
        <v-menu
          v-if="checkAcl(itemMenu.acl) && checkMobile(itemMenu.onMobile)"
          bottom
          right
          offset-y
          transition="slide-y-transition"
        >
          <template #activator="{ attrs, on: menu }">
            <v-tooltip bottom>
              <template #activator="{ on: tooltip }">
                <v-btn v-bind="attrs" icon v-on="{ ...tooltip, ...menu }">
                  <alert-count-badge v-if="itemMenu.badge" :params="itemMenu" />
                  <v-icon v-if="!itemMenu.badge">
                    {{ itemMenu.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $tc(itemMenu.i18n, 2) }}</span>
            </v-tooltip>
          </template>
          <v-card v-if="itemMenu.children">
            <v-list dense>
              <template v-for="child in itemMenu.children">
                <v-list-item
                  v-if="checkAcl(child.acl) && checkMobile(child.onMobile)"
                  :key="itemMenu.path + '/' + child.path"
                  no-action
                  value="true"
                  :to="{ path: itemMenu.path + '/' + child.path }"
                >
                  <v-list-item-icon>
                    <alert-count-badge v-if="child.badge" :params="child" />
                    <v-icon v-if="!child.badge">{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $tc(child.i18n, 2) }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </template>
  </div>
</template>

<script>
import AlertCountBadge from '@/components/alert/AlertCountBadge'
import paths from '@/router/paths.js'

export default {
  name: 'AppBarMenu',
  components: {
    AlertCountBadge,
  },
  data: () => ({
    activeMenu: [],
  }),
  created() {
    this.activeMenu = this.getMenu()
  },
  methods: {
    checkAcl(acl) {
      return (
        !acl || this.$store.getters['auth/isAuth']([Object.keys(acl)[0], Object.values(acl)[0]])
      )
    },
    checkMobile(mobile) {
      return this.$vuetify.breakpoint.mobile ? mobile : true
    },
    getMenu() {
      return paths.filter((item) => item.menu)
    },
  },
}
</script>