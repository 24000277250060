<template>
  <v-row justify="center">
    <v-dialog
      v-model="localOpenGraph"
      content-class="device-dialog-fullscreen"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="tertiary" dense flat>
          <v-toolbar-title>
            <span>{{ $t('report.graph') }}&nbsp;</span>
            <span class="text-caption">{{ localGraphInfo }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn id="intro-reportgraph-3-savegraph" elevation="0" @click="saveGraph()">
              <v-icon color="primary" class="mr-1">$iconSave</v-icon>{{ $t('common.save') }}
            </v-btn>
            <v-btn elevation="0" @click="closeGraph(false)">
              <v-icon color="primary" class="mr-1">$iconClose</v-icon>{{ $t('common.close') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid class="mx-auto">
          <div v-for="(graph, i) in localGraphData" :key="i" class="pb-2">
            <v-banner class="graph-report-banner" elevation="1">
              <v-card-subtitle class="py-0">
                <v-tooltip right>
                  <template #activator="{ on }">
                    <v-btn icon x-small v-on="on" @click="duplicateGraph(i)">
                      <v-icon id="intro-reportgraph-0-duplication" color="primary" class="mr-1"
                        >$iconChartCopy</v-icon
                      >#{{ i + 1 }}
                    </v-btn>
                  </template>
                  <span>{{ $t('common.duplicate') }} {{ graph.device_select }}</span>
                </v-tooltip>
                <span class="d-inline-block font-weight-bold pl-4">
                  {{ graph.device_select }} {{ graph.duplicate_graph }}
                </span>
              </v-card-subtitle>
              <time-interval v-model="optionsTimeInterval[i]" />
            </v-banner>
            <graph-single :options-params.sync="optionsParams[i]" :ref-id="'refGraph' + i" />
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import GraphSingle from '@/components/project/graphs/GraphSingle'
import TimeInterval from '@/components/helper/TimeInterval'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'GraphReport',
  components: {
    GraphSingle,
    TimeInterval,
  },
  props: {
    graphData: {
      type: Array,
      default: () => {
        return []
      },
    },
    graphInfo: {
      type: String,
      default: '',
    },
    openGraph: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localGraphData: this.graphData,
      localGraphInfo: this.graphInfo,
      localOpenGraph: this.openGraph,
      optionsParams: [],
      optionsTimeInterval: [],
    }
  },
  watch: {
    optionsTimeInterval: {
      deep: true,
      handler: function () {
        this.getOptionsParamsDateTime()
        this.updateOptionsGraphData()
      },
    },
  },
  mounted() {
    this.optionsParams = this.getOptionsParams()
    this.optionsTimeInterval = this.getOptionsParamsDateTime()
  },
  methods: {
    closeGraph(val) {
      this.$emit('update:openGraph', val)
    },
    duplicateGraph(i) {
      let newGraphs = []
      newGraphs = cloneDeep(this.localGraphData)
      const duplicate = cloneDeep(this.localGraphData[i])
      const newIndex = i + 1
      newGraphs.splice(newIndex, 0, duplicate)
      newGraphs[newIndex].duplicate_graph = 'clone_' + newIndex
      this.optionsTimeInterval[newIndex] = {
        dateTimeFrom: newGraphs[newIndex].optionsParams.dateTimeFrom,
        dateTimeTo: newGraphs[newIndex].optionsParams.dateTimeTo,
      }
      this.localGraphData = [] // ! clear value
      this.localGraphData = cloneDeep(newGraphs)
      this.updateOptionsGraphData()
    },
    getOptionsParams() {
      const options = []
      this.localGraphData.forEach((i) => {
        options.push(i.optionsParams)
      })
      return options
    },
    getOptionsParamsDateTime() {
      const timeInterval = []
      this.localGraphData.forEach((i) => {
        timeInterval.push({
          dateTimeFrom: i.optionsParams.dateTimeFrom,
          dateTimeTo: i.optionsParams.dateTimeTo,
        })
      })
      return timeInterval
    },
    saveGraph() {
      this.$emit('update:graph-data', this.localGraphData)
      this.closeGraph(false)
    },
    updateOptionsGraphData() {
      this.optionsTimeInterval.forEach((i, key) => {
        this.localGraphData[key].optionsParams.dateTimeFrom = i.dateTimeFrom
        this.localGraphData[key].optionsParams.dateTimeTo = i.dateTimeTo
      })
      this.optionsParams = [] // ! clear value
      this.$nextTick(function () {
        this.optionsParams = this.getOptionsParams()
      })
    },
  },
}
</script>

<style lang="css">
.device-dialog-fullscreen {
  top: 48px;
  height: calc(100% - 48px);
}
.graph-report-banner > .v-banner__wrapper {
  padding: 6px 12px 0;
}
</style>
