<template lang="html">
  <v-card class="overflow-y-auto" style="height: 100%" flat :outlined="isTrue">
    <form ref="fileform" class="fill-height ma-0 pa-0">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        color="green accent-4"
      />
      <v-card-text>
        <gallery
          :documents="documentsGallery"
          :is-delete="$store.getters['auth/isAuth'](['lib_device_document', 'delete'])"
          :is-edit="$store.getters['auth/isAuth'](['lib_device_document', 'update'])"
          size="150"
          @delete-doc="deleteDoc"
          @edit-doc="editDocFromGallery"
        />
        <document-edit
          v-model="documentEdit"
          :document="documentEditSrc"
          @edit-doc="updateDocumentEdit"
        />
      </v-card-text>
    </form>
  </v-card>
</template>

<script>
import { storageClient } from '@/plugins/storage'
import {
  PATCH_LIB_DEVICE_DOCUMENT,
  SUBSCRIPTION_LIB_DEVICE_DOCUMENTS,
  DELETE_LIB_DEVICE_DOCUMENT,
} from '@/graphql/LibDeviceDocumentQueries'
import { DocumentMixin } from '@/mixins/DocumentMixin'
import DocumentEdit from '@/components/helper/DocumentEdit'
import Gallery from '@/components/helper/Gallery'

export default {
  name: 'FileContainer',
  components: {
    DocumentEdit,
    Gallery,
  },
  mixins: [DocumentMixin],
  props: {
    libDeviceId: {
      type: String,
      default: '',
    },
  },
  apollo: {
    $subscribe: {
      documents: {
        query: SUBSCRIPTION_LIB_DEVICE_DOCUMENTS,
        variables() {
          return {
            where: { lib_device_uuid: { _eq: this.libDeviceId } },
          }
        },
        result({ data }) {
          this.documents = data.lib_device_document
        },
      },
    },
  },
  data: () => ({
    documentEdit: false,
    documentEditSrc: undefined,
    documents: [],
    isTrue: true,
    loading: false,
  }),
  computed: {
    documentsGallery() {
      return this.getDocumentsGallery()
    },
  },
  mounted() {
    ;['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
      function (evt) {
        /*
          For each event add an event listener that prevents the default action
          (opening the file in the browser) and stop the propagation of the event (so
          no other elements open the file in the browser)
        */
        this.$refs.fileform.addEventListener(
          evt,
          function (e) {
            e.preventDefault()
            e.stopPropagation()
          },
          false
        )
      }.bind(this)
    )

    // Add an event listener for drop to the form
    this.$refs.fileform.addEventListener('drop', (e) => this.uploadDocument(e))
  },
  methods: {
    editDoc(doc) {
      this.documentEditSrc = {
        contentType: doc.file.contentType,
        datetime: doc.created_at,
        key: doc.file.key,
        lib_document_category: {
          id: doc.lib_device_document_category.id,
          name: doc.lib_device_document_category.name,
        },
        title: doc.title,
        token: doc.file.userMetadata['X-Amz-Meta-Token'],
        uuid: doc.uuid,
      }
      this.documentEdit = true
    },
    editDocFromGallery(doc) {
      const docGallery = this.documents.find(
        (i) => i.file.key === doc.key && i.file.userMetadata['X-Amz-Meta-Token'] === doc.token
      )
      this.editDoc(docGallery)
    },
    getDocumentsGallery() {
      const list = []
      this.documents?.forEach((doc) => {
        const token = doc.file.userMetadata['X-Amz-Meta-Token']
        list.push({
          categoryName: doc.lib_device_document_category.name,
          contentType: doc.file.contentType,
          datetime: doc.created_at,
          key: doc.file.key,
          title: doc.title,
          token: token,
          uuid: doc.uuid,
        })
      })
      return list
    },
    async deleteDoc(doc) {
      const removal = this.$t('common.removal')
      const removalFormatting = this.$t('common.removalFormatting', {
        attribute: doc.title,
      })
      const sureToDeleteThis = this.$t('common.sureToDeleteThis', {
        attribute: doc.title,
      })

      if (await this.$root.$confirm(removal, sureToDeleteThis, { color: 'red' })) {
        await this.$root.$dialogLoader.start(removalFormatting + '...', {}, () => {
          try {
            return new Promise(() => {
              try {
                this.$apollo.mutate({
                  mutation: DELETE_LIB_DEVICE_DOCUMENT,
                  variables: {
                    uuid: doc.uuid,
                  },
                })
              } catch (e) {
                console.log(e)
              }
            })
          } catch (e) {
            console.log(e)
          }
        })
      }
    },
    async uploadDocument(event) {
      try {
        // Upload image(s)
        this.loading = true
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          await storageClient.put(
            `lib_device/original/${event.dataTransfer.files[i].name}`,
            event.dataTransfer.files[i],
            {
              type: 'lib_device',
              lib_device_id: this.libDeviceId,
            }
          )
          this.loading = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async updateDocumentEdit(doc) {
      await this.$apollo
        .mutate({
          mutation: PATCH_LIB_DEVICE_DOCUMENT,
          variables: {
            uuid: doc.uuid,
            body: {
              title: doc.title,
              lib_device_document_category_id: doc.lib_document_category.id,
            },
          },
        })
        .then((data) => {
          if (data) this.ShowSnackbarSuccess()
        })
        .catch((error) => {
          this.ShowSnackbarError('PATCH_LIB_DEVICE_DOCUMENT: ' + error.message)
          throw error
        })
    },
  },
}
</script>
