<template>
  <v-card>
    <v-card-title class="text-body-1 white--text secondary py-1">
      {{ $tc('user.loginInfo', 1) }}
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12" class="py-0">
          <form-error
            :validator="$v.userEmail"
            :attribute="$t('common.email')"
            :messages="messages"
          >
            <v-text-field
              v-model="userEmail"
              slot-scope="{ attrs }"
              v-bind="attrs"
              :label="$t('common.email') + ' *'"
              :placeholder="$t('common.placeholder.email')"
              @input="$v.userEmail.$touch()"
              @blur="$v.userEmail.$touch()"
            />
          </form-error>
        </v-col>
        <v-col cols="12" class="py-0">
          <form-error
            :validator="$v.password"
            :attribute="$t('user.password')"
            :messages="messages"
          >
            <v-text-field
              v-model="password"
              slot-scope="{ attrs }"
              v-bind="attrs"
              :append-icon="passShow1 ? '$iconEye' : '$iconEyeOff'"
              :label="$t('user.password')"
              :hint="$t('common.passwordHint')"
              :type="passShow1 ? 'text' : 'password'"
              autocomplete="new-password"
              counter
              @click:append="passShow1 = !passShow1"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            />
          </form-error>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import { password } from '@/components/validators'
import bcrypt from 'bcryptjs'

export default {
  name: 'CrmLoginInfoEditor',
  props: {
    isCurrentUser: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      passShow1: false,
      password: '',
    }
  },
  validations: {
    password: { password },
    userEmail: { required, email },
  },
  computed: {
    crmLocal() {
      return this.value
    },
    messages() {
      return this.$t('validationMessage')
    },
    userEmail: {
      get() {
        const email = this.isCurrentUser ? this.$store.state.auth.user.email : this.crmLocal.email
        return email || ''
      },
      set(val) {
        if (this.isCurrentUser) {
          this.$store.dispatch('auth/updateUser', { email: val })
        }
        this.crmLocal.email = val
        this.$emit('input', this.crmLocal)
      },
    },
  },
  watch: {
    password: async function (val) {
      if (val.length >= 8) {
        this.crmLocal.password_hash = await this.hashPassword(val)
        this.$emit('input', this.crmLocal)
      }
    },
  },
  methods: {
    async hashPassword(password) {
      return await bcrypt.hash(password, 10)
    },
  },
}
</script>