import gql from 'graphql-tag'

const issueFragment = gql`
  fragment issueFragment on issue {
    id
    project_uuid
    log_ref
    created_at
  }
`

const issueVersionFragment = gql`
  fragment issueVersionFragment on issue_version {
    uuid
    issue_id
    version_id
    author_uuid
    common
    comment
    graph
    item
    user
    attached_file
    updated_at
  }
`

// queries
export const GET_ISSUE = gql`
  query ($id: Int!, $order_by: [issue_version_order_by!] = {}) {
    issue_by_pk(id: $id) {
      ...issueFragment
      issue_versions(order_by: $order_by) {
        ...issueVersionFragment
      }
      issue_versions_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${issueFragment}
  ${issueVersionFragment}
`

export const GET_ISSUES = gql`
  query issue(
    $where: issue_bool_exp
    $order_by: [issue_order_by!]
    $order_by_versions: [issue_version_order_by!]
    $limit: Int
    $offset: Int
  ) {
    issue_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    issue(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...issueFragment
      issue_versions(order_by: $order_by_versions) {
        ...issueVersionFragment
      }
    }
  }
  ${issueFragment}
  ${issueVersionFragment}
`

export const GET_ISSUE_VERSIONS_COMMON_PATH = gql`
  query issue(
    $path: String = ""
    $where: issue_bool_exp = {}
    $order_by_versions: [issue_version_order_by!] = {}
  ) {
    issue(where: $where) {
      id
      project_uuid
      issue_versions(order_by: $order_by_versions) {
        uuid
        issue_id
        version_id
        common(path: $path)
      }
    }
  }
`

export const GET_LAST_ISSUE = gql`
  query issue_aggregate {
    issue_aggregate {
      aggregate {
        max {
          id
        }
      }
    }
  }
`

// mutations
// note: INSERT_ISSUE_ONE
// Schema for issue_content:
// common: { state, status, title, description, deadline, end_at }
// graph: [{ device_uuid, device_name, config },],
// item: [{ id_name, value, process },],
// user: [{ user_uuid, firstname, lastname, fullname companyName, email, view_last_version },]
// attached_file: [{ key, token, encoding, metadata, mimetype, extension, originalname },]
export const INSERT_ISSUE_ONE = gql`
  mutation insert_issue_one($object: issue_insert_input!) {
    insert_issue_one(object: $object) {
      ...issueFragment
      issue_versions {
        ...issueVersionFragment
      }
    }
  }
  ${issueFragment}
  ${issueVersionFragment}
`

export const INSERT_ISSUE_VERSION_ONE = gql`
  mutation insert_issue_version_one($object: issue_version_insert_input!) {
    insert_issue_version_one(object: $object) {
      ...issueVersionFragment
    }
  }
  ${issueVersionFragment}
`

export const UPDATE_ISSUE_BY_PK_LOG_REF = gql`
  mutation update_issue_by_pk($id: Int!, $log_ref: jsonb) {
    update_issue_by_pk(pk_columns: { id: $id }, _set: { log_ref: $log_ref }) {
      id
      project_uuid
      log_ref
    }
  }
`

export const UPDATE_ISSUE_VERSION_BY_PK = gql`
  mutation update_issue_version_by_pk($uuid: uuid!, $_set: issue_version_set_input!) {
    update_issue_version_by_pk(pk_columns: { uuid: $uuid }, _set: $_set) {
      ...issueVersionFragment
    }
  }
  ${issueVersionFragment}
`
