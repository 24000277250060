<template lang="html">
  <div v-if="isUserProject" id="intro-home-3-report">
    <v-menu bottom left offset-y transition="slide-y-transition">
      <template #activator="{ attrs, on: menu }">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-btn icon to="/report" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              <v-badge v-if="reports > 0" color="warning" overlap>
                <template slot="badge">
                  {{ reports }}
                </template>
                <v-icon>$iconBook</v-icon>
              </v-badge>
              <v-icon v-else>$iconBook</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc('common.report', 2) }}</span>
        </v-tooltip>
      </template>
    </v-menu>
  </div>
</template>

<script>
// Remove for a future upgrade from user_project to crm_contact_project :
import { GET_USERS_PROJECT_NOTIFICATION } from '@/graphql/UserProjectQueries'
// Keep for a future upgrade from user_project to crm_contact_project :
// import { GET_CRM_CONTACT_PROJECT } from '@/graphql/CrmContactProjectQueries'
import { GET_ISSUE_VERSIONS_COMMON_PATH } from '@/graphql/IssueQueries'
import { CommonMixin } from '@/mixins/CommonMixin'

export default {
  name: 'BarProjectReport',
  mixins: [CommonMixin],
  apollo: {
    /*
      // Keep for a future upgrade from user_project to crm_contact_project :
      crm_contact_project: {
        query: GET_CRM_CONTACT_PROJECT,
        variables () {
          const vars = {
            order_by: {
              crm_contact: { lastname: 'asc' },
            },
            where: {
              project_uuid: {
                _eq: this.$store.getters['project/currentProjectId'],
              },
              notification: {
                _contains: { report: true },
              },
              crm_contact: {
                user: {
                  id: { _eq: this.$store.getters['auth/user'].id },
                  active: { _eq: true },
                },
              },
            },
          }
          return vars
        },
      },
      */
    issue: {
      query: GET_ISSUE_VERSIONS_COMMON_PATH,
      pollInterval: 60000, // every 1 mn
      variables() {
        const vars = {
          path: 'status',
          where: {
            project_uuid: {
              _eq: this.$store.getters['project/currentProjectId'],
            },
          },
          order_by_versions: {
            version_id: 'asc',
          },
        }
        return vars
      },
      result({ data }) {
        this.issue = data?.issue
      },
      skip() {
        return !this.$store.getters['auth/isAuthenticated']
      },
    },
    // Remove for a future upgrade from user_project to crm_contact_project :
    user_project: {
      query: GET_USERS_PROJECT_NOTIFICATION,
      variables() {
        return {
          order_by: {
            user: { crm_contact: { lastname: 'asc' } },
          },
          where: {
            project_uuid: {
              _eq: this.$store.getters['project/currentProjectId'],
            },
            notification: {
              _contains: { report: true },
            },
            user: {
              active: { _eq: true },
            },
            user_uuid: {
              _eq: this.$store.getters['auth/user'].id,
            },
          },
        }
      },
    },
  },
  data: () => ({
    issue: [],
  }),
  computed: {
    isUserProject() {
      // Remove for a future upgrade from user_project to crm_contact_project :
      return this.user_project && this.user_project.length > 0
      // Keep for a future upgrade from user_project to crm_contact_project :
      // return this.crm_contact_project && this.crm_contact_project.length > 0
    },
    reports() {
      return this.countCommonStatusOpen()
    },
  },
  methods: {
    countCommonStatusOpen() {
      let countOpen = 0
      if (this.issue && this.issue.length > 0) {
        this.issue.forEach((i) => {
          const search = i.issue_versions.filter((key) => key.common).slice(-1)[0]
          if (search.common !== 'closed') {
            countOpen += 1
          }
        })
      }
      return countOpen
    },
  },
}
</script>
