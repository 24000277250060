<template>
  <v-card>
    <v-card-title class="text-body-1 white--text secondary py-1">
      {{ $tc('common.company', 1) }}
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col v-if="readonly" cols="12" class="py-0">
          <v-text-field
            v-model="crmContactLocal.crm_company.name"
            :readonly="readonly"
            :label="$tc('common.company', 1)"
          />
        </v-col>
        <v-col v-else cols="12" class="py-0">
          <form-error
            :validator="$v.crmContactLocal.crm_company.uuid"
            :attribute="$tc('common.company', 1)"
            :messages="messages"
          >
            <company-selector
              v-model="crmContactLocal.crm_company"
              slot-scope="{ attrs }"
              v-bind="attrs"
              @input="$v.crmContactLocal.crm_company.uuid.$touch()"
              @blur="$v.crmContactLocal.crm_company.uuid.$touch()"
            />
          </form-error>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
            v-model="crmContactLocal.job_title"
            :readonly="readonly"
            :label="$t('contact.jobTitle')"
            :placeholder="$t('contact.placeholder.jobTitle')"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CompanySelector from '@/components/helper/CompanySelector'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CrmCompanyEditor',
  components: {
    CompanySelector,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {
          crm_company: {
            uuid: '',
            name: '',
          },
          job_title: '',
        }
      },
    },
  },
  validations: {
    crmContactLocal: {
      crm_company: {
        uuid: { required },
      },
    },
  },
  computed: {
    crmContactLocal() {
      return this.value
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    crmContactLocal: {
      handler: function (val) {
        if (val.crm_company.uuid > 0) this.$emit('input', val)
      },
      deep: true,
    },
  },
}
</script>