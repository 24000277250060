var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('form-error',{attrs:{"validator":_vm.$v.localFieldConfig.min,"attribute":_vm.$t('common.minimum'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"type":"number","label":_vm.$t('common.minimum')},on:{"input":function($event){return _vm.$v.localFieldConfig.min.$touch()},"blur":function($event){return _vm.$v.localFieldConfig.min.$touch()}},model:{value:(_vm.localFieldConfig.min),callback:function ($$v) {_vm.$set(_vm.localFieldConfig, "min", $$v)},expression:"localFieldConfig.min"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('form-error',{attrs:{"validator":_vm.$v.localFieldConfig.max,"attribute":_vm.$t('common.maximum'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"type":"number","label":_vm.$t('common.maximum')},on:{"input":function($event){return _vm.$v.localFieldConfig.max.$touch()},"blur":function($event){return _vm.$v.localFieldConfig.max.$touch()}},model:{value:(_vm.localFieldConfig.max),callback:function ($$v) {_vm.$set(_vm.localFieldConfig, "max", $$v)},expression:"localFieldConfig.max"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('form-error',{attrs:{"validator":_vm.$v.localFieldConfig.offset,"attribute":_vm.$t('common.offset'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"type":"number","label":_vm.$t('common.offset')},on:{"input":function($event){return _vm.$v.localFieldConfig.max.$touch()},"blur":function($event){return _vm.$v.localFieldConfig.max.$touch()}},model:{value:(_vm.localFieldConfig.offset),callback:function ($$v) {_vm.$set(_vm.localFieldConfig, "offset", $$v)},expression:"localFieldConfig.offset"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('unit-selector',{attrs:{"label":_vm.$t('common.unit')},on:{"input":function($event){return _vm.$v.localFieldConfig.unit.$touch()},"blur":function($event){return _vm.$v.localFieldConfig.unit.$touch()}},model:{value:(_vm.localFieldConfig.unit),callback:function ($$v) {_vm.$set(_vm.localFieldConfig, "unit", $$v)},expression:"localFieldConfig.unit"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('form-error',{attrs:{"validator":_vm.$v.localDefaultValue.value,"attribute":_vm.$t('common.defaultValue'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"label":_vm.$t('common.defaultValue')},on:{"input":function($event){return _vm.$v.localDefaultValue.value.$touch()},"blur":function($event){return _vm.$v.localDefaultValue.value.$touch()}},model:{value:(_vm.localDefaultValue.value),callback:function ($$v) {_vm.$set(_vm.localDefaultValue, "value", $$v)},expression:"localDefaultValue.value"}},'v-text-field',attrs,false))}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }