<template>
  <v-navigation-drawer
    id="project-drawer-device"
    v-model="displayDrawer"
    class="mt-8"
    color="tertiary"
    height="calc(100% - (32px * 2))"
    width="292px"
    absolute
    right
    stateless
  >
    <v-container>
      <v-row>
        <device-dialog
          v-if="selectedDevice"
          :id="selectedDevice"
          v-model="deviceDialog"
          :project-id="currentProjectId"
        />
        <device-selector
          v-model="deviceSelector"
          :filter-type="deviceAddType"
          @selected="addDevice"
        />
        <div class="text-overline">
          <span class="text-uppercase pl-3">
            {{ $tc('project.device', 2) }}
          </span>
        </div>
        <v-spacer />
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              id="intro-drawerdevice-0-lastvalue"
              icon
              small
              class="mr-2"
              @click="$router.push('/last-metrics')"
              v-on="on"
            >
              <v-icon>$iconOpenInNew</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.see') }} {{ $tc('common.lastMeasurements', 2) }}</span>
        </v-tooltip>
      </v-row>
      <v-row v-if="loading" class="pt-4" justify="center">
        <v-progress-circular indeterminate color="quaternary" />
      </v-row>
      <v-row v-else>
        <v-tabs
          id="intro-drawerdevice-1-selectdevicetype"
          ref="tabs"
          v-model="tab"
          background-color="tertiary"
          centered
          color="quaternary"
          height="32px"
          :show-arrows="tabItemsCurrent.length > 5"
          slider-size="3"
        >
          <v-tab v-for="item in tabItemsCurrent" :key="item.id">
            <span>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon v-on="on">
                    {{ item.icon }}
                  </v-icon>
                </template>
                <span>{{ item.text }} ({{ item.totalData }})</span>
              </v-tooltip>
            </span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItemsCurrent" :key="item.id" color="transparent">
            <v-card width="100vw">
              <project-drawer-devices
                v-if="item.id !== 'hub'"
                :device-type="item.id"
                :devices="getProjectDevices(item.id)"
                :hub="getProjectDevices('hub')"
                :tab="tab"
              />
              <project-drawer-device-hub
                v-if="item.id === 'hub'"
                :devices="getProjectDevices('hub')"
                :tab="tab"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { eventBus } from '@/plugins/eventBus'
import { mapActions, mapGetters } from 'vuex'
import {
  GET_PROJECT_VIEW_DEVICE_COORDINATES,
  POST_PROJECT_VIEW_DEVICE_COORDINATE,
} from '@/graphql/ProjectViewDeviceCoordinateQueries'
import { POST_PROJECT_DEVICES } from '@/graphql/ProjectDeviceQueries'
import DeviceSelector from '@/components/device_library/DeviceSelector'
import DeviceDialog from '@/components/project/devices/dialog/DeviceDialog'
import ProjectDrawerDevices from '@/components/project/devices/drawer/ProjectDrawerDevices'
import ProjectDrawerDeviceHub from '@/components/project/devices/drawer/ProjectDrawerDeviceHub'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'

export default {
  name: 'ProjectDrawerDevice',
  components: {
    DeviceDialog,
    DeviceSelector,
    ProjectDrawerDevices,
    ProjectDrawerDeviceHub,
  },
  mixins: [ProjectMixin],
  data: function () {
    return {
      deviceAddHub: null,
      deviceAddType: null,
      deviceDialog: false,
      deviceSelector: false,
      loading: false,
      selectedDevice: false,
      tab: null,
      tabItems: [],
      tabItemsCurrent: [],
      tabItemsDefault: [
        {
          id: 'sensor',
          icon: '$iconSensor2',
          rights: true,
          text: this.$tc('project.sensor', 2),
        },
        {
          id: 'hub',
          icon: '$iconStation',
          rights: true,
          text: this.$tc('project.station', 2),
        },
        {
          id: 'effector',
          icon: '$iconEffector',
          rights: true,
          text: this.$tc('project.effector', 2),
        },
        {
          id: 'transmitter',
          icon: '$iconTransmitter',
          rights: false,
          text: this.$tc('project.transmitter', 2),
        },
        {
          id: 'plot',
          icon: '$iconPlot',
          rights: true,
          text: this.$tc('project.plot', 2),
        },
        {
          id: 'other',
          icon: '$iconOther',
          rights: true,
          text: this.$tc('project.other', 2),
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentDrawer: 'project/currentDrawer',
      currentProjectId: 'project/currentProjectId',
      currentView: 'project/currentView',
      viewTool: 'project/viewTool',
    }),
    displayDrawer: {
      get() {
        return this.currentDrawer === 'device'
      },
      set(newName) {
        return newName
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (
          this.$route.meta.isProjectDrawerDevice &&
          !this.$route.meta.isProjectDrawerDeviceDialog
        ) {
          this.updateCurrentDrawer('device')
          this.$route.meta.isProjectDrawerDeviceDialog = true
          this.selectedDevice = this.$route.params.uuid
          this.deviceDialog = true
        }
      },
    },
    ProjectDevices: {
      handler: function (val, oldVal) {
        if (val !== oldVal) this.getProjectDeviceCount(val)
      },
      deep: true,
    },
    tabItemsCurrent: {
      handler(val, oldVal) {
        if (((val && val.length > 0) || val !== oldVal) && this.$refs.tabs) {
          this.$refs.tabs.callSlider()
        }
      },
      deep: true,
    },
  },
  async mounted() {
    eventBus.$on('viewClick', this.onViewClickEvent)
    eventBus.$on('positionDevice', this.positionDevice)
    eventBus.$on('editDevice', this.editDeviceEvent)
    eventBus.$on('addDevice', this.addDeviceEvent)
  },
  methods: {
    ...mapActions({
      setViewTool: 'project/setViewTool',
      updateCurrentDrawer: 'project/updateCurrentDrawer',
    }),
    addDeviceEvent(params) {
      this.deviceSelector = true
      this.deviceAddType = params.type

      if (params.hub) {
        this.deviceAddHub = params.hub
      }
    },
    editDeviceEvent(item) {
      this.selectedDevice = item.id
      this.deviceDialog = true
    },
    getProjectDeviceCount(val) {
      this.loading = true
      this.tabItems = []
      this.tabItemsCurrent = []
      this.tabItems = this.tabItemsDefault

      this.tabItems.forEach((item) => {
        const newArray = Array.from(val, (e) => e.device_type)
        const uniq = uniqWith(newArray, isEqual)
        const search = uniq.find((el) => el === item.id)
        const count = val.filter((e) => e.device_type === search).length

        item.rights = count > 0
        item.totalData = count || 0
        // If no device, display the sensor tab by default
        if ((!val || val.length === 0) && item.id === 'sensor') {
          item.rights = true
        }
      })

      this.tabItemsCurrent = this.tabItems.filter((item) => {
        return item.rights
      })

      this.loading = false
    },
    getProjectDevices(type) {
      return this.ProjectDevices.filter((e) => e.device_type === type)
    },
    async addDevice(device) {
      try {
        const varObj = {
          project_id: this.currentProjectId,
          lib_device_id: device.id,
          name: '00-NEW-' + device.model,
          description: device.description,
          device_type: device.device_type,
          model: device.model,
        }
        await this.$apollo.mutate({
          mutation: POST_PROJECT_DEVICES,
          variables: {
            objects: varObj,
          },
        })
        this.ProjectDevicesUpdate = true
      } catch (e) {
        console.log('error addDevice', e)
      }
    },
    async onViewClickEvent(event) {
      switch (this.viewTool) {
        case 'device-pos': {
          const coordinate = {
            project_uuid: this.currentProjectId,
            project_view_uuid: this.currentView.uuid,
            project_view_coordinate_system_id: this.currentView.type === '2d' ? 1 : 2,
            project_device_uuid: this.selectedDevice,
            x: event.coordinate[0],
            y: event.coordinate[1],
          }
          await this.$apollo.mutate({
            mutation: POST_PROJECT_VIEW_DEVICE_COORDINATE,
            variables: { coordinate: coordinate },
            refetchQueries: [
              {
                query: GET_PROJECT_VIEW_DEVICE_COORDINATES,
                variables: {
                  project_uuid: this.currentProjectId,
                  project_view_uuid: this.currentView.uuid,
                  coordinate_system_id: this.currentView.type === '2d' ? 1 : 2,
                },
              },
            ],
          })
          await this.setViewTool('select')
          break
        }
      }
    },
    async positionDevice(item) {
      this.selectedDevice = item.id
    },
  },
}
</script>

<style>
#intro-drawerdevice-1-selectdevicetype .v-tab {
  min-width: 34px;
  padding: 0 12px;
}
tr.v-data-table__selected {
  background: #64bdeb !important;
}
.deviceDataTable tr {
  cursor: pointer;
}
</style>
