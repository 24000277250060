<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" md="3">
        <form-error
          :validator="$v.localFieldConfig.min"
          :attribute="$t('common.minimum')"
          :messages="messages"
        >
          <v-text-field
            v-model="localFieldConfig.min"
            slot-scope="{ attrs }"
            type="number"
            v-bind="attrs"
            :label="$t('common.minimum')"
            @input="$v.localFieldConfig.min.$touch()"
            @blur="$v.localFieldConfig.min.$touch()"
          />
        </form-error>
      </v-col>
      <v-col cols="12" md="3">
        <form-error
          :validator="$v.localFieldConfig.max"
          :attribute="$t('common.maximum')"
          :messages="messages"
        >
          <v-text-field
            v-model="localFieldConfig.max"
            slot-scope="{ attrs }"
            v-bind="attrs"
            type="number"
            :label="$t('common.maximum')"
            @input="$v.localFieldConfig.max.$touch()"
            @blur="$v.localFieldConfig.max.$touch()"
          />
        </form-error>
      </v-col>
      <v-col cols="12" md="3">
        <form-error
          :validator="$v.localFieldConfig.offset"
          :attribute="$t('common.offset')"
          :messages="messages"
        >
          <v-text-field
            v-model="localFieldConfig.offset"
            slot-scope="{ attrs }"
            type="number"
            v-bind="attrs"
            :label="$t('common.offset')"
            @input="$v.localFieldConfig.max.$touch()"
            @blur="$v.localFieldConfig.max.$touch()"
          />
        </form-error>
      </v-col>
      <v-col cols="12" md="3">
        <unit-selector
          v-model="localFieldConfig.unit"
          :label="$t('common.unit')"
          @input="$v.localFieldConfig.unit.$touch()"
          @blur="$v.localFieldConfig.unit.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <form-error
          :validator="$v.localDefaultValue.value"
          :attribute="$t('common.defaultValue')"
          :messages="messages"
        >
          <v-text-field
            v-model="localDefaultValue.value"
            slot-scope="{ attrs }"
            :label="$t('common.defaultValue')"
            v-bind="attrs"
            @input="$v.localDefaultValue.value.$touch()"
            @blur="$v.localDefaultValue.value.$touch()"
          />
        </form-error>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, integer, between } from 'vuelidate/lib/validators'
import UnitSelector from '@/components/helper/UnitSelector'

const minmax = (vm) => parseInt(vm.min) <= parseInt(vm.max)

export default {
  name: 'IntegerFieldConfig',
  components: {
    UnitSelector,
  },
  props: {
    fieldConfig: {
      type: Object,
      default() {
        return {
          min: Number.MIN_SAFE_INTEGER,
          max: Number.MAX_SAFE_INTEGER,
          offset: 0,
        }
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {
          value: 0,
        }
      },
    },
  },
  data: function () {
    return {
      localFieldConfig: this.fieldConfig,
      localDefaultValue: this.defaultValue,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    localFieldConfig: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localFieldConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
    localDefaultValue: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localFieldConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
  },
  validations() {
    return {
      localFieldConfig: {
        min: {
          required,
          integer,
          minmax,
        },
        max: {
          required,
          integer,
          minmax,
        },
        offset: {
          required,
          integer,
        },
        unit: {
          integer,
        },
      },
      localDefaultValue: {
        value: {
          integer,
          between: between(this.localFieldConfig.min, this.localFieldConfig.max),
        },
      },
    }
  },
  mounted() {
    this.$emit('input', {
      fieldConfig: this.localFieldConfig,
      localDefaultValue: this.localDefaultValue,
    })
  },
}
</script>
