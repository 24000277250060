import { GET_USER, GET_USERS, PATCH_USER } from '@/graphql/UserQueries'
import { CommonMixin } from '@/mixins/CommonMixin'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export const UserMixin = {
  mixins: [CommonMixin],
  apollo: {
    user: {
      query: GET_USERS,
      deep: true,
      variables() {
        const vars = {
          where: {
            user_projects: {
              project_uuid: {
                _eq: this.currentProjectId,
              },
            },
          },
        }
        return vars
      },
      result({ data }) {
        if (data) {
          this.Users = []
          this.Users = data.user
          this.SetUsersFullname()
        }
      },
      skip() {
        return !this.currentProjectId
      },
    },
  },
  data() {
    return {
      UsersFullname: [],
      Users: [],
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
    UserCurrent() {
      return this.$store.getters['auth/user']
    },
    UserLang() {
      return this.$store.getters['auth/user']?.profile?.lang || 'fr'
    },
  },
  methods: {
    GetUserFullname(uuid) {
      return this.Users.length > 0
        ? this.UsersFullname[uuid] || this.$t('report.deletedUser')
        : null
    },
    SetUsersFullname() {
      this.Users.forEach((el) => {
        el.user_projects.forEach((el2) => {
          if (el2.project_uuid === this.currentProjectId) {
            // All uppercase
            const lastname = el.crm_contact.lastname.toUpperCase()
            // First letter uppercase
            const firstname = this.GetFirstLetterUppercase(el.crm_contact.firstname)
            // Push into an array when key is user_uuid and value is fullname
            this.UsersFullname[el2.user_uuid] = lastname + ' ' + firstname
          }
        })
      })
    },
    async GetUser(uuid) {
      let result = null

      await this.$apollo
        .query({
          query: GET_USER,
          variables: {
            id: uuid,
          },
        })
        .then((res) => {
          result = res.data.user_by_pk
        })
        .catch((err) => {
          throw err
        })

      return result
    },
    /**
     * Update User
     * @param {object} [userBody]
     * @param {string} [crmContactUuid]
     * @param {object} [crmContactBody]
     * @returns
     */
    async UpdateUser(userBody, crmContactUuid, crmContactBody) {
      const userId = this.$store.state.auth.user.id
      userBody = userBody || cloneDeep(this.$store.state.auth.user)
      crmContactUuid = crmContactUuid || cloneDeep(this.$store.state.auth.user.crm_contact_uuid)
      crmContactBody = crmContactBody || cloneDeep(this.$store.state.auth.user.crm_contact)

      if (userId && userBody && crmContactUuid && crmContactBody) {
        crmContactBody.crm_company = null
        crmContactBody.crm_emails = null
        crmContactBody.crm_phones = null
        userBody.crm_contact = null
        userBody.user_projects = null
        userBody.user_roles = null
        delete crmContactBody.__typename
        delete crmContactBody.crm_company
        delete crmContactBody.crm_emails
        delete crmContactBody.crm_phones
        delete userBody.created_at
        delete userBody.crm_contact
        delete userBody.crm_contact_uuid
        delete userBody.id
        delete userBody.updated_at
        delete userBody.user_projects
        delete userBody.user_roles
        delete userBody.__typename

        let result = null
        await this.$apollo
          .mutate({
            mutation: PATCH_USER,
            variables: {
              user_id: userId,
              user_body: userBody,
              crm_contact_uuid: crmContactUuid,
              crm_contact_body: crmContactBody,
            },
            /**
             * Update cache Users
             */
            refetchQueries: [
              {
                query: GET_USERS,
              },
            ],
          })
          .then((resp) => {
            result = resp?.data
          })
          .catch((error) => {
            throw error
          })
        return result
      }
    },
  },
}
