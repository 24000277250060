<template lang="html">
  <v-card :class="classStyle" :outlined="outlinedStyle === 'on' ? true : false" flat tile>
    <v-row>
      <v-col v-for="(filter, index) in filters" :key="index" v-bind="filter.col" class="py-2">
        <component
          :is="filter.type"
          v-model="filter.value"
          v-bind="filter.options"
          :append-icon="filter.type === 'v-text-field' ? '$iconMagnify' : '$iconArrowDown'"
          name="index"
          class="text-capitalize"
          clearable
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { VTextField, VSelect } from 'vuetify/lib'

export default {
  components: {
    VTextField,
    VSelect,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    styleClass: {
      type: String,
      default: '',
    },
    styleOutlined: {
      type: String,
      default: '',
    },
  },
  computed: {
    classStyle() {
      return this.styleClass === '' ? 'px-4' : this.styleClass
    },
    outlinedStyle() {
      return this.styleOutlined === '' ? 'on' : this.styleOutlined
    },
  },
  watch: {
    filters: {
      handler() {
        this.$emit('input', this.gqlFilterGenerate())
      },
      deep: true,
    },
  },
  methods: {
    gqlFilterGenerate() {
      let gqlFilter = []
      let filter = {}

      Object.keys(this.filters).forEach(function (key) {
        if (this.filters[key].value) {
          filter = this.filters[key].gql(this.filters[key].value)
          if (Array.isArray(filter)) {
            gqlFilter = [...gqlFilter, ...filter]
          } else {
            gqlFilter.push(filter)
          }
        }
      }, this)
      return { _and: gqlFilter }
    },
  },
}
</script>
