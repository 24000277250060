<template>
  <v-container
    fluid
    class="mx-auto pa-0"
    :style="StyleHeightCalc('48px + 48px + 64px + 2px', true)"
  >
    <progress-linear-timer v-model="timerRemember" :is-loading="isLoading" />
    <div v-if="!isLoading">
      <div class="text-caption text-right pr-1">
        {{ $t('common.duration') }} {{ timerRemember }} - {{ $t('common.pointInterval') }} (bucket)
        : {{ bucketInfo }}
      </div>
      <v-container v-for="(graph, key) in graphs" :key="key" fluid class="mx-auto pa-0">
        <v-card class="mx-auto pa-0">
          <v-card-text v-if="isSeriesData(graph.series)" class="pt-2 pb-0">
            <apex-charts
              :ref="key"
              type="line"
              height="250"
              width="99.9%"
              :options="graph.options"
              :series="graph.series"
            />
          </v-card-text>
          <v-card-text v-else class="py-2">
            {{ getInfoNoData(graph) }}
          </v-card-text>
        </v-card>
        <v-divider />
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_METRICS_BY_HUB_TIME_BUCKET_GAPFILL } from '@/graphql/ProjectGraphQuery'
import { GET_PROJECT_DEVICE_BY_HUB } from '@/graphql/ProjectDeviceLinkQueries'
import { calculateBucketHub, getTimeInterval } from '@/components/project/graphs/GraphHelper'
import { CommonMixin } from '@/mixins/CommonMixin'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import { UserMixin } from '@/mixins/UserMixin'
import {
  dateTimeHumanFormatFromTo,
  formatDate,
  formatDateTimeIso8601,
} from '@/locales/formats/dataTimeFormats'
import ProgressLinearTimer from '@/components/helper/ProgressLinearTimer'
import VueApexCharts from 'vue-apexcharts'
import en from 'apexcharts/dist/locales/en.json'
import fr from 'apexcharts/dist/locales/fr.json'
import groupBy from 'lodash/groupBy'
import * as moment from 'moment/moment'

export default {
  name: 'GraphMulti',
  components: {
    apexCharts: VueApexCharts,
    ProgressLinearTimer,
  },
  mixins: [CommonMixin, ProjectMixin, UserMixin],
  props: {
    optionsParams: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    metricsByHub: {
      query: GET_METRICS_BY_HUB_TIME_BUCKET_GAPFILL,
      variables() {
        const args = this.varsMetricsByHub
        this.isLoading = true
        return args
      },
      update(data) {
        this.isLoading = false
        return data.get_metrics_by_hub_time_bucket_gapfill
      },
      skip() {
        return this.countDevicesByHub <= 0 || !this.varsMetricsByHub?.args
      },
    },
    project_device_link: {
      query: GET_PROJECT_DEVICE_BY_HUB,
      variables() {
        return {
          project_uuid: this.currentProjectId,
          hub_uuid: this.optionsParams.hub_id,
          order_by: {
            project_device2: {
              model: 'asc',
            },
          },
        }
      },
      result({ data }) {
        this.countDevicesByHub = data?.project_device_link_aggregate.aggregate.count || 0
        this.devicesByHub = data?.project_device_link
          ? groupBy(
              data.project_device_link.map((a) => a.project_device2),
              function (device) {
                return device.lib_device_id
              }
            )
          : {}
      },
      skip() {
        return !this.currentProjectId
      },
    },
  },
  data: function () {
    return {
      bucketInfo: '',
      countDevicesByHub: 0,
      devicesByHub: {},
      graphs: {},
      isLoading: true,
      metricsByHub: [],
      localOptionsParams: {},
      timerRemember: '00:00.000',
      varsMetricsByHub: {},
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
  },
  watch: {
    isLoading: function (val) {
      if (!val) this.createGraphs()
    },
    optionsParams: function (val, oldVal) {
      if (val && val !== oldVal) {
        this.localOptionsParams = val
        this.metricsByHubVars()
      }
    },
  },
  methods: {
    createGraphs() {
      const graph = {}
      const lang = this.UserLang
      this.graphs = {}

      if (!this.isLoading && Object.keys(this.devicesByHub).length > 0) {
        Object.entries(this.devicesByHub).forEach(([deviceType, deviceList]) => {
          const filename =
            this.ProjectName + '_' + this.localOptionsParams.hub_name + '_' + deviceList[0].model
          graph[deviceType] = {
            options: {
              chart: {
                id: deviceType,
                group: 'hub',
                animations: {
                  enabled: false,
                },
                defaultLocale: lang,
                locales: [en, fr],
                toolbar: {
                  export: {
                    csv: {
                      dateFormatter(timestamp) {
                        return moment(timestamp).format(formatDateTimeIso8601)
                      },
                      filename: filename,
                      headerCategory: 'Datetime by interval',
                    },
                    png: {
                      filename: filename,
                    },
                    svg: {
                      filename: filename,
                    },
                  },
                  tools: {
                    customIcons: [
                      {
                        icon: '<span class="mdi mdi-eye-off"></span>',
                        index: 5,
                        title: this.$t('common.hideAll'),
                        class: 'custom-icon',
                        click: function (chart) {
                          chart.opts.series.forEach((i) => {
                            chart.hideSeries(i.name)
                          })
                        },
                      },
                      {
                        icon: '<span class="mdi mdi-eye"></span>',
                        index: 6,
                        title: this.$t('common.showAll'),
                        class: 'custom-icon',
                        click: function (chart) {
                          chart.opts.series.forEach((i) => {
                            chart.showSeries(i.name)
                          })
                        },
                      },
                    ],
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              fill: {
                opacity: 1,
              },
              legend: {
                showForSingleSeries: true,
              },
              markers: {
                size: 0,
              },
              stroke: {
                width: 1,
              },
              subtitle: {
                align: 'right',
                margin: -20,
                offsetX: -180,
                offsetY: 15,
                text:
                  this.$t('common.periodColon') +
                  ' ' +
                  dateTimeHumanFormatFromTo(this.dateMin, this.dateMax, this.getLangAndTranslate()),
              },
              title: {
                align: 'left',
                style: {
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: 600,
                },
                text:
                  this.$t('common.siteColon') +
                  this.ProjectName +
                  ' - ' +
                  this.$t('common.hubColon') +
                  this.localOptionsParams.hub_name +
                  ' - ' +
                  this.$t('common.modelColon') +
                  deviceList[0].model,
              },
              tooltip: {
                x: {
                  format: formatDate[lang].picker + ' HH:mm',
                },
                y: {
                  formatter: (value) => {
                    return value
                  },
                },
              },
              xaxis: {
                labels: {
                  datetimeUTC: false,
                },
                tooltip: {
                  enabled: false,
                },
                type: 'datetime',
              },
              yaxis: {
                decimalsInFloat: 4,
                forceNiceScale: true,
                labels: {
                  minWidth: 40,
                },
                title: {
                  text: '',
                },
              },
            },
          }

          let appRoundOrdinateBetter = null

          graph[deviceType].series = []
          deviceList.forEach((device) => {
            const serie = this.metricsByHub.filter((metric) => {
              return metric.device_uuid === device.id
            })

            const appRoundOrdinate = this.GetProjectDeviceParameter(device.id, 'app_round_ordinate')

            appRoundOrdinateBetter =
              appRoundOrdinateBetter <= appRoundOrdinate ? appRoundOrdinate : appRoundOrdinateBetter

            const chUnit = this.GetProjectDeviceParameter(device.id, 'ch_unit')

            graph[deviceType].options.yaxis.decimalsInFloat =
              Number.parseInt(appRoundOrdinateBetter, 10) || 4
            graph[deviceType].options.yaxis.title.text = this.$t('common.unitColon') + chUnit

            graph[deviceType].series.push({
              name: device.name,
              data: serie.map((metric) => [moment(metric.metric_time).format(), metric.metric]),
            })
          })
        })
      }

      this.graphs = graph
    },
    getInfoNoData(graph) {
      const titleTxt = graph?.options?.title?.text
      let model = ''
      let series = ''
      if (Array.isArray(graph.series)) {
        graph.series.forEach((serie) => {
          series += serie.name + ' '
        })
      }
      if (titleTxt) model = this.GetStringSplit(titleTxt, ':') + ' : '
      return this.$t('common.noDataText') + ' - ' + model + series.trim()
    },
    getLangAndTranslate() {
      return {
        lang: this.UserLang,
        to: this.$t('common.to'),
      }
    },
    isSeriesData(series) {
      let result = false
      series.forEach((i) => {
        if (i.data.length > 0) result = true
      })
      return result
    },
    metricsByHubVars() {
      this.dateMin = getTimeInterval(
        this.localOptionsParams.dateTimeFrom,
        this.localOptionsParams.dateTimeTo
      ).dateMin
      this.dateMax = getTimeInterval(
        this.localOptionsParams.dateTimeFrom,
        this.localOptionsParams.dateTimeTo
      ).dateMax
      const bucket = calculateBucketHub(this.dateMin, this.dateMax, this.countDevicesByHub)
      this.bucketInfo = bucket
      this.varsMetricsByHub = {
        args: {
          f_project_uuid: this.currentProjectId,
          f_hub_uuid: this.optionsParams.hub_id,
          f_bucket_width: bucket,
          f_date_high: this.dateMax,
          f_date_low: this.dateMin,
        },
      }
    },
  },
}
</script>
