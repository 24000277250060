<template lang="html">
  <v-card elevation="0" max-height="40">
    <v-alert
      v-if="!browser.isBlink"
      rounded="0"
      type="error"
      dense
      dismissible
      text
      class="mb-0 text-caption text-center"
    >
      Votre navigateur n'a pas encore été testé. Nous vous invitons à utiliser un navigateur comme
      Chrome ou Chromium.
    </v-alert>
  </v-card>
</template>

<script>
export default {
  name: 'DetectBrowser',
  computed: {
    browser() {
      return {
        isBlink: this.isBlink,
        isChrome: this.isChrome,
        isEdge: this.isEdge,
        isEdgeChromium: this.isEdgeChromium,
        isFirefox: this.isFirefox,
        isIE: this.isIE,
        isOpera: this.isOpera,
        isSafari: this.isSafari,
      }
    },
    isBlink() {
      // Blink engine detection
      return (this.isChrome || this.isOpera) && !!window.CSS
    },
    isChrome() {
      // Chrome
      return !!window.chrome && !!window.chrome.csi
    },
    isEdge() {
      // Edge 20+
      return !this.isIE && !!window.StyleMedia
    },
    isEdgeChromium() {
      // Edge (based on chromium) detection
      return this.isChrome && navigator.userAgent.indexOf('Edg') != -1
    },
    isFirefox() {
      // Firefox 1.0+
      return typeof InstallTrigger !== 'undefined'
    },
    isIE() {
      // Internet Explorer 6-11
      return /*@cc_on!@*/ false || !!document.documentMode
    },
    isOpera() {
      // Opera 8.0+
      return (
        (!!window.opr && !!window.opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(' OPR/') >= 0
      )
    },
    isSafari() {
      // Safari 3.0+ "[object HTMLElementConstructor]"
      return (
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]'
        })(
          !window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)
        )
      )
    },
  },
}
</script>