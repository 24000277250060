<template>
  <v-select
    v-model="finalValue"
    :items="fieldConfig.list"
    item-text="name"
    item-index="name"
    :multiple="fieldConfig.multiple"
  />
</template>

<script>
export default {
  name: 'SimpleListField',
  props: {
    name: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data: function () {
    return {
      finalValue: this.defaultValue.value,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    finalValue: {
      deep: true,
      handler() {
        this.$emit('input', { value: this.finalValue })
      },
    },
  },
}
</script>
