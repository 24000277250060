/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import i18n from '@/plugins/i18n'

// Routes
import store from '../store'
import paths from './paths'

function route(path, view, props, name, children, menu, meta) {
  return {
    name: name || view,
    path,
    component: (resovle) => import('@/views/' + view + '.vue').then(resovle),
    props: props,
    children: children
      ? children.map((path) =>
          route(path.path, path.view, path.props, path.name, path.children, path.menu, path.meta)
        )
      : [],
    menu: menu || true,
    meta: meta,
  }
}

Vue.use(Router)

const DEFAULT_TITLE = 'Vision'

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths
    .map((path) =>
      route(path.path, path.view, path.props, path.name, path.children, path.menu, path.meta)
    )
    .concat([{ path: '*', redirect: 'login' }]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  // Check if the user is logged
  const isUserLoggedIn = store.getters['auth/isAuthenticated']
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      await store.dispatch('auth/refreshToken')
      i18n.locale = store.getters['auth/user']?.profile?.lang || 'fr'
      if (!store.getters['auth/isAuthenticated']) {
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    let titleNew = null
    if (to?.meta?.title === 'dashboard') {
      titleNew = i18n.t('dashboard.' + to.meta.title) + ' - ' + DEFAULT_TITLE
    }
    document.title = titleNew || DEFAULT_TITLE
  })
})

Vue.use(Meta)

export default router
