<template>
  <v-card class="mb-4">
    <v-card-title class="text-body-1 white--text secondary py-1">
      {{ $tc('alert.mail', 1) }}
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12" md="6">
          <form-error
            :validator="$v.options.contacts"
            :attribute="$tc('alert.contact', 2)"
            :messages="messages"
          >
            <contact-selector
              v-bind="$attrs"
              v-model="localOptions.contacts"
              :label="`${$tc('alert.contact', 2)} *`"
              @input="$v.options.contacts.$touch()"
              @blur="$v.options.contacts.$touch()"
            />
          </form-error>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-textarea v-model="localOptions.emailText" :label="$t('alert.message')" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ContactSelector from '@/components/helper/ContactSelector'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AlertContactPointMail',
  components: {
    ContactSelector,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          contacts: [],
          emailText: '',
        }
      },
    },
  },
  data: function () {
    return {
      localOptions: this.options,
    }
  },
  validations: {
    options: {
      contacts: { required },
    },
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    localOptions: {
      deep: true,
      handler() {
        this.$emit('input', { options: this.localOptions })
      },
    },
  },
  mounted() {
    this.$emit('input', {
      options: this.localOptions,
    })
  },
}
</script>

<style scoped>
</style>
