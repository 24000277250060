<template>
  <v-navigation-drawer
    id="project-drawer-view"
    v-model="displayDrawer"
    class="mt-8"
    color="tertiary"
    height="calc(100% - (32px * 2))"
    width="292px"
    absolute
    right
    stateless
  >
    <v-container>
      <v-row>
        <div class="text-overline">
          <span class="text-uppercase pl-3">
            {{ $tc('project.view', 2) }}
          </span>
        </div>
      </v-row>
      <v-row>
        <v-select
          :items="projectViews"
          item-text="name"
          item-value="uuid"
          :value="currentView.uuid"
          :label="$t('common.selectview')"
          :menu-props="{ top: false, offsetY: true, maxHeight: 380 }"
          background-color="#FFF"
          class="mt-1 mx-2"
          dense
          hide-details
          outlined
          @change="updateCurrentView"
        >
          <template #selection="{ item }">
            <v-icon class="mr-2" small>
              {{ iconType(item.type) }}
            </v-icon>
            {{ item.name }}
          </template>
          <template #item="{ item }">
            <v-icon class="mr-2" small>
              {{ iconType(item.type) }}
            </v-icon>
            {{ item.name }}
          </template>
        </v-select>
      </v-row>
      <v-row>
        <project-drawer-view-panel />
      </v-row>
      <v-row>
        <v-toolbar color="tertiary" height="32px">
          <v-tooltip v-if="currentView.type === '2d' && isAuthProjectViewDeviceCoorDel" left>
            <template #activator="{ on }">
              <v-icon
                class="mr-3"
                :color="view2dToolDeviceDel ? '#F44336' : ''"
                small
                v-on="on"
                @click="view2dTool()"
              >
                $iconMapMarkerRemove
              </v-icon>
            </template>
            <span>
              {{ $t('project.deleteDeviceView') }}
            </span>
          </v-tooltip>
          <v-tooltip v-if="currentView.type === 'net' && isAuthProjectViewDeviceCoorDel" left>
            <template #activator="{ on }">
              <v-icon
                class="mr-3"
                :color="viewNetToolPin ? 'secondary' : ''"
                small
                v-on="on"
                @click="viewNetTool()"
              >
                $iconPin
              </v-icon>
            </template>
            <span>
              {{ $t('project.pinDevice') }}
            </span>
          </v-tooltip>
          <v-spacer />
          <span v-if="isAuthProjectViewUpdate">
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn icon small @click="dialog = true">
                  <v-icon v-on="on"> $iconPlus </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('common.addView') }}</span>
            </v-tooltip>
          </span>
          <v-tooltip v-if="!currentView.default && isAuthProjectViewUpdate" left>
            <template #activator="{ on }">
              <v-btn icon small @click="deleteView">
                <v-icon v-on="on"> $iconDelete </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('common.deleteview') }}
            </span>
          </v-tooltip>
        </v-toolbar>
      </v-row>
      <v-dialog v-model="dialog" persistent max-width="350">
        <v-card>
          <v-card-title class="text-h5">
            {{ $t('project.newView') }}
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="view.name" :label="$t('common.name')" />
            <v-select
              v-model="view.type"
              :items="ViewType"
              :menu-props="{ maxHeight: '400' }"
              :label="$t('common.type')"
              :placeholder="$t('project.viewType')"
              item-text="label"
              item-value="name"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="createView">
              {{ $t('common.add') }}
            </v-btn>
            <v-btn text @click="closeDialog">
              {{ $t('common.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  GET_PROJECT_VIEW,
  GET_PROJECT_VIEWS,
  POST_PROJECT_VIEW,
  DELETE_PROJECT_VIEW,
} from '@/graphql/ProjectViewQueries'
import ProjectDrawerViewPanel from '@/components/project/views/drawer/ProjectDrawerViewPanel'
import ViewType from '@/components/project/views/ViewType.js'
import { CommonMixin } from '@/mixins/CommonMixin'

export default {
  name: 'ProjectDrawerView',
  components: {
    ProjectDrawerViewPanel,
  },
  mixins: [CommonMixin],
  apollo: {
    project_view: {
      query: GET_PROJECT_VIEWS,
      variables() {
        return this.defaultVars()
      },
      result({ data }) {
        if (data) {
          if (!data.project_view.find((view) => view.uuid === '')) {
            data.project_view.push(this.defaultViewNet)
          }
          this.projectViews = []
          this.projectViews = data.project_view
        }
      },
      skip() {
        return !this.currentProjectId
      },
    },
  },
  data: function () {
    return {
      dialog: false,
      projectView: {},
      projectViews: [],
      view: {
        uuid: '',
        name: '',
        type: '',
        options: {
          zoom: 6,
          center: [10.892430818696198, 47.63210874630997],
          rotation: 0,
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentView: 'project/currentView',
      currentDrawer: 'project/currentDrawer',
    }),
    defaultViewNet() {
      return {
        uuid: '',
        name: this.$t('project.sensorNetworks'),
        type: 'net',
        project_uuid: this.currentProjectId,
        options: {},
        updated_at: '',
        created_at: '',
        default: true, // true: To not display the "delete" button for this "net" view
        __typename: 'project_view',
      }
    },
    displayDrawer: {
      get() {
        return this.currentDrawer === 'view'
      },
      set(newName) {
        return newName
      },
    },
    isAuthProjectViewDeviceCoorDel() {
      return this.$store.getters['auth/isAuth'](['project_view_device_coordinate', 'delete'])
    },
    isAuthProjectViewUpdate() {
      return this.$store.getters['auth/isAuth'](['project_view', 'update'])
    },
    view2dToolDeviceDel() {
      return this.$store.getters['project/viewTool'] === 'device-del'
    },
    viewNetToolPin() {
      return this.$store.getters['project/viewTool'] === 'pin'
    },
    ViewType() {
      const nameLabel = this.$t('common.cartographic')
      return ViewType.filter(function (index) {
        if (index.label === 'Cartographique') {
          index.label = nameLabel
        }
        return index
      })
    },
  },
  methods: {
    ...mapActions({
      setCurrentView: 'project/setCurrentView',
      setCurrentViewDefaultOptions: 'project/setCurrentViewDefaultOptions',
      setDefaultView: 'project/setDefaultView',
      setViewTool: 'project/setViewTool',
    }),
    closeDialog() {
      this.view = {
        name: '',
        type: '',
      }
      this.dialog = false
    },
    defaultVars() {
      return {
        where: {
          project_uuid: {
            _eq: this.currentProjectId,
          },
        },
        order_by: {
          name: 'asc',
        },
      }
    },
    iconType(type) {
      let icon = ''
      switch (type) {
        case '2d':
          icon = '$iconAddress'
          break
        case 'map':
          icon = '$iconMapLegend'
          break
        case 'net':
          icon = '$iconSatelliteDown'
          break
        default:
          break
      }
      return icon
    },
    async createView() {
      this.view.project_uuid = this.currentProjectId
      delete this.view.uuid
      if (this.view.type === '2d') {
        this.view.options = {
          center: [0, 0],
          zoom: 1,
        }
      }
      const result = await this.$apollo
        .mutate({
          mutation: POST_PROJECT_VIEW,
          variables: {
            objects: this.view,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_VIEWS,
              variables: this.defaultVars(),
            },
          ],
        })
        .catch((error) => {
          this.ShowSnackbarError('POST_PROJECT_VIEW: ' + error.message)
          throw error
        })
      if (result.data.insert_project_view.returning.length > 0) {
        this.setCurrentView(result.data.insert_project_view.returning[0])
        const options = JSON.parse(JSON.stringify(result.data.insert_project_view.returning[0]))
        this.setCurrentViewDefaultOptions(options)
      }
      this.closeDialog()
    },
    async view2dTool() {
      if (this.view2dToolDeviceDel) {
        await this.setViewTool('select')
      } else {
        await this.setViewTool('device-del')
      }
    },
    async viewNetTool() {
      if (this.viewNetToolPin) {
        await this.setViewTool('select')
      } else {
        await this.setViewTool('pin')
      }
    },
    async deleteView() {
      const removal = this.$t('common.removal')
      const sureToDelete = this.$t('common.sureToDelete')
      const res = await this.$root.$confirm(removal, sureToDelete, {
        color: 'red',
      })
      if (res) {
        await this.$apollo
          .mutate({
            mutation: DELETE_PROJECT_VIEW,
            variables: {
              uuid: this.currentView.uuid,
            },
            refetchQueries: [
              {
                query: GET_PROJECT_VIEWS,
                variables: this.defaultVars(),
              },
            ],
          })
          .catch((error) => {
            this.ShowSnackbarError('DELETE_PROJECT_VIEW: ' + error.message)
            throw error
          })
        this.setDefaultView()
      }
      const list = this.projectViews.filter((item) => {
        return item.uuid !== this.currentView.uuid
      })
      this.projectViews = list
      this.setDefaultView()
    },
    async updateCurrentView(selectId) {
      let loadView = this.defaultViewNet
      if (selectId !== '') {
        loadView = this.projectViews.find((view) => view.uuid === selectId)
        const res = await this.$apollo.query({
          query: GET_PROJECT_VIEW,
          variables: {
            uuid: selectId,
          },
        })
        const options = res.data?.project_view_by_pk?.options
          ? JSON.parse(JSON.stringify(res.data.project_view_by_pk.options))
          : JSON.parse(JSON.stringify(loadView.options))
        this.setCurrentViewDefaultOptions(options)
      }
      this.setCurrentView(loadView)
      return true
    },
  },
}
</script>

<style>
#project-drawer-view .v-navigation-drawer__content {
  overflow-y: hidden;
}
</style>
