import { apolloProvider } from '@/apollo-provider'

import { GET_PROJECT_VIEWS } from '@/graphql/ProjectViewQueries'
/**
 * Initial state
 */
const state = () => ({
  currentDisplay: 'none',
  currentDrawer: 'none',
  currentProjectId: null,
  currentView: {},
  currentViewDefaultOptions: {
    zoom: 6,
    center: [10.892430818696198, 47.63210874630997],
    rotation: 0,
  },
  targetDevices: null,
  viewTool: 'select',
})

/**
 * Getters
 */
const getters = {
  currentDisplay: (state) => state.currentDisplay,
  currentDrawer: (state) => state.currentDrawer,
  currentProjectId: (state) => state.currentProjectId,
  currentView: (state) => state.currentView,
  currentViewDefaultOptions: (state) => state.currentViewDefaultOptions,
  targetDevices: (state) => state.targetDevices,
  viewTool: (state) => state.viewTool,
}

/**
 * Mutation
 */
const mutations = {
  SET_CURRENTDISPLAY(state, currentDisplay) {
    state.currentDisplay = currentDisplay
  },
  SET_CURRENTDRAWER(state, currentDrawer) {
    state.currentDrawer = currentDrawer
  },
  SET_CURRENTVIEW(state, currentView) {
    state.currentView = currentView
  },
  SET_CURRENTVIEWDEFAULTOPTIONS(state, currentViewDefaultOptions) {
    state.currentViewDefaultOptions = currentViewDefaultOptions
  },
  SET_PROJECT(state, projectId) {
    state.currentProjectId = projectId
  },
  SET_TARGETDEVICE(state, targetDevices) {
    state.targetDevices = targetDevices
  },
  SET_VIEWTOOL(state, viewTool) {
    state.viewTool = viewTool
  },
  UPDATE_CURRENTVIEW(state, payload) {
    state.currentView = Object.assign(state.currentView, payload)
  },
  UPDATE_CURRENTVIEW_OPTIONS(state, payload) {
    state.currentView.options = Object.assign(state.currentView.options, payload)
  },
}

/**
 * Actions
 */
const actions = {
  async setCurrentProject({ commit, dispatch }, projectId) {
    commit('SET_PROJECT', projectId)
    dispatch('setDefaultView')
  },
  async setCurrentView({ commit }, currentView) {
    commit('SET_CURRENTVIEW', currentView)
  },
  async setCurrentViewDefaultOptions({ commit }, currentViewDefaultOptions) {
    commit('SET_CURRENTVIEWDEFAULTOPTIONS', currentViewDefaultOptions)
  },
  async setDefaultProject({ commit, rootGetters, dispatch }) {
    commit('SET_PROJECT', rootGetters['auth/user'].default_project_uuid)
    dispatch('setDefaultView')
  },
  async setDefaultView({ commit, getters }) {
    const { data } = await apolloProvider.defaultClient.query({
      query: GET_PROJECT_VIEWS,
      variables: {
        where: {
          project_uuid: { _eq: getters.currentProjectId },
          _and: { default: { _eq: true } },
        },
      },
    })
    const options = JSON.parse(JSON.stringify(data.project_view[0].options))
    commit('SET_CURRENTVIEW', data.project_view[0])
    commit('SET_CURRENTVIEWDEFAULTOPTIONS', options)
  },
  async setViewTool({ commit }, viewTool) {
    commit('SET_VIEWTOOL', viewTool)
  },
  setTargetDevice: ({ commit }, targetDevices) => {
    commit('SET_TARGETDEVICE', targetDevices)
  },
  updateCurrentDisplay: ({ commit, getters }, currentDisplay) => {
    const finalDisplay = getters.currentDisplay === currentDisplay ? 'none' : currentDisplay
    commit('SET_CURRENTDISPLAY', finalDisplay)
  },
  updateCurrentDrawer: ({ commit, getters }, currentDrawer) => {
    const finalDrawer = getters.currentDrawer === currentDrawer ? 'none' : currentDrawer
    commit('SET_CURRENTDRAWER', finalDrawer)
  },
  updateCurrentView: ({ commit }, payload) => commit('UPDATE_CURRENTVIEW', payload),
  updateCurrentViewOptions: ({ commit }, payload) => commit('UPDATE_CURRENTVIEW_OPTIONS', payload),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
