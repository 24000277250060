import { AuthAndBlogicClient } from '@/plugins/authAndBlogic'
import { apolloProvider } from '@/apollo-provider'
import Router from '@/router'
import { GET_USER } from '@/graphql/UserQueries'
import { GET_ACLS } from '@/graphql/AuthQueries'
import jwtDecode from 'jwt-decode'
import gql from 'graphql-tag'

// initial state
const state = () => ({
  token: null,
  user: {},
  acl: [],
  authStatus: false,
  rowApiDB: [],
})

// getters
const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.authStatus,
  user: (state) => state.user,
  acl: (state) => state.acl,
  token: (state) => state.token,
  isAuth: (state) => (checkPermission) => {
    const acls = state.acl
    const object = acls.find((acl) => acl.name === checkPermission[0])
    return object ? object.permission.includes(checkPermission[1]) : false
  },
  rowApiDB: (state) => state.rowApiDB,
  isRowApiDB: (state) => (checkRow) => {
    const rows = state.rowApiDB
    const rowParams = checkRow[1]
    const object = rows.find((row) => row.name === checkRow[0])
    return object ? object[rowParams] : false
  },
}

// mutations
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  LOGIN_USER(state, user) {
    state.authStatus = true
    state.user = { ...user }
  },
  SET_ACL(state, acl) {
    state.acl = acl
  },
  LOGOUT_USER(state) {
    state.authStatus = false
    state.token = null
    state.user = {}
  },
  SET_ROW_API_DB(state, row) {
    state.rowApiDB = row
  },
  UPDATE_USER(state, payload) {
    state.user = Object.assign(state.user, payload)
  },
  UPDATE_USER_PROFILE(state, payload) {
    state.user.profile = Object.assign(state.user.profile, payload)
  },
  UPDATE_USER_CRM_CONTACT(state, payload) {
    state.user.crm_contact = Object.assign(state.user.crm_contact, payload)
  },
}

// actions
const actions = {
  /**
   * Login function
   *
   * @param {Object} Vuex action stuffs
   * @param authDetails {Object.<string, string>}
   */
  async login({ commit, dispatch }, authDetails) {
    const res = await AuthAndBlogicClient.login(authDetails)
    if (res.error) {
      return res.message
    } else {
      commit('SET_TOKEN', res.jwtToken)
      await dispatch('setUser')
    }
  },
  /**
   * Logout function
   *
   * @param {Object} Vuex action stuffs
   */
  async logout({ commit, getters }) {
    await AuthAndBlogicClient.logout(getters.token)
    commit('LOGOUT_USER')
    Router.push('/login').catch(() => {})
  },
  /**
   * Refresh jwtToken
   *
   * @param {Object} Vuex action stuffs
   */
  async refreshToken({ commit, dispatch }) {
    try {
      const { jwtToken } = await AuthAndBlogicClient.refreshToken()
      commit('SET_TOKEN', jwtToken)
      await dispatch('setUser')
    } catch (e) {
      console.log('refreshToken e', e)
    }
  },
  /**
   * Set ACL
   *
   * @param {Object} Vuex action stuffs
   */
  async setAcl({ commit }) {
    const { data } = await apolloProvider.defaultClient.query({
      query: GET_ACLS,
      fetchPolicy: 'no-cache',
    })

    // Rewrite query Schema
    const vueQueryACL = data.__schema.queryType.fields
      .filter((query) => !query.name.endsWith('by_pk') && !query.name.endsWith('aggregate'))
      .map((query) => ({
        name: query.name,
        permission: ['read'],
      }))

    // Rewrite mutations Schema
    const vueMutationACL = data.__schema.mutationType.fields
      .filter((query) => !query.name.endsWith('by_pk') && !query.name.endsWith('one'))
      .map((query) => {
        if (query.name.startsWith('delete')) {
          return {
            name: query.name.replace('delete_', ''),
            permission: ['delete'],
          }
        } else if (query.name.startsWith('insert')) {
          return {
            name: query.name.replace('insert_', ''),
            permission: ['create'],
          }
        } else if (query.name.startsWith('update')) {
          return {
            name: query.name.replace('update_', ''),
            permission: ['update'],
          }
        }
        return null
      })

    // Concat query and mutations
    const acl = [...vueQueryACL, ...vueMutationACL].reduce((res, b) => {
      const found = res.find((item) => item.name === b.name)
      if (found) {
        found.permission = found.permission.concat(b.permission)
      } else {
        res.push(b)
      }
      return res
    }, [])

    commit('SET_ACL', acl)
  },
  /**
   * Set Row DB
   *
   * @param {Object} Vuex action stuffs
   */
  async setRowApiDB({ commit }) {
    const list = []

    // Get schema db
    const { data } = await apolloProvider.defaultClient.query({
      query: GET_ACLS,
      fetchPolicy: 'no-cache',
    })

    // Get table aggregate
    const queryAggregate = data.__schema.queryType.fields
      .filter((query) => query.name.endsWith('aggregate') && query.name.includes('issue'))
      .map((query) => ({ name: query.name }))

    // Set result db
    for (const q of queryAggregate) {
      const queryName = q.name
      const result = await apolloProvider.defaultClient.query({
        query: gql`
          query ${queryName} {
            ${queryName} {
              aggregate {
                count
              }
            }
          }`,
      })
      if (result) {
        const name = queryName.substring(0, queryName.lastIndexOf('_'))
        list.push({
          queryName: queryName,
          name: name,
          count: result.data[queryName].aggregate.count,
          isRow: result.data[queryName].aggregate.count > 0,
        })
      }
    }
    commit('SET_ROW_API_DB', list)
  },
  /**
   * Save user data into store
   *
   * @param {Object} Vuex action stuffs
   */
  async setUser({ commit, getters, dispatch }) {
    try {
      const tokenPayload = jwtDecode(getters.token)
      const userUuid = tokenPayload['https://hasura.io/jwt/claims']['x-hasura-user-id']
      const { data } = await apolloProvider.defaultClient.query({
        query: GET_USER,
        variables: {
          id: userUuid,
        },
      })
      commit('LOGIN_USER', data.user_by_pk)
      dispatch('setAcl')
      dispatch('setRowApiDB')
    } catch (e) {
      console.log(e)
    }
  },
  updateUser: ({ commit }, payload) => commit('UPDATE_USER', payload),
  updateUserProfile: ({ commit }, payload) => commit('UPDATE_USER_PROFILE', payload),
  updateUserCrmContact: ({ commit }, payload) => commit('UPDATE_USER_CRM_CONTACT', payload),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
