<template>
  <v-combobox
    v-model="companySelect"
    v-bind="$attrs"
    :items="companyItems"
    :label="$tc('crmCompany.company', 1) + ' *'"
    :placeholder="$tc('crmCompany.company', 1)"
    :no-data-text="$t('common.noDataText')"
    :search-input.sync="companySearch"
    item-text="name"
    item-value="uuid"
    :hide-no-data="!companySearch"
    return-object
    v-on="$listeners"
    @change="selectItem"
  >
    <template #no-data>
      <v-list-item>
        <span class="subheading">{{ $t('common.create') }}</span>
        <span class="ml-2">{{ companySearch }}</span>
      </v-list-item>
    </template>
    <template #item="{ index, item }">
      <v-list-item v-if="editing === item" dense @click.stop>
        <v-text-field
          v-model="editing.name"
          autofocus
          flat
          background-color="transparent"
          hide-details
          @keyup.enter="edit(index, item)"
        />
      </v-list-item>
      <span v-else>{{ item.name }}</span>
      <v-spacer />
      <v-list-item-action @click.stop>
        <v-btn icon x-small @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? '$iconEdit' : '$iconCheck' }}</v-icon>
        </v-btn>
        <v-btn
          v-if="editing !== item"
          icon
          x-small
          @click.stop.prevent="deleteCompany(index, item)"
        >
          <v-icon>$iconDelete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>
<script>
import {
  GET_CRM_COMPANIES,
  POST_CRM_COMPANY,
  PATCH_CRM_COMPANY,
  DELETE_CRM_COMPANY,
} from '@/graphql/CrmCompanyQueries'

export default {
  name: 'CompanySelector',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    companyItems: [],
    companySelect: {},
    companySearch: '',
    editing: null,
    firstload: true,
  }),
  watch: {
    value: {
      handler: 'loadCompany',
      immediate: true,
    },
    companySelect: {
      handler: 'addCompany',
      immediate: true,
    },
    companySearch(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch()
      }
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      } else {
        this.editing = null
        this.index = -1
        this.updateCompany(item)
      }
    },
    loadCompany() {
      this.companySelect = this.value
    },
    async querySearch() {
      this.loading = true
      const rv = await this.$apollo.query({
        query: GET_CRM_COMPANIES,
        variables: {
          order_by: { name: 'asc' },
        },
      })
      this.companyItems = rv.data.crm_company
      this.loading = false
    },
    async addCompany(val) {
      if (typeof val !== 'string' && val === '') return
      await this.$apollo.mutate({
        mutation: POST_CRM_COMPANY,
        variables: {
          objects: { name: val },
        },
        // eslint-disable-next-line
          update: (cache, { data: { insert_crm_company } }) => {
          try {
            const company = cache.readQuery({
              query: GET_CRM_COMPANIES,
              variables: {
                order_by: { name: 'asc' },
              },
            })
            // eslint-disable-next-line
              company.crm_company.push(insert_crm_company.returning[0])
            company.crm_company_aggregate.aggregate.totalCount++
            cache.writeQuery({
              query: GET_CRM_COMPANIES,
              data: company,
            })
            // eslint-disable-next-line
              this.$emit('input', insert_crm_company.returning[0])
          } catch (e) {
            console.log(e)
          }
        },
      })
    },
    async updateCompany(item) {
      try {
        await this.$apollo.mutate({
          mutation: PATCH_CRM_COMPANY,
          variables: {
            uuid: parseInt(item.uuid),
            body: { name: item.name },
          },
          // eslint-disable-next-line
            update: (cache, { data: { update_crm_company } }) => {
            cache.reset()
            // eslint-disable-next-line
              this.companySelect = update_crm_company.returning[0]
          },
        })
      } catch (e) {
        console.log(e)
      }
    },
    async deleteCompany(index, item) {
      await this.$apollo.mutate({
        mutation: DELETE_CRM_COMPANY,
        variables: {
          uuid: item.uuid,
        },
        // eslint-disable-next-line
          update: (cache, { data: { delete_project } }) => {
          cache.reset()
        },
      })
      this.querySearch()
    },
    selectItem() {
      if (this.editing) {
        return false
      }

      this.$emit('input', this.companySelect)
      this.companySearch = ''
    },
  },
}
</script>

<style>
.subheading::first-letter {
  text-transform: capitalize;
}
</style>
