import gql from 'graphql-tag'

// query
export const GET_PROJECT_DEVICE_DOCUMENT = gql`
  query project_device_document(
    $where: project_device_document_bool_exp
    $order_by: [project_device_document_order_by!]
    $limit: Int
  ) {
    project_device_document(where: $where, order_by: $order_by, limit: $limit) {
      uuid
      title
      file
      description
      project_device_document_category_id
      user_uuid
      created_at
      updated_at
    }
  }
`

// mutations
export const UPDATE_PROJECT_DEVICE_DOCUMENT_BY_PK = gql`
  mutation update_project_device_document_by_pk(
    $uuid: uuid!
    $_set: project_device_document_set_input = {}
  ) {
    update_project_device_document_by_pk(pk_columns: { uuid: $uuid }, _set: $_set) {
      uuid
    }
  }
`

export const DELETE_PROJECT_DEVICE_DOCUMENT_BY_PK = gql`
  mutation delete_project_device_document_by_pk($uuid: uuid!) {
    delete_project_device_document_by_pk(uuid: $uuid) {
      uuid
    }
  }
`
