var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"mt-8",attrs:{"id":"project-drawer-dashboard","color":"tertiary","height":"calc(100% - (32px * 2))","width":"292px","absolute":"","right":"","stateless":""},model:{value:(_vm.displayDrawer),callback:function ($$v) {_vm.displayDrawer=$$v},expression:"displayDrawer"}},[_c('v-container',[_c('v-row',[_c('div',{staticClass:"text-overline"},[_c('span',{staticClass:"text-uppercase pl-3"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.dashboard'))+" ")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push('/dashboard')}}},on),[_c('v-icon',[_vm._v("$iconOpenInNew")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.see'))+" "+_vm._s(_vm.$t('dashboard.dashboard')))])])],1),_c('v-row',[_c('dashboard',{attrs:{"is-drawer":_vm.isTrue,"is-view":_vm.isSelectedToView}})],1),_c('v-row',[_c('v-toolbar',{attrs:{"color":"tertiary","height":"32px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":_vm.isSelectedToView ? 'secondary' : 'primary'},on:{"click":function($event){return _vm.clickIconToolbar('view')}}},on),[_vm._v(" $iconEye ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.see')))])]),_c('v-spacer'),(!_vm.IsMobile() && _vm.$store.getters['auth/isAuth'](['dashboard', 'create']))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$router.push('dashboard/add')}}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconPlus")])],1)]}}],null,false,2921527466)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.addDashboard'))+" ")])]):_vm._e(),(!_vm.IsMobile() && _vm.$store.getters['auth/isAuth'](['dashboard', 'delete']))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.isTrue},on:{"click":_vm.deleteDashboard}},[_c('v-icon',_vm._g({},on),[_vm._v("$iconDelete")])],1)]}}],null,false,3777702765)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('dashboard.deleteDashboard'))+" ")])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }