import moment from 'moment'

export default {
  formatDate(time) {
    if (time) {
      return moment(String(time)).format('DD/MM/YYYY HH:mm')
    }
  },
  formatDateSeconds(time) {
    let result = ''
    if (time) {
      if (typeof time === 'string') {
        if (time.indexOf('/') !== -1) {
          result = time
        } else {
          result = moment(String(time)).format('DD/MM/YYYY HH:mm:ss')
        }
      } else if (typeof time === 'object') {
        result = moment(time).format('DD/MM/YYYY HH:mm:ss')
      }
    }
    return result
  },
  uppercase(text) {
    return text.toUpperCase()
  },
}
