import axios from 'axios'
import Configuration from '@/configuration'

class AuthAndBlogic {
  constructor(config) {
    this.endpoint = config.endpoint
  }

  async login(authDetails) {
    const headers = {
      'Content-Type': 'application/json',
    }

    const res = await axios.post(`${this.endpoint}/auth/login`, authDetails, {
      headers,
      withCredentials: true,
    })
    return res.data
  }

  async logout() {
    const headers = {
      'Content-Type': 'application/json',
    }

    await axios.post(
      `${this.endpoint}/auth/logout`,
      { all: true },
      {
        headers,
        withCredentials: true,
      }
    )
  }

  async refreshToken() {
    const headers = {
      'Content-Type': 'application/json',
    }
    const res = await axios.get(`${this.endpoint}/auth/token/refresh`, {
      headers,
      withCredentials: true,
    })
    return res.data
  }

  async preRegister(token) {
    const headers = {
      'Content-Type': 'application/json',
    }

    const res = await axios.post(
      `${this.endpoint}/register/pre-register`,
      { token: token },
      {
        headers,
        withCredentials: true,
      }
    )

    return res.data.auth_invitation[0]
  }

  async Register(token, user) {
    const headers = {
      'Content-Type': 'application/json',
    }

    const res = await axios.post(
      `${this.endpoint}/register/register`,
      { token: token, user: user },
      {
        headers,
        withCredentials: true,
      }
    )

    return res.data.action[0]
  }
}

export const AuthAndBlogicClient = new AuthAndBlogic({
  endpoint: Configuration.value('apiAuthHttp'),
})
