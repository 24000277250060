<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" md="3">
        <form-error
          :validator="$v.localfieldConfig.min"
          :attribute="$t('common.minimum')"
          :messages="messages"
        >
          <v-text-field
            v-model="localfieldConfig.min"
            slot-scope="{ attrs }"
            type="number"
            v-bind="attrs"
            :label="$t('common.minimum')"
            @input="$v.localfieldConfig.min.$touch()"
            @blur="$v.localfieldConfig.min.$touch()"
          />
        </form-error>
      </v-col>
      <v-col cols="12" md="3">
        <form-error
          :validator="$v.localfieldConfig.max"
          :attribute="$t('common.maximum')"
          :messages="messages"
        >
          <v-text-field
            v-model="localfieldConfig.max"
            slot-scope="{ attrs }"
            type="number"
            v-bind="attrs"
            :label="$t('common.maximum')"
            @input="$v.localfieldConfig.max.$touch()"
            @blur="$v.localfieldConfig.max.$touch()"
          />
        </form-error>
      </v-col>
      <v-col cols="12" md="3">
        <form-error
          :validator="$v.localfieldConfig.decimal"
          :attribute="$t('common.decimal')"
          :messages="messages"
        >
          <v-text-field
            v-model="localfieldConfig.decimal"
            slot-scope="{ attrs }"
            type="number"
            v-bind="attrs"
            :label="$t('common.decimal')"
            @input="$v.localfieldConfig.max.$touch()"
            @blur="$v.localfieldConfig.max.$touch()"
          />
        </form-error>
      </v-col>
      <v-col cols="12" md="3">
        <unit-selector
          v-model="localfieldConfig.unit"
          :label="$t('common.unit')"
          @input="$v.localfieldConfig.unit.$touch()"
          @blur="$v.localfieldConfig.unit.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <form-error
          :validator="$v.localDefaultValue.value"
          :attribute="$t('common.defaultValue')"
          :messages="messages"
        >
          <v-text-field
            v-model="localDefaultValue.value"
            slot-scope="{ attrs }"
            :label="$t('common.defaultValue')"
            v-bind="attrs"
            type="number"
            @input="$v.localDefaultValue.value.$touch()"
            @blur="$v.localDefaultValue.value.$touch()"
          />
        </form-error>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { helpers, required, integer, between } from 'vuelidate/lib/validators'
import UnitSelector from '@/components/helper/UnitSelector'

const minmax = (vm) => parseFloat(vm.min) <= parseFloat(vm.max)
const number = (n) => !isNaN(parseFloat(n)) && isFinite(n)
const decimalcount = (param) =>
  helpers.withParams({ type: 'decimalcount', decimal: param }, (value) => {
    return value.toString().split('e')[0] % 1 !== 0
      ? value.toString().split('e')[0].toString().split('.')[1].length <= param
      : true
  })

export default {
  name: 'FloatFieldConfig',
  components: {
    UnitSelector,
  },
  props: {
    fieldConfig: {
      type: Object,
      default() {
        return {
          min: -Number.MAX_VALUE,
          max: Number.MAX_VALUE,
          decimal: 2,
        }
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {
          value: 0.0,
        }
      },
    },
  },
  data() {
    return {
      localfieldConfig: this.fieldConfig,
      localDefaultValue: this.defaultValue,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    localfieldConfig: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localfieldConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
    localDefaultValue: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localfieldConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
  },
  validations() {
    return {
      localfieldConfig: {
        min: {
          required,
          number,
          minmax,
        },
        max: {
          required,
          number,
          minmax,
        },
        decimal: {
          required,
          integer,
        },
        unit: {
          integer,
        },
      },
      localDefaultValue: {
        value: {
          number,
          decimalcount: decimalcount(this.localfieldConfig.decimal),
          between: between(this.localfieldConfig.min, this.localfieldConfig.max),
        },
      },
    }
  },
  mounted() {
    this.$emit('input', {
      fieldConfig: this.localfieldConfig,
      defaultValue: this.localDefaultValue,
    })
  },
}
</script>
