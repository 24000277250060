import gql from 'graphql-tag'

export const GET_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS = gql`
  query project_devices_project_device_parameters(
    $order_by: [project_devices_project_device_parameters_order_by!]
    $where: project_devices_project_device_parameters_bool_exp
  ) {
    project_devices_project_device_parameters(order_by: $order_by, where: $where) {
      uuid
      project_device_uuid
      project_device_parameter_uuid
      order
      parameter_value
      project_device_parameter {
        uuid
        name
        config_label
        description
        field_type
        field_config
        default_value
        constant
        target
      }
    }
  }
`

// mutations
export const UPDATE_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS = gql`
  mutation update_project_devices_project_device_parameters(
    $where: project_devices_project_device_parameters_bool_exp!
    $_set: project_devices_project_device_parameters_set_input = {}
  ) {
    update_project_devices_project_device_parameters(where: $where, _set: $_set) {
      affected_rows
      returning {
        uuid
        project_device_uuid
        project_device_parameter_uuid
        order
        parameter_value
      }
    }
  }
`
