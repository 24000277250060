import gql from 'graphql-tag'

// queries
export const GET_ACLS = gql`
  query acl {
    __schema {
      queryType {
        fields {
          name
          description
        }
      }
      mutationType {
        fields {
          name
          description
        }
      }
    }
  }
`

export const POST_INVITATION = gql`
  mutation ($invitation: auth_invitation_insert_input!) {
    insert_auth_invitation_one(object: $invitation) {
      token
      firstname
      lastname
      email
      created_at
    }
  }
`
