<template>
  <v-container class="pa-0">
    <v-select
      v-model="ContactPointModel"
      :items="ContactPointList"
      :label="labelText"
      :readonly="isReadonly"
      :menu-props="{ top: false, offsetY: true }"
      :no-data-text="$t('common.noDataText')"
      :placeholder="$t('common.select')"
      height="46px"
      item-text="name"
      item-value="uuid"
      :clearable="!isReadonly"
      dense
      return-object
      @change="selectItem"
    />
  </v-container>
</template>
<script>
import { GET_ALERT_CONTACT_POINTS } from '@/graphql/AlertContactPointQueries'

export default {
  name: 'ContactPointSelector',
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: undefined,
    },
  },
  apollo: {
    alert_contact_point: {
      query: GET_ALERT_CONTACT_POINTS,
      variables() {
        const vars = {
          order_by: { name: 'asc' },
        }
        return vars
      },
      result({ data }) {
        if (data) {
          return data.alert_contact_point
        }
      },
      skip() {
        return !this.$store.getters['auth/isAuthenticated']
      },
    },
  },
  data: () => ({
    ContactPointSelected: [],
  }),
  computed: {
    ContactPointList() {
      return this.getContactPointList()
    },
    ContactPointModel: {
      get() {
        return this.getContactPointSelected()
      },
      set(val) {
        this.ContactPointSelected = val
      },
    },
    labelText() {
      return this.label !== '' ? this.label : this.$tc('alert.contactpoint', 1) + ' *'
    },
  },
  watch: {
    ContactPointModel: function (val) {
      this.ContactPointSelected = val
    },
    ContactPointSelected: function (val) {
      this.ContactPointModel = val
    },
  },
  methods: {
    getContactPointList() {
      const list = []
      if (typeof this.alert_contact_point !== 'undefined') {
        this.alert_contact_point.forEach((i) => {
          list.push({
            name: i.name,
            uuid: i.uuid,
          })
        })
      }
      return list
    },
    getContactPointSelected() {
      let result = []
      if (this.value) {
        result = this.value
      }
      return result
    },
    selectItem() {
      this.$emit('input', this.ContactPointSelected.uuid)
    },
  },
}
</script>
