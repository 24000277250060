import gql from 'graphql-tag'

export const GET_PROJECT_DEVICE_DOCUMENT_CATEGORIES = gql`
  query project_device_document_category(
    $where: project_device_document_category_bool_exp
    $order_by: [project_device_document_category_order_by!]
    $limit: Int
    $offset: Int
  ) {
    project_device_document_category_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    project_device_document_category(
      where: $where
      offset: $offset
      limit: $limit
      order_by: $order_by
    ) {
      id
      name
    }
  }
`

// mutations
export const POST_PROJECT_DEVICE_DOCUMENT_CATEGORY_ONE = gql`
  mutation ($object: project_device_document_category_insert_input!) {
    insert_project_device_document_category_one(object: $object) {
      id
      name
    }
  }
`

export const UPDATE_PROJECT_DEVICE_DOCUMENT_CATEGORY = gql`
  mutation ($id: Int!, $body: project_device_document_category_set_input!) {
    update_project_device_document_category(where: { id: { _eq: $id } }, _set: $body) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`

export const DELETE_PROJECT_DEVICE_DOCUMENT_CATEGORY = gql`
  mutation ($id: Int!) {
    delete_project_device_document_category(where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`
