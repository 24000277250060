<template>
  <v-navigation-drawer
    id="project-drawer-documents"
    v-model="displayDrawer"
    class="mt-8"
    color="tertiary"
    height="calc(100% - (32px * 2))"
    width="292px"
    absolute
    right
    stateless
  >
    <v-container>
      <v-row>
        <div class="text-overline">
          <span class="text-uppercase pl-3">
            {{ $tc('common.document', 2) }}
          </span>
        </div>
        <v-spacer />
      </v-row>
      <v-row>
        <project-document-list source="drawer" :is-view="isSelectedToView" />
      </v-row>
      <v-row>
        <v-toolbar color="tertiary" height="32px">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                :color="isSelectedToView ? 'secondary' : 'primary'"
                v-on="on"
                @click="clickIconToolbar('view')"
              >
                $iconEye
              </v-icon>
            </template>
            <span>{{ $t('common.see') }}</span>
          </v-tooltip>
          <v-spacer />
        </v-toolbar>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { DocumentMixin } from '@/mixins/DocumentMixin'
import ProjectDocumentList from '@/components/project/helper/ProjectDocumentList.vue'

export default {
  name: 'ProjectDrawerDocument',
  components: {
    ProjectDocumentList,
  },
  mixins: [DocumentMixin],
  data: function () {
    return {
      isSelectedToView: true,
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentDrawer: 'project/currentDrawer',
    }),
    displayDrawer: {
      get() {
        return this.currentDrawer === 'documents'
      },
      set(newName) {
        return newName
      },
    },
  },
  methods: {
    clickIconToolbar() {
      this.isSelectedToView = !this.isSelectedToView
    },
  },
}
</script>
