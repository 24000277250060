<template>
  <v-container fluid class="fill-height ma-0 pa-0">
    <device-dialog v-if="selectedDeviceId" :id="selectedDeviceId" v-model="deviceDialog" />
    <tooltip-view
      :current-device="currentDevice"
      :current-position="currentPosition"
      :size-map="sizeMap"
    />
    <v-row justify="center" align="center" no-gutters class="fill-height">
      <v-col class="fill-height pa-0 ma-0">
        <VlMap
          v-if="projection"
          ref="map"
          :key="projectionKey"
          :update-while-animating="true"
          :update-while-interacting="true"
          :style="{ cursor: MouseCursor }"
          class="fill-height ma-0"
          @click="clickMap"
          @pointermove="onMapPointerMove"
          @mounted="onMapMounted"
          @rendercomplete="onRenderComplete"
        >
          <VlView
            :projection="projection"
            :zoom.sync="zoom"
            :center.sync="center"
            :rotation.sync="rotation"
          />
          <VlInteractionSelect :features.sync="selectedFeatures" :hit-tolerance="10">
            <VlStyleFunc :function="DeviceStyleFunc()" />
          </VlInteractionSelect>
          <VlLayerImage id="xkcd">
            <VlSourceImageStatic
              v-if="imgUrl && projection"
              ref="image"
              :url="imgUrl"
              :projection="projection"
              :image-extent="imgExtent"
            />
          </VlLayerImage>
          <VlLayerVector
            v-for="(layer, i) in Layers"
            :id="layer.uuid"
            :key="i"
            :ref="layer.name"
            :visible="layer.options.visibility"
            :opacity="layer.options.opacity / 100"
          >
            <VlSourceVector v-if="layer.options.visibility" :features.sync="layer.features" />
            <VlStyleFunc v-if="loading" :function="DeviceStyleFunc()" />
          </VlLayerVector>
          <v-btn
            style="
              position: absolute;
              background-color: rgba(0, 60, 136, 0.5);
              border-radius: 4px;
              padding: 2px;
              top: 7em;
              left: 1em;
              z-index: 1;
            "
            absolute
            dark
            fab
            height="24px"
            icon
            left
            max-width="24px"
            outlined
            tile
            top
            x-small
            @click="homeMap()"
          >
            <v-icon>$iconHome</v-icon>
          </v-btn>
        </VlMap>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { eventBus } from '@/plugins/eventBus'
import { storageClient } from '@/plugins/storage'
import { DELETE_PROJECT_VIEW_DEVICE_COORDINATE } from '@/graphql/ProjectViewDeviceCoordinateQueries'
import DeviceDialog from '@/components/project/devices/dialog/DeviceDialog'
import TooltipView from '@/components/project/helper/TooltipView'
import { getCenter } from 'ol/extent'
import { Projection, addProjection } from 'ol/proj'
import { OlView } from '@/components/project/views/OlViewMixin'

export default {
  name: 'ProjectView2d',
  components: {
    DeviceDialog,
    TooltipView,
  },
  mixins: [OlView],
  data: () => ({
    currentDevice: undefined,
    currentPosition: undefined,
    customProj: undefined,
    deviceDialog: false,
    flyingAnimation: true,
    imgExtent: [0, 0, 2550, 1250],
    imgUrl: null,
    localMouseCursor: 'default',
    loading: false,
    projection: '',
    projectionKey: 0,
    selectedDeviceId: false,
    selectedFeatures: [],
    sizeMap: [1, 1],
  }),
  computed: {
    ...mapGetters({
      currentViewDefaultOptions: 'project/currentViewDefaultOptions',
    }),
  },
  watch: {
    currentDevice: function (val) {
      if (val) {
        this.sizeMap = this.$refs.map?.size
      }
    },
    currentView: function (val, oldVal) {
      if (val?.uuid !== oldVal?.uuid) {
        this.loading = false
        this.imgUrl = this.getImgUrl()
      }
    },
    customProj: function (val) {
      if (val) {
        this.projection = val.getCode()
      }
    },
    deviceDialog() {
      this.selectedFeatures = []
    },
    Layers: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.loading = true
        }
      },
      deep: true,
    },
    selectedFeatures: {
      handler() {
        this.currentPosition = this.currentDevice = undefined
        if (this.selectedFeatures.length > 0) {
          this.selectedDeviceId = this.selectedFeatures[0].properties.device_uuid
          if (this.viewTool === 'device-del') {
            this.deleteProjectDeviceViewCoordinate(
              this.selectedDeviceId,
              this.selectedFeatures[0].properties.device_name
            )
          } else {
            this.deviceDialog = true
          }
        }
      },
    },
    async imgUrl() {
      this.forceRerender()
      await this.recalculateProjection()

      if (
        this.imgSize[0] < this.center[0] ||
        this.imgSize[1] < this.center[1] ||
        this.center[0] === 0 ||
        this.center[1] === 0
      ) {
        this.center = getCenter(this.imgExtent)
        // setCurrentViewDefaultOptions is false for updateView in ProjectDrawerViewConfiguration
        this.setCurrentViewDefaultOptions(false)
      } else {
        this.center =
          Number.isNaN(this.center[0]) || Number.isNaN(this.center[1])
            ? getCenter(this.imgExtent)
            : this.center
      }
      this.$refs.map?.getLayers().forEach((layer) => {
        if (layer.constructor.name === 'VectorLayer') {
          layer.listeners_.postrender = []
        }
      })
    },
  },
  async mounted() {
    this.imgUrl = this.getImgUrl()
    await this.recalculateProjection()
  },
  methods: {
    ...mapActions({
      setCurrentViewDefaultOptions: 'project/setCurrentViewDefaultOptions',
    }),
    forceRerender() {
      this.projectionKey += 1
    },
    getImage() {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve([img.width, img.height])
        img.onerror = reject
        img.src = storageClient.getUrl(this.currentView.options.key, this.currentView.options.token)
      })
    },
    getImgUrl() {
      let result = null
      if (this.currentView.options.key) {
        result = storageClient.getUrl(this.currentView.options.key, this.currentView.options.token)
      }
      return result
    },
    homeMap() {
      this.zoom = this.currentViewDefaultOptions.zoom
      this.center = this.currentViewDefaultOptions.center
      if (this.$route.name === 'ViewFocus') this.$router.push('/project')
    },
    async clickMap(evt) {
      if (this.viewTool === 'device-pos') {
        eventBus.$emit('viewClick', evt)
      }
    },
    async deleteProjectDeviceViewCoordinate(deviceUuid, deviceName) {
      const result = await this.$apollo
        .mutate({
          mutation: DELETE_PROJECT_VIEW_DEVICE_COORDINATE,
          variables: {
            where: {
              project_uuid: {
                _eq: this.$store.getters['project/currentProjectId'],
              },
              project_view_uuid: {
                _eq: this.$store.getters['project/currentView'].uuid,
              },
              project_device_uuid: {
                _eq: deviceUuid,
              },
            },
          },
        })
        .then((data) => {
          return data
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'DELETE_PROJECT_VIEW_DEVICE_COORDINATE ' + error.message,
            { color: 'error' }
          )
          throw error
        })

      if (result) {
        const removal = this.$t('common.removalFormatting', {
          attribute: deviceName,
        })
        this.$root.$dialogLoader.showSnackbar(removal, { color: 'success' })
        this.setViewTool('select')
      }
    },
    async onMapMounted() {
      await this.recalculateProjection()
      this.$refs.map?.$map.render()
    },
    async recalculateProjection() {
      if (!this.currentView.options.key) {
        return
      }
      const size = await this.getImage()
      this.imgSize = size
      this.imgExtent = [0, 0, size[0], size[1]]
      this.customProj = new Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: this.imgExtent,
      })
      addProjection(this.customProj)
    },
  },
}
</script>
