<template>
  <v-card>
    <v-card-title class="text-body-1 white--text secondary py-1">
      {{ $tc('user.siteLanguage', 1) }}
    </v-card-title>
    <v-card-text class="pt-4 py-4 pb-0">
      <v-select
        v-model="userLang"
        :items="languageArray"
        :value="$vuetify.lang.current"
        :label="$t('user.chooseLanguageDefault')"
        item-color="quaternary"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CrmLanguageEditor',
  props: {
    isCurrentUser: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    crmLocal() {
      return this.value
    },
    languageArray() {
      return Object.keys(this.$vuetify.lang.locales)
    },
    userLang: {
      get() {
        const lang = this.isCurrentUser
          ? this.$store.getters['auth/user'].profile?.lang
          : this.crmLocal?.profile?.lang
        return lang || 'fr'
      },
      set(val) {
        if (this.isCurrentUser) {
          this.$vuetify.lang.current = val
          this._i18n.locale = val
          this.$store.dispatch('auth/updateUserProfile', { lang: val })
        }
        if (this.crmLocal?.profile) {
          this.crmLocal.profile.lang = val
        } else {
          this.crmLocal.profile = {
            lang: val,
          }
        }
        this.$emit('input', this.crmLocal)
      },
    },
  },
}
</script>