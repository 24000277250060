<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" md="6">
        <v-data-table
          :headers="headers"
          :items="localFieldConfig.list"
          fixed-header
          height="300px"
          dense
          hide-default-footer
        >
          <template #[`item.actions`]="{ item }">
            <v-icon small @click="deleteValue(item)"> $iconDelete </v-icon>
          </template>
          <template #footer>
            <v-row>
              <v-col cols="12" md="6">
                <form-error
                  :validator="$v.newvalue"
                  :attribute="$t('common.newKey')"
                  :messages="messages"
                >
                  <v-text-field
                    v-model="newvalue"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :label="$t('common.newKey')"
                    clearable
                    @input="$v.newvalue.$touch()"
                    @blur="$v.newvalue.$touch()"
                  />
                </form-error>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newlabel"
                  :label="$t('common.newValue')"
                  clearable
                  append-outer-icon="mdi-plus"
                  @click:append-outer="addNewValue"
                  @keydown.enter="addNewValue"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="6">
        <v-checkbox
          v-model="localFieldConfig.multiple"
          class="mx-2"
          label="Multiple"
          @change="localDefaultValue.value = []"
        />
        <v-select
          v-model="localDefaultValue.value"
          :items="localFieldConfig.list"
          item-text="label"
          item-index="value"
          :label="$t('common.defaultValue')"
          :multiple="localFieldConfig.multiple"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const isin = (value, vm) => {
  return !vm.fieldConfig.list.some((item) => item.value === value)
}

export default {
  name: 'KeyValueListFieldConfig',
  props: {
    fieldConfig: {
      type: Object,
      default() {
        return {
          list: [
            { label: 'item1', value: '1' },
            { label: 'item2', value: '2' },
          ],
          multiple: false,
        }
      },
      defaultValue: {
        type: Object,
        default() {
          return {
            value: ['item1'],
          }
        },
      },
    },
  },
  data() {
    return {
      newvalue: '',
      newlabel: '',
      headers: [
        {
          text: 'Clé',
          sortable: false,
          value: 'value',
        },
        {
          text: 'Etiquette',
          sortable: false,
          value: 'label',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      localDefaultValue: this.defaultValue,
      localFieldConfig: this.fieldConfig,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    localFieldConfig: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localFieldConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
    localDefaultValue: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localFieldConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
  },
  validations() {
    return {
      newvalue: {
        isin,
      },
    }
  },
  mounted() {
    this.$emit('input', {
      fieldConfig: this.localFieldConfig,
      defaultValue: this.localDefaultValue,
    })
  },
  methods: {
    addNewValue() {
      if (this.$v.$invalid) return
      this.localFieldConfig.list.push({
        value: this.newvalue,
        label: this.newlabel,
      })
      this.newvalue = ''
      this.newlabel = ''
    },
    deleteValue(item) {
      this.localFieldConfig.list = this.localFieldConfig.list.filter((i) => i.value !== item.value)
    },
  },
}
</script>

<style lang="scss">
.rolloveroff:hover {
  background-color: transparent !important;
}
</style>
