import gql from 'graphql-tag'

// queries
export const GET_CRM_EMAILS = gql`
  query crm_email($where: crm_email_bool_exp = {}) {
    crm_email_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    crm_email(where: $where) {
      uuid
      email
      type
      crm_company_uuid
      crm_company {
        uuid
        name
      }
      crm_contact_uuid
      crm_contact {
        uuid
        lastname
      }
      created_at
      updated_at
    }
  }
`

export const POST_CRM_EMAIL = gql`
  mutation ($objects: [crm_email_insert_input!]!) {
    insert_crm_email(objects: $objects) {
      returning {
        uuid
        email
        type
        crm_company_uuid
        crm_company {
          uuid
          name
        }
        crm_contact_uuid
        crm_contact {
          uuid
          lastname
        }
        created_at
        updated_at
      }
    }
  }
`

export const DELETE_CRM_EMAIL = gql`
  mutation ($uuid: uuid!) {
    delete_crm_email(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
      }
    }
  }
`
