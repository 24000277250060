<template>
  <v-container fluid class="mx-auto">
    <v-banner single-line elevation="2" rounded="4">
      <time-interval v-model="optionsTimeInterval" />
    </v-banner>
    <graph-multi :options-params.sync="optionsParams" />
  </v-container>
</template>

<script>
import GraphMulti from '@/components/project/graphs/GraphMulti'
import TimeInterval from '@/components/helper/TimeInterval'

export default {
  name: 'DeviceDialogTabHubGraph',
  components: {
    GraphMulti,
    TimeInterval,
  },
  props: {
    hub: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    optionsParams: {},
    optionsTimeInterval: {},
  }),
  watch: {
    'hub.id': function (val, oldVal) {
      if (oldVal !== val) {
        this.optionsParams = this.getOptionsParamsDefault()
        this.optionsTimeInterval = {}
        delete this.optionsParams.newOptions
      }
    },
    optionsTimeInterval: {
      handler: function (val) {
        this.optionsParams = Object.assign({}, this.optionsParams, val)
        this.updateGraph()
      },
      deep: true,
    },
  },
  mounted() {
    this.optionsParams = this.getOptionsParamsDefault()
  },
  methods: {
    getOptionsParamsDefault() {
      const options = {
        hub_id: this.hub.id,
        hub_name: this.hub.name,
      }
      return options
    },
    updateGraph() {
      this.optionsParams.newOptions = true
    },
  },
}
</script>
