<template>
  <v-card>
    <v-card-title class="text-body-1 white--text secondary py-1">
      {{ $t('contact.avatar') }}
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row :class="isAvatar ? 'align-center' : 'align-baseline'">
        <v-col cols="8" class="py-0">
          <v-file-input
            v-model="avatarFile"
            :placeholder="$t('contact.placeholder.avatar')"
            :rules="rules"
            accept="image/*"
            class="mt-0 pt-0"
            prepend-icon="$iconCamera"
            full-width
          />
        </v-col>
        <v-col cols="4" class="py-0">
          <div v-if="isAvatar">
            <v-avatar class="mx-auto d-block" size="64">
              <img :src="getAvatarUrl()" :lazy-src="getAvatarUrl()" :alt="avatarName" />
            </v-avatar>
          </div>
          <div v-else class="mx-auto text-center">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon v-on="on">$iconUserOff</v-icon>
              </template>
              <span>{{ $t('contact.noAvatar') }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { storageClient } from '@/plugins/storage'

export default {
  name: 'CrmAvatarEditor',
  props: {
    isCurrentUser: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      avatarFile: null,
      rules: [(value) => !value || value.size < 2000000 || this.$t('contact.avatarRule')],
    }
  },
  computed: {
    crmLocal() {
      return this.value
    },
    avatarName() {
      const name = this.isCurrentUser
        ? this.$store.state.auth.user?.profile?.avatar?.originalname
        : this.crmLocal?.profile?.avatar?.originalname
      return name || ''
    },
    isAvatar() {
      const store =
        this.$store.state.auth.user?.profile?.avatar?.key &&
        this.$store.state.auth.user?.profile?.avatar?.token
      const db = this.crmLocal?.profile?.avatar?.key && this.crmLocal?.profile?.avatar?.token
      return this.isCurrentUser ? store : db
    },
  },
  watch: {
    avatarFile: function (val, oldVal) {
      if (val !== oldVal) {
        const avatarNew = val?.size < 2000000 ? val : null
        this.crmLocal.avatarFile = avatarNew
        this.crmLocal.isAvatar = avatarNew ? true : false
        this.$emit('input', this.crmLocal)
      }
    },
  },
  methods: {
    getAvatarUrl() {
      if (this.isAvatar) {
        const avatar = this.isCurrentUser
          ? {
              key: this.$store.state.auth.user.profile.avatar.key,
              token: this.$store.state.auth.user.profile.avatar.token,
            }
          : {
              key: this.crmLocal.profile.avatar.key,
              token: this.crmLocal.profile.avatar.token,
            }

        return storageClient.getUrl(avatar.key, avatar.token)
      }
    },
  },
}
</script>