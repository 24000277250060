import gql from 'graphql-tag'

// queries
export const GET_ENUM_DEVICE_SET_PARAMS_TYPE = gql`
  query enum_device_set_params_type {
    enum_device_set_params_type {
      value
      comment
    }
  }
`

export const GET_LIB_DEVICE_PARAMETER = gql`
  query ($id: Int!) {
    lib_device_parameter_by_pk(id: $id) {
      id
      name
      config_label
      description
      help
      lib_device_parameter_tags {
        lib_tag {
          id
          tag_value
        }
      }
      updated_at
      field_type
      field_config
      default_value
      created_at
      constant
      target
    }
  }
`

export const GET_LIB_DEVICE_PARAMETERS = gql`
  query lib_device_parameter(
    $where: lib_device_parameter_bool_exp
    $order_by: [lib_device_parameter_order_by!]
    $limit: Int
    $offset: Int
  ) {
    lib_device_parameter_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    lib_device_parameter(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      id
      name
      config_label
      description
      field_type
      lib_device_parameter_tags {
        lib_tag {
          id
          tag_value
        }
      }
      updated_at
      created_at
      constant
      target
    }
  }
`

// mutations
export const PATCH_LIB_DEVICE_PARAMETER = gql`
  mutation (
    $id: Int!
    $lib_device_parameter: lib_device_parameter_set_input!
    $tag_relation: [lib_device_parameter_tag_insert_input!]!
  ) {
    delete_lib_device_parameter_tag(where: { lib_device_parameter_id: { _eq: $id } }) {
      affected_rows
    }
    insert_lib_device_parameter_tag(objects: $tag_relation) {
      affected_rows
    }
    update_lib_device_parameter(where: { id: { _eq: $id } }, _set: $lib_device_parameter) {
      affected_rows
      returning {
        id
        name
        config_label
        description
        field_type
        lib_device_parameter_tags {
          lib_tag {
            id
            tag_value
          }
        }
        updated_at
        created_at
        constant
        target
      }
    }
  }
`

export const POST_LIB_DEVICE_PARAMETER = gql`
  mutation ($objects: [lib_device_parameter_insert_input!]!) {
    insert_lib_device_parameter(objects: $objects) {
      returning {
        id
        name
        config_label
        description
        field_type
        lib_device_parameter_tags {
          lib_tag {
            id
            tag_value
          }
        }
        updated_at
        created_at
        constant
        target
      }
    }
  }
`

export const DELETE_LIB_DEVICE_PARAMETER = gql`
  mutation ($id: Int!) {
    delete_lib_device_parameter(where: { id: { _eq: $id } }) {
      returning {
        id
        name
        created_at
        updated_at
      }
    }
  }
`
