<template>
  <v-combobox
    v-model="documentCategorySelect"
    v-bind="$attrs"
    :items="documentCategorysItems"
    :label="$tc('common.category', 1)"
    :placeholder="$tc('common.category', 1)"
    :search-input.sync="documentCategorySearch"
    item-text="name"
    item-value="id"
    :hide-no-data="!documentCategorySearch"
    return-object
    v-on="$listeners"
    @change="selectItem"
  >
    <template #no-data>
      <v-list-item>
        <v-btn
          v-if="typeof documentCategorySearch === 'string' && documentCategorySearch.length > 1"
          depressed
          small
          @click="addDocumentCategory(documentCategorySearch)"
        >
          {{ $t('common.create') }}
        </v-btn>
        <span class="ml-2">{{ documentCategorySearch }}</span>
      </v-list-item>
    </template>
    <template #item="{ index, item }">
      <v-list-item class="px-0" dense>
        <v-list-item-content v-if="editing === item" @click.stop>
          <v-text-field
            v-model="editing.name"
            autofocus
            flat
            background-color="transparent"
            hide-details
            @keyup.enter="edit(index, item)"
          />
        </v-list-item-content>
        <v-list-item-content v-else>
          {{ item.name }}
        </v-list-item-content>
      </v-list-item>
      <v-spacer />
      <v-list-item-action class="d-inline-block" @click.stop>
        <v-btn icon x-small @click.stop.prevent="edit(index, item)">
          <v-icon>
            {{ editing !== item ? '$iconEdit' : '$iconCheck' }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="editing !== item"
          class="mx-3"
          icon
          x-small
          @click.stop.prevent="deleteDocumentCategory(index, item)"
        >
          <v-icon> $iconDelete </v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
import {
  GET_PROJECT_DEVICE_DOCUMENT_CATEGORIES,
  POST_PROJECT_DEVICE_DOCUMENT_CATEGORY_ONE,
  UPDATE_PROJECT_DEVICE_DOCUMENT_CATEGORY,
  DELETE_PROJECT_DEVICE_DOCUMENT_CATEGORY,
} from '@/graphql/ProjectDeviceDocumentCategoryQueries'

export default {
  name: 'ProjectDocumentCategorySelector',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    documentCategorysItems: [],
    documentCategorySelect: {},
    documentCategorySearch: '',
    editing: null,
    firstload: true,
  }),
  computed: {
    modificationCompleted() {
      return (
        this.$tc('common.category', 1) + ' ' + this.$t('common.modificationCompleted').toLowerCase()
      )
    },
  },
  watch: {
    documentCategorySearch(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch()
      }
    },
    value: {
      handler: 'loadDocumentCategory',
      immediate: true,
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      } else {
        this.editing = null
        this.index = -1
        this.updateDocumentCategory(item)
      }
    },
    loadDocumentCategory() {
      this.documentCategorySelect = this.value
    },
    selectItem() {
      if (this.editing) {
        return false
      }

      this.$emit('input', this.documentCategorySelect)
      this.documentCategorySearch = ''
    },
    async addDocumentCategory(val) {
      if (typeof val !== 'string') return
      await this.$apollo
        .mutate({
          mutation: POST_PROJECT_DEVICE_DOCUMENT_CATEGORY_ONE,
          variables: {
            object: { name: val },
          },
          // eslint-disable-next-line
          update: ({
            data: { insert_project_device_document_category_one },
          }) => {
            this.$emit('input', insert_project_device_document_category_one)
            this.documentCategorySearch = ''

            this.$root.$dialogLoader.showSnackbar(this.modificationCompleted, {
              color: 'success',
            })
          },
          refetchQueries: [
            {
              query: GET_PROJECT_DEVICE_DOCUMENT_CATEGORIES,
              variables: {
                order_by: { name: 'asc' },
              },
            },
          ],
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'POST_PROJECT_DEVICE_DOCUMENT_CATEGORY_ONE ' + error.message,
            { color: 'error' }
          )
          throw error
        })
      this.querySearch()
    },
    async deleteDocumentCategory(item) {
      await this.$apollo
        .mutate({
          mutation: DELETE_PROJECT_DEVICE_DOCUMENT_CATEGORY,
          variables: {
            id: item.id,
          },
          // eslint-disable-next-line
          update: (cache) => {
            cache.reset()
            this.$root.$dialogLoader.showSnackbar(this.modificationCompleted, {
              color: 'success',
            })
          },
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'DELETE_PROJECT_DEVICE_DOCUMENT_CATEGORY ' + error.message,
            { color: 'error' }
          )
          throw error
        })
      this.querySearch()
    },
    async querySearch() {
      this.loading = true
      const rv = await this.$apollo.query({
        query: GET_PROJECT_DEVICE_DOCUMENT_CATEGORIES,
        variables: {
          order_by: { name: 'asc' },
        },
      })
      this.documentCategorysItems = rv.data.project_device_document_category
      this.loading = false
    },
    async updateDocumentCategory(item) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_PROJECT_DEVICE_DOCUMENT_CATEGORY,
          variables: {
            id: parseInt(item.id),
            body: { name: item.name },
          },
          // eslint-disable-next-line
          update: (
            cache,
            { data: { update_project_device_document_category } }
          ) => {
            cache.reset()
            // eslint-disable-next-line
            this.documentCategorySelect =
              update_project_device_document_category.returning[0];
            this.$root.$dialogLoader.showSnackbar(this.modificationCompleted, {
              color: 'success',
            })
          },
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'UPDATE_PROJECT_DEVICE_DOCUMENT_CATEGORY ' + error.message,
            { color: 'error' }
          )
          throw error
        })
      this.querySearch()
    },
  },
}
</script>
