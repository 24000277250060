<template lang="html">
  <v-autocomplete
    v-model="selDevices"
    :items="devices"
    :label="localLabel"
    :readonly="isReadonly"
    :search-input.sync="search"
    chips
    hide-no-data
    hide-selected
    color="blue-grey lighten-2"
    multiple
    return-object
    item-text="name"
    item-value="id"
    v-bind="$attrs"
    @change="selectItem"
    v-on="$listeners"
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        :close="!isReadonly"
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-icon>
            {{ getIcon(data.item.device_type) }}
          </v-icon>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>
    <template #item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content>
          {{ data.item }}
        </v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-icon>
          <v-icon>
            {{ getIcon(data.item.device_type) }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
import { ProjectMixin } from '@/mixins/ProjectMixin'

export default {
  mixins: [ProjectMixin],
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String],
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    devices: [],
    search: null,
    selDevices: [],
  }),
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
    localLabel() {
      return this.label ?? this.$tc('project.device', 2)
    },
  },
  watch: {
    ProjectDevices: {
      handler: function (val) {
        this.devices = val
      },
      deep: true,
    },
    value: {
      handler: function () {
        if (this.value) {
          this.selDevices = this.value.map((v) => v?.project_device)
        }
      },
      immediate: true,
    },
  },
  methods: {
    getIcon(type) {
      let result = '?'
      switch (type) {
        case 'hub':
          result = '$iconStation'
          break
        case 'plot':
          result = '$iconPlot'
          break
        case 'sensor':
          result = '$iconSensor2'
          break
        case 'transmitter':
          result = '$iconTransmitter'
          break
        default:
          break
      }
      return result
    },
    remove(item) {
      const index = this.selDevices.findIndex((selDevice) => selDevice.uuid === item.uuid)
      if (index >= 0) this.selDevices.splice(index, 1)
      this.selectItem()
    },
    selectItem() {
      const list = []
      this.selDevices.forEach(function (item) {
        list.push({ project_device: item })
      })
      this.$emit('input', list)
      this.search = ''
    },
  },
}
</script>
