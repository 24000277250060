<template>
  <v-container fluid class="fill-height pa-1">
    <v-row class="fill-height" justify="center" no-gutters>
      <device-dialog
        :id="SelectedNodeId"
        v-model="deviceDialog"
        :project-id="$store.getters['project/currentProjectId']"
      />
      <d3-network
        v-if="devices && project_device_link"
        ref="net"
        :net-nodes="devices"
        :net-links="project_device_link"
        :options="options"
        :node-cb="node_cb"
        style="min-height: 90vh; min-width: 99vw"
        @node-dbclick="clickNode"
      />
    </v-row>
  </v-container>
</template>

<script>
import D3Network from 'vue-d3-network'
import DeviceType from '@/components/device_library/DeviceType'
import { GET_PROJECT_DEVICE_LINKS } from '@/graphql/ProjectDeviceLinkQueries'
import DeviceDialog from '@/components/project/devices/dialog/DeviceDialog'
import { ProjectMixin } from '@/mixins/ProjectMixin'

export default {
  name: 'ProjectViewNet',
  components: {
    D3Network,
    DeviceDialog,
  },
  mixins: [ProjectMixin],
  apollo: {
    project_device_link: {
      query: GET_PROJECT_DEVICE_LINKS,
      variables() {
        return {
          where: {
            project_id: {
              _eq: this.$store.getters['project/currentProjectId'],
            },
          },
        }
      },
      update(data) {
        data.project_device_link.forEach(function (deviceLink) {
          deviceLink.sid = deviceLink.project_device1_id
          deviceLink.tid = deviceLink.project_device2_id
        })
        return data.project_device_link
      },
    },
  },
  data: () => ({
    deviceDialog: false,
    devices: [],
    SelectedNodeId: false,
  }),
  computed: {
    options() {
      return {
        force: 3000,
        nodeSize: this.nodeSize,
        nodeLabels: true,
        linkLabels: true,
        canvas: this.canvas,
        size: {},
      }
    },
  },
  watch: {
    ProjectDevices: {
      handler: function (val) {
        this.devices = val
      },
      deep: true,
    },
  },
  methods: {
    node_cb(node) {
      if (!node.oldname) {
        node.oldname = node.name
      }
      node.name = `${node.oldname}`
      node._cssClass = `${node.device_type}`
      node._size = this.getDeviceTypeData(node.device_type)?.size || 20
      node.svgSym = this.getDeviceTypeData(node.device_type)?.icon || null
      node._width = node._height = 30
      return node
    },
    getDeviceTypeData(deviceType) {
      return DeviceType.find((x) => x.name === deviceType)
    },
    link_cb(link) {
      return link
    },
    clickNode(event, node) {
      const tool = this.$store.getters['project/viewTool']
      switch (tool) {
        case 'pin':
          this.pinNode(node)
          break
        case 'pointer':
          this.SelectedNodeId = node.id
          this.deviceDialog = true
          break
        default:
          this.SelectedNodeId = node.id
          this.deviceDialog = true
          break
      }
    },
  },
}
</script>

<style lang="css">
.effector,
.other,
.plot,
.sensor,
.transmitter {
  fill: #81d4fa;
  stroke: #039be5;
  stroke-width: 1px;
  cursor: pointer;
}
.hub {
  fill: #1565c0;
  stroke: #002233;
  stroke-width: 1px;
  cursor: pointer;
}
.link {
  stroke: black;
  stroke-width: 1px;
  opacity: 0.6;
}
.node {
  opacity: 0.8;
  pointer-events: bounding-box;
}
.node:hover {
  opacity: 1;
}
</style>
