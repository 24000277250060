import { GET_PROJECT_VIEW_LAYERS, POST_PROJECT_VIEW_LAYER } from '@/graphql/ProjectViewLayerQueries'
import { POST_PROJECT_GEOJSON_OBJECT } from '@/graphql/ProjectGeoJSonObject'

export const geojson = {
  methods: {
    async addGeoJSONLayer(e) {
      try {
        const content = JSON.parse(e.target.result)
        const geoJsonLayer = {
          project_uuid: this.$store.getters['project/currentProjectId'],
          project_view_uuid: this.$store.getters['project/currentView'].uuid,
          name: content.name,
          type: 'geojson',
          options: {
            bgcolor: '#FF000022',
            color: '#F00',
            visibility: true,
            opacity: 100,
          },
        }

        const geojsonObject = {
          data: content,
        }

        await this.$apollo.mutate({
          mutation: POST_PROJECT_GEOJSON_OBJECT,
          variables: {
            objects: geojsonObject,
          },
          // eslint-disable-next-line
          update: (cache, { data: { insert_project_geojson_object } }) => {
            // eslint-disable-next-line
            geoJsonLayer.options.geojsonUuid = insert_project_geojson_object.returning[0].uuid
            this.$apollo.mutate({
              mutation: POST_PROJECT_VIEW_LAYER,
              variables: {
                layers: geoJsonLayer,
              },
              refetchQueries: [
                {
                  query: GET_PROJECT_VIEW_LAYERS,
                  variables: {
                    where: {
                      project_view_uuid: {
                        _eq: this.$store.getters['project/currentView'].uuid,
                      },
                    },
                    order_by: { vorder: 'asc' },
                  },
                },
              ],
            })
          },
        })
      } catch (error) {
        const errorImportFile = this.$t('common.errorImportFile')
        this.$root.$dialogLoader.showSnackbar(errorImportFile, {
          color: 'error',
        })
        throw error
      }
    },
  },
}
