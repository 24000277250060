<template>
  <v-select
    v-model="localId"
    v-bind="$attrs"
    :items="eventCategories"
    :label="localLabel"
    :readonly="isReadonly"
    dense
    item-text="name"
    item-value="id"
    item-color="color"
    v-on="$listeners"
    @change="selectItem"
  >
    <template #selection="data">
      <v-list-item-avatar size="15" :color="data.item.color" />
      <v-list-item-content>
        <v-list-item-title>
          {{ data.item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template #item="data, index">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content>
          {{ data.item }}
        </v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar size="15" :color="data.item.color" />
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
        </v-list-item-content>
        <v-spacer />
        <v-list-item-action @click.stop>
          <v-btn icon @click.stop.prevent="edit(index, data)">
            <v-icon>$iconEdit</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </template>
    <template v-if="!isReadonly" #append-outer>
      <v-dialog v-model="dialogCategory" persistent max-width="600px">
        <template #activator="{ on, attrs }">
          <v-btn icon class="ma-0" v-bind="attrs" v-on="on">
            <v-icon>$iconPlus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ $tc('common.category', 1) }} :</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-col cols="12" sm="12" md="12">
                <form-error
                  :validator="$v.category.name"
                  :attribute="$t('common.name')"
                  :messages="messages"
                >
                  <v-text-field
                    v-model="category.name"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :label="$t('common.name') + ' *'"
                    @input="$v.category.name.$touch()"
                    @blur="$v.category.name.$touch()"
                  />
                </form-error>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <form-error
                  :validator="$v.category.color"
                  :attribute="$t('calendar.color')"
                  :messages="messages"
                >
                  <v-text-field
                    v-model="category.color"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    hide-details
                    :label="$t('calendar.color')"
                    outlined
                    @input="$v.category.color.$touch()"
                    @blur="$v.category.color.$touch()"
                  >
                    <template #append>
                      <v-menu
                        v-model="menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template #activator="{ on }">
                          <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="category.color" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </form-error>
              </v-col>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="closeForm()">
              {{ $t('common.close') }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveForm()">
              {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-select>
</template>

<script>
import {
  GET_EVENT_CATEGORIES,
  INSERT_EVENT_CATEGORY_ONE,
  UPDATE_EVENT_CATEGORY_BY_PK,
} from '@/graphql/EventCategoryQueries'
import { required } from 'vuelidate/lib/validators'

export default {
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String],
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  apollo: {
    eventCategories: {
      query: GET_EVENT_CATEGORIES,
      variables() {
        return {
          order_by: { name: 'asc' },
        }
      },
      update(data) {
        return data?.event_category
      },
    },
  },
  data: () => ({
    dialogCategory: false,
    menu: false,
    eventCategories: [],
    category: {},
    category_default: {
      id: null,
      name: '',
      color: '#FF9600',
    },
    categorySelectedId: null,
  }),
  validations: {
    category: {
      name: { required },
      color: { required },
    },
  },
  computed: {
    localLabel() {
      return this.label ?? this.$tc('common.category', 2)
    },
    localId: {
      get() {
        return this.value
      },
      set(val) {
        this.categorySelectedId = val
      },
    },
    swatchStyle() {
      const { menu } = this
      return {
        backgroundColor: this.category.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
    messages() {
      return this.$t('validationMessage')
    },
  },
  mounted() {
    this.category = this.category_default
  },
  methods: {
    closeForm() {
      this.category = this.category_default
      this.dialogCategory = false
    },
    edit(data) {
      this.category = data.item
      this.dialogCategory = true
    },
    async saveForm() {
      const errorForm = this.$t('common.errorOnForm')
      const modificationCompleted = this.$t('common.modificationCompleted')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$root.$dialogLoader.showSnackbar(errorForm, { color: 'error' })
        return
      }

      if (!this.category.id) {
        await this.create()
      } else {
        await this.update()
      }

      this.closeForm()
      this.$root.$dialogLoader.showSnackbar(modificationCompleted, {
        color: 'success',
      })
    },
    selectItem() {
      this.$emit('input', this.categorySelectedId)
    },
    async create() {
      const category = Object.assign({}, this.category)
      delete category.id

      await this.$apollo
        .mutate({
          mutation: INSERT_EVENT_CATEGORY_ONE,
          variables: {
            object: category,
          },
          // eslint-disable-next-line
          update: (cache, { data: { insert_event_category_one } }) => {
            const categoryCache = cache.readQuery({
              query: GET_EVENT_CATEGORIES,
              variables: {
                order_by: { name: 'asc' },
              },
            })

            categoryCache.event_category.push(insert_event_category_one)
            categoryCache.event_category_aggregate.aggregate.totalCount++

            cache.writeQuery({
              query: GET_EVENT_CATEGORIES,
              data: categoryCache,
            })
          },
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'create INSERT_EVENT_CATEGORY_ONE: ' + error.message,
            { color: 'error' }
          )
          throw error
        })
    },
    async update() {
      const category = Object.assign({}, this.category)
      delete category.__typename

      await this.$apollo
        .mutate({
          mutation: UPDATE_EVENT_CATEGORY_BY_PK,
          variables: {
            id: this.category.id,
            object: category,
          },
          refetchQueries: [
            {
              query: GET_EVENT_CATEGORIES,
            },
          ],
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'update UPDATE_EVENT_CATEGORY_BY_PK: ' + error.message,
            { color: 'error' }
          )
          throw error
        })
    },
  },
}
</script>
