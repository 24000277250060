var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"project-drawer-view-layer"}},[_c('layer-options-dialog',{attrs:{"layer":_vm.selectedLayer},model:{value:(_vm.layerOptionDialogShow),callback:function ($$v) {_vm.layerOptionDialogShow=$$v},expression:"layerOptionDialogShow"}}),_c('add-system-layer-dialog',{model:{value:(_vm.addSystemLayerDialogShow),callback:function ($$v) {_vm.addSystemLayerDialogShow=$$v},expression:"addSystemLayerDialogShow"}}),_c('v-toolbar',{staticClass:"my-1 px-1",attrs:{"color":"tertiary","height":"32px"}},[(_vm.$store.getters['auth/isAuth'](['project_view_layer', 'update']))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.changeAllVisibility()}}},[_vm._v(_vm._s(_vm.allVisibility ? '$iconEye' : '$iconEyeOff'))])],1)]}}],null,false,4044348432)},[_c('span',{domProps:{"textContent":_vm._s(_vm.allVisibility ? _vm.$t('common.hideAll') : _vm.$t('common.showAll'))}})]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","transition":"slide-y-transition","light":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.getters['auth/isAuth'](['project_view_layer', 'create']))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g({},on),[_vm._v("$iconPlus")])],1):_vm._e()]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.AddSystemLayer()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$iconLayerSystem")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('project.addSystemLayer')))])],1),(_vm.type === 'map')?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.$refs.geojson.click()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$iconGeojsonAdd")])],1),_c('input',{ref:"geojson",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"application/geo+json,.geojson"},on:{"change":function($event){return _vm.handleGeojsonUpload()}}}),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('project.addGeojsonLayer')))])],1):_vm._e()],1)],1)],1),_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},[_c('draggable',{on:{"change":_vm.onMove},model:{value:(_vm.Layers),callback:function ($$v) {_vm.Layers=$$v},expression:"Layers"}},_vm._l((_vm.Layers),function(layer,i){return _c('div',{key:i,staticClass:"mb-1",staticStyle:{"border":"1px solid #5d5d5d"}},[_c('v-list-item',{staticClass:"pa-0",style:(!layer.options.visibility ? 'opacity: 25%;' : '')},[_c('v-list-item-icon',{staticClass:"align-center mr-2"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.getters['auth/isAuth'](['project_view_layer', 'update']))?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.changeVisibility(i)}}},[_vm._v(_vm._s(layer.options.visibility ? '$iconEye' : '$iconEyeOff'))])],1):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(layer.options.visibility ? _vm.$t('common.hide') : _vm.$t('common.show'))}})]),_c('div',{staticClass:"previewLayer"},[_c('div',{style:(_vm.getLayerStyle(layer))})])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(layer.name))])],1),_c('v-list-item-avatar',{attrs:{"height":"20px"}},[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$store.getters['auth/isAuth'](['project_geojson_object', 'update']))?_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" $iconSubMenu ")]):_vm._e()]}}],null,true)},[_c('v-list',{attrs:{"dense":"","min-width":"200px"}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.openLayerDialog(layer)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$iconSettings")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('common.options')))])],1),(layer.type === 'geojson')?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.exportGeoJson(layer)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" $iconGeojsonExport ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('project.exportGeoJSON')))])],1):_vm._e(),(_vm.$store.getters['auth/isAuth'](['project_geojson_object', 'delete']))?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.deleteLayer(layer)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v("$iconLayerDel")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('common.delete')))])],1):_vm._e()],1)],1)],1)],1)],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }