<template>
  <v-select
    v-model="finalValue"
    :items="fieldConfig.list"
    item-text="label"
    item-index="value"
    :multiple="fieldConfig.multiple"
  />
</template>

<script>
export default {
  name: 'KeyValueListField',
  props: {
    name: {
      type: String,
      default: '',
    },
    fieldConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {}
      },
    },
    value: {
      type: [Array, String],
      default() {
        return this.defaultValue.value
      },
    },
  },
  data: function () {
    return {
      finalValue: this.value,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.$emit('input', { value: this.finalValue })
      },
    },
  },
  mounted() {
    this.$emit('input', { value: this.finalValue })
  },
}
</script>
