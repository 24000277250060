<template>
  <v-dialog
    v-model="displayDialog"
    class="ma-0 pa-0"
    fullscreen
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card class="ma-0 pa-0">
      <v-card-text class="overflow-y-hidden pa-0" style="position: relative">
        <div v-if="localImage" class="cropper-content cropper-info pa-2 primary">
          <span>{{ localImage.title }}</span>
          <span v-if="localImage.categoryName">&nbsp;({{ localImage.categoryName }})</span>
        </div>
        <cropper
          v-if="displayCropper"
          ref="cropper"
          class="cropper"
          :auto-zoom="true"
          :default-size="defaultSize"
          :src="optionsCropper.img"
          :style="styleCropper()"
          :transitions="true"
          foreground-class="cropper-foreground"
          image-restriction="fit-area"
        />
        <div class="cropper-content cropper-vertical-buttons primary">
          <v-row align="center">
            <v-col>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="displayDialog = false">
                    <v-icon v-on="on">$iconClose</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.close') }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="maximize()">
                    <v-icon v-on="on">$iconHome</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.original') }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="center()">
                    <v-icon v-on="on">$iconCenterFocus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.center') }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="resize(2, 2)">
                    <v-icon v-on="on">$iconArrowExpandAll</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.resize') }} (x2)</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="resize(1, 2)">
                    <v-icon v-on="on">$iconArrowUpDown</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.resizeHeight') }} (x2)</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="resize(2, 1)">
                    <v-icon v-on="on">$iconArrowLeftRight</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.resizeWidth') }} (x2)</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="resize(0.5, 0.5)">
                    <v-icon v-on="on">$iconArrowCollapseAll</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.resize') }} (x1/2)</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="flip(true, false)">
                    <v-icon v-on="on">$iconFlipHorizontal</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.flipHorizontal') }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="flip(false, true)">
                    <v-icon v-on="on">$iconFlipVertical</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.flipVertical') }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="rotate(90)">
                    <v-icon v-on="on">$iconReload</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.rotateClockwise') }} (90°)</span>
              </v-tooltip>
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn icon @click="rotate(-90)">
                    <v-icon v-on="on">$iconRestore</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('imageViewer.rotateCounterClockwise') }} (-90°)</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import { CommonMixin } from '@/mixins/CommonMixin'
import { storageClient } from '@/plugins/storage'

export default {
  name: 'ImageViewer',
  components: {
    Cropper,
  },
  mixins: [CommonMixin],
  props: {
    image: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      displayCropper: false,
      displayDialog: false,
      localImage: {
        categoryName: '',
        key: '',
        title: '',
        token: '',
        uuid: '',
      },
      optionsCropper: {
        img: null,
      },
    }
  },
  watch: {
    image: function (val, oldVal) {
      if (val.uuid || val.uuid !== oldVal.uuid) {
        this.displayDialog = true
        this.localImage = val
        this.addDefaultImage()
      }
    },
  },
  mounted() {
    this.displayCropper = false
    this.localImage = this.image
    this.addDefaultImage()
  },
  methods: {
    addDefaultImage() {
      const key = this.localImage?.key
      const token = this.localImage?.token
      if (key && token) {
        const imageUrl = storageClient.getUrl(key, token)
        this.optionsCropper.img = imageUrl
        this.displayCropper = true
      }
    },
    center() {
      this.$refs.cropper.setCoordinates(({ coordinates, imageSize }) => ({
        left: imageSize.width / 2 - coordinates.width / 2,
        top: imageSize.height / 2 - coordinates.height / 2,
      }))
    },
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      }
    },
    flip(x, y) {
      if (this.$refs.cropper.imageTransforms.rotate % 180 !== 0) {
        this.$refs.cropper.flip(!x, !y)
      } else {
        this.$refs.cropper.flip(x, y)
      }
    },
    maximize() {
      const { cropper } = this.$refs
      const center = {
        left: cropper.coordinates.left + cropper.coordinates.width / 2,
        top: cropper.coordinates.top + cropper.coordinates.height / 2,
      }
      cropper.setCoordinates([
        ({ coordinates, imageSize }) => ({
          width: imageSize.width,
          height: imageSize.height,
          left: center.left - coordinates.width / 2,
          top: center.top - coordinates.height / 2,
        }),
      ])
    },
    resize(width = 1, height = 1) {
      let startCoordinates
      this.$refs.cropper.setCoordinates([
        ({ coordinates }) => {
          startCoordinates = coordinates
          return {
            width: coordinates.width * width,
            height: coordinates.height * height,
          }
        },
        ({ coordinates }) => ({
          left: startCoordinates.left + (startCoordinates.width - coordinates.width) / 2,
          top: startCoordinates.top + (startCoordinates.height - coordinates.height) / 2,
        }),
      ])
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle)
    },
    styleCropper() {
      return {
        minHeight: this.WindowSize[1] + 'px',
      }
    },
  },
}
</script>

<style lang="css">
.cropper {
  margin: 0 auto;
  width: 99%;
}
.cropper-content {
  position: absolute;
  top: 10px;
  z-index: 100;
}
.cropper-foreground {
  opacity: 0 !important;
}
.cropper-info {
  left: 1%;
}
.cropper-vertical-buttons {
  right: 1%;
  width: 36px;
}
.vue-bounding-box {
  display: none;
}
</style>
