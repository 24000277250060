import gql from 'graphql-tag'

export const GET_SENSOR = gql`
  query sensor($where: sensor_bool_exp = {}, $order_by: [sensor_order_by!] = {}) {
    sensor(where: $where, order_by: $order_by) {
      metric_time
      metric
    }
  }
`

export const GET_DEVICES_SENSORS_LAST_VALUE = gql`
  query devices_sensors_last_value(
    $where: project_device_bool_exp = {}
    $order_by: [project_device_order_by!] = {}
  ) {
    project_device(where: $where, order_by: $order_by) {
      id
      project_id
      name
      project_device2_links {
        project_device1 {
          id
          name
        }
      }
      devices_sensors(limit: 1, order_by: { metric_time: desc }) {
        project_uuid
        hub_uuid
        device_uuid
        metric_time
        parsing_time
        metric
      }
    }
  }
`

export const GET_METRICS_BY_HUB_TIME_BUCKET_GAPFILL = gql`
  query get_metrics_by_hub_time_bucket_gapfill(
    $args: get_metrics_by_hub_time_bucket_gapfill_args!
  ) {
    get_metrics_by_hub_time_bucket_gapfill(args: $args) {
      device_uuid
      metric_time
      metric
    }
  }
`

export const GET_METRICS_BY_SENSOR_TIME_BUCKET_GAPFILL = gql`
  query get_metrics_by_sensor_time_bucket_gapfill(
    $args: get_metrics_by_sensor_time_bucket_gapfill_args!
  ) {
    get_metrics_by_sensor_time_bucket_gapfill(args: $args) {
      metric_time
      metric
    }
  }
`
