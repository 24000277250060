<template>
  <v-card class="ma-1" flat outlined>
    <v-banner id="tab-logs-banner" single-line elevation="0" rounded="4">
      <time-interval v-model="optionsTimeInterval" />
    </v-banner>
    <v-card-subtitle class="mt-0 pa-4">
      <v-row>
        <v-col md="3" sm="6">
          <v-select
            v-model="logTypeSelected"
            :items="logTypeList"
            :label="$t('common.type')"
            :menu-props="{ top: false, offsetY: true }"
            :no-data-text="$t('common.noDataText')"
            :placeholder="$t('common.select')"
            item-text="text"
            item-value="value"
            clearable
            deletable-chips
            dense
            hide-details
            multiple
            small-chips
          />
        </v-col>
        <v-col md="3" sm="6">
          <v-select
            v-model="logMessageTypeSelected"
            :items="logMessageTypeList"
            :label="$tc('common.tag', 1)"
            :menu-props="{ top: false, offsetY: true }"
            :no-data-text="$t('common.noDataText')"
            :placeholder="$t('common.select')"
            item-text="text"
            item-value="value"
            clearable
            deletable-chips
            dense
            hide-details
            small-chips
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="searchLogs"
            :append-icon="'$iconMagnify'"
            :label="$t('common.searchAllFieldsLogs')"
            class="mt-0 pt-1"
            height="26"
            clearable
            hide-details
          />
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-data-table
      :headers="headers"
      :items="devicesLogs"
      :item-class="rowClassesLog"
      item-key="uuid"
      :expanded.sync="expanded"
      :footer-props="{
        'items-per-page-text': itemsPerPageText,
        'page-text': pageText,
        'items-per-page-options': [5, 10, 25, 50, -1],
      }"
      :height="StyleHeightCalc('48px + 48px + 25px + 57px + 62px + 14px').height"
      :loading="loading"
      :loading-text="$t('common.loadingData')"
      :no-data-text="$t('common.noDataText')"
      :options.sync="options"
      :search="searchLogs"
      @click:row="selectLog($event)"
    >
      <v-progress-linear v-show="loading" slot="progress" color="secondary" indeterminate />
      <template #[`item.type`]="{ item }">
        <v-chip :color="getColorLogType(item.type)" class="d-inline-block text-center" dark small>
          {{ translateTypeLog(item.type) }}
        </v-chip>
      </template>
      <template #[`item.sent`]="{ item }">
        <v-row justify="space-between" no-gutters>
          <v-icon v-if="item.message.send_email_time" small>$iconSendEmail</v-icon>
          <v-icon v-if="item.message.send_sms_time" small>$iconSms</v-icon>
        </v-row>
      </template>
      <template #[`item.log_time`]="{ item }">
        {{ item.log_time | formatDateSeconds }}
      </template>
      <template #[`item.hub_uuid`]="{ item }">
        {{ getDeviceName(item.hub_uuid, null) }}
      </template>
      <template #[`item.device_uuid`]="{ item }">
        {{ getDeviceName(item.hub_uuid, item.device_uuid) }}
      </template>
      <template #[`expanded-item`]="{ item }">
        <td :colspan="headers.length" class="pa-4">
          <span class="d-block text-caption text--disabled ml-3">
            {{ item.uuid }}
          </span>
          <span v-if="item.message.send_email_time" class="d-block">
            <v-chip class="ma-2" small>
              {{ item.message.send_email_time | formatDateSeconds }}
            </v-chip>
            <span v-if="item.message.send_email_accepted">
              <v-icon>$iconSendEmail</v-icon>
              {{ getUser(item.message.send_email_accepted) }}
            </span>
            <span v-if="item.message.send_email_rejected">
              <v-icon>$iconSendEmailOff</v-icon>
              {{ getUser(item.message.send_email_rejected) }}
            </span>
          </span>

          <span v-if="item.message.send_sms_time" class="d-block">
            <v-chip class="ma-2" small>
              {{ item.message.send_sms_time | formatDateSeconds }}
            </v-chip>
            <span v-if="item.message.send_sms_accepted">
              <v-icon>$iconSms</v-icon>
              {{ getUser(item.message.send_sms_accepted) }}
            </span>
            <span v-if="item.message.send_sms_rejected">
              <v-icon>$iconSmsOff</v-icon>
              {{ getUser(item.message.send_sms_rejected) }}
            </span>
          </span>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { GET_ENUM_TYPE_LOG, GET_LOGS } from '@/graphql/LogQueries'
import { GET_USERS } from '@/graphql/UserQueries'
import { CommonMixin } from '@/mixins/CommonMixin'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import TimeInterval from '@/components/helper/TimeInterval'
import { formatDateTimeIso8601 } from '@/locales/formats/dataTimeFormats'
import * as moment from 'moment/moment'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import orderBy from 'lodash/orderBy'
import uniqWith from 'lodash/uniqWith'

export default {
  name: 'DeviceDialogTabLogs',
  components: {
    TimeInterval,
  },
  mixins: [CommonMixin, ProjectMixin],
  props: {
    device: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    log: {
      query: GET_LOGS,
      variables() {
        const vars = this.queryVars
        return vars
      },
      result({ data }) {
        if (data) {
          this.devicesLogs = data.log
        }
      },
      watchLoading(isLoading) {
        this.loading = isLoading
      },
      skip() {
        return !this.$store.getters['auth/isAuthenticated']
      },
    },
    enum_type_log: {
      query: GET_ENUM_TYPE_LOG,
    },
    user: {
      query: GET_USERS,
    },
  },
  data: () => ({
    devices: [],
    devicesLogs: [],
    expanded: [],
    expandedCurrent: [],
    filtersLogs: {
      dateTimeFrom: null,
      dateTimeTo: null,
      messageType: null,
      type: null,
    },
    isTrue: true,
    loading: false,
    logMessageTypeSelected: null,
    logTypeSelected: null,
    options: {
      itemsPerPage: 10,
      multiSort: false,
      page: 1,
      sortBy: ['log_time'],
      sortDesc: [true],
      filter: {},
    },
    optionsTimeInterval: {},
    queryVars: {},
    searchLogs: '',
  }),
  computed: {
    headers: function () {
      return [
        { text: this.$t('common.type'), value: 'type' },
        {
          text: '',
          value: 'sent',
          cellClass: 'px-2',
          class: 'px-2',
          width: '60px',
        },
        { text: this.$tc('common.tag', 1), value: 'message.message_type' },
        { text: this.$t('project.message'), value: 'message.message_text' },
        { text: this.$t('common.value'), value: 'message.message_value' },
        { text: this.$t('common.eventTime'), value: 'log_time' },
        {
          text: this.$tc('project.station', 1),
          value: 'hub_uuid',
          sort: (a, b) => {
            return this.getDeviceName(b, null).localeCompare(this.getDeviceName(a, null))
          },
        },
        {
          text: this.$tc('project.device', 1),
          value: 'device_uuid',
          sort: (a, b) => {
            return this.getDeviceName(null, b).localeCompare(this.getDeviceName(null, a))
          },
        },
      ]
    },
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    logMessageTypeList() {
      return this.getLogMessageType()
    },
    logTypeList() {
      return this.getEnumTypeLog()
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
  },
  watch: {
    'device.id': function (val, oldVal) {
      if (oldVal !== val) {
        this.logMessageTypeSelected = ''
        this.logTypeSelected = []
        this.searchLogs = ''
        this.onNew()
      }
    },
    logMessageTypeSelected(val) {
      this.filtersLogs.messageType = val !== '' ? val : null
      this.subscribeLogsVars()
    },
    logTypeSelected(val) {
      this.filtersLogs.type = val.length > 0 ? val : null
      this.subscribeLogsVars()
    },
    optionsTimeInterval: function (val) {
      if (val.dateTimeFrom) {
        this.filtersLogs.dateTimeFrom = val.dateTimeFrom
      }
      if (val.dateTimeTo) {
        this.filtersLogs.dateTimeTo = val.dateTimeTo
      }
      this.subscribeLogsVars()
    },
    ProjectDevices: {
      handler: function (val) {
        this.devices = val
      },
      deep: true,
    },
  },
  mounted() {
    this.onNew()
  },
  methods: {
    getColorLogType(type) {
      let color = ''
      switch (type) {
        case 'verbose':
          color = 'blue-grey darken-1'
          break
        case 'info':
          color = 'quaternary'
          break
        case 'warnings':
          color = 'red'
          break
        case 'errors':
          color = 'orange'
          break
        default:
          color = ''
          break
      }
      return color
    },
    getCurrentDeviceType() {
      let deviceType = ''
      if (this.device) {
        deviceType = this.device.device_type
      }
      return deviceType
    },
    getDeviceName(hubUuid, deviceUuid) {
      let result = '?'
      if (deviceUuid === null) {
        this.devices.find((device) => {
          if (device.id === hubUuid) {
            result = device.name
          }
          return null
        })
      } else {
        if (hubUuid === deviceUuid) {
          this.devices.find((device) => {
            if (device.id === hubUuid) {
              result = device.name
            }
            return null
          })
        } else {
          if (deviceUuid !== '') {
            this.devices.find((device) => {
              if (device.id === deviceUuid) {
                result = device.name
              }
              return null
            })
          } else {
            this.devices.find((device) => {
              if (device.id === hubUuid) {
                result = device.name
              }
              return null
            })
          }
        }
      }
      return result
    },
    getEnumTypeLog() {
      const list = this.enum_type_log
      if (list) {
        list.forEach((item) => {
          item.text = this.translateTypeLog(item.value)
        })
        const listSort = orderBy(list, ['text'], ['asc'])
        return listSort
      }
    },
    getLogMessageType() {
      let messageType = []
      if (this.devicesLogs.length > 0) {
        messageType = this.devicesLogs.map(({ message }) =>
          message.message_type ? message.message_type : null
        )
      }
      const uniq = uniqWith(messageType, isEqual)
      const orderByUniq = orderBy(uniq)
      const filtered = orderByUniq.filter((el) => {
        return el != null
      })
      return filtered
    },
    getUser(uuid) {
      let list = ''
      if (this.user) {
        uuid.forEach((uuid) => {
          const search = this.user.find((u) => u.id === uuid)
          if (search) {
            list +=
              search.crm_contact.lastname.toUpperCase() + ' ' + search.crm_contact.firstname + ' - '
          } else {
            list += this.$t('report.deletedUser') + ' - '
          }
        })
      }
      return list.substring(0, list.length - 3)
    },
    onNew() {
      this.devicesLogs = []
      this.optionsTimeInterval = {}

      const dateTimeTo = this.$route.params?.logTime || null
      const dateTimeFrom = dateTimeTo
        ? moment(dateTimeTo).subtract(14, 'days').format(formatDateTimeIso8601)
        : moment().subtract(14, 'days').format(formatDateTimeIso8601)

      this.filtersLogs.dateTimeTo = dateTimeTo
      this.filtersLogs.dateTimeFrom = dateTimeFrom

      this.optionsTimeInterval.dateTimeTo = dateTimeTo
      this.optionsTimeInterval.dateTimeFrom = dateTimeFrom

      this.subscribeLogsVars()
    },
    rowClassesLog() {
      return this.isTrue ? 'pointer' : null
    },
    translateTypeLog(type) {
      let text = ''
      switch (type) {
        case 'info':
          text = this.$t('project.enum.typeLog.info')
          break
        case 'errors':
          text = this.$t('project.enum.typeLog.errors')
          break
        case 'verbose':
          text = this.$t('project.enum.typeLog.verbose')
          break
        case 'warnings':
          text = this.$t('project.enum.typeLog.warnings')
          break
      }
      return text
    },
    selectLog(event) {
      if (this.expandedCurrent.length > 0) {
        if (this.expandedCurrent[0] === event) {
          this.expandedCurrent.shift()
          this.expanded.shift()
        } else {
          this.expandedCurrent.shift()
          this.expandedCurrent[0] = event
          this.expanded.shift()
          this.expanded.push(event)
        }
      } else {
        this.expandedCurrent[0] = event
        this.expanded.push(event)
      }
    },
    subscribeLogsVars() {
      let vars = {
        order_by: {
          created_at: 'desc',
          log_time: 'desc',
        },
        where: {
          project_uuid: {
            _eq: this.$store.getters['project/currentProjectId'],
          },
          _and: [
            {
              log_time: { _gte: this.filtersLogs.dateTimeFrom },
              _and: {
                log_time: { _lte: this.filtersLogs.dateTimeTo },
              },
            },
          ],
        },
      }

      if (this.getCurrentDeviceType() === 'hub') {
        const where = {
          where: {
            hub_uuid: { _eq: this.device.id },
          },
        }
        vars = merge(vars, where)
      } else {
        const where = {
          where: {
            device_uuid: { _eq: this.device.id },
          },
        }
        vars = merge(vars, where)
      }

      if (this.filtersLogs.messageType !== null) {
        const where = {
          where: {
            message: {
              _contains: { message_type: this.filtersLogs.messageType },
            },
          },
        }
        vars = merge(vars, where)
      }

      if (this.filtersLogs.type !== null) {
        const where = {
          where: {
            type: { _in: this.filtersLogs.type },
          },
        }
        vars = merge(vars, where)
      }

      this.queryVars = vars
    },
  },
}
</script>

<style>
#tab-logs-banner > .v-banner__wrapper {
  padding: 8px 0 0 4px;
}
.tab-logs-input {
  height: auto;
  min-height: 32px;
  max-width: 210px;
}
</style>
