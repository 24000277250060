<template>
  <v-badge
    id="intro-home-2-log"
    :content="TotalCount"
    :value="TotalCount"
    :color="params.badge.color || 'red'"
    overlap
  >
    <v-icon>{{ params.icon }}</v-icon>
  </v-badge>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AlertCountBadge',
  props: {
    params: {
      type: Object,
      default: () => {},
      require: true,
    },
  },
  data: () => ({
    TotalCount: 0,
  }),
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
  },
  async created() {
    const graphqllib = await import(`@/graphql/${this.params.badge.graphqlFile}`)
    await this.$apollo.addSmartQuery('TotalCount', {
      query: graphqllib[this.params.badge.query],
      pollInterval: this.params.badge.pollInterval || 30000, // every 30s
      variables() {
        return {
          project_uuid: this.currentProjectId,
        }
      },
      update(data) {
        return Object.values(data)
          .map((item) => item.aggregate.count)
          .reduce((prev, curr) => prev + curr, 0)
      },
    })
  },
}
</script>
