import gql from 'graphql-tag'

// queries
export const GET_LIB_DEVICE = gql`
  query ($id: uuid!) {
    lib_device_by_pk(id: $id) {
      id
      model
      description
      device_type
      brand_id
      lib_device_tags {
        lib_tag {
          id
          tag_value
        }
      }
      lib_device_brand {
        id
        name
      }
      updated_at
      created_at
    }
  }
`

export const GET_LIB_DEVICES = gql`
  query lib_device(
    $where: lib_device_bool_exp
    $order_by: [lib_device_order_by!]
    $limit: Int
    $offset: Int
  ) {
    lib_device_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    lib_device(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      id
      model
      description
      device_type
      lib_device_tags {
        lib_tag {
          id
          tag_value
        }
      }
      lib_device_brand {
        id
        name
      }
      created_at
      updated_at
    }
  }
`

// mutations
export const PATCH_LIB_DEVICE = gql`
  mutation ($id: uuid!, $libDevice: lib_device_set_input!, $tags: [lib_device_tag_insert_input!]!) {
    delete_lib_device_tag(where: { lib_device_id: { _eq: $id } }) {
      affected_rows
    }
    insert_lib_device_tag(objects: $tags) {
      affected_rows
    }
    update_lib_device(where: { id: { _eq: $id } }, _set: $libDevice) {
      affected_rows
      returning {
        id
        model
        description
        device_type
        lib_device_tags {
          lib_tag {
            id
            tag_value
          }
        }
        lib_device_brand {
          id
          name
        }
        updated_at
        created_at
      }
    }
  }
`

export const POST_LIB_DEVICE = gql`
  mutation ($objects: [lib_device_insert_input!]!) {
    insert_lib_device(objects: $objects) {
      returning {
        id
        model
        description
        device_type
        lib_device_tags {
          lib_tag {
            id
            tag_value
          }
        }
        lib_device_brand {
          id
          name
        }
        updated_at
        created_at
      }
    }
  }
`

export const DELETE_LIB_DEVICE = gql`
  mutation ($id: uuid!) {
    delete_lib_device(where: { id: { _eq: $id } }) {
      returning {
        id
        created_at
        updated_at
      }
    }
  }
`

// subscriptions
export const LIB_DEVICE_ADDED = gql`
  subscription {
    lib_device {
      id
      model
      description
      device_type
      updated_at
      created_at
    }
  }
`

export const LIB_DEVICE_UPDATED = gql`
  subscription {
    lib_device {
      id
      model
      description
      device_type
      updated_at
      created_at
    }
  }
`
