import * as moment from 'moment/moment'
import { convertDateTime, formatDateTimeIso8601 } from '@/locales/formats/dataTimeFormats'

/**
 * 1 day = 86400000 ms
 * 14 days = 1209600000 ms
 * 60 days = 5184000000 ms
 * 365 days = 31536000000 ms
 */

export const calcBelowXDays = (dateMin, dateMax, xDays) => {
  const dateMinClone = moment(dateMin)
  const dateMaxClone = moment(dateMax)
  const diff = dateMaxClone.diff(dateMinClone)
  // milliseconds = days * hour * min * sec * ms
  const ms = xDays ? xDays * 24 * 60 * 60 * 1000 : 1209600000
  return diff <= ms
}

export const calcBucket = (dateMin, dateMax) => {
  const dateMinClone = moment(dateMin)
  const dateMaxClone = moment(dateMax)
  const diff = dateMaxClone.diff(dateMinClone)
  const betterDataCount = 10000
  const bucketMilliseconds = diff / betterDataCount
  const bucketSeconds = Math.floor(bucketMilliseconds / 1000)
  let bucket = ''
  if (diff <= 5184000000) {
    bucket = '5 minutes'
  } else {
    bucket = bucketSeconds + ' s'
  }
  return bucket
}

export const calculateBucketHub = (dateMin, dateMax, countDevices) => {
  countDevices = countDevices <= 0 ? 1 : countDevices
  const dateMinClone = moment(dateMin)
  const dateMaxClone = moment(dateMax)
  const diff = dateMaxClone.diff(dateMinClone)
  const betterDataCount = 20000
  const bucketMilliseconds = (diff / betterDataCount) * countDevices
  const bucketSeconds = Math.floor(bucketMilliseconds / 1000)
  let bucket = ''
  /*
  if (diff <= 86400000) {
    bucket = '5 minutes'
  } else if (diff > 86400000 && diff <= 1209600000) {
    bucket = '15 minutes'
  } else {
    bucket = bucketSeconds + ' s'
  }
  */
  if (diff <= 1209600000) {
    bucket = '5 minutes'
  } else {
    bucket = bucketSeconds + ' s'
  }
  return bucket
}

export const getTimeInterval = (dateTimeFrom, dateTimeTo) => {
  let timeInterval = {
    dateMax: null,
    dateMin: null,
  }

  if (dateTimeFrom && dateTimeTo) {
    timeInterval = {
      dateMax: dateTimeTo,
      dateMin: dateTimeFrom,
    }
  }

  /**
   * date now
   * @type {Object}
   */
  const dateNow = moment()

  /**
   * date maximum
   * @type {String} Eg: 2020-12-14T14:14:14+01:00
   */
  if (!timeInterval.dateMax || timeInterval.dateMax === null) {
    timeInterval.dateMax = convertDateTime(dateNow.clone())
  }

  /**
   * date mininum = dateMax - 14 days
   * @type {String} Eg: 2020-10-14T14:14:14+01:00
   */
  if (!timeInterval.dateMin || timeInterval.dateMin === null) {
    timeInterval.dateMin = dateNow.clone().subtract(14, 'days').format(formatDateTimeIso8601)
  }

  return timeInterval
}
