var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mx-0 pa-0"},[_c('v-card',{attrs:{"elevation":"0","max-width":"550","tile":""}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('v-card',{attrs:{"elevation":"0","min-width":"255px"}},[_c('v-row',{attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('form-error',{attrs:{"validator":_vm.$v.dateLocaleFrom,"attribute":_vm.$t('common.from'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({staticClass:"input-manual-date",attrs:{"hint":_vm.dateFormatManual,"label":_vm.$t('common.from'),"placeholder":_vm.dateFormatManual,"readonly":_vm.isReadonly,"dense":"","single-line":""},on:{"focus":function($event){return _vm.resetTimer()},"input":function($event){return _vm.$v.dateLocaleFrom.$touch()},"blur":function($event){return _vm.updateInterval()}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('common.from'))+" ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-menu',{ref:"menuDateFrom",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"tabindex":"-1"},on:{"focus":function($event){return _vm.resetTimer()}}},on),[_vm._v(" $iconEvent ")])]}}],null,true),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[(!_vm.isReadonly)?_c('v-date-picker',{attrs:{"first-day-of-week":_vm.dateProps.firstDayOfWeek,"max":_vm.dateProps.max,"color":"secondary","no-title":"","show-current":""},on:{"input":function($event){_vm.menuDateFrom = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.dateLocaleFrom),callback:function ($$v) {_vm.dateLocaleFrom=$$v},expression:"dateLocaleFrom"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('form-error',{attrs:{"validator":_vm.$v.timeFrom,"attribute":_vm.$t('common.hour'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({staticClass:"input-manual-hour",attrs:{"label":_vm.$t('common.hour'),"readonly":_vm.isReadonly,"hint":"hh:mm","dense":"","single-line":""},on:{"focus":function($event){return _vm.resetTimer()},"input":function($event){return _vm.$v.timeFrom.$touch()},"blur":function($event){return _vm.updateInterval()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{ref:"menuTimeFrom",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"tabindex":"-1"},on:{"focus":function($event){return _vm.resetTimer()}}},on),[_vm._v(" $iconCalendarClock ")])]}}],null,true),model:{value:(_vm.menuTimeFrom),callback:function ($$v) {_vm.menuTimeFrom=$$v},expression:"menuTimeFrom"}},[(!_vm.isReadonly)?_c('v-time-picker',{attrs:{"color":"secondary","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menuTimeFrom.save(_vm.menuTimeFrom)}},model:{value:(_vm.timeFrom),callback:function ($$v) {_vm.timeFrom=$$v},expression:"timeFrom"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.timeFrom),callback:function ($$v) {_vm.timeFrom=$$v},expression:"timeFrom"}},'v-text-field',attrs,false))}}])})],1)],1)],1)],1),_c('v-col',[_c('v-card',{attrs:{"elevation":"0","min-width":"255px"}},[_c('v-row',{attrs:{"justify":"space-around","no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('form-error',{attrs:{"validator":_vm.$v.dateLocaleTo,"attribute":_vm.$t('common.to'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({staticClass:"input-manual-date",attrs:{"hint":_vm.dateFormatManual,"label":_vm.$t('common.to'),"placeholder":_vm.dateFormatManual,"readonly":_vm.isReadonly,"dense":"","single-line":""},on:{"focus":function($event){return _vm.resetTimer()},"input":function($event){return _vm.$v.dateLocaleTo.$touch()},"blur":function($event){return _vm.updateInterval()}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$t('common.to'))+" ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-menu',{ref:"menuDateTo",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"tabindex":"-1"},on:{"focus":function($event){return _vm.resetTimer()}}},on),[_vm._v(" $iconEvent ")])]}}],null,true),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[(!_vm.isReadonly)?_c('v-date-picker',{attrs:{"first-day-of-week":_vm.dateProps.firstDayOfWeek,"max":_vm.dateProps.max,"color":"secondary","no-title":"","show-current":""},on:{"input":function($event){_vm.menuDateTo = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.dateLocaleTo),callback:function ($$v) {_vm.dateLocaleTo=$$v},expression:"dateLocaleTo"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('form-error',{attrs:{"validator":_vm.$v.timeTo,"attribute":_vm.$t('common.hour'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({staticClass:"input-manual-hour",attrs:{"label":_vm.$t('common.hour'),"readonly":_vm.isReadonly,"hint":"hh:mm","dense":"","single-line":""},on:{"focus":function($event){return _vm.resetTimer()},"input":function($event){return _vm.$v.timeTo.$touch()},"blur":function($event){return _vm.updateInterval()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{ref:"menuTimeTo",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"tabindex":"-1"},on:{"focus":function($event){return _vm.resetTimer()}}},on),[_vm._v(" $iconCalendarClock ")])]}}],null,true),model:{value:(_vm.menuTimeTo),callback:function ($$v) {_vm.menuTimeTo=$$v},expression:"menuTimeTo"}},[(!_vm.isReadonly)?_c('v-time-picker',{attrs:{"color":"secondary","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.menuTimeTo.save(_vm.menuTimeTo)}},model:{value:(_vm.timeTo),callback:function ($$v) {_vm.timeTo=$$v},expression:"timeTo"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.timeTo),callback:function ($$v) {_vm.timeTo=$$v},expression:"timeTo"}},'v-text-field',attrs,false))}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }