<template lang="html">
  <v-container class="mx-8 pa-2">
    <v-row align="center" no-gutters>
      <div class="mr-6">
        <v-icon color="blue darken-2">$iconSatelliteUp</v-icon>
      </div>
      <div>
        <v-row v-if="!editMode">
          <div v-for="(link, i) in projectDevice2Links" :key="i" class="pr-2">
            <span class="font-italic font-weight-medium">
              {{ getDeviceName(link) }}
            </span>
            <span class="text-caption font-weight-light px-1">
              ({{ getLinkTypeName(link.link_type) }})
            </span>
          </div>
          <span v-if="projectDevice2Links.length < 1" class="font-weight-medium">(...)</span>
          <v-btn
            v-if="$store.getters['auth/isAuth'](['project_device_link', 'update'])"
            icon
            x-small
            @click="editMode = true"
          >
            <v-icon>$iconEdit</v-icon>
          </v-btn>
        </v-row>
        <div v-if="editMode">
          <v-row v-for="(link, i) in projectDevice2Links" :key="i">
            <v-select
              v-model="link.project_device1.id"
              :label="$t('common.hub')"
              :placeholder="$t('project.communicationStation')"
              :items="project_device"
              item-text="name"
              item-value="id"
              dense
              class="select-device-hub"
            />
            <v-select
              v-model="link.link_type"
              :label="$t('common.type')"
              :placeholder="$t('project.communicationType')"
              :items="DeviceLinkType"
              item-text="label"
              item-value="name"
              dense
              class="ml-3 select-device-hub"
            />
            <v-btn icon @click="saveLink(link)">
              <v-icon>$iconSave</v-icon>
            </v-btn>
            <v-btn icon @click="deleteLink(i, link)">
              <v-icon>$iconDelete</v-icon>
            </v-btn>
            <v-btn icon @click="editMode = false">
              <v-icon>$iconClose</v-icon>
            </v-btn>
          </v-row>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import DeviceLinkType from '@/components/project/devices/DeviceLinkType'
import { GET_PROJECT_DEVICE, GET_PROJECT_DEVICES } from '@/graphql/ProjectDeviceQueries'
import {
  PATCH_PROJECT_DEVICE_LINK,
  DELETE_PROJECT_DEVICE_LINK_BY_PK,
} from '@/graphql/ProjectDeviceLinkQueries'

export default {
  props: {
    device: {
      type: Object,
      default: () => {},
    },
  },
  apollo: {
    project_device: {
      query: GET_PROJECT_DEVICES,
      variables() {
        const vars = {
          where: {
            project_id: { _eq: this.device.project_id },
            _and: { device_type: { _eq: 'hub' } },
          },
          order_by: { name: 'asc' },
        }
        return vars
      },
      skip() {
        return !this.device
      },
    },
  },
  data: () => ({
    editMode: false,
    DeviceLinkType,
    deviceNew: null,
    linkDefault: [
      {
        project_device1: {
          id: null,
          name: '',
        },
        link_type: '',
      },
    ],
  }),
  computed: {
    projectDevice2Links() {
      return this.getProjectDevice2Links()
    },
  },
  methods: {
    getDeviceName(link) {
      let result = ''
      if (link.project_device1.name !== '') {
        result = link.project_device1.name
      } else {
        if (typeof this.project_device !== 'undefined') {
          const search = this.project_device.find((i) => i.id === link.project_device1.id)
          result = typeof search !== 'undefined' ? search.name : ''
        }
      }
      return result
    },
    getLinkTypeName(type) {
      return this.DeviceLinkType?.find((i) => i.name === type)?.label || '?'
    },
    getProjectDevice2Links() {
      let link = this.linkDefault
      if (this.deviceNew === null) {
        if (this.device.project_device2_links.length > 0) {
          link = this.device.project_device2_links
        }
      } else {
        this.deviceNew = this.device.id === this.deviceNew.id ? this.deviceNew : null
        if (this.deviceNew !== null && this.deviceNew.project_device2_links.length > 0) {
          link = this.deviceNew.project_device2_links
        }
      }
      return link
    },
    async deleteLink(link) {
      const removal = this.$t('common.removal')
      const sureToDeleteThis = this.$t('common.sureToDeleteLinkHub', {
        attribute: link.project_device1.name,
      })
      const modificationCompleted = this.$t('common.modificationCompleted')
      let result = null
      if (await this.$root.$confirm(removal, sureToDeleteThis, { color: 'red' })) {
        await this.$apollo
          .mutate({
            mutation: DELETE_PROJECT_DEVICE_LINK_BY_PK,
            variables: {
              project_id: this.device.project_id,
              project_device1_id: link.project_device1.id,
              project_device2_id: this.device.id,
            },
          })
          .then((data) => {
            result = data.delete_project_device_link_by_pk
          })
          .catch((error) => {
            this.$root.$dialogLoader.showSnackbar(
              'deleteLink DELETE_PROJECT_DEVICE_LINK_BY_PK: ' + error.message,
              { color: 'error' }
            )
            throw error
          })

        let newDevice = {}
        if (result !== null) {
          await this.$apollo
            .mutate({
              mutation: GET_PROJECT_DEVICE,
              variables: {
                id: this.device.id,
              },
            })
            .then((data) => {
              newDevice = data
            })
            .catch((error) => {
              this.$root.$dialogLoader.showSnackbar(
                'deleteLink GET_PROJECT_DEVICE: ' + error.message,
                { color: 'error' }
              )
              throw error
            })
          this.$root.$dialogLoader.showSnackbar(modificationCompleted, {
            color: 'success',
          })
        }
        this.deviceNew = newDevice.data.project_device_by_pk
      }
    },
    async saveLink(link) {
      const modificationCompleted = this.$t('common.modificationCompleted')
      let result = null

      await this.$apollo
        .mutate({
          mutation: PATCH_PROJECT_DEVICE_LINK,
          variables: {
            objects: {
              project_id: this.device.project_id,
              project_device1_id: link.project_device1.id,
              project_device2_id: this.device.id,
              link_type: link.link_type,
            },
          },
        })
        .then((data) => {
          result = data.insert_project_device_link
        })
        .catch((error) => {
          this.$root.$dialogLoader.showSnackbar(
            'saveLink PATCH_PROJECT_DEVICE_LINK: ' + error.message,
            { color: 'error' }
          )
          throw error
        })

      let newDevice = {}
      if (result !== null) {
        await this.$apollo
          .mutate({
            mutation: GET_PROJECT_DEVICE,
            variables: {
              id: this.device.id,
            },
          })
          .then((data) => {
            newDevice = data
          })
          .catch((error) => {
            this.$root.$dialogLoader.showSnackbar('saveLink GET_PROJECT_DEVICE: ' + error.message, {
              color: 'error',
            })
            throw error
          })
        this.$root.$dialogLoader.showSnackbar(modificationCompleted, {
          color: 'success',
        })
      }
      this.deviceNew = newDevice.data.project_device_by_pk
    },
  },
}
</script>

<style lang="css">
.select-device-hub {
  max-width: 200px;
}
</style>
