<template lang="html">
  <v-row align="center" no-gutters>
    <v-col cols="2">
      <v-card id="intro-reportform-17-fileinput'" flat max-width="130">
        <v-file-input
          v-model="attachedFileCurrent"
          :clearable="!isTrue"
          :label="$tc('common.attachedFile', 1)"
          :placeholder="$tc('common.attachedFile', 1)"
          dense
          hide-details
          multiple
          single-line
          @change="changeAttachedFile()"
        >
          <template v-if="buttonSaveForm && attachedFile.length > 0" #append-outer>
            <v-btn icon small @click="saveForm">
              <v-icon>$iconSave</v-icon>
            </v-btn>
          </template>
        </v-file-input>
      </v-card>
    </v-col>
    <v-col v-if="attachedFile.length > 0" cols="9" class="text-left">
      <v-chip
        v-for="(file, i) in attachedFile"
        :key="i"
        class="mx-1 mb-1"
        close
        small
        @click:close="deleteChipAttachedFile(i)"
      >
        {{ file.name }}
        <span v-if="file.size > 10000000" class="font-weight-medium error--text pl-2">
          {{ $t('common.attachedFileRule') }}
        </span>
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
import { CommonMixin } from '@/mixins/CommonMixin'

export default {
  name: 'FileInput',
  mixins: [CommonMixin],
  props: {
    buttonSaveForm: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    attachedFile: [],
    attachedFileCurrent: [],
    isTrue: true,
  }),
  watch: {
    attachedFile: function (val) {
      if (!this.buttonSaveForm && val.length > 0) this.saveForm()
    },
  },
  methods: {
    changeAttachedFile() {
      this.attachedFile = [...this.attachedFileCurrent, ...this.attachedFile]
      // clear input file
      this.attachedFileCurrent = []
    },
    deleteChipAttachedFile(index) {
      this.attachedFile.splice(index, 1)
    },
    saveForm() {
      const modificationCompleted =
        this.$t('common.modificationCompleted') + ' - ' + this.$tc('common.attachedFile', 1)
      const fileRule = []

      this.attachedFile.forEach((i) => fileRule.push(i.size < 10000000))

      if (fileRule.includes(false)) {
        this.ShowSnackbarError()
      } else {
        this.$emit('click-save-form', this.attachedFile)
        if (this.buttonSaveForm) {
          this.attachedFile = []
          this.ShowSnackbarSuccess(modificationCompleted)
        }
      }
    },
  },
}
</script>
