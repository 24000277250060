var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"mt-8",attrs:{"id":"project-drawer-view","color":"tertiary","height":"calc(100% - (32px * 2))","width":"292px","absolute":"","right":"","stateless":""},model:{value:(_vm.displayDrawer),callback:function ($$v) {_vm.displayDrawer=$$v},expression:"displayDrawer"}},[_c('v-container',[_c('v-row',[_c('div',{staticClass:"text-overline"},[_c('span',{staticClass:"text-uppercase pl-3"},[_vm._v(" "+_vm._s(_vm.$tc('project.view', 2))+" ")])])]),_c('v-row',[_c('v-select',{staticClass:"mt-1 mx-2",attrs:{"items":_vm.projectViews,"item-text":"name","item-value":"uuid","value":_vm.currentView.uuid,"label":_vm.$t('common.selectview'),"menu-props":{ top: false, offsetY: true, maxHeight: 380 },"background-color":"#FFF","dense":"","hide-details":"","outlined":""},on:{"change":_vm.updateCurrentView},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.iconType(item.type))+" ")]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.iconType(item.type))+" ")]),_vm._v(" "+_vm._s(item.name)+" ")]}}])})],1),_c('v-row',[_c('project-drawer-view-panel')],1),_c('v-row',[_c('v-toolbar',{attrs:{"color":"tertiary","height":"32px"}},[(_vm.currentView.type === '2d' && _vm.isAuthProjectViewDeviceCoorDel)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",attrs:{"color":_vm.view2dToolDeviceDel ? '#F44336' : '',"small":""},on:{"click":function($event){return _vm.view2dTool()}}},on),[_vm._v(" $iconMapMarkerRemove ")])]}}],null,false,3400619882)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('project.deleteDeviceView'))+" ")])]):_vm._e(),(_vm.currentView.type === 'net' && _vm.isAuthProjectViewDeviceCoorDel)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",attrs:{"color":_vm.viewNetToolPin ? 'secondary' : '',"small":""},on:{"click":function($event){return _vm.viewNetTool()}}},on),[_vm._v(" $iconPin ")])]}}],null,false,1643965070)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('project.pinDevice'))+" ")])]):_vm._e(),_c('v-spacer'),(_vm.isAuthProjectViewUpdate)?_c('span',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',_vm._g({},on),[_vm._v(" $iconPlus ")])],1)]}}],null,false,3416250359)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.addView')))])])],1):_vm._e(),(!_vm.currentView.default && _vm.isAuthProjectViewUpdate)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.deleteView}},[_c('v-icon',_vm._g({},on),[_vm._v(" $iconDelete ")])],1)]}}],null,false,1564576590)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.deleteview'))+" ")])]):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('project.newView'))+" ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('common.name')},model:{value:(_vm.view.name),callback:function ($$v) {_vm.$set(_vm.view, "name", $$v)},expression:"view.name"}}),_c('v-select',{attrs:{"items":_vm.ViewType,"menu-props":{ maxHeight: '400' },"label":_vm.$t('common.type'),"placeholder":_vm.$t('project.viewType'),"item-text":"label","item-value":"name"},model:{value:(_vm.view.type),callback:function ($$v) {_vm.$set(_vm.view, "type", $$v)},expression:"view.type"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.createView}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }