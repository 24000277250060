<template>
  <div id="project-drawer-view-layer">
    <layer-options-dialog v-model="layerOptionDialogShow" :layer="selectedLayer" />
    <add-system-layer-dialog v-model="addSystemLayerDialogShow" />
    <v-toolbar class="my-1 px-1" color="tertiary" height="32px">
      <v-tooltip v-if="$store.getters['auth/isAuth'](['project_view_layer', 'update'])" left>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon x-small v-on="on">
            <v-icon @click="changeAllVisibility()">{{
              allVisibility ? '$iconEye' : '$iconEyeOff'
            }}</v-icon>
          </v-btn>
        </template>
        <span v-text="allVisibility ? $t('common.hideAll') : $t('common.showAll')" />
      </v-tooltip>
      <v-spacer />
      <v-menu bottom left offset-y transition="slide-y-transition" light>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="$store.getters['auth/isAuth'](['project_view_layer', 'create'])"
            v-bind="attrs"
            icon
            x-small
            v-on="on"
          >
            <v-icon v-on="on">$iconPlus</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item dense @click="AddSystemLayer()">
            <v-list-item-icon>
              <v-icon small>$iconLayerSystem</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('project.addSystemLayer') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="type === 'map'" dense @click="$refs.geojson.click()">
            <v-list-item-icon>
              <v-icon small>$iconGeojsonAdd</v-icon>
            </v-list-item-icon>
            <input
              ref="geojson"
              type="file"
              style="display: none"
              accept="application/geo+json,.geojson"
              @change="handleGeojsonUpload()"
            />
            <v-list-item-title>{{ $t('project.addGeojsonLayer') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-list class="pa-0" dense>
      <draggable v-model="Layers" @change="onMove">
        <div v-for="(layer, i) in Layers" :key="i" style="border: 1px solid #5d5d5d" class="mb-1">
          <v-list-item :style="!layer.options.visibility ? 'opacity: 25%;' : ''" class="pa-0">
            <v-list-item-icon class="align-center mr-2">
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-if="$store.getters['auth/isAuth'](['project_view_layer', 'update'])"
                    v-bind="attrs"
                    icon
                    v-on="on"
                  >
                    <v-icon small @click="changeVisibility(i)">{{
                      layer.options.visibility ? '$iconEye' : '$iconEyeOff'
                    }}</v-icon>
                  </v-btn>
                </template>
                <span v-text="layer.options.visibility ? $t('common.hide') : $t('common.show')" />
              </v-tooltip>
              <div class="previewLayer">
                <div :style="getLayerStyle(layer)" />
              </div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ layer.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar height="20px">
              <v-menu bottom left offset-y transition="slide-y-transition">
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-if="$store.getters['auth/isAuth'](['project_geojson_object', 'update'])"
                    v-bind="attrs"
                    dense
                    small
                    v-on="on"
                  >
                    $iconSubMenu
                  </v-icon>
                </template>
                <v-list dense min-width="200px">
                  <v-list-item dense @click="openLayerDialog(layer)">
                    <v-list-item-icon>
                      <v-icon small>$iconSettings</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('common.options') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="layer.type === 'geojson'" dense @click="exportGeoJson(layer)">
                    <v-list-item-icon>
                      <v-icon small> $iconGeojsonExport </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('project.exportGeoJSON') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="$store.getters['auth/isAuth'](['project_geojson_object', 'delete'])"
                    dense
                    @click="deleteLayer(layer)"
                  >
                    <v-list-item-icon>
                      <v-icon small>$iconLayerDel</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('common.delete') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-avatar>
          </v-list-item>
        </div>
      </draggable>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LayerOptionsDialog from '@/components/project/views/map/LayerOptionsDialog'
import AddSystemLayerDialog from '@/components/project/views/map/AddSystemLayerDialog'
import { geojson } from '@/components/project/views/map/GeoJsonMixin.js'
import draggable from 'vuedraggable'
import {
  GET_PROJECT_VIEW_LAYERS,
  PATCH_PROJECT_VIEW_LAYER,
  DELETE_PROJECT_VIEW_LAYER,
  UPDATE_ORDER_UP_PROJECT_VIEW_LAYER,
  UPDATE_ORDER_DOWN_PROJECT_VIEW_LAYER,
  PATCH_PROJECT_VIEW_LAYERS_OPTIONS,
} from '@/graphql/ProjectViewLayerQueries'
import {
  GET_PROJECT_GEOJSON_OBJECTS,
  DELETE_PROJECT_GEOJSON_OBJECT,
} from '@/graphql/ProjectGeoJSonObject'

export default {
  name: 'ProjectDrawerViewLayers',
  components: {
    LayerOptionsDialog,
    AddSystemLayerDialog,
    draggable,
  },
  mixins: [geojson],
  props: {
    type: {
      type: String,
      default: '2D',
    },
  },
  apollo: {
    Layers: {
      query: GET_PROJECT_VIEW_LAYERS,
      deep: true,
      variables() {
        return {
          where: { project_view_uuid: { _eq: this.currentView.uuid } },
          order_by: { vorder: 'asc' },
        }
      },
      skip() {
        return !this.currentView.uuid
      },
      update(data) {
        return data.project_view_layer
      },
    },
  },
  data: () => ({
    layerOptionDialogShow: false,
    addSystemLayerDialogShow: false,
    selectedLayer: undefined,
  }),
  computed: {
    ...mapGetters({
      currentView: 'project/currentView',
    }),
    allVisibility() {
      if (
        this.Layers &&
        this.Layers.filter((layer) => layer.options.visibility === true).length === 0
      ) {
        return false
      }
      return true
    },
  },
  methods: {
    getLayerStyle(layer) {
      if (layer.type === 'system') {
        return {
          backgroundColor: layer.options.color,
          width: '100%',
          height: '100%',
        }
      }
      return {
        border: '1px solid ' + layer.options.color,
        backgroundColor: layer.options.bgcolor,
        width: '100%',
        height: '100%',
      }
    },
    async changeAllVisibility() {
      await this.$apollo
        .mutate({
          mutation: PATCH_PROJECT_VIEW_LAYERS_OPTIONS,
          variables: {
            where: { project_view_uuid: { _eq: this.currentView.uuid } },
            options: {
              visibility: !this.allVisibility,
            },
          },
          refetchQueries: [
            {
              query: GET_PROJECT_VIEW_LAYERS,
              variables: {
                where: { project_view_uuid: { _eq: this.currentView.uuid } },
                order_by: { vorder: 'asc' },
              },
            },
          ],
        })
        .catch((error) => {
          throw error
        })
      // isolé par PSM le 04/01/2020 renvoie un err ds console.log
      // this.allVisibility = !this.allVisibility
    },
    async changeVisibility(layerId) {
      await this.$apollo
        .mutate({
          mutation: PATCH_PROJECT_VIEW_LAYERS_OPTIONS,
          variables: {
            where: {
              uuid: { _eq: this.Layers[layerId].uuid },
            },
            options: {
              visibility: !this.Layers[layerId].options.visibility,
            },
          },
          refetchQueries: [
            {
              query: GET_PROJECT_VIEW_LAYERS,
              variables: {
                where: { project_view_uuid: { _eq: this.currentView.uuid } },
                order_by: { vorder: 'asc' },
              },
            },
          ],
        })
        .catch((error) => {
          throw error
        })
    },
    async deleteLayer(layer) {
      const removal = this.$t('common.removal')
      const sureToDeleteLayer = this.$t('project.sureToDeleteLayer')
      if (await this.$root.$confirm(removal, sureToDeleteLayer, { color: 'red' })) {
        this.$root.$dialogLoader.start(
          removal + '...',
          {},
          () => {
            return new Promise((resolve, reject) => {
              try {
                if (layer.type === 'geojson') {
                  this.checkDeleteGeoJson(layer.options.geojsonUuid)
                }
                this.$apollo.mutate({
                  mutation: DELETE_PROJECT_VIEW_LAYER,
                  variables: {
                    uuid: layer.uuid,
                  },
                  refetchQueries: [
                    {
                      query: GET_PROJECT_VIEW_LAYERS,
                      variables: {
                        where: {
                          project_view_uuid: { _eq: this.currentView.uuid },
                        },
                        order_by: { vorder: 'asc' },
                      },
                    },
                  ],
                })
              } catch (error) {
                reject(error.toString())
                throw error
              }
            })
          },
          true
        )
      }
    },
    openLayerDialog(layer) {
      this.selectedLayer = layer
      this.layerOptionDialogShow = true
    },
    async onMove({ moved }) {
      let OrderQuery = UPDATE_ORDER_UP_PROJECT_VIEW_LAYER
      if (moved.newIndex > moved.oldIndex) {
        OrderQuery = UPDATE_ORDER_DOWN_PROJECT_VIEW_LAYER
      }
      await this.$apollo.mutate({
        mutation: PATCH_PROJECT_VIEW_LAYER,
        variables: {
          uuid: moved.element.uuid,
          body: {
            vorder: 0,
          },
        },
        update: async () => {
          await this.$apollo.mutate({
            mutation: OrderQuery,
            variables: {
              project_view_uuid: this.currentView.uuid,
              new_positon: moved.newIndex,
              old_positon: moved.oldIndex,
            },
            update: async () => {
              await this.$apollo
                .mutate({
                  mutation: PATCH_PROJECT_VIEW_LAYER,
                  variables: {
                    uuid: moved.element.uuid,
                    body: {
                      vorder: moved.newIndex,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GET_PROJECT_VIEW_LAYERS,
                      variables: {
                        where: {
                          project_view_uuid: { _eq: this.currentView.uuid },
                        },
                        order_by: { vorder: 'asc' },
                      },
                    },
                  ],
                })
                .catch((error) => {
                  const errorChangeLayer = this.$t('project.errorChangeLayer')
                  this.$root.$dialogLoader.showSnackbar(
                    errorChangeLayer + `  ${moved.element.name} (${moved.element.uuid})`,
                    { color: 'error' }
                  )
                  throw error
                })
            },
          })
        },
      })
    },
    async handleGeojsonUpload() {
      const file = this.$refs.geojson.files[0]
      const reader = new FileReader()
      reader.addEventListener('loadend', this.addGeoJSONLayer)
      await reader.readAsText(file, 'UTF-8')
      this.$refs.geojson.value = ''
    },
    AddSystemLayer() {
      this.addSystemLayerDialogShow = true
    },
    async checkDeleteGeoJson(geoJsonUuid) {
      const geojson = await this.$apollo.query({
        query: GET_PROJECT_GEOJSON_OBJECTS,
        variables() {
          return {
            where: { options: { _contains: { geojsonUuid: geoJsonUuid } } },
          }
        },
      })
      if (geojson.data.project_geojson_object_aggregate.aggregate.totalCount <= 1) {
        this.$apollo.mutate({
          mutation: DELETE_PROJECT_GEOJSON_OBJECT,
          variables: {
            uuid: geoJsonUuid,
          },
        })
      }
    },
    async exportGeoJson(layer) {
      const geojson = await this.$apollo.query({
        query: GET_PROJECT_GEOJSON_OBJECTS,
        variables: {
          where: { uuid: { _eq: layer.options.geojsonUuid } },
        },
      })
      const a = document.createElement('a')
      const file = new Blob([JSON.stringify(geojson.data.project_geojson_object[0].data)], {
        type: 'application/geo+json,.geojson',
      })
      a.href = URL.createObjectURL(file)
      a.download = layer.name + '.geojson'
      a.click()
    },
  },
}
</script>

<style lang="css" scoped>
.previewLayer {
  background-color: white;
  border-radius: 4px;
  height: 15px;
  width: 15px;
  margin-left: 12px;
}
</style>
