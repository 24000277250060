import gql from 'graphql-tag'

// queries
export const GET_PROJECT = gql`
  query ($id: uuid!) {
    project_by_pk(id: $id) {
      id
      quickname
      name
      description
      created_at
      updated_at
    }
  }
`
export const GET_PROJECTS = gql`
  query project(
    $where: project_bool_exp
    $order_by: [project_order_by!]
    $limit: Int
    $offset: Int
  ) {
    project_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    project(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      id
      quickname
      name
      created_at
      updated_at
    }
  }
`

// mutations
export const PATCH_PROJECT = gql`
  mutation ($id: uuid!, $body: project_set_input!) {
    update_project(where: { id: { _eq: $id } }, _set: $body) {
      affected_rows
      returning {
        id
        quickname
        name
        description
        created_at
        updated_at
      }
    }
  }
`

export const POST_PROJECT = gql`
  mutation ($objects: [project_insert_input!]!) {
    insert_project(objects: $objects) {
      returning {
        id
        quickname
        name
        description
        created_at
        updated_at
      }
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation ($id: uuid!) {
    delete_project(where: { id: { _eq: $id } }) {
      returning {
        id
        name
        quickname
        created_at
        updated_at
      }
    }
  }
`

// subscriptions
export const PROJECT_ADDED = gql`
  subscription {
    project {
      id
      name
      quickname
      created_at
      updated_at
    }
  }
`

export const PROJECT_UPDATED = gql`
  subscription {
    project {
      id
      name
      quickname
      created_at
      updated_at
    }
  }
`
