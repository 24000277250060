<template>
  <v-container fluid class="ma-0">
    <v-row>
      <v-col cols="12" md="12">
        <form-error
          :validator="$v.localDefaultValue.value"
          :attribute="$t('common.defaultValue')"
          :messages="messages"
        >
          <v-text-field
            v-model="localDefaultValue.value"
            slot-scope="{ attrs }"
            :label="$t('common.defaultValue')"
            v-bind="attrs"
            @input="$v.localDefaultValue.value.$touch()"
            @blur="$v.localDefaultValue.value.$touch()"
          />
        </form-error>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { email } from 'vuelidate/lib/validators'

export default {
  name: 'EmailFieldConfig',
  props: {
    fieldConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {
          value: 'contact@myotis.fr',
        }
      },
    },
  },
  data() {
    return {
      localDefaultValue: this.defaultValue,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    fieldConfig: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: undefined,
          defaultValue: this.localDefaultValue,
        })
      },
    },
    localDefaultValue: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: undefined,
          defaultValue: this.localDefaultValue,
        })
      },
    },
  },
  validations() {
    return {
      localDefaultValue: {
        value: { email },
      },
    }
  },
  mounted() {
    this.$emit('input', {
      fieldConfig: undefined,
      defaultValue: this.localDefaultValue,
    })
  },
}
</script>
