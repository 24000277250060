<template>
  <div v-if="IsMediumDown()">
    <v-app-bar app color="tertiary" :clipped-left="IsMediumDown()" height="48">
      <v-app-bar-nav-icon v-if="isAuthenticated" @click="navigationDrawer = true" />
    </v-app-bar>
    <v-navigation-drawer v-model="navigationDrawer" absolute temporary width="auto">
      <v-list nav dense>
        <v-list-item-group v-model="navigationDrawerGroup" active-class="secondary--text">
          <v-list-item to="/project">
            <v-tooltip right>
              <template #activator="{ on }">
                <v-avatar
                  color="tranparent"
                  height="36"
                  min-width="36"
                  width="36"
                  @click="openLink"
                  v-on="on"
                >
                  <v-img v-bind="logo" contain />
                </v-avatar>
              </template>
              <span>Vision - {{ $tc('common.project', 1) }}</span>
            </v-tooltip>
          </v-list-item>

          <app-bar-menu />

          <v-list-item>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-btn class="mx-auto" icon @click="logout" v-on="on">
                  <v-icon>$iconLogout</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('authentication.logout') }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
  <v-app-bar
    v-else
    id="app-bar"
    app
    color="tertiary"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    :max-width="$vuetify.breakpoint.width"
    height="48"
  >
    <v-avatar color="tranparent" height="36" min-width="36" width="36" @click="openLink">
      <v-img v-bind="logo" contain />
    </v-avatar>
    <span v-if="!$vuetify.breakpoint.mobile" class="text-h6 mr-4" style="color: #003e96"
      >VISION</span
    >
    <v-spacer />
    <v-toolbar-items v-if="isAuthenticated && UserCurrent.user_projects">
      <v-row align="center" no-gutters>
        <v-card
          v-if="UserCurrent.user_projects.length > 1"
          id="intro-home-1-project"
          elevation="0"
          :width="$vuetify.breakpoint.mobile ? '100px' : '340px'"
        >
          <v-container class="tertiary justify-space-between py-0">
            <span v-if="!$vuetify.breakpoint.mobile" class="text-body-2 pr-2">
              {{ $tc('common.projectColon', 2) }}
            </span>
            <v-autocomplete
              :items="project"
              item-text="name"
              item-value="id"
              :value="currentProjectId"
              :height="$vuetify.breakpoint.mobile ? 16 : 26"
              class="d-inline-block"
              dense
              hide-details
              @input="updateCurrentProject"
            />
          </v-container>
        </v-card>

        <app-bar-menu />

        <bar-project-report />

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn id="intro-home-4-userprofile" to="/user-profile" icon v-on="on">
              <v-icon>$iconUser</v-icon>
            </v-btn>
          </template>
          <span>{{ currentUser }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              id="intro-home-0-introjs"
              :disabled="!isInfo"
              icon
              @click="StartIntro(startIntroParam(true))"
              v-on="on"
            >
              <v-icon>$iconInfo</v-icon>
            </v-btn>
          </template>
          <span>{{ $tc('common.info', 2) }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn id="intro-home-5-logout" icon @click="logout" v-on="on">
              <v-icon>$iconLogout</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('authentication.logout') }}</span>
        </v-tooltip>
      </v-row>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { eventBus } from '@/plugins/eventBus'
import { GET_PROJECTS } from '@/graphql/ProjectQueries'
import BarProjectReport from '@/components/project/BarProjectReport'
import { CommonMixin } from '@/mixins/CommonMixin'
import { IntroMixin } from '@/mixins/IntroMixin'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import { UserMixin } from '@/mixins/UserMixin'
import AppBarMenu from '@/components/core/AppBarMenu'
import cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    AppBarMenu,
    BarProjectReport,
  },
  mixins: [CommonMixin, IntroMixin, ProjectMixin, UserMixin],
  apollo: {
    project: {
      query: GET_PROJECTS,
      skip() {
        return !this.isAuthenticated
      },
    },
  },
  data: () => ({
    logo: {
      alt: 'logo vision',
      src: '/img/logo-v.png',
    },
    navigationDrawer: false,
    navigationDrawerGroup: null,
    project: [],
    responsive: false,
    responsiveInput: false,
    title: null,
  }),
  computed: {
    ...mapGetters({
      currentDisplay: 'project/currentDisplay',
      currentDrawer: 'project/currentDrawer',
      currentProjectId: 'project/currentProjectId',
    }),
    currentUser() {
      return this.getCurrentUser()
    },
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated'] && this.$route.name !== 'Login'
    },
    isInfo() {
      let check = false
      if (this.currentDisplay === 'reportGraph') {
        check = true
      } else {
        check =
          this.$route.name === 'home' ||
          this.currentDisplay === 'reportForm' ||
          this.$route.name === 'ReportEdit'
      }
      return check && !this.$vuetify.breakpoint.mobile
    },
  },
  watch: {
    $route(val, oldVal) {
      this.title = val.name
      if (val.name !== oldVal.name) this.launchIntro(5000)
    },
    currentDisplay(val, oldVal) {
      if (val !== oldVal) this.launchIntro(2000)
    },
    currentDrawer(val, oldVal) {
      if (val !== oldVal) this.launchIntro(2000)
    },
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.updateCurrentDisplay('none')
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      setCurrentProject: 'project/setCurrentProject',
      updateCurrentDisplay: 'project/updateCurrentDisplay',
    }),
    getCurrentUser() {
      const fullname = this.GetUserFullname(this.UserCurrent.id)
      const role = this.UserCurrent?.user_roles[0]?.role?.role
      const concat = fullname + ' (' + role + ')'
      return fullname && role ? concat : this.$tc('common.user', 1)
    },
    launchIntro(timeOut) {
      setTimeout(() => {
        this.StartIntro(this.startIntroParam())
      }, timeOut || 3000)
    },
    onClick() {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
      this.responsive = true
    },
    openLink() {
      if (this.$route.path === '/project') {
        this.$router.go()
      } else {
        this.$router.push('/project')
      }
    },
    startIntroParam(manual) {
      let param = null

      if (this.isInfo) {
        if (this.$route.name === 'home' && this.currentDrawer !== 'none') {
          param = 'drawer' + this.currentDrawer
        } else {
          param =
            this.currentDisplay !== 'none'
              ? this.currentDisplay.toLowerCase()
              : this.$route.name.toLowerCase()
        }

        if (!manual) {
          if (this.UserCurrent.profile?.viewIntro?.includes(param)) {
            param = null
          } else {
            this.updateViewIntro(param)
          }
        }
      }
      return param
    },
    async updateCurrentProject(selectId) {
      this.setCurrentProject(selectId)
      eventBus.$emit('updateProjectId', selectId)
      return true
    },
    async updateViewIntro(param) {
      const userBody = cloneDeep(this.UserCurrent)
      let result = null

      if (!userBody.profile?.viewIntro) {
        userBody.profile.viewIntro = []
        userBody.profile.viewIntro.push(param)
        result = await this.UpdateUser(userBody)
      } else if (!userBody.profile?.viewIntro.includes(param)) {
        userBody.profile.viewIntro.push(param)
        result = await this.UpdateUser(userBody)
      } else {
        // do nothing
      }

      if (result?.update_user) {
        this.$store.dispatch('auth/updateUserProfile', {
          viewIntro: userBody.profile.viewIntro,
        })
      }
    },
  },
}
</script>

<style>
#app-bar a {
  text-decoration: none;
}
#app-bar .v-toolbar__content {
  padding: 2px 2px 0 8px;
}
</style>
