var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"ma-0 pa-0",attrs:{"max-width":"500","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t('user.sendInvitationTitle'))+" ")]),_c('v-card-text',{staticClass:"pa-0"},[_c('v-stepper',{attrs:{"light":""},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 1,"editable":"","step":"1"}},[_vm._v(" "+_vm._s(_vm.$tc('common.user', 1))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 2,"editable":"","step":"2"}},[_vm._v(" "+_vm._s(_vm.$t('user.rights'))+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"editable":"","step":"3"}},[_vm._v(" "+_vm._s(_vm.$tc('common.project', 1))+" ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('form-error',{attrs:{"validator":_vm.$v.invitation.firstname,"attribute":_vm.$t('contact.firstname'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"label":_vm.$t('contact.firstname') + ' *',"required":""},on:{"input":function($event){return _vm.$v.invitation.firstname.$touch()},"blur":function($event){return _vm.$v.invitation.firstname.$touch()}},model:{value:(_vm.invitation.firstname),callback:function ($$v) {_vm.$set(_vm.invitation, "firstname", $$v)},expression:"invitation.firstname"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('form-error',{attrs:{"validator":_vm.$v.invitation.lastname,"attribute":_vm.$t('contact.lastname'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"label":_vm.$t('contact.lastname') + ' *',"required":""},on:{"input":function($event){return _vm.$v.invitation.lastname.$touch()},"blur":function($event){return _vm.$v.invitation.lastname.$touch()}},model:{value:(_vm.invitation.lastname),callback:function ($$v) {_vm.$set(_vm.invitation, "lastname", $$v)},expression:"invitation.lastname"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-error',{attrs:{"validator":_vm.$v.invitation.options.company,"attribute":_vm.$tc('common.company', 1),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('company-selector',_vm._b({on:{"input":function($event){return _vm.$v.invitation.options.company.$touch()},"blur":function($event){return _vm.$v.invitation.options.company.$touch()}},model:{value:(_vm.invitation.options.company),callback:function ($$v) {_vm.$set(_vm.invitation.options, "company", $$v)},expression:"invitation.options.company"}},'company-selector',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('form-error',{attrs:{"validator":_vm.$v.invitation.email,"attribute":_vm.$t('common.email'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-text-field',_vm._b({attrs:{"label":_vm.$t('common.email') + ' *',"required":""},on:{"input":function($event){return _vm.$v.invitation.email.$touch()},"blur":function($event){return _vm.$v.invitation.email.$touch()}},model:{value:(_vm.invitation.email),callback:function ($$v) {_vm.$set(_vm.invitation, "email", $$v)},expression:"invitation.email"}},'v-text-field',attrs,false))}}])})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.nextStep(2)}}},[_vm._v(" "+_vm._s(_vm.$t('common.continue'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")])],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('form-error',{attrs:{"validator":_vm.$v.invitation.options.userRoleDefault,"attribute":_vm.$t('user.roleDefaultSelect'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-select',_vm._b({attrs:{"items":_vm.role,"item-text":"role","item-value":"uuid","label":_vm.$t('user.roleDefaultSelect') + ' *',"chips":"","persistent-hint":""},on:{"input":function($event){return _vm.$v.invitation.options.userRoleDefault.$touch()},"blur":function($event){return _vm.$v.invitation.options.userRoleDefault.$touch()}},model:{value:(_vm.invitation.options.userRoleDefault),callback:function ($$v) {_vm.$set(_vm.invitation.options, "userRoleDefault", $$v)},expression:"invitation.options.userRoleDefault"}},'v-select',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.rolesComplementaryList,"item-text":"role","item-value":"uuid","label":_vm.$t('user.roleComplementarySelect'),"chips":"","multiple":"","persistent-hint":""},model:{value:(_vm.invitation.options.userRoles),callback:function ($$v) {_vm.$set(_vm.invitation.options, "userRoles", $$v)},expression:"invitation.options.userRoles"}}),_c('v-col',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.nextStep(3)}}},[_vm._v(" "+_vm._s(_vm.$t('common.continue'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")])],1)],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('form-error',{attrs:{"validator":_vm.$v.invitation.options.projectDefault,"attribute":_vm.$t('user.projectDefaultSelect'),"messages":_vm.messages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return _c('v-select',_vm._b({attrs:{"items":_vm.project,"item-text":"name","item-value":"id","label":_vm.$t('user.projectDefaultSelect') + ' *',"chips":"","persistent-hint":""},on:{"input":function($event){return _vm.$v.invitation.options.projectDefault.$touch()},"blur":function($event){return _vm.$v.invitation.options.projectDefault.$touch()}},model:{value:(_vm.invitation.options.projectDefault),callback:function ($$v) {_vm.$set(_vm.invitation.options, "projectDefault", $$v)},expression:"invitation.options.projectDefault"}},'v-select',attrs,false))}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.projectsComplementaryList,"item-text":"name","item-value":"id","label":_vm.$t('user.projectComplementarySelect'),"chips":"","multiple":"","persistent-hint":""},model:{value:(_vm.invitation.options.projects),callback:function ($$v) {_vm.$set(_vm.invitation.options, "projects", $$v)},expression:"invitation.options.projects"}}),_c('v-col',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.sendInvitation}},[_vm._v(" "+_vm._s(_vm.$t('common.send'))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }