<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col class="pt-4">
        <v-combobox
          v-model="sensorModel"
          :items="sensorList"
          item-text="device_select"
          :item-value="sensorList"
          :label="
            $t('report.enum.deviceType.sensor') + ' (' + $t('report.enum.deviceType.hub') + ')'
          "
          :menu-props="{ top: false, offsetY: true }"
          :placeholder="$t('common.select')"
          :return-object="isTrue"
          prepend-icon="$iconSensor"
          clearable
          dense
          hide-selected
        />
      </v-col>
    </v-row>
    <v-row align="baseline" no-gutters>
      <v-col :cols="settingsLocal.isTimeInterval ? 7 : 5">
        <v-switch
          id="switch-is-time-interval"
          v-model="settingsLocal.isTimeInterval"
          :label="$t('report.deadline')"
          class="mt-0 pt-0"
          color="secondary"
          dense
          hide-details
        />
      </v-col>
      <v-col v-if="settingsLocal.isTimeInterval" cols="4">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-switch
              v-model="settingsLocal.isTimeIntervalToNow"
              class="mt-0"
              color="secondary"
              dense
              hide-details
            >
              <template #label>
                <v-icon dense v-on="on">$iconClockStartReverse</v-icon>
              </template>
            </v-switch>
          </template>
          <span>
            {{ $t('dashboard.endDateIsNow') }}
          </span>
        </v-tooltip>
      </v-col>
      <v-col v-else class="d-inline-flex align-baseline">
        <span class="text-caption" style="min-width: 110px">
          {{ $t('dashboard.slipperyPeriod') }}
        </span>
        <v-text-field
          v-model.number="settingsLocal.timeBefore"
          class="mt-0"
          height="24px"
          min="1"
          step="1"
          type="number"
          dense
          hide-details
          single-line
        />
        <v-select
          v-model="settingsLocal.timeBeforeUnit"
          :items="timeBeforeList"
          class="ml-2 mt-0"
          height="24px"
          dense
          hide-details
        >
          <template #selection="data">
            {{ $tc('common.time.' + data.item.substring(0, data.item.length - 1), 2) }}
          </template>
          <template #item="data">
            {{ $tc('common.time.' + data.item.substring(0, data.item.length - 1), 2) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="settingsLocal.isTimeInterval" no-gutters>
      <v-col>
        <time-interval
          v-model="optionsTimeInterval"
          :is-date-props-max="!isTrue"
          :is-in-progress="!isTrue"
        />
      </v-col>
    </v-row>
    <v-row align="center" class="pt-2" no-gutters>
      <v-col>
        <v-switch
          id="switch-scale"
          v-model="isScale"
          class="mt-0"
          color="secondary"
          :label="$t('common.scale')"
          dense
          hide-details
        />
      </v-col>
      <v-col v-if="isScale">
        <graph-scale v-model="optionsScale" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatDateTimeIso8601 } from '@/locales/formats/dataTimeFormats'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import GraphScale from '@/components/project/graphs/GraphScale'
import TimeInterval from '@/components/helper/TimeInterval'
import moment from 'moment'

export default {
  name: 'DashboardWidgetSettingsGaph',
  components: {
    GraphScale,
    TimeInterval,
  },
  mixins: [ProjectMixin],
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isScale: false,
    isTrue: true,
    optionsScale: {},
    optionsTimeInterval: {
      dateTimeFrom: null,
      dateTimeTo: null,
    },
    settingsLocal: {},
    timeBeforeDefault: 2,
    timeBeforeList: ['hours', 'days', 'weeks', 'months', 'years'],
    timeBeforeUnitDefault: 'days',
  }),
  computed: {
    sensorList() {
      return this.getDeviceList('sensor')
    },
    sensorModel: {
      get() {
        return this.searchSensor(this.settingsLocal.device_id)
      },
      set(val) {
        this.settingsLocal.device_id = val?.uuid || ''
        const title = this.searchSensor(val?.uuid)?.device_select || ''
        this.$emit('changeTitle', title)
      },
    },
  },
  watch: {
    isScale: function (val) {
      if (!val) {
        delete this.settingsLocal.newScale
        delete this.settingsLocal.yaxisMax
        delete this.settingsLocal.yaxisMin
      } else {
        this.initScale()
      }
    },
    optionsScale: function (val) {
      if (val.newScale) {
        this.settingsLocal.newScale = val?.newScale
        this.settingsLocal.yaxisMax = val?.yaxisMax
        this.settingsLocal.yaxisMin = val?.yaxisMin
      }
    },
    optionsTimeInterval: function (val) {
      this.settingsLocal.dateTimeFrom = val.dateTimeFrom
      this.settingsLocal.dateTimeTo = val.dateTimeTo
    },
    'settingsLocal.isTimeIntervalToNow': function (val) {
      if (val) {
        this.settingsTimeInterval('isTimeIntervalToNow')
      }
    },
    'settingsLocal.isTimeInterval': function (val) {
      if (val) {
        delete this.settingsLocal.timeBefore
        delete this.settingsLocal.timeBeforeUnit
      } else {
        this.settingsLocal.timeBefore = this.timeBeforeDefault
        this.settingsLocal.timeBeforeUnit = this.timeBeforeUnitDefault
        this.settingsTimeInterval('timeBefore')
      }
    },
    'settingsLocal.timeBefore': function () {
      this.settingsTimeInterval('timeBefore')
    },
    'settingsLocal.timeBeforeUnit': function () {
      this.settingsTimeInterval('timeBefore')
    },
  },
  mounted() {
    this.settingsLocal = this.settings
    if (!this.settingsLocal.dateTimeFrom && !this.settingsLocal.dateTimeTo) {
      this.settingsTimeInterval('default')
    } else {
      this.optionsTimeInterval = {
        dateTimeFrom: this.settingsLocal.dateTimeFrom,
        dateTimeTo: this.settingsLocal.dateTimeTo,
      }
    }
    this.initScale()
    this.isScale = this.settingsLocal?.newScale
  },
  methods: {
    getDeviceList(device) {
      return this.GetDeviceList(device)
    },
    initScale() {
      this.optionsScale = {
        newScale: this.settingsLocal?.newScale || false,
        yaxisMax: this.settingsLocal?.yaxisMax || 0,
        yaxisMin: this.settingsLocal?.yaxisMin || 0,
      }
    },
    searchSensor(deviceId) {
      const search = this.sensorList.find((i) => i.uuid === deviceId)
      return search || []
    },
    settingsTimeInterval(type) {
      const dateNow = moment().format(formatDateTimeIso8601)

      // default value
      let from = moment()
        .subtract(this.timeBeforeDefault, this.timeBeforeUnitDefault)
        .format(formatDateTimeIso8601)

      switch (type) {
        case 'isTimeIntervalToNow':
          from = this.settingsLocal.dateTimeFrom
          break
        case 'timeBefore':
          this.settingsLocal.isTimeInterval = false
          this.settingsLocal.isTimeIntervalToNow = false
          from = moment()
            .subtract(this.settingsLocal.timeBefore, this.settingsLocal.timeBeforeUnit)
            .format(formatDateTimeIso8601)
          break
        case 'default':
        default:
          break
      }

      this.optionsTimeInterval = {
        dateTimeFrom: from,
        dateTimeTo: dateNow,
      }
    },
  },
}
</script>

<style lang="css" scoped>
label[for='switch-is-time-interval'],
label[for='switch-scale'] {
  font-size: 12px;
}
</style>
