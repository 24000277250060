<template>
  <v-container class="pa-0">
    <v-card
      id="intro-reportgraph-1-scale"
      elevation="0"
      height="36"
      :max-width="isDashboard ? 200 : 245"
      :min-width="isDashboard ? 200 : 245"
      tile
    >
      <v-row align="baseline" justify="space-around" no-gutters>
        <v-col v-if="!isDashboard" cols="2" class="text-caption" style="min-width: 41px">
          {{ $t('common.scale') }}
        </v-col>
        <v-col class="mr-2 ml-1">
          <v-text-field
            v-model.number="yaxisMin"
            :label="$tc('common.threshold.low', 2)"
            :step="step()"
            :validate-on-blur="isTrue"
            class="mt-0 text-body-2"
            dense
            hide-details="true"
            single-line
            type="number"
            @focus="resetTimer()"
            @blur="updateScale()"
          >
            <template #prepend>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon class="pt-1" dense small v-on="on"> $iconArrowLow </v-icon>
                </template>
                <span>{{ $t('common.scaleLow') }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model.number="yaxisMax"
            :label="$tc('common.threshold.high', 2)"
            :step="step()"
            :validate-on-blur="isTrue"
            class="mt-0 text-body-2"
            dense
            hide-details="true"
            single-line
            type="number"
            @focus="resetTimer()"
            @blur="updateScale()"
          >
            <template #prepend>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon class="pt-1" dense small v-on="on"> $iconArrowHigh </v-icon>
                </template>
                <span>{{ $t('common.scaleHigh') }}</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'GraphScale',
  props: {
    isDashboard: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      downloadTimer: 0,
      isTrue: true,
      timeleft: -1,
      yaxisMax: this.value.yaxisMax || 0,
      yaxisMin: this.value.yaxisMin || 0,
    }
  },
  watch: {
    timeleft: function (val) {
      if (val === 0) {
        const completed = this.$t('project.enum.stateLog.completed')
        this.$emit('input', {
          yaxisMin: this.yaxisMin,
          yaxisMax: this.yaxisMax,
          newScale: true,
        })
        this.$root.$dialogLoader.showSnackbar(completed, { color: 'success' })
      }
      if (val === 1) {
        const wip = this.$t('project.enum.stateLog.in_progress')
        this.$root.$dialogLoader.showSnackbar(wip, { color: 'primary' })
      }
    },
  },
  methods: {
    resetTimer() {
      this.$root.$dialogLoader.hideSnackbar()
      clearInterval(this.downloadTimer)
    },
    startTimer() {
      this.timeleft = 5
      this.downloadTimer = setInterval(() => {
        if (this.timeleft <= 0) {
          this.resetTimer()
        } else {
          this.timeleft -= 1
        }
      }, 100)
    },
    step() {
      const diff = this.yaxisMax - this.yaxisMin
      let step = 1
      if (diff < 1) {
        step = 0.001
      } else if (diff <= 1 && diff >= 3) {
        step = 0.1
      } else {
        step = 1
      }
      return step
    },
    updateScale() {
      if (!Number.isNaN(event.target.valueAsNumber)) {
        this.startTimer()
      }
    },
  },
}
</script>
