<template>
  <v-card flat tile class="d-flex" :height="size" :max-width="size">
    <v-badge color="transparent" offset-x="15" offset-y="7" :value="calcDiff(localDoc.datetime)">
      <v-hover v-if="isHover">
        <template #default="{ hover }">
          <v-tooltip
            :left="!hoverRight"
            :right="hoverRight"
            color="primary"
            content-class="pa-1"
            :nudge-left="hoverRight ? 0 : 10"
            :nudge-right="hoverRight ? 10 : 0"
          >
            <template #activator="{ on }">
              <v-img
                :src="thumbnailSrc"
                :alt="imgAlt"
                :contain="isContain"
                :max-height="size"
                :max-width="size"
                :min-height="size"
                :min-width="size"
                class="tertiary"
                width="100%"
                @error="onDocError"
                v-on="on"
              >
                <template #placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" />
                  </v-row>
                </template>
              </v-img>
            </template>
            <v-img
              v-if="!hover"
              :src="thumbnailSrc"
              :alt="imgAlt"
              :contain="isContain"
              :max-height="size * 7"
              :max-width="size * 7"
              :min-height="size * 7"
              :min-width="size * 7"
              class="tertiary"
              width="100%"
              @error="onDocError"
            >
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary" />
                </v-row>
              </template>
            </v-img>
          </v-tooltip>
        </template>
      </v-hover>
      <v-img
        v-else
        :src="thumbnailSrc"
        :alt="imgAlt"
        :contain="isContain"
        :max-height="size"
        :max-width="size"
        :min-height="size"
        :min-width="size"
        class="tertiary"
        width="100%"
        @error="onDocError"
      >
        <template #placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5" />
          </v-row>
        </template>
      </v-img>
      <template slot="badge">
        <v-tooltip right>
          <template #activator="{ on }">
            <v-icon color="green" v-on="on">$iconAlert</v-icon>
          </template>
          <span>{{ newDocument }}</span>
        </v-tooltip>
      </template>
    </v-badge>
  </v-card>
</template>

<script>
import { CommonMixin } from '@/mixins/CommonMixin'
import { storageClient } from '@/plugins/storage'
import * as moment from 'moment/moment'

export default {
  name: 'HelperThumbnail',
  mixins: [CommonMixin],
  props: {
    doc: {
      type: Object,
      default: () => {}, // @see docDefault
    },
    hoverRight: {
      type: Boolean,
      default: true,
    },
    isContain: {
      type: Boolean,
      default: false,
    },
    isHover: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'auto',
    },
  },
  data: () => ({
    docFailed: false,
    docDefault: {
      datetime: '2021-08-08T00:00:00.000000+00:00',
      key: null,
      title: 'thumbnail image',
      token: null,
    },
    thumbnailPath: '',
  }),
  computed: {
    imgAlt() {
      return this.localDoc.title
    },
    localDoc() {
      return Object.assign({}, this.docDefault, this.doc)
    },
    newDocument() {
      return (
        this.$t('common.new') +
        ' ' +
        this.$tc('common.document', 1).toLowerCase() +
        ' < ' +
        this.$tc('common.day', 2, { count: 10 })
      )
    },
    thumbnailSrc() {
      return this.getThumbnailStorage()
    },
  },
  methods: {
    calcDiff(datetime) {
      const dateNow = moment()
      const diff = dateNow.diff(datetime)
      // 24h = 86400000 ms
      return diff <= 86400000 * 10
    },
    getThumbnailStorage() {
      let storageSrc = ''
      if (this.localDoc.key && this.localDoc.token) {
        const key = this.localDoc.key
        const token = this.localDoc.token

        // Replace path with original by thumbnails to get access thumbnail
        const path = decodeURIComponent(key).replace('/original/', '/thumbnails/')

        this.thumbnailCheck(path, token)

        if (this.thumbnailPath !== '') {
          const thumbSplit = this.thumbnailPath.split('|')
          storageSrc = storageClient.getUrl(thumbSplit[0], thumbSplit[1])
        }
      }
      return storageSrc
    },
    onDocError() {
      this.docFailed = true
    },
    async thumbnailCheck(path, token) {
      // Old path thumbnail: Remove original extension + add extension .png
      const pathOld = this.GetStringSplit(path, '.', 0) + '.png'

      // Check pathOld
      const isFileExistOld = await storageClient.isStorageFileExist(pathOld)

      if (isFileExistOld) {
        this.thumbnailPath = pathOld + '|' + token
      } else {
        // New path thubnmail: Keep original extension + add extension .png
        this.thumbnailPath = path + '.png' + '|' + token
      }
    },
  },
}
</script>
