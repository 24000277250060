import gql from 'graphql-tag'

const projectViewFragment = gql`
  fragment ProjectView on project_view {
    uuid
    name
    project_uuid
    options
    type
    default
    created_at
    updated_at
  }
`

// queries
export const GET_PROJECT_VIEW = gql`
  query ($uuid: uuid!) {
    project_view_by_pk(uuid: $uuid) {
      ...ProjectView
    }
  }
  ${projectViewFragment}
`
export const GET_PROJECT_VIEWS = gql`
  query project_view(
    $where: project_view_bool_exp
    $order_by: [project_view_order_by!]
    $limit: Int
    $offset: Int
  ) {
    project_view_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    project_view(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...ProjectView
    }
  }
  ${projectViewFragment}
`

// mutations
export const PATCH_PROJECT_VIEW = gql`
  mutation ($uuid: uuid!, $body: project_view_set_input!) {
    update_project_view(where: { uuid: { _eq: $uuid } }, _set: $body) {
      affected_rows
      returning {
        ...ProjectView
      }
    }
  }
  ${projectViewFragment}
`

export const POST_PROJECT_VIEW = gql`
  mutation ($objects: [project_view_insert_input!]!) {
    insert_project_view(objects: $objects) {
      returning {
        ...ProjectView
      }
    }
  }
  ${projectViewFragment}
`

export const DELETE_PROJECT_VIEW = gql`
  mutation ($uuid: uuid!) {
    delete_project_view(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
        name
      }
    }
  }
`
