import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { HttpLink } from 'apollo-link-http'
import { concat, split, fromPromise } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import Configuration from '@/configuration'

import store from '@/store'
import Router from '@/router'

Vue.use(VueApollo)

const wsCoreLink = new GraphQLWsLink(
  createClient({
    url: Configuration.value('apiCoreWs'),
    lazy: true,
    connectionParams: async () => {
      const Authorization = authorizeToken()
      return Authorization ? { Authorization, headers: { Authorization } } : {}
    },
    on: {
      error: async (error) => {
        if (error === 'Could not verify JWT: JWTExpired') {
          await store.dispatch('auth/refreshToken')
          wsCoreLink.client.close(false, false)
        }
      },
    },
  })
)

// HTTP connexion to the API
const httpCoreLink = new HttpLink({
  // You should use an absolute URL here
  uri: Configuration.value('apiCoreHttp'),
})

const authLink = setContext(async (_, { headers }) => {
  const Authorization = authorizeToken()
  const authorizationHeader = Authorization ? { Authorization } : {}
  return {
    headers: {
      ...headers,
      ...authorizationHeader,
    },
  }
})

let isRefreshing = false
let pendingRequests = []

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback())
  pendingRequests = []
}

// Error handler
const errorLink = onError(({ operation, forward }) => {
  let forward$
  if (!isRefreshing) {
    isRefreshing = true
    forward$ = fromPromise(
      store
        .dispatch('auth/refreshToken')
        .then(() => {
          // Store the new tokens for your auth link
          resolvePendingRequests()
          return true
        })
        .catch(() => {
          pendingRequests = []
          store.dispatch('auth/logout')
          Router.push('/login').catch(() => {})
        })
        .finally(() => {
          isRefreshing = false
        })
    ).filter((value) => {
      return Boolean(value)
    })
  } else {
    // Will only emit once the Promise is resolved
    forward$ = fromPromise(
      new Promise((resolve) => {
        pendingRequests.push(() => resolve())
      })
    )
  }
  return forward$.flatMap(() => {
    return forward(operation)
  })
})

const Corelink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  concat(authLink, wsCoreLink),
  concat(errorLink, concat(authLink, httpCoreLink))
  // authLink.concat(errorLink, wsCoreLink),
  // authLink.concat(errorLink, httpCoreLink),
)

export function createProvider() {
  // Create the API Core apollo client
  const apolloCoreClient = new ApolloClient({
    link: Corelink,
    cache: new InMemoryCache(),
  })

  apolloCoreClient.cache.reset()

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      apolloCoreClient,
    },
    defaultClient: apolloCoreClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('apolloProvider', error)
      }
    },
  })

  return apolloProvider
}

function authorizeToken() {
  return `Bearer ${store.getters['auth/token']}`
}
