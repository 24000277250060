<template lang="html">
  <v-container class="fill-height overflow-y-auto px-0" fluid>
    <v-card class="mx-auto pt-6" elevation="3" max-width="350" max-height="380">
      <v-img v-bind="logo" contain height="110" />
      <v-alert v-if="loading" class="mt-8 mb-0" rounded="0" type="warning" text>
        {{ $t('common.loading') }}
      </v-alert>
      <v-alert v-else class="mt-8 mb-0" rounded="0" type="error" text>
        <v-row align="center">
          <v-col class="grow">
            {{ $t('common.accessDenied') }}
          </v-col>
          <v-col class="shrink">
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn icon small color="error" @click="$router.push('/project')" v-on="on">
                  <v-icon> $iconArrowLeft2 </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('common.back') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'AccessDenied',
  data() {
    return {
      loading: true,
      logo: {
        alt: 'logo vision',
        src: '/img/logo-vision-baseline-en.png',
        title: 'Vision',
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 2000)
  },
}
</script>
