<template>
  <v-row>
    <v-col v-for="(doc, i) in localDocuments" :key="i" cols="auto">
      <v-hover>
        <template #default="{ hover }">
          <v-card flat tile class="elevation-1" :height="size" :max-width="size">
            <thumbnail
              :doc="{
                datetime: doc.datetime,
                key: doc.key,
                title: doc.key,
                token: doc.token,
              }"
              :size="size"
            />
            <v-overlay v-if="hover" absolute class="text-center py-1" :style="styleOverlay">
              <v-tooltip v-if="ShowDoc(doc.contentType)" bottom>
                <template #activator="{ on }">
                  <v-btn fab outlined x-small @click="viewDocument(doc)" v-on="on">
                    <v-icon>$iconEye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.see') }} {{ doc.title }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    class="mx-1"
                    fab
                    outlined
                    x-small
                    v-on="on"
                    @click="Download(doc.key, doc.token, doc.title)"
                  >
                    <v-icon>$iconDownload</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.upload') }}</span>
              </v-tooltip>
              <v-tooltip v-if="isEdit" bottom>
                <template #activator="{ on }">
                  <v-btn class="mr-1" fab outlined x-small v-on="on" @click="editDoc(doc)">
                    <v-icon>$iconEdit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.edit') }}</span>
              </v-tooltip>
              <v-tooltip v-if="isDelete" bottom>
                <template #activator="{ on }">
                  <v-btn fab outlined x-small v-on="on" @click="deleteDoc(doc)">
                    <v-icon>$iconDelete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('common.delete') }}</span>
              </v-tooltip>
            </v-overlay>
          </v-card>
        </template>
      </v-hover>
    </v-col>
    <image-viewer v-model="imageViewer" :image="imageViewerSrc" />
    <pdf-viewer v-model="pdfViewer" :pdf-file="pdfViewerSrc" />
  </v-row>
</template>

<script>
import ImageViewer from '@/components/helper/ImageViewer'
import PdfViewer from '@/components/helper/PdfViewer'
import Thumbnail from '@/components/helper/Thumbnail'
import { DocumentMixin } from '@/mixins/DocumentMixin'

export default {
  name: 'HelperGallery',
  components: {
    ImageViewer,
    PdfViewer,
    Thumbnail,
  },
  mixins: [DocumentMixin],
  props: {
    documents: {
      type: Array,
      default: () => [
        /*
        // default object
        {
          categoryName: '',
          contentType: 'text/plain',
          datetime: '2021-08-08T00:00:00.000000+00:00',
          key: null,
          title: 'thumbnail image',
          token: null,
          uuid: '',
        }
        */
      ],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '150',
    },
  },
  data: function () {
    return {
      imageViewer: false,
      imageViewerSrc: undefined,
      pdfViewer: false,
      pdfViewerSrc: undefined,
    }
  },
  computed: {
    localDocuments() {
      return this.documents
    },
    styleOverlay() {
      const topOverlay = this.size === 'auto' ? '110px' : this.size - 40 + 'px'
      return { top: topOverlay }
    },
  },
  methods: {
    editDoc(doc) {
      this.$emit('edit-doc', doc)
    },
    deleteDoc(doc) {
      this.$emit('delete-doc', doc)
    },
    viewDocument(doc) {
      const key = doc.key
      const token = doc.token
      if (this.ImageType.includes(doc.contentType)) {
        this.imageViewerSrc = {
          categoryName: doc?.categoryName,
          key: key,
          title: doc.title,
          token: token,
          uuid: doc.uuid,
        }
        this.imageViewer = true
      } else {
        this.pdfViewerSrc = {
          key: key,
          token: token,
        }
        this.pdfViewer = true
      }
    },
  },
}
</script>
