<template>
  <v-dialog
    v-model="displayDialog"
    fullscreen
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-text class="pa-0">
        <vue-pdf-app
          v-if="displayPdf"
          :config="config"
          :pdf="pdfUrl"
          theme="dark"
          @open="openHandler"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { storageClient } from '@/plugins/storage'
import { DocumentMixin } from '@/mixins/DocumentMixin'
import 'vue-pdf-app/dist/icons/main.css'
import VuePdfApp from 'vue-pdf-app'

export default {
  name: 'PdfViewer2',
  components: {
    VuePdfApp,
  },
  mixins: [DocumentMixin],
  props: {
    pdfFile: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      config: {
        toolbar: {
          toolbarViewerRight: {
            openFile: false,
            viewBookmark: false,
          },
        },
      },
      displayDialog: false,
      displayPdf: false,
      localPdfFile: {
        key: '',
        token: '',
      },
      pdfUrl: null,
    }
  },
  watch: {
    pdfFile: function (val) {
      if (val.key && val.token) {
        this.displayDialog = true
        this.localPdfFile = val
        this.getPdfPreviewUrl()
      }
    },
  },
  methods: {
    addCloseDialog() {
      const btn = document.createElement('button')
      btn.classList.add('vue-pdf-app-icon', 'toolbarButton', 'pt-1', 'px-0')
      btn.setAttribute('title', this.$t('common.close'))
      btn.addEventListener('click', this.closeDialog)
      btn.innerHTML = `<span class="d-table mdi mdi-close mdi-24px"></span>`
      const getId = document.getElementById('toolbarViewerRight')
      if (getId) getId.appendChild(btn)
    },
    closeDialog() {
      this.pdfUrl = null
      this.displayPdf = false
      this.displayDialog = false
    },
    openHandler() {
      this.addCloseDialog()
    },
    async getPdfPreviewUrl() {
      const url = this.GetUrlDoc(this.localPdfFile.key, this.localPdfFile.token)
      const fileStat = await storageClient.isFileExist(url)

      if (fileStat) {
        this.pdfUrl = url
        this.displayPdf = true
      } else {
        this.closeDialog()
      }
    },
  },
}
</script>

<style lang="css">
.pdf-viewer-content {
  position: absolute;
  top: 40px;
  z-index: 100;
}
.pdf-viewer-vertical-buttons {
  right: 2%;
  width: 36px;
}
</style>