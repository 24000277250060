<template>
  <v-app>
    <helper-confirm ref="confirm" />
    <helper-dialog-loader ref="dialogLoader" />
    <core-app-bar />
    <core-view />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: mapState(['locale']),
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$dialogLoader = this.$refs.dialogLoader
  },
}
</script>

<style src="intro.js/minified/introjs.min.css" />
