<template lang="html">
  <v-dialog v-model="dialog" max-width="75%">
    <template #activator="{ on }">
      <v-btn class="mb-2" v-on="on">
        {{ buttonTitle }}
      </v-btn>
    </template>
    <v-card>
      <div class="d-flex justify-space-between py-1 pl-3 pr-4">
        <v-card-title>
          {{ title }}
        </v-card-title>
        <v-spacer />
        <v-card-actions>
          <v-btn @click="close">
            <v-icon class="mr-1" color="primary">$iconClose</v-icon>{{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <v-sheet class="pa-3 mb-4" outlined>
          <v-row>
            <v-col cols="12" sm="6">
              <helper-crud-list-filter
                v-model="options.filter"
                :filters.sync="filters"
                :style-class="styleClassCrudListFilter"
                :style-outlined="styleOutlinedCrudListFilter"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <helper-tags-selector v-model="tagsLibDevice" :edit-item="itemEdit" />
            </v-col>
          </v-row>
        </v-sheet>
        <v-data-table
          :headers="headers"
          :items="libDeviceParameters"
          :options.sync="options"
          dense
          fixed-header
          :footer-props="{
            'items-per-page-text': itemsPerPageText,
            'page-text': pageText,
            'items-per-page-options': [5, 10, 25, 50, -1],
          }"
          :height="StyleHeightCalc('64px + 64px + 72px + 86px + 14px').height"
          :no-data-text="$t('common.noDataText')"
          :server-items-length="totalCount"
          @click:row="selectConfig($event)"
        >
          <template #[`item.field_type`]="{ item }">
            <span>{{ getConfigParamLabel(item.field_type) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  GET_LIB_DEVICE_PARAMETERS,
  GET_LIB_DEVICE_PARAMETER,
} from '@/graphql/LibDeviceParameterQueries'
import { CommonMixin } from '@/mixins/CommonMixin'

export default {
  name: 'ConfigPropertySelector',
  mixins: [CommonMixin],
  props: {
    editItem: {
      type: String,
      default: '',
    },
    libDeviceTags: {
      type: Array,
      default: () => [],
    },
    styleClass: {
      type: String,
      default: '',
    },
    styleOutlined: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: 'device',
    },
  },
  apollo: {
    libDeviceParameters: {
      query: GET_LIB_DEVICE_PARAMETERS,
      variables() {
        this.convertOptionsToGQL()
        return this.params
      },
      update(data) {
        return data.lib_device_parameter
      },
      result({ data }) {
        if (data) {
          this.totalCount = data.lib_device_parameter_aggregate.aggregate.totalCount
        }
      },
    },
  },
  data: () => ({
    dialog: false,
    filters: {
      name: {
        type: 'v-text-field',
        col: { cols: 12 },
        options: {
          label: '',
          placeholder: '',
          clearable: true,
        },
        gql: (value) => ({ name: { _ilike: `%${value}%` } }),
        value: '',
      },
    },
    itemEdit: 'off',
    options: {
      itemsPerPage: 10,
      page: 1,
      sortBy: ['name'],
      sortDesc: [true],
      filter: {},
    },
    styleClassCrudListFilter: 'my-0 pa-0',
    styleOutlinedCrudListFilter: 'off',
    totalCount: 0,
    tagsLibDevice: [],
  }),
  computed: {
    buttonTitle: function () {
      return this.$t('libDevice.addParameter')
    },
    configPropertyType() {
      const componentFields = require.context(
        '@/components/formfields',
        true,
        /^.*FieldConfig\.vue$/
      )

      const formfields = componentFields.keys().map((filename) => {
        const field = {}
        field.component = filename
        field.name = filename.replace('FieldConfig.vue', '').replace('./', '')
        field.label = this.$t(`libDeviceParameter.fieldtype.${field.name.toLowerCase()}`)

        return field
      })

      return formfields
    },
    headers: function () {
      return [
        {
          text: this.$t('libDeviceParameter.configLabel'),
          value: 'config_label',
        },
        { text: this.$t('common.name'), value: 'name' },
        { text: this.$t('common.description'), value: 'description' },
      ]
    },
    itemsPerPageText: function () {
      return this.$t('dataFooter.itemsPerPageText')
    },
    pageText: function () {
      return this.$t('dataFooter.pageText')
    },
    title: function () {
      return this.$t('libDevice.parameter.add')
    },
  },
  watch: {
    options: {
      handler() {
        this.translate()
        this.convertOptionsToGQL()
      },
      deep: true,
    },
  },
  mounted() {
    this.tagsLibDevice = this.getTagsLibDevice()
  },
  methods: {
    close() {
      this.$emit('close')
      this.dialog = false
    },
    convertOptionsToGQL() {
      const { sortBy, sortDesc, page, itemsPerPage, filter } = this.options
      const limit = itemsPerPage === -1 ? null : itemsPerPage
      let newFilter = {}
      let offset = (page - 1) * Math.abs(itemsPerPage)

      if (this.tagsLibDevice.length > 0) {
        const tagValue = []
        this.tagsLibDevice.forEach(function (item) {
          tagValue.push(item.lib_tag.tag_value)
        })
        newFilter = {
          lib_device_parameter_tags: {
            lib_tag: { tag_value: { _in: tagValue } },
          },
        }
        Object.assign(newFilter, filter)
      } else {
        newFilter = filter
      }

      newFilter.target = {
        _eq: this.target,
      }

      if (this.totalCount <= offset) {
        offset = 0
      }

      this.params = {
        where: newFilter,
        order_by: sortBy.length ? { [sortBy]: sortDesc[0] ? 'asc' : 'desc' } : {},
        limit: limit,
        offset: offset,
      }
    },
    getConfigParamLabel(name) {
      const index = this.configPropertyType.findIndex(function (item) {
        return item.name === name
      })
      if (index !== -1) {
        return this.configPropertyType[index].label
      }
    },
    getTagsLibDevice() {
      return this.libDeviceTags.length > 0 ? this.libDeviceTags : []
    },
    translate() {
      const name = this.$t('common.name')
      this.filters.name.options.label = name
      this.filters.name.options.placeholder = name
    },
    async selectConfig(event) {
      const add = this.$t('common.add')
      const rv = await this.$apollo
        .query({
          query: GET_LIB_DEVICE_PARAMETER,
          variables: { id: event.id },
        })
        .then((data) => {
          return data
        })
        .catch((error) => {
          throw error
        })

      if (rv) {
        this.$root.$dialogLoader.showSnackbar(add + ' ' + event.name + ' OK', {
          color: 'success',
        })
        this.$emit('selected', rv.data.lib_device_parameter_by_pk)
      }
    },
  },
}
</script>
