import { helpers } from 'vuelidate/lib/validators'
import i18n from '@/plugins/i18n'

// DD/MM/YYYY
const regexDateFr = /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}$/

// YYYY-MM-DD
const regexDateEn = /^\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])$/

const dateRegex = {
  fr: regexDateFr,
  en: regexDateEn,
}

const date = (param) =>
  helpers.withParams({ type: 'date', value: dateRegex[param] }, (value) => {
    param = i18n.locale
    const res = value && value.match(dateRegex[param])
    return !helpers.req(value) || res !== null
  })

export default date
