<template>
  <v-container fluid class="fill-height ma-0 pa-0">
    <device-dialog v-if="SelectedDeviceId" :id="SelectedDeviceId" v-model="deviceDialog" />
    <tooltip-view
      :current-device="currentDevice"
      :current-position="currentPosition"
      :size-map="sizeMap"
    />
    <v-row justify="center" align="center" no-gutters class="fill-height">
      <v-col class="fill-height pa-0 ma-0">
        <VlMap
          ref="map"
          :update-while-animating="true"
          :update-while-interacting="true"
          class="fill-height ma-0"
          :style="{ cursor: MouseCursor }"
          data-projection="EPSG:4326"
          @click="clickMap"
          @pointermove="onMapPointerMove"
          @mounted="onMapMounted"
          @rendercomplete="onRenderComplete"
        >
          <VlView ref="view" :center.sync="center" :zoom.sync="zoom" :rotation.sync="rotation" />

          <VlInteractionSelect :features.sync="selectedFeatures" :hit-tolerance="10">
            <VlStyleFunc :function="DeviceStyleFunc()" />
          </VlInteractionSelect>
          <v-speed-dial
            v-model="fab"
            absolute
            bottom
            left
            direction="top"
            transition="slide-y-reverse-transition"
            class="mb-8"
          >
            <template #activator>
              <v-btn id="intro-home-12-map" v-model="fab" fab color="blue darken-2" dark>
                <v-icon v-if="fab">$iconClose</v-icon>
                <v-icon v-else>$iconPlus</v-icon>
              </v-btn>
            </template>

            <v-speed-dial v-model="fab" direction="right">
              <template #activator>
                <v-btn v-model="fab" fab color="green" small>
                  <v-icon>{{ baseLayers[currentLayerIndex].icon }}</v-icon>
                </v-btn>
              </template>

              <v-btn v-for="(bl, key) in baseLayers" :key="key" color="green" dark small fab>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      :color="bl.icon === baseLayers[currentLayerIndex].icon ? 'yellow' : ''"
                      v-on="on"
                      @click="SwitchMapView(key)"
                    >
                      {{ bl.icon }}
                    </v-icon>
                  </template>
                  {{ bl.title }}
                </v-tooltip>
              </v-btn>
            </v-speed-dial>
            <v-btn fab dark small color="indigo">
              <v-icon>$iconPlus</v-icon>
            </v-btn>
          </v-speed-dial>

          <VlLayerTile
            v-for="layer in extBaseLayers"
            :id="layer.name"
            :key="layer.name"
            :visible="layer.visible"
          >
            <component :is="'vl-source-' + layer.name" v-bind="layer" />
          </VlLayerTile>

          <VlLayerVector
            v-for="(layer, i) in Layers"
            :id="layer.uuid"
            :key="i"
            :ref="layer.name"
            :visible="layer.options.visibility"
            :z-index="100 - layer.vorder"
            :opacity="layer.options.opacity / 100"
          >
            <VlSourceVector
              v-if="layer.options.visibility"
              :features.sync="layer.features"
              @created="onSourceCreated"
            />
            <VlStyleFunc :function="DeviceStyleFunc()" />
          </VlLayerVector>
          <v-btn
            style="
              position: absolute;
              background-color: rgba(0, 60, 136, 0.5);
              border-radius: 4px;
              padding: 2px;
              top: 7em;
              left: 1em;
              z-index: 1;
            "
            absolute
            dark
            fab
            height="24px"
            icon
            left
            max-width="24px"
            outlined
            tile
            top
            x-small
            @click="homeMap()"
          >
            <v-icon>$iconHome</v-icon>
          </v-btn>
        </VlMap>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { eventBus } from '@/plugins/eventBus'
import DeviceDialog from '@/components/project/devices/dialog/DeviceDialog'
import TooltipView from '@/components/project/helper/TooltipView'
import { geojson } from '@/components/project/views/map/GeoJsonMixin'
import { OlView } from '@/components/project/views/OlViewMixin'
import { GET_PROJECT_GEOJSON_OBJECTS } from '@/graphql/ProjectGeoJSonObject'
import { DragAndDrop } from 'ol/interaction'
import { GeoJSON } from 'ol/format'
import { ScaleLine } from 'ol/control'

export default {
  name: 'ProjectViewMap',
  components: {
    DeviceDialog,
    TooltipView,
  },
  mixins: [geojson, OlView],
  apollo: {
    GeoJSonObjects: {
      query: GET_PROJECT_GEOJSON_OBJECTS,
      deep: true,
      variables() {
        const geojsonLayersId = this.Layers.filter((layer) => layer.type === 'geojson').map(
          (layer) => layer.options.geojsonUuid
        )
        return { where: { uuid: { _in: geojsonLayersId } } }
      },
      skip() {
        return !this.Layers
      },
      update(data) {
        return data.project_geojson_object
      },
      result({ data, loading }) {
        if (loading) return
        this.GeoJSonObjects = data.project_geojson_object.reduce((acc, curr) => {
          acc[curr.uuid] = Object.assign([], curr.data.features)
          return acc
        }, {})
        this.updateLayerGeoJSON()
      },
    },
  },
  data: () => ({
    currentDevice: undefined,
    currentLayerIndex: 0,
    currentPosition: undefined,
    deviceDialog: false,
    fab: false,
    flyingAnimation: true,
    SelectedDeviceId: false,
    selectedFeatures: [],
    sizeMap: [1, 1],
  }),
  computed: {
    ...mapGetters({
      currentView: 'project/currentView',
      currentViewDefaultOptions: 'project/currentViewDefaultOptions',
    }),
    baseLayers() {
      const optionsIgn = [
        /**
         * 0. Plan IGN
         * @link https://geoservices.ign.fr/services-web-experts-cartes
         *
         * @link https://geoservices.ign.fr/documentation/services/utilisation-web/exemples/extension-openlayers-affichage-dynamique-des
         *
         * Openlayers and wmts:
         * @link https://geoservices.ign.fr/documentation/services/utilisation-web/affichage-wmts/openlayers-et-wmts
         *
         * Parameters infos:
         * @link https://geoservices.ign.fr/documentation/services/api-et-services-ogc/images-tuilees-wmts-ogc
         *
         * Others layers:
         * @link https://geoservices.ign.fr/services-web
         *
         * geoportal-extensions:
         * @link https://github.com/IGNF/geoportal-extensions
         */
        {
          attributions: 'IGN - Plan',
          format: 'image/png',
          layerName: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
          url: 'https://wxs.ign.fr/cartes/geoportail/wmts?SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities',
        },
      ]
      const optionsIgnSelected = optionsIgn[0]
      return [
        {
          name: 'osm',
          title: 'OpenStreetMap',
          icon: '$iconMapOutline',
          crossOrigin: null,
          attributions: '<small>&copy; OpenStreetMap</small>',
        },
        {
          name: 'xyz',
          title: 'Satellite',
          icon: '$iconSatellite',
          crossOrigin: null,
          maxZoom: 22,
          attributions: '<small>Map data ©2015 Google</small>',
          url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
        },
        {
          name: 'wmts',
          title: 'IGN',
          icon: '$iconMap',
          attributions: optionsIgnSelected.attributions,
          layerName: optionsIgnSelected.layerName,
          matrixSet: 'PM',
          format: optionsIgnSelected.format,
          styleName: 'normal',
          maxZoom: 18,
          url: optionsIgnSelected.url,
          tileGrid: {
            origin: [-20037508, 20037508], // topLeftCorner
            resolutions: [
              156543.03392804103, 78271.5169640205, 39135.75848201024, 19567.879241005125,
              9783.939620502562, 4891.969810251281, 2445.9849051256406, 1222.9924525628203,
              611.4962262814101, 305.74811314070485, 152.87405657035254, 76.43702828517625,
              38.218514142588134, 19.109257071294063, 9.554628535647034, 4.777314267823517,
              2.3886571339117584, 1.1943285669558792, 0.5971642834779396, 0.29858214173896974,
              0.14929107086948493, 0.07464553543474241,
            ],
            matrixIds: [
              '0',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
              '19',
            ],
          },
        },
      ]
    },
    extBaseLayers() {
      return this.baseLayers.map((layer) => ({
        ...layer,
        visible: layer.name === this.baseLayers[this.currentLayerIndex].name,
      }))
    },
  },
  watch: {
    currentDevice: function (val) {
      if (val) {
        this.sizeMap = this.$refs.map?.size
      }
    },
    deviceDialog() {
      this.selectedFeatures = []
    },
    selectedFeatures: {
      handler() {
        this.currentPosition = this.currentDevice = undefined
        if (this.selectedFeatures.length > 0) {
          this.SelectedDeviceId = this.selectedFeatures[0].properties.device_uuid
          this.deviceDialog = true
        }
      },
    },
  },
  async mounted() {
    eventBus.$on('selectDevice', this.selectDeviceEvent)
  },
  methods: {
    clickMap(evt) {
      eventBus.$emit('click', evt)
    },
    homeMap() {
      this.zoom = this.currentViewDefaultOptions.zoom
      this.center = this.currentViewDefaultOptions.center
      if (this.$route.name === 'ViewFocus') this.$router.push('/project')
    },
    onSourceCreated(sourceVm) {
      /**
       * Fix return boundingExtent with array [Infinity, Infinity, -Infinity, -Infinity]
       * {@link https://github.com/ghettovoice/vuelayers/issues/126#issuecomment-618248597 Subscribe in the created event handler}
       */
      if (sourceVm.getState() === 'ready') {
        this.onRenderComplete()
      }
    },
    selectDeviceEvent(selectedDevices) {
      if (!this.$refs.map) {
        return
      }
      const _this = this
      this.Layers.filter((layer) => layer.type === 'system').forEach((sysLayer) => {
        const layers = _this.$refs.map.getLayers()
        const layer = layers.find((layer) => layer.get('id') === sysLayer.uuid)
        layer
          ?.get('source')
          ?.getFeatures()
          .map((element) => {
            element.set('selected', false)
            return element
          })
        selectedDevices.forEach((element) => {
          const feature = layer
            ?.get('source')
            ?.getFeatures()
            .find((feature) => feature.get('device_uuid') === element.id)
          if (feature) {
            feature.set('selected', true)
          }
          _this.$refs.map.$map.render()
        })
      })
    },
    SwitchMapView(index) {
      this.currentLayerIndex = index
    },
    updateLayer() {
      this.updateLayerFeatures()
      this.updateLayerGeoJSON()
    },
    updateLayerGeoJSON() {
      if (!this.Layers || !this.GeoJSonObjects) return
      this.Layers = this.Layers.map((layer) => {
        if (layer.type === 'geojson') {
          layer.features = this.GeoJSonObjects[layer.options.geojsonUuid]
          layer.features?.map((f) => {
            f.properties.color = layer.options.color
            f.properties.bgcolor = layer.options.bgcolor
            f.properties.showlabel = layer.options.showlabel
            return f
          })
        }
        return layer
      })
    },
    async onMapMounted() {
      // now ol.Map instance is ready and we can work with it directly
      this.$refs.map.$map.getControls().extend([
        new ScaleLine(),
        // new FullScreen(),
      ])

      const dd = new DragAndDrop({
        formatConstructors: [GeoJSON],
      })
      this.$refs.map.$map.addInteraction(dd)

      dd.on('addfeatures', (evt) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', this.addGeoJSONLayer)
        reader.readAsText(evt.file, 'UTF-8')
      })
    },
  },
}
</script>

<style>
@import '~vuelayers/dist/vuelayers.css';
</style>
