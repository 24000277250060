<template lang="html">
  <v-container fluid class="elevation-0 pa-0 fill-height flat">
    <v-row no-gutters style="height: 100%">
      <v-col>
        <slot
          v-if="showForm"
          name="form"
          :edited-id="editedId"
          :listparams="params"
          :close-form="closeForm"
          transition="scroll-x-reverse-transition"
        />
        <v-scroll-x-reverse-transition>
          <div v-if="!showForm" fill-height>
            <v-toolbar v-if="showToolbar" flat class="grey lighten-5">
              <helper-breadcrumbs />
              <v-spacer />
              <slot name="toolbar-button" />
              <v-btn v-if="createButton" class="mr-1" @click="createItem">
                <v-icon class="mr-1" color="primary"> $iconPlus </v-icon>{{ $t('common.new') }}
              </v-btn>
              <v-btn v-if="homeButton" class="mr-1" :to="{ name: 'home' }">
                <v-icon class="mr-1" color="primary"> $iconClose </v-icon>{{ $t('common.close') }}
              </v-btn>
            </v-toolbar>
            <helper-crud-list-filter v-model="options.filter" :filters.sync="filters" />
            <v-card v-if="!showForm" flat>
              <v-data-table
                :headers="headers"
                :items="records"
                :item-class="rowClasses"
                :custom-filter="convertOptionsToGQL"
                :show-expand="showExpand"
                :footer-props="{
                  'items-per-page-text': itemsPerPageText,
                  'page-text': pageText,
                  'items-per-page-options': [5, 10, 25, 50, -1],
                }"
                :height="heightDataTable"
                :hide-default-footer="options.hideDefaultFooter"
                :loading="loading"
                :no-data-text="$t('common.noDataText')"
                :options.sync="options"
                :server-items-length="totalCount"
                fixed-header
                sort-by="name"
                @[rowclickCondition]="editItem"
              >
                <template #progress>
                  <v-progress-linear color="secondary" indeterminate />
                </template>
                <template #[`item.action`]="{ item }">
                  <slot name="preAction" :item="item" :listparams="params"></slot>
                  <v-icon class="mr-2" small @click.stop="editItem(item)">
                    {{ isEdit(item) ? '$iconEdit' : '$iconEye' }}
                  </v-icon>
                  <v-icon v-if="headersDelete" small @click.stop="deleteItem(item)">
                    $iconDelete
                  </v-icon>
                </template>
                <template #[`item.created_at`]="{ item }">
                  {{ item.created_at | formatDate }}
                </template>
                <template #[`item.updated_at`]="{ item }">
                  {{ item.updated_at | formatDate }}
                </template>
                <template #no-data>
                  <p class="mt-2 mb-0">
                    {{ $t('common.noDataText') }}
                  </p>
                </template>
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                  <slot :name="name" v-bind="slotData" />
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-scroll-x-reverse-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { CommonMixin } from '@/mixins/CommonMixin'
import camelCase from 'lodash/camelCase'
import merge from 'lodash/merge'
import upperFirst from 'lodash/upperFirst'
import pluralize from 'pluralize'

export default {
  name: 'CrudList',
  mixins: [CommonMixin],
  apollo: {
    records: {
      query() {
        if (this.queries) {
          return this.loadQuery('GET', true)
        }
      },
      variables() {
        this.convertOptionsToGQL()
        return this.params
      },
      result({ data }) {
        if (data) {
          this.totalCount = data[`${this.gqlobject}_aggregate`].aggregate.totalCount
        }
      },
      update(data) {
        return data[this.gqlobject]
      },
      watchLoading(isLoading) {
        this.loading = isLoading
      },
      skip() {
        return !this.queries
      },
    },
  },
  data: () => ({
    clickRow: true, // by default row is clickable
    createButton: true,
    editedId: -1,
    filters: {},
    headerReading: true,
    headers: [],
    headersDelete: true,
    heightDataTable: null,
    homeButton: false,
    loading: false,
    showExpand: false,
    options: {
      filter: {},
      itemsPerPage: 10,
      page: 1,
      sortBy: ['name'],
      sortDesc: [true],
      where: {},
    },
    queries: false,
    query: false,
    showForm: false,
    showToolbar: true, // by default display toolbar
    totalCount: 0,
  }),
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
    }),
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
    rowclickCondition: function () {
      return this.clickRow ? 'click:row' : null
    },
  },
  watch: {
    currentProjectId: function (val, oldVal) {
      if (val !== oldVal) {
        this.init()
      }
    },
    options: {
      handler() {
        this.convertOptionsToGQL()
      },
      deep: true,
    },
  },
  async mounted() {
    const queries = await import('@/graphql/' + upperFirst(camelCase(this.gqlobject)) + 'Queries')
    this.queries = queries
  },
  created() {
    const attrsHeaders = this.$attrs.headers
    attrsHeaders.map((column) => {
      const translate = this.translatePlural(column.text)
      column.text = translate
      return column
    })

    const attrsFilters = Object.entries(this.$attrs.filters)
    attrsFilters.map((index) => {
      const options = index[1].options
      options.label = this.translatePlural(options.label)
      options.placeholder = this.translatePlural(options.placeholder)
      return index
    })

    this.gqlobject = this.$attrs.gqlobject ?? ''
    this.headers = Object.assign(this.headers, this.$attrs.headers || {})
    this.homeButton = this.$attrs.homeButton ?? this.homeButton
    this.identifier = this.$attrs.identifier ?? 'id'
    this.createButton =
      this.$attrs.createButton === false ? this.$attrs.createButton : this.createButton
    this.headerReading =
      this.$attrs.headerReading === false ? this.$attrs.headerReading : this.headerReading
    this.headersDelete =
      this.$attrs.headersDelete === false ? this.$attrs.headersDelete : this.headersDelete
    this.heightDataTable = this.$attrs.heightDataTable
      ? this.$attrs.heightDataTable
      : this.StyleHeightCalc('64px + 64px + 48px + 16px').height
    this.showToolbar =
      this.$attrs.showToolbar === false ? this.$attrs.showToolbar : this.showToolbar
    this.clickRow = typeof this.$attrs.clickRow !== 'undefined' ? this.$attrs.clickRow : true
    this.showExpand = this.$attrs.showExpand ?? false
    this.init()
  },
  methods: {
    createItem() {
      this.showForm = true
    },
    closeForm() {
      this.showForm = false
      this.editedId = -1
    },
    convertOptionsToGQL() {
      const { sortBy, sortDesc, page, itemsPerPage, filter, where } = this.options
      const limit = itemsPerPage === -1 ? null : itemsPerPage
      const newWhere = where ? merge(filter, where) : filter
      let newOrderBy = {}
      let offset = (page - 1) * Math.abs(itemsPerPage)

      if (sortBy.length) {
        const sortByString = sortBy[0]
        const search = sortByString.search(/[.]/g)
        const sortDescCurrent = sortDesc[0] ? 'asc' : 'desc'
        if (search !== -1) {
          const split = sortByString.split('.')
          if (split.length === 2) {
            newOrderBy[split[0]] = {
              [split[1]]: sortDescCurrent,
            }
          }
          if (split.length === 3) {
            newOrderBy[split[0]] = {
              [split[1]]: {
                [split[2]]: sortDescCurrent,
              },
            }
          }
        } else {
          newOrderBy = { [sortBy]: sortDescCurrent }
        }
      }

      if (this.totalCount <= offset) {
        offset = 0
      }

      this.params = {
        where: newWhere,
        order_by: newOrderBy,
        limit: limit,
        offset: offset,
      }
    },
    editItem(item) {
      if (this.$listeners.viewItem) {
        this.$emit('viewItem', item)
      } else {
        this.editedId = item[this.identifier]
        this.showForm = true
      }
    },
    init() {
      this.filters = Object.assign(this.filters, this.$attrs.filters || {})
      this.options = Object.assign(this.options, this.$attrs.options || {})
    },
    isEdit(item) {
      return (
        this.headerReading &&
        (this.$store.state.auth.user.crm_contact_uuid === item.uuid ||
          this.$store.getters['auth/isAuth'](['crm_contact', 'create']))
      )
    },
    loadQuery(prefix, plural = false) {
      return this.queries[
        plural
          ? pluralize(`${prefix}_${this.gqlobject.toUpperCase()}`)
          : `${prefix}_${this.gqlobject.toUpperCase()}`
      ]
    },
    rowClasses() {
      return this.clickRow ? 'pointer' : null
    },
    translatePlural(string) {
      const split = string.split('|')
      let t = ''
      if (split.length > 1) {
        t = split[1] === 'plural' ? this.$tc(split[0], 2) : this.$tc(split[0], 1)
      } else {
        t = this.$t(string)
      }
      return t
    },
    async deleteItem(record) {
      const removal = this.$t('common.removal')
      const sureToDelete = this.$t('common.sureToDelete')
      if (await this.$root.$confirm(removal, sureToDelete, { color: 'red' })) {
        this.$root.$dialogLoader.start(
          removal + '...',
          {},
          () => {
            return new Promise((resolve, reject) => {
              try {
                const _this = this
                this.$apollo
                  .mutate({
                    mutation: _this.loadQuery('DELETE'),
                    variables: {
                      [this.identifier]: record[this.identifier],
                    },
                    update: (cache) => {
                      try {
                        const data = cache.readQuery({
                          query: _this.loadQuery('GET', true),
                          variables: _this.params,
                        })

                        data[this.gqlobject] = data[this.gqlobject].filter((item) => {
                          return item[this.identifier] !== record[this.identifier]
                        })
                        data[`${this.gqlobject}_aggregate`].aggregate.totalCount -= 1

                        cache.writeQuery({
                          query: _this.loadQuery('GET', true),
                          data,
                          variables: _this.params,
                        })
                        resolve()
                      } catch (e) {
                        reject(e.toString())
                      }
                    },
                  })
                  .catch((error) => {
                    this.ShowSnackbarError(error)
                  })
              } catch (e) {
                reject(e.toString())
              }
            })
          },
          true
        )
      }
    },
  },
}
</script>

<style lang="css">
.v-data-table thead tr th::first-letter {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}
</style>
