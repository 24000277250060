var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"fileform",staticClass:"fill-height ma-0 pa-0"},[_c('v-card',[_c('v-tabs',{attrs:{"background-color":"grey lighten-5","centered":"","color":"quaternary","slider-size":"2"}},[_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("$iconViewList")])],1),_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("$iconApps")])],1),(_vm.$store.getters['auth/isAuth'](['project_device_document', 'create']))?_c('v-tab',[_c('v-icon',{attrs:{"left":""}},[_vm._v("$iconPaperclip")])],1):_vm._e(),_c('v-tab-item',[_c('v-card',{staticClass:"drop-zone",attrs:{"flat":""}},[_c('v-card-subtitle',[_c('v-row',{attrs:{"align":"baseline","justify":"space-between"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.categoryList,"label":_vm.$tc('common.category', 1),"menu-props":{ top: false, offsetY: true },"no-data-text":_vm.$t('common.noDataText'),"placeholder":_vm.$t('common.select'),"item-text":"name","item-value":"id","clearable":"","dense":"","hide-details":"","small-chips":""},model:{value:(_vm.categorySelected),callback:function ($$v) {_vm.categorySelected=$$v},expression:"categorySelected"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documents,"expanded":_vm.expanded,"no-data-text":_vm.$t('common.noDataText'),"options":_vm.options,"single-expand":_vm.isTrue,"footer-props":{
                'items-per-page-text': _vm.itemsPerPageText,
                'page-text': _vm.pageText,
                'items-per-page-options': [5, 10, 25, 50, 100],
              },"item-key":"project_device_document_uuid","dense":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.thumbnail",fn:function(ref){
              var item = ref.item;
return [_c('thumbnail',{attrs:{"doc":_vm.getThumbnail(item),"is-hover":_vm.isTrue,"size":"36"}})]}},{key:"item.data-table-expand",fn:function(ref){
              var item = ref.item;
return [(item.project_device_document.description)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.isExpand(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(!_vm.compareExpand([].concat( item ), _vm.expanded) ? '$iconArrowRight' : '$iconArrowDown')+" ")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"py-4",attrs:{"colspan":_vm.headers.length}},[_vm._v(" "+_vm._s(item.project_device_document.description)+" ")])]}},{key:"item.type",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.GetStringSplit(item.project_device_document.file.contentType, '/'))+" ")]}},{key:"item.size",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.project_device_document.file.size / (1024 * 1024)).toFixed(2))+" Mo ")]}},{key:"item.project_device_document.user_uuid",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.UsersFullname[item.project_device_document.user_uuid])+" ")]}},{key:"item.project_device_document.updated_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.project_device_document.updated_at))+" ")]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [(_vm.ShowDoc(item.project_device_document.file.contentType))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.viewDocument(item)}}},on),[_c('v-icon',[_vm._v("$iconEye")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.see'))+" "+_vm._s(item.project_device_document.title)+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.Download(
                          item.project_device_document.file.key,
                          _vm.getToken(item),
                          item.project_device_document.title
                        )}}},on),[_c('v-icon',[_vm._v("$iconDownload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.upload')))])]),(_vm.$store.getters['auth/isAuth'](['project_device_document', 'update']))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.editDoc(item)}}},on),[_c('v-icon',[_vm._v("$iconEdit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.edit')))])]):_vm._e(),(_vm.$store.getters['auth/isAuth'](['project_device_document', 'delete']))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.deleteDoc(item)}}},on),[_c('v-icon',[_vm._v("$iconDelete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"drop-zone",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[(_vm.documentsGallery.length === 0)?_c('v-col',[_c('span',{staticClass:"d-block text-center text--disabled"},[_vm._v(" "+_vm._s(_vm.$t('common.noDataText'))+" ")])]):_vm._e()],1),_c('gallery',{attrs:{"documents":_vm.documentsGallery,"is-delete":_vm.$store.getters['auth/isAuth'](['project_device_document', 'delete']),"is-edit":_vm.$store.getters['auth/isAuth'](['project_device_document', 'update']),"size":"150"},on:{"delete-doc":_vm.deleteDoc,"edit-doc":_vm.editDocFromGallery}})],1)],1)],1),(_vm.$store.getters['auth/isAuth'](['project_device_document', 'create']))?_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"drop-zone"},[_c('file-input',{attrs:{"button-save-form":_vm.isTrue},on:{"click-save-form":_vm.saveFileInput}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.dragAndDropDoc'))+" ")])],1)],1)],1):_vm._e()],1)],1),_c('document-edit',{attrs:{"document":_vm.documentEditSrc},on:{"edit-doc":_vm.updateDocumentEdit},model:{value:(_vm.documentEdit),callback:function ($$v) {_vm.documentEdit=$$v},expression:"documentEdit"}}),_c('image-viewer',{attrs:{"image":_vm.imageViewerSrc},model:{value:(_vm.imageViewer),callback:function ($$v) {_vm.imageViewer=$$v},expression:"imageViewer"}}),_c('pdf-viewer',{attrs:{"pdf-file":_vm.pdfViewerSrc},model:{value:(_vm.pdfViewer),callback:function ($$v) {_vm.pdfViewer=$$v},expression:"pdfViewer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }