<template>
  <v-combobox
    v-model="documentCategorySelect"
    v-bind="$attrs"
    :items="documentCategorysItems"
    :label="$tc('common.category', 1)"
    :placeholder="$tc('common.category', 1)"
    :search-input.sync="documentCategorySearch"
    item-text="name"
    item-value="id"
    :hide-no-data="!documentCategorySearch"
    return-object
    v-on="$listeners"
    @change="selectItem"
  >
    <template #no-data>
      <v-list-item>
        <span class="subheading">{{ $t('common.create') }}</span>
        <span class="ml-2">{{ documentCategorySearch }}</span>
      </v-list-item>
    </template>
    <template #item="{ index, item }">
      <v-list-item v-if="editing === item" dense @click.stop>
        <v-text-field
          v-model="editing.name"
          autofocus
          flat
          background-color="transparent"
          hide-details
          @keyup.enter="edit(index, item)"
        />
      </v-list-item>
      <span v-else>{{ item.name }}</span>
      <v-spacer />
      <v-list-item-action @click.stop>
        <v-btn icon x-small @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? '$iconEdit' : '$iconCheck' }}</v-icon>
        </v-btn>
        <v-btn v-if="editing !== item" icon x-small @click.stop.prevent="deleteBrand(index, item)">
          <v-icon>$iconDelete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>
<script>
import {
  GET_LIB_DEVICE_DOCUMENT_CATEGORIES,
  POST_LIB_DEVICE_DOCUMENT_CATEGORY,
  PATCH_LIB_DEVICE_DOCUMENT_CATEGORY,
  DELETE_LIB_DEVICE_DOCUMENT_CATEGORY,
} from '@/graphql/LibDeviceDocumentCategoryQueries'

export default {
  name: 'BrandSelector',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    documentCategorysItems: [],
    documentCategorySelect: {},
    documentCategorySearch: '',
    editing: null,
    firstload: true,
  }),
  watch: {
    value: {
      handler: 'loadDocumentCategory',
      immediate: true,
    },
    documentCategorySelect: {
      handler: 'addDocumentCategory',
      immediate: true,
    },
    documentCategorySearch(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch()
      }
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      } else {
        this.editing = null
        this.index = -1
        this.updateDocumentCategory(item)
      }
    },
    loadDocumentCategory() {
      this.documentCategorySelect = this.value
    },
    async querySearch() {
      this.loading = true
      const rv = await this.$apollo.query({
        query: GET_LIB_DEVICE_DOCUMENT_CATEGORIES,
        variables: {
          order_by: { name: 'asc' },
        },
      })
      this.documentCategorysItems = rv.data.lib_device_document_category
      this.loading = false
    },
    async addDocumentCategory(val) {
      if (typeof val !== 'string') return
      await this.$apollo.mutate({
        mutation: POST_LIB_DEVICE_DOCUMENT_CATEGORY,
        variables: {
          objects: { name: val },
        },
        // eslint-disable-next-line
        update: (cache, { data: { insert_lib_device_document_category } }) => {
          try {
            const documentCategories = cache.readQuery({
              query: GET_LIB_DEVICE_DOCUMENT_CATEGORIES,
              variables: {
                order_by: { name: 'asc' },
              },
            })
            // eslint-disable-next-line
            documentCategories.lib_device_document_category.push(
              insert_lib_device_document_category.returning[0]
            )
            documentCategories.lib_device_document_category_aggregate.aggregate.totalCount++
            cache.writeQuery({
              query: GET_LIB_DEVICE_DOCUMENT_CATEGORIES,
              data: documentCategories,
            })
            // eslint-disable-next-line
            this.$emit(
              "input",
              insert_lib_device_document_category.returning[0]
            );
          } catch (e) {
            console.log(e)
          }
        },
      })
    },
    async updateDocumentCategory(item) {
      try {
        await this.$apollo.mutate({
          mutation: PATCH_LIB_DEVICE_DOCUMENT_CATEGORY,
          variables: {
            id: parseInt(item.id),
            body: { name: item.name },
          },
          /* eslint-disable */
          update: (
            cache,
            { data: { update_lib_device_document_category } }
          ) => {
            cache.reset();
            this.documentCategorySelect =
              update_lib_device_document_category.returning[0];
          },
          /* eslint-enable */
        })
      } catch (e) {
        console.log(e)
      }
    },
    async deleteBrand(item) {
      await this.$apollo.mutate({
        mutation: DELETE_LIB_DEVICE_DOCUMENT_CATEGORY,
        variables: {
          id: item.id,
        },
        // eslint-disable-next-line
        update: (cache) => {
          cache.reset()
        },
      })
      this.querySearch()
    },
    selectItem() {
      if (this.editing) {
        return false
      }

      this.$emit('input', this.documentCategorySelect)
      this.documentCategorySearch = ''
    },
  },
}
</script>
