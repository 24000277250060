import gql from 'graphql-tag'

export const GET_LIB_TAGS = gql`
  query lib_tag(
    $where: lib_tag_bool_exp
    $sorters: [lib_tag_order_by!]
    $limit: Int
    $offset: Int
  ) {
    lib_tag_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    lib_tag(where: $where, offset: $offset, limit: $limit, order_by: $sorters) {
      id
      tag_value
    }
  }
`

export const POST_LIB_TAG = gql`
  mutation ($objects: [lib_tag_insert_input!]!) {
    insert_lib_tag(objects: $objects) {
      returning {
        id
        tag_value
      }
    }
  }
`

export const PATCH_LIB_TAG = gql`
  mutation ($id: Int!, $body: lib_tag_set_input!) {
    update_lib_tag(where: { id: { _eq: $id } }, _set: $body) {
      affected_rows
      returning {
        id
        tag_value
      }
    }
  }
`

export const DELETE_LIB_TAG = gql`
  mutation ($id: Int!) {
    delete_lib_tag(where: { id: { _eq: $id } }) {
      returning {
        id
        tag_value
      }
    }
  }
`
