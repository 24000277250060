import gql from 'graphql-tag'

const eventCategoryFragment = gql`
  fragment EventCategory on event_category {
    id
    name
    color
    updated_at
    created_at
  }
`

export const GET_EVENT_CATEGORIES = gql`
  query event_category(
    $where: event_category_bool_exp
    $order_by: [event_category_order_by!]
    $limit: Int
    $offset: Int
  ) {
    event_category_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    event_category(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...EventCategory
    }
  }
  ${eventCategoryFragment}
`

export const INSERT_EVENT_CATEGORY_ONE = gql`
  mutation insert_event_category_one($object: event_category_insert_input!) {
    insert_event_category_one(object: $object) {
      ...EventCategory
    }
  }
  ${eventCategoryFragment}
`

export const UPDATE_EVENT_CATEGORY_BY_PK = gql`
  mutation update_event_category_by_pk($id: Int!, $object: event_category_set_input!) {
    update_event_category_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...EventCategory
    }
  }
  ${eventCategoryFragment}
`
