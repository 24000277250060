import gql from 'graphql-tag'

// query
export const PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS = gql`
  query project_devices_project_device_documents(
    $where: project_devices_project_device_documents_bool_exp
    $order_by: [project_devices_project_device_documents_order_by!]
    $offset: Int
    $limit: Int
  ) {
    project_devices_project_device_documents(
      where: $where
      order_by: $order_by
      offset: $offset
      limit: $limit
    ) {
      project_device_uuid
      project_device_document_uuid
      project_device_document {
        uuid
        description
        file
        project_device_document_category_id
        project_device_document_category {
          id
          name
        }
        title
        user_uuid
        created_at
        updated_at
      }
      project_device {
        project_id
      }
    }
  }
`

export const PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_AGGREGATE = gql`
  query project_devices_project_device_documents_aggregate(
    $where: project_devices_project_device_documents_bool_exp = {}
  ) {
    project_devices_project_device_documents_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

// mutations
export const POST_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_ONE = gql`
  mutation ($project_device_uuid: uuid!, $project_device_document_uuid: uuid!) {
    insert_project_devices_project_device_documents_one(
      object: {
        project_device_uuid: $project_device_uuid
        project_device_document_uuid: $project_device_document_uuid
      }
    ) {
      project_device_uuid
      project_device_document_uuid
    }
  }
`

export const DELETE_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS_BY_PK = gql`
  mutation delete_project_devices_project_device_documents_by_pk(
    $project_device_uuid: uuid!
    $project_device_document_uuid: uuid!
  ) {
    delete_project_devices_project_device_documents_by_pk(
      project_device_document_uuid: $project_device_document_uuid
      project_device_uuid: $project_device_uuid
    ) {
      project_device_uuid
      project_device_document_uuid
    }
  }
`

// subscription
export const SUBSCRIPTION_PROJECT_DEVICES_PROJECT_DEVICE_DOCUMENTS = gql`
  subscription project_devices_project_device_documents(
    $where: project_devices_project_device_documents_bool_exp
    $order_by: [project_devices_project_device_documents_order_by!]
    $offset: Int
    $limit: Int
  ) {
    project_devices_project_device_documents(
      where: $where
      order_by: $order_by
      offset: $offset
      limit: $limit
    ) {
      project_device_uuid
      project_device_document_uuid
      project_device_document {
        uuid
        description
        file
        project_device_document_category_id
        project_device_document_category {
          id
          name
        }
        title
        user_uuid
        created_at
        updated_at
      }
      project_device {
        project_id
      }
    }
  }
`
