import gql from 'graphql-tag'

const userFragment = gql`
  fragment User_user on user {
    id
    email
    active
    profile
    crm_contact_uuid
    crm_contact {
      uuid
      firstname
      lastname
      address
      zip_code
      city
      job_title
      crm_company_uuid
      crm_company {
        uuid
        name
      }
      crm_phones {
        crm_contact_uuid
        uuid
        phone
        priority
        type
      }
      crm_emails {
        crm_contact_uuid
        uuid
        email
        type
      }
    }
    default_role_uuid
    user_roles {
      user_uuid
      role_uuid
      role {
        role
      }
    }
    default_project_uuid
    user_projects {
      user_uuid
      project_uuid
      project {
        name
      }
    }
    created_at
    updated_at
  }
`

// queries
export const GET_USER = gql`
  query ($id: uuid!) {
    user_by_pk(id: $id) {
      ...User_user
    }
  }
  ${userFragment}
`

export const GET_USERS = gql`
  query user($where: user_bool_exp, $order_by: [user_order_by!], $limit: Int, $offset: Int) {
    user_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    user(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...User_user
    }
  }
  ${userFragment}
`

// mutations
// Note INSERT_USER_ONE:
// These fields are used only for the response
// (they are not sent for insertion):
// created_at, updated_at,
// user_uuid, role {...} in user_roles,
// user_uuid, project {...} in user_projects,
// crm_contact {...}
export const INSERT_USER_ONE = gql`
  mutation insert_user_one($object: user_insert_input!) {
    insert_user_one(object: $object) {
      ...User_user
    }
  }
  ${userFragment}
`

export const PATCH_USER = gql`
  mutation (
    $user_id: uuid!
    $user_body: user_set_input!
    $crm_contact_uuid: uuid!
    $crm_contact_body: crm_contact_set_input!
  ) {
    update_user(where: { id: { _eq: $user_id } }, _set: $user_body) {
      affected_rows
      returning {
        id
        email
        active
        profile
        default_role_uuid
        default_project_uuid
        created_at
        updated_at
      }
    }
    update_crm_contact(where: { uuid: { _eq: $crm_contact_uuid } }, _set: $crm_contact_body) {
      affected_rows
      returning {
        firstname
        lastname
        address
        zip_code
        city
        job_title
        crm_company_uuid
      }
    }
  }
`

export const DELETE_USER = gql`
  mutation ($id: uuid!) {
    delete_user(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`
