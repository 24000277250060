import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate' // vuex-persistedstate plugin will store the locale variable in localStorage
import auth from './modules/auth'
import project from './modules/project'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    project,
  },
  strict: debug,
  plugins: [
    createPersistedState({
      paths: ['project'],
    }),
  ],
})
