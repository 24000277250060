var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_vm._l((_vm.localDocuments),function(doc,i){return _c('v-col',{key:i,attrs:{"cols":"auto"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"elevation-1",attrs:{"flat":"","tile":"","height":_vm.size,"max-width":_vm.size}},[_c('thumbnail',{attrs:{"doc":{
              datetime: doc.datetime,
              key: doc.key,
              title: doc.key,
              token: doc.token,
            },"size":_vm.size}}),(hover)?_c('v-overlay',{staticClass:"text-center py-1",style:(_vm.styleOverlay),attrs:{"absolute":""}},[(_vm.ShowDoc(doc.contentType))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.viewDocument(doc)}}},on),[_c('v-icon',[_vm._v("$iconEye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.see'))+" "+_vm._s(doc.title))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.Download(doc.key, doc.token, doc.title)}}},on),[_c('v-icon',[_vm._v("$iconDownload")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.upload')))])]),(_vm.isEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.editDoc(doc)}}},on),[_c('v-icon',[_vm._v("$iconEdit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.edit')))])]):_vm._e(),(_vm.isDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.deleteDoc(doc)}}},on),[_c('v-icon',[_vm._v("$iconDelete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])]):_vm._e()],1):_vm._e()],1)]}}],null,true)})],1)}),_c('image-viewer',{attrs:{"image":_vm.imageViewerSrc},model:{value:(_vm.imageViewer),callback:function ($$v) {_vm.imageViewer=$$v},expression:"imageViewer"}}),_c('pdf-viewer',{attrs:{"pdf-file":_vm.pdfViewerSrc},model:{value:(_vm.pdfViewer),callback:function ($$v) {_vm.pdfViewer=$$v},expression:"pdfViewer"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }