import gql from 'graphql-tag'

export const GET_LIB_DEVICE_DOCUMENTS = gql`
  query lib_device_document(
    $where: lib_device_document_bool_exp
    $order_by: [lib_device_document_order_by!]
    $limit: Int
    $offset: Int
  ) {
    lib_device_document_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    lib_device_document(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      uuid
      lib_device_document_category_id
      lib_device_uuid
      title
      lib_device_document_category {
        id
        name
      }
      file
      updated_at
      created_at
    }
  }
`

export const POST_LIB_DEVICE_DOCUMENT = gql`
  mutation ($objects: [lib_device_document_insert_input!]!) {
    insert_lib_device_document(objects: $objects) {
      returning {
        uuid
        lib_device_document_category_id
        lib_device_uuid
        title
        file
        updated_at
        created_at
      }
    }
  }
`

export const PATCH_LIB_DEVICE_DOCUMENT = gql`
  mutation ($uuid: uuid!, $body: lib_device_document_set_input!) {
    update_lib_device_document(where: { uuid: { _eq: $uuid } }, _set: $body) {
      affected_rows
      returning {
        uuid
        lib_device_document_category_id
        lib_device_uuid
        title
        file
      }
    }
  }
`

export const DELETE_LIB_DEVICE_DOCUMENT = gql`
  mutation ($uuid: uuid!) {
    delete_lib_device_document(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
      }
    }
  }
`

export const SUBSCRIPTION_LIB_DEVICE_DOCUMENTS = gql`
  subscription lib_device_document(
    $where: lib_device_document_bool_exp
    $order_by: [lib_device_document_order_by!]
    $limit: Int
    $offset: Int
  ) {
    lib_device_document(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      uuid
      lib_device_document_category_id
      lib_device_uuid
      title
      lib_device_document_category {
        id
        name
      }
      file
      updated_at
      created_at
    }
  }
`
