<template>
  <v-navigation-drawer
    id="project-drawer-dashboard"
    v-model="displayDrawer"
    class="mt-8"
    color="tertiary"
    height="calc(100% - (32px * 2))"
    width="292px"
    absolute
    right
    stateless
  >
    <v-container>
      <v-row>
        <div class="text-overline">
          <span class="text-uppercase pl-3">
            {{ $t('dashboard.dashboard') }}
          </span>
        </div>
        <v-spacer />
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn icon small class="mr-2" @click="$router.push('/dashboard')" v-on="on">
              <v-icon>$iconOpenInNew</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.see') }} {{ $t('dashboard.dashboard') }}</span>
        </v-tooltip>
      </v-row>
      <v-row>
        <dashboard :is-drawer="isTrue" :is-view="isSelectedToView" />
      </v-row>
      <v-row>
        <v-toolbar color="tertiary" height="32px">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                :color="isSelectedToView ? 'secondary' : 'primary'"
                v-on="on"
                @click="clickIconToolbar('view')"
              >
                $iconEye
              </v-icon>
            </template>
            <span>{{ $t('common.see') }}</span>
          </v-tooltip>
          <v-spacer />
          <v-tooltip
            v-if="!IsMobile() && $store.getters['auth/isAuth'](['dashboard', 'create'])"
            left
          >
            <template #activator="{ on }">
              <v-btn icon small @click="$router.push('dashboard/add')">
                <v-icon v-on="on">$iconPlus</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('dashboard.addDashboard') }}
            </span>
          </v-tooltip>
          <v-tooltip
            v-if="!IsMobile() && $store.getters['auth/isAuth'](['dashboard', 'delete'])"
            left
          >
            <template #activator="{ on }">
              <v-btn icon small :disabled="isTrue" @click="deleteDashboard">
                <v-icon v-on="on">$iconDelete</v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('dashboard.deleteDashboard') }}
            </span>
          </v-tooltip>
        </v-toolbar>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { CommonMixin } from '@/mixins/CommonMixin'
import Dashboard from '@/views/Dashboard'

export default {
  name: 'ProjectDrawerDashboard',
  components: {
    Dashboard,
  },
  mixins: [CommonMixin],
  data: function () {
    return {
      isSelectedToView: true,
      isTrue: true,
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentDrawer: 'project/currentDrawer',
    }),
    displayDrawer: {
      get() {
        return this.currentDrawer === 'dashboard'
      },
      set(newName) {
        return newName
      },
    },
  },
  methods: {
    deleteDashboard() {
      alert('Temporary disabled. deleteDashboard is coming.')
    },
    async clickIconToolbar() {
      this.isSelectedToView = !this.isSelectedToView
    },
  },
}
</script>

<style lang="css">
#project-drawer-dashboard > .v-navigation-drawer__content {
  overflow-y: hidden !important;
}
</style>
