<template>
  <component
    :is="AlertContactPointComponent"
    v-if="AlertContactPointComponent"
    :options="options"
    @input="$emit('input', $event)"
  />
</template>

<script>
import upperFirst from 'lodash/upperFirst'

export default {
  name: 'AlertContactPointLoader',
  props: {
    type: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    AlertContactPointComponent() {
      if (this.type) {
        const type = upperFirst(this.type)
        return () => import(`@/components/alert/AlertContactPoint${type}`)
      }
      return false
    },
  },
}
</script>
