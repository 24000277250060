<template>
  <form-wrapper :validator="$v">
    <v-container fluid class="ma-0">
      <v-row>
        <v-col cols="12" md="6">
          <form-error
            :validator="$v.localFieldConfig.min"
            :attribute="$t('common.nbCharMin')"
            :messages="messages"
          >
            <v-text-field
              v-model="localFieldConfig.min"
              slot-scope="{ attrs }"
              type="number"
              v-bind="attrs"
              :label="$t('common.nbCharMin')"
              @input="$v.localFieldConfig.min.$touch()"
              @blur="$v.localFieldConfig.min.$touch()"
            />
          </form-error>
        </v-col>
        <v-col cols="12" md="6">
          <form-error
            :validator="$v.localFieldConfig.max"
            :attribute="$t('common.nbCharMax')"
            :messages="messages"
          >
            <v-text-field
              v-model="localFieldConfig.max"
              slot-scope="{ attrs }"
              type="number"
              v-bind="attrs"
              :label="$t('common.nbCharMax')"
              @input="$v.localFieldConfig.max.$touch()"
              @blur="$v.localFieldConfig.max.$touch()"
            />
          </form-error>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <form-error
            :validator="$v.localDefaultValue.value"
            :attribute="$t('common.defaultValue')"
            :messages="messages"
          >
            <v-text-field
              v-model="localDefaultValue.value"
              slot-scope="{ attrs }"
              :label="$t('common.defaultValue')"
              v-bind="attrs"
              counter
              @input="$v.localDefaultValue.value.$touch()"
              @blur="$v.localDefaultValue.value.$touch()"
            />
          </form-error>
        </v-col>
      </v-row>
    </v-container>
  </form-wrapper>
</template>

<script>
import { required, integer, minLength, maxLength } from 'vuelidate/lib/validators'

const minmax = (value, vm) => parseInt(vm.min) <= parseInt(vm.max)

export default {
  name: 'StringFieldConfig',
  props: {
    fieldConfig: {
      type: Object,
      default() {
        return {
          min: 0,
          max: 255,
        }
      },
    },
    defaultValue: {
      type: Object,
      default() {
        return {
          value: 'Test',
        }
      },
    },
  },
  data: function () {
    return {
      localFieldConfig: this.fieldConfig,
      localDefaultValue: this.defaultValue,
    }
  },
  computed: {
    messages() {
      return this.$t('validationMessage')
    },
  },
  watch: {
    localFieldConfig: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localFieldConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
    localDefaultValue: {
      deep: true,
      handler() {
        this.$emit('input', {
          fieldConfig: this.localFieldConfig,
          defaultValue: this.localDefaultValue,
        })
      },
    },
  },
  validations() {
    return {
      localFieldConfig: {
        min: {
          required,
          integer,
          minmax,
        },
        max: {
          required,
          integer,
          minmax,
        },
      },
      localDefaultValue: {
        value: {
          minLength: minLength(this.localFieldConfig.min),
          maxLength: maxLength(this.localFieldConfig.max),
        },
      },
    }
  },
  mounted() {
    this.$emit('input', {
      fieldConfig: this.localFieldConfig,
      defaultValue: this.localDefaultValue,
    })
  },
}
</script>
