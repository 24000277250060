import gql from 'graphql-tag'

// queries
export const GET_LIB_DEVICES_LIB_DEVICES_PARAMETERS = gql`
  query lib_devices_lib_device_parameters(
    $where: lib_devices_lib_device_parameters_bool_exp
    $order_by: [lib_devices_lib_device_parameters_order_by!]
    $limit: Int
    $offset: Int
  ) {
    lib_devices_lib_device_parameters_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    lib_devices_lib_device_parameters(
      where: $where
      offset: $offset
      limit: $limit
      order_by: $order_by
    ) {
      id
      default_value
      order
      lib_device_parameter {
        id
        name
        config_label
        default_value
        description
        field_config
        field_type
        help
        updated_at
        created_at
        constant
        target
      }
    }
  }
`

export const POST_LIB_DEVICES_LIB_DEVICES_PARAMETERS = gql`
  mutation ($object: lib_devices_lib_device_parameters_insert_input!) {
    insert_lib_devices_lib_device_parameters_one(object: $object) {
      id
      default_value
      order
      lib_device_parameter {
        id
        name
        config_label
        default_value
        description
        field_config
        field_type
        help
        updated_at
        created_at
        constant
        target
      }
    }
  }
`

export const UPDATE_LIB_DEVICES_LIB_DEVICE_PARAMETERS_BY_PK = gql`
  mutation ($id: Int!, $_set: lib_devices_lib_device_parameters_set_input = {}) {
    update_lib_devices_lib_device_parameters_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      order
      default_value
    }
  }
`

export const DELETE_LIB_DEVICES_LIB_DEVICES_PARAMETERS = gql`
  mutation ($id: Int!) {
    delete_lib_devices_lib_device_parameters(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`
