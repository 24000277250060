<template lang="html">
  <v-card flat outlined tile>
    <v-card-subtitle>
      <v-row align="baseline" justify="space-between">
        <v-col class="pr-0" cols="6" md="2">
          <v-btn v-if="btnActionCondition" class="pl-1 pr-2" @click="btnAction">
            <v-icon class="mr-1" color="primary">$iconPlus</v-icon>
            {{ btnActionText }}
          </v-btn>
        </v-col>
        <v-col class="px-2" cols="6" md="4">
          <v-text-field
            v-model="searchLogs"
            :append-icon="'$iconMagnify'"
            :label="$t('common.searchAllFieldsNotifications')"
            clearable
            dense
            hide-details
            @keyup="onSelected('searchLogs')"
            @click:clear="searchLogsTable = ''"
          />
        </v-col>
        <v-col class="px-2" cols="4" md="2">
          <v-select
            v-model="hubSelected"
            :items="hubList"
            :label="$tc('project.station', 1)"
            :menu-props="{ top: false, offsetY: true }"
            :no-data-text="$t('common.noDataText')"
            :placeholder="$t('common.select')"
            item-text="hub_name"
            item-value="hub_uuid"
            clearable
            dense
            hide-details
            small-chips
            @change="onSelected('hubSelected')"
          />
        </v-col>
        <v-col class="px-2" cols="4" md="2">
          <v-select
            v-model="deviceSelected"
            :items="deviceList"
            :label="$tc('project.device', 1)"
            :menu-props="{ top: false, offsetY: true }"
            :no-data-text="$t('common.noDataText')"
            :placeholder="$t('common.select')"
            item-text="device_name"
            item-value="device_uuid"
            clearable
            dense
            hide-details
            small-chips
            @change="onSelected('deviceSelected')"
          />
        </v-col>
        <v-col v-if="source === 'notification'" class="px-2" cols="4" md="2">
          <v-select
            v-model="stateSelected"
            :items="stateList"
            :label="$tc('common.state', 1)"
            :menu-props="{ top: false, offsetY: true }"
            :no-data-text="$t('common.noDataText')"
            :placeholder="$t('common.select')"
            clearable
            dense
            hide-details
            small-chips
            @change="onSelected('stateSelected')"
          >
            <template #[`selection`]="{ item }">
              <v-chip small>
                {{ $t('project.enum.stateLog.' + item) }}
              </v-chip>
            </template>
            <template #[`item`]="{ item }">
              {{ $t('project.enum.stateLog.' + item) }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider />
    <v-data-table
      v-model="notificationsSelected"
      :headers="headers"
      :items="notifications"
      :item-class="rowClass"
      :expanded.sync="expanded"
      :footer-props="{
        'items-per-page-text': itemsPerPageText,
        'page-text': pageText,
        'items-per-page-options': [5, 10, 25, 50, -1],
      }"
      :height="StyleHeightCalc('64px + 64px + 48px + 14px').height"
      :no-data-text="$t('common.noDataText')"
      :options.sync="options"
      :search="searchLogsTable"
      :single-expand="isTrue"
      :single-select="notificationsSingleSelect"
      :show-select="showSelect"
      item-key="uuid"
      fixed-header
      show-expand
    >
      <template #[`header.ch_ope_fonc`]="{ header }">
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              {{ header.text }}
            </span>
          </template>
          <span>
            {{ $t('common.operationalOrFunctional') }}
          </span>
        </v-tooltip>
      </template>
      <template #[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          v-if="!item.message.state"
          :value="isSelected"
          :ripple="false"
          @input="select($event)"
        />
      </template>
      <template #[`item.message.message_type`]="{ item }">
        <v-chip :color="getColorLogType(item.message.message_type)" dark small>
          {{ item.message.message_type }}
        </v-chip>
      </template>
      <template #[`item.ch_ope_fonc`]="{ item }">
        {{ item.ch_ope_fonc ? 'O' : 'F' }}
      </template>
      <template #[`item.sent`]="{ item }">
        <v-row justify="space-between" no-gutters>
          <v-icon v-if="item.message.send_email_time" small>$iconSendEmail</v-icon>
          <v-icon v-if="item.message.send_sms_time" small>$iconSms</v-icon>
        </v-row>
      </template>
      <template #[`expanded-item`]="{ item }">
        <td :colspan="headers.length" class="pa-4">
          <span class="d-block text-caption text--disabled ml-3">
            {{ item.uuid }}
          </span>
          <span v-if="item.message.send_email_time" class="d-block">
            <v-chip class="ma-2" small>
              {{ item.message.send_email_time | formatDateSeconds }}
            </v-chip>
            <span v-if="item.message.send_email_accepted">
              <v-icon>$iconSendEmail</v-icon>
              {{ getUser(item.message.send_email_accepted) }}
            </span>
            <span v-if="item.message.send_email_rejected">
              <v-icon>$iconSendEmailOff</v-icon>
              {{ getUser(item.message.send_email_rejected) }}
            </span>
          </span>

          <span v-if="item.message.send_sms_time" class="d-block">
            <v-chip class="ma-2" small>
              {{ item.message.send_sms_time | formatDateSeconds }}
            </v-chip>
            <span v-if="item.message.send_sms_accepted">
              <v-icon>$iconSms</v-icon>
              {{ getUser(item.message.send_sms_accepted) }}
            </span>
            <span v-if="item.message.send_sms_rejected">
              <v-icon>$iconSmsOff</v-icon>
              {{ getUser(item.message.send_sms_rejected) }}
            </span>
          </span>
        </td>
      </template>
      <template #[`item.log_time`]="{ item }">
        {{ item.log_time | formatDateSeconds }}
      </template>
      <template #[`item.hub_uuid`]="{ item }">
        <span
          v-if="source === 'notification'"
          class="link-project-device"
          @click="goHistorical(item.hub_uuid, item.log_time)"
        >
          {{ getDeviceName(item.hub_uuid, null) }}
        </span>
        <span v-else>
          {{ getDeviceName(item.hub_uuid, null) }}
        </span>
      </template>
      <template #[`item.device_uuid`]="{ item }">
        <span class="d-flex">
          <span
            v-if="source === 'notification'"
            class="link-project-device"
            @click="goHistorical(item.device_uuid, item.log_time)"
          >
            {{ getDeviceName(item.hub_uuid, item.device_uuid) }}
          </span>
          <span v-else>
            {{ getDeviceName(item.hub_uuid, item.device_uuid) }}
          </span>
          <v-spacer />
          <span v-if="source === 'notification'">
            <v-icon
              v-if="isProjectViewDeviceCoordinate(item.device_uuid)"
              small
              @click="goMap(item.device_uuid)"
            >
              $iconEye
            </v-icon>
          </span>
          <v-icon
            v-if="getDeviceName(item.hub_uuid, item.device_uuid, true) === 'sensor'"
            class="ml-2"
            small
            @click="openGraph(item)"
          >
            $iconChartBar
          </v-icon>
          <span v-if="source === 'notification' && isIssue(item.uuid)">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-icon class="ml-2" small @click="goIssue(item.uuid)" v-on="on">
                  $iconBook
                </v-icon>
              </template>
              <span>{{ $tc('common.report', 1) }} #{{ isIssue(item.uuid).issueId }}</span>
            </v-tooltip>
          </span>
        </span>
      </template>
      <template #[`item.message.state`]="{ item }">
        {{
          item.message.state
            ? $t('project.enum.stateLog.' + item.message.state)
            : $t('project.enum.stateLog.new')
        }}
      </template>
    </v-data-table>
    <v-dialog
      v-model="showGraph"
      :width="IsMobile() ? '99%' : '70%'"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-actions class="px-6">
          <span v-if="graphDataDevice" class="font-weight-medium">
            {{ graphDataDevice.name }} ({{ graphDataDevice.hub_name }})
          </span>
          <v-spacer />
          <v-btn
            elevation="0"
            @click=";(showGraph = false), (graphDataDevice = null), (graphDataSettings = null)"
          >
            <v-icon color="primary">$iconClose</v-icon>
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <graph-notification
            v-if="graphDataDevice"
            :device="graphDataDevice"
            :settings="graphDataSettings"
          />
          <span v-else>
            {{ $t('common.noDataText') }}
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// Remove for a future upgrade from user_project to crm_contact_project :
import { GET_USERS_PROJECT_NOTIFICATION } from '@/graphql/UserProjectQueries'
// Keep for a future upgrade from user_project to crm_contact_project :
// import { GET_CRM_CONTACT_PROJECT } from '@/graphql/CrmContactProjectQueries'
import { GET_ISSUES } from '@/graphql/IssueQueries'
import { GET_LOGS } from '@/graphql/LogQueries'
import { GET_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS } from '@/graphql/ProjectDevicesProjectDeviceParameters'
import { GET_PROJECT_VIEWS } from '@/graphql/ProjectViewQueries'
import { GET_PROJECT_VIEW_DEVICE_COORDINATES } from '@/graphql/ProjectViewDeviceCoordinateQueries'
import { GET_USERS } from '@/graphql/UserQueries'
import { CommonMixin } from '@/mixins/CommonMixin'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import { formatDateTimeIso8601, toIsoString } from '@/locales/formats/dataTimeFormats'
import GraphNotification from '@/components/project/graphs/GraphNotification'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import orderBy from 'lodash/orderBy'
import uniqWith from 'lodash/uniqWith'

export default {
  name: 'NotificationSelector',
  components: {
    GraphNotification,
  },
  mixins: [CommonMixin, ProjectMixin],
  props: {
    // Used with source = 'reportForm'
    associateNotification: {
      type: Array,
      default: () => [],
    },
    // Used with source = 'reportForm'
    logRef: {
      type: Array,
      default: () => [],
    },
    // Used with source = 'reportForm'
    openNotification: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String, // notification || reportForm
      default: undefined,
    },
  },
  apollo: {
    /*
    // Keep for a future upgrade from user_project to crm_contact_project :
    crm_contact_project: {
      query: GET_CRM_CONTACT_PROJECT,
      variables () {
        const vars = {
          order_by: {
            crm_contact: { lastname: 'asc' },
          },
          where: {
            project_uuid: {
              _eq: this.$store.getters['project/currentProjectId'],
            },
            notification: {
              _contains: { report: true },
            },
            crm_contact: {
              user: {
                id: { _eq: this.$store.getters['auth/user'].id },
                active: { _eq: true },
              },
            },
          },
        }
        return vars
      },
    },
    */
    log: {
      query: GET_LOGS,
      pollInterval: 60000, // every 1 mn
      variables() {
        const vars = this.logRef.length > 0 ? this.varsQueryLogs(this.logRef) : this.varsQueryLogs()
        return vars
      },
      result({ data }) {
        this.logs = data?.log || []
      },
    },
    project_devices_project_device_parameters: {
      query: GET_PROJECT_DEVICES_PROJECT_DEVICE_PARAMETERS,
      variables() {
        const newArray = Array.from(this.logs, (i) => i.device_uuid)
        const uniq = uniqWith(newArray, isEqual)
        const vars = {
          where: {
            project_device_uuid: {
              _in: uniq,
            },
            project_device_parameter: {
              config_label: {
                _eq: 'ch_ope_fonc',
              },
            },
          },
        }
        return vars
      },
      result({ data }) {
        if (data) {
          const notificationsNew = this.logs

          this.logs?.forEach((i, k) => {
            const search = data.project_devices_project_device_parameters.find(
              (p) => p.project_device_uuid === i.device_uuid
            )
            notificationsNew[k].ch_ope_fonc = search?.parameter_value.value
            notificationsNew[k].warnings_type = search?.parameter_value.value
              ? i.message.message_type + '_O'
              : i.message.message_type + '_F'
          })

          this.notifications = notificationsNew
        }
      },
      skip() {
        return !this.logs
      },
    },
    project_view: {
      query: GET_PROJECT_VIEWS,
      variables() {
        const vars = {
          where: {
            project_uuid: {
              _eq: this.$store.getters['project/currentProjectId'],
            },
            type: {
              _eq: 'map',
            },
            default: {
              _eq: true,
            },
          },
        }
        return vars
      },
    },
    project_view_device_coordinate: {
      query: GET_PROJECT_VIEW_DEVICE_COORDINATES,
      variables() {
        const vars = {
          project_uuid: this.$store.getters['project/currentProjectId'],
          project_view_uuid: this.project_view[0].uuid,
          coordinate_system_id: 2,
        }
        return vars
      },
      result({ data }) {
        if (data) {
          this.projectViewDeviceCoordinate = data.project_view_device_coordinate
        }
      },
      skip() {
        return !this.project_view
      },
    },
    user: {
      query: GET_USERS,
    },
    // Remove for a future upgrade from user_project to crm_contact_project :
    user_project: {
      query: GET_USERS_PROJECT_NOTIFICATION,
      variables() {
        const vars = {
          where: {
            project_uuid: {
              _eq: this.$store.getters['project/currentProjectId'],
            },
            notification: {
              _contains: {
                report: true,
              },
            },
            user: {
              active: {
                _eq: true,
              },
            },
            user_uuid: {
              _eq: this.$store.getters['auth/user'].id,
            },
          },
          order_by: {
            user: {
              crm_contact: {
                lastname: 'asc',
              },
            },
          },
        }
        return vars
      },
    },
  },
  data: () => ({
    devices: [],
    deviceSelected: null,
    expanded: [],
    graphDataDevice: null,
    graphDataSettings: null,
    hubSelected: null,
    isTrue: true,
    logs: [],
    logRefIssue: [],
    notifications: [],
    notificationsReport: [],
    notificationsSelected: [],
    notificationsSingleSelect: false,
    options: {
      itemsPerPage: 10,
      sortBy: ['log_time'],
      sortDesc: [true],
      filter: {},
    },
    projectViewDeviceCoordinate: [],
    searchLogs: '',
    searchLogsTable: '',
    showGraph: false,
    stateList: ['new', 'in_progress', 'completed'],
    stateSelected: 'new',
  }),
  computed: {
    btnActionCondition() {
      let result = false
      if (this.source === 'notification') {
        result = this.isUserProject && this.notificationsSelected.length > 0
      }
      if (this.source === 'reportForm') {
        result = false
      }
      return result
    },
    btnActionText() {
      let text = ''
      if (this.source === 'notification') {
        text = this.$t('common.createReport')
      }
      return text
    },
    deviceList() {
      return this.getNotificationsDevices()
    },
    headers: function () {
      return [
        {
          text: this.$t('common.type'),
          value: 'message.message_type',
          width: '100px',
        },
        {
          text: 'O/F',
          value: 'ch_ope_fonc',
          align: 'center',
          sortable: false,
          width: '55px',
        },
        {
          text: this.$t('common.sent'),
          value: 'sent',
          align: 'right',
          cellClass: 'px-3',
          class: 'px-0',
          width: '64px',
        },
        {
          text: '',
          value: 'data-table-expand',
          cellClass: 'px-0',
          class: 'px-0',
        },
        {
          text: this.$t('project.message'),
          value: 'message.message_text',
        },
        {
          text: this.$t('common.value'),
          value: 'message.message_value',
        },
        {
          text: this.$t('common.eventTime'),
          value: 'log_time',
        },
        {
          text: this.$tc('project.station', 1),
          value: 'hub_uuid',
          sort: (a, b) => {
            return this.getDeviceName(b, null).localeCompare(this.getDeviceName(a, null))
          },
        },
        {
          text: this.$tc('project.device', 1),
          value: 'device_uuid',
          sort: (a, b) => {
            return this.getDeviceName(null, b).localeCompare(this.getDeviceName(null, a))
          },
        },
        {
          text: this.$tc('common.state', 1),
          value: 'message.state',
        },
      ]
    },
    hubList() {
      return this.getNotificationsHub()
    },
    itemsPerPageText() {
      return this.$t('dataFooter.itemsPerPageText')
    },
    isUserProject() {
      // Remove for a future upgrade from user_project to crm_contact_project :
      return this.user_project && this.user_project.length > 0
      // Keep for a future upgrade from user_project to crm_contact_project :
      // return this.crm_contact_project && this.crm_contact_project.length > 0
    },
    pageText() {
      return this.$t('dataFooter.pageText')
    },
    showSelect() {
      return this.source === 'notification' ? this.isUserProject : true
    },
  },
  watch: {
    logs: function (val) {
      if (val && val.length > 0) this.getIssue(val)
    },
    notificationsSelected: function (val, oldVal) {
      let check = []
      val.forEach((i) => {
        const search = this.notifications.filter((item) => item.uuid === i.uuid)
        if (search.length > 0) check.push(search[0])
      })
      this.notificationsReport = check.filter((item) => !item.message.state)
      if (this.source === 'reportForm' && oldVal.length !== val.length) {
        this.$emit('update:associateNotification', this.notificationsReport)
      }
    },
    ProjectDevices: {
      handler: function (val) {
        this.devices = val
      },
      deep: true,
    },
    $route: {
      immediate: true,
      handler(to) {
        if (Object.prototype.hasOwnProperty.call(to.params, 'state')) {
          this.stateSelected = to.params.state
        }
      },
    },
  },
  mounted() {
    if (this.source === 'reportForm') {
      this.notificationsSelected = this.associateNotification
    }
    this.onSelected('stateSelected')
  },
  methods: {
    btnAction() {
      const notif = this.notificationsReport
      if (this.source === 'notification') {
        this.$router.push({
          name: 'Report',
          params: {
            notification: notif,
          },
        })
      }
      if (this.source === 'reportForm') {
        this.$emit('update:associateNotification', notif)
        this.$emit('update:openNotification', false)
      }
    },
    getColorLogType(type) {
      let color = ''
      switch (type) {
        case 'ALARM':
          color = 'red'
          break
        case 'ALERT':
          color = 'orange'
          break
        default:
          color = ''
          break
      }
      return color
    },
    /**
     * Return device name or device type
     * @param {string} hubUuid
     * @param {string} deviceUuid
     * @param {boolean} [type] If true, return device type
     * @return {string}
     */
    getDeviceName(hubUuid, deviceUuid, type) {
      let result = '?'
      if (deviceUuid === null) {
        this.devices.find((device) => {
          if (device.id === hubUuid) {
            result = device.name
          }
          return null
        })
      } else {
        if (hubUuid === deviceUuid) {
          this.devices.find((device) => {
            if (device.id === hubUuid) {
              result = device.name
            }
            return null
          })
        } else {
          if (deviceUuid !== '') {
            this.devices.find((device) => {
              if (device.id === deviceUuid) {
                result = type ? device.device_type : device.name
              }
              return null
            })
          } else {
            this.devices.find((device) => {
              if (device.id === hubUuid) {
                result = device.name
              }
              return null
            })
          }
        }
      }
      return result
    },
    getNotificationsDevices() {
      const list = []
      this.notifications?.filter((i) =>
        list.push({
          device_uuid: i.device_uuid,
          device_name: this.getDeviceName(null, i.device_uuid),
        })
      )
      const uniq = uniqWith(list, isEqual)
      const orderByUniq = orderBy(uniq, ['device_name'], ['asc'])
      return orderByUniq
    },
    getNotificationsHub() {
      const list = []
      this.notifications?.filter((i) =>
        list.push({
          hub_uuid: i.hub_uuid,
          hub_name: this.getDeviceName(null, i.hub_uuid),
        })
      )
      const uniq = uniqWith(list, isEqual)
      const orderByUniq = orderBy(uniq, ['hub_name'], ['asc'])
      return orderByUniq
    },
    getProjectDeviceDeviceUuid() {
      const deviceUuid = []
      for (const i in this.devices) {
        deviceUuid.push(this.devices[i].id)
      }
      return deviceUuid
    },
    getUser(uuid) {
      let users = ''
      if (uuid && this.user) {
        uuid.forEach((uuid) => {
          const search = this.user.find((u) => u.id === uuid)
          if (search) {
            users +=
              search.crm_contact.lastname.toUpperCase() + ' ' + search.crm_contact.firstname + ' - '
          } else {
            users += this.$t('report.deletedUser') + ' - '
          }
        })
      }
      return users.substring(0, users.length - 3)
    },
    goHistorical(deviceUuid, logTime) {
      this.$router.push({
        name: 'projectDevice',
        params: {
          uuid: deviceUuid,
          tab: 'historical',
          logTime: toIsoString(logTime),
        },
      })
    },
    goIssue(logUuid) {
      this.$router.push({
        name: 'ReportEdit',
        params: {
          notif: true,
          state: this.stateSelected,
          value: this.isIssue(logUuid).issueId,
        },
      })
    },
    goMap(deviceUuid) {
      this.$router.push('../viewfocus/' + this.project_view[0].uuid + '/' + deviceUuid + '/none')
    },
    isIssue(logUuid) {
      return this.logRefIssue.find((i) => i.logUuid === logUuid)
    },
    isProjectViewDeviceCoordinate(deviceUuid) {
      return this.projectViewDeviceCoordinate.find((i) => i.project_device_uuid === deviceUuid)
    },
    onSelected(selection) {
      switch (selection) {
        case 'deviceSelected':
          this.hubSelected = null
          this.searchLogs = null
          this.searchLogsTable = this.deviceSelected !== null ? this.deviceSelected : ''
          break
        case 'hubSelected':
          this.deviceSelected = null
          this.searchLogs = null
          this.searchLogsTable = this.hubSelected !== null ? this.hubSelected : ''
          break
        case 'searchLogs':
          this.deviceSelected = null
          this.hubSelected = null
          this.searchLogsTable = this.searchLogs !== null ? this.searchLogs : ''
          break
        case 'stateSelected':
          this.deviceSelected = null
          this.hubSelected = null
          this.searchLogs = ''
          this.searchLogsTable = ''
          break
        default:
          this.searchLogsTable = ''
          break
      }
    },
    openGraph(item) {
      this.showGraph = true
      this.graphDataDevice = {
        id: item.device_uuid,
        name: this.getDeviceName(item.hub_uuid, item.device_uuid),
        hub_name: this.getDeviceName(item.hub_uuid, item.hub_uuid),
      }
      this.graphDataSettings = {
        dateTimeFrom: moment(item.log_time).subtract(2, 'days').format(formatDateTimeIso8601),
        dateTimeTo: moment(item.log_time).add(2, 'days').format(formatDateTimeIso8601),
        annotationsPoints: {
          color: this.getColorLogType(item.message.message_type), // annotations.points[]...color
          log_time: item.log_time, // annotations.points[].x
          value: item.message.message_value, // annotations.points[].y
          type: item.message.message_type, // annotations.points[].label.text
        },
      }
    },
    rowClass(item) {
      return item.message.state === 'in_progress' || item.message.state === 'completed'
        ? 'white'
        : ''
    },
    varsQueryLogs(logRef) {
      let vars = {
        order_by: {
          log_time: 'desc',
          created_at: 'desc',
        },
        where: {
          project_uuid: {
            _eq: this.$store.getters['project/currentProjectId'],
          },
          type: {
            _eq: 'warnings',
          },
        },
      }

      if (logRef && logRef.length > 0) {
        const where = {
          where: {
            uuid: {
              _in: logRef,
            },
          },
        }
        vars = merge(vars, where)
      } else {
        const where = {
          where: {
            device_uuid: {
              // For select by Hasura Permission
              _in: this.getProjectDeviceDeviceUuid(),
            },
          },
        }
        vars = merge(vars, where)

        if (!this.stateSelected) {
          // do nothing
        } else if (this.stateSelected === 'new') {
          const where = {
            where: {
              _not: {
                message: {
                  _has_key: 'state',
                },
              },
            },
          }
          vars = merge(vars, where)
        } else {
          const where = {
            where: {
              message: {
                _contains: {
                  state: this.stateSelected,
                },
              },
            },
          }
          vars = merge(vars, where)
        }
      }
      return vars
    },
    async getIssue(logs) {
      this.logRefIssue = []
      for (let i = 0; i < logs.length; i++) {
        const req = await this.$apollo.query({
          query: GET_ISSUES,
          variables: {
            where: {
              log_ref: {
                _contains: logs[i].uuid,
              },
              project_uuid: {
                _eq: this.$store.getters['project/currentProjectId'],
              },
            },
          },
        })
        if (req.data && req.data.issue.length > 0) {
          this.logRefIssue.push({
            logUuid: logs[i].uuid,
            issueId: req.data.issue[0]?.id,
          })
        }
      }
    },
  },
}
</script>

<style lang="css">
.link-project-device:hover {
  color: #64bdeb;
  cursor: pointer;
  text-decoration: underline;
}
</style>
