var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.activeMenu.length > 0)?_c('div',{class:_vm.$vuetify.breakpoint.mobile ? 'v-list-item v-list-item--link' : 'd-flex'},[_vm._l((_vm.activeMenu),function(itemMenu){return [(_vm.checkAcl(itemMenu.acl) && _vm.checkMobile(itemMenu.onMobile))?_c('div',{key:itemMenu.slug},[(!itemMenu.children)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{ path: itemMenu.path }}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v(" "+_vm._s(itemMenu.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc(itemMenu.i18n, 2)))])]):_vm._e(),(_vm.checkAcl(itemMenu.acl) && _vm.checkMobile(itemMenu.onMobile))?_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[(itemMenu.badge)?_c('alert-count-badge',{attrs:{"params":itemMenu}}):_vm._e(),(!itemMenu.badge)?_c('v-icon',[_vm._v(" "+_vm._s(itemMenu.icon)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$tc(itemMenu.i18n, 2)))])])]}}],null,true)},[(itemMenu.children)?_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_vm._l((itemMenu.children),function(child){return [(_vm.checkAcl(child.acl) && _vm.checkMobile(child.onMobile))?_c('v-list-item',{key:itemMenu.path + '/' + child.path,attrs:{"no-action":"","value":"true","to":{ path: itemMenu.path + '/' + child.path }}},[_c('v-list-item-icon',[(child.badge)?_c('alert-count-badge',{attrs:{"params":child}}):_vm._e(),(!child.badge)?_c('v-icon',[_vm._v(_vm._s(child.icon))]):_vm._e()],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc(child.i18n, 2)))])],1):_vm._e()]})],2)],1):_vm._e()],1):_vm._e()],1):_vm._e()]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }