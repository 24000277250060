var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0",attrs:{"flat":"","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headersParamsConfig,"items":_vm.deviceParameters,"item-key":"uuid","items-per-page":100,"footer-props":{
      'items-per-page-text': _vm.itemsPerPageText,
      'page-text': _vm.pageText,
      'items-per-page-options': [20, 30, 40, 50, 100],
    },"no-data-text":_vm.$t('common.noDataText'),"dense":""},on:{"click:row":function($event){return _vm.selectParameterValue($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('common.settingsDevice'))+" ("+_vm._s(_vm.target)+") ")]),_c('v-spacer'),(_vm.checkChange)?_c('v-btn',{staticClass:"mb-2 mr-1",on:{"click":_vm.saveForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" $iconSave ")]),_vm._v(_vm._s(_vm.$t('common.save'))+" ")],1):_vm._e()],1)]},proxy:true},{key:"item.order",fn:function(ref){
    var item = ref.item;
return [_c('v-text-field',{staticStyle:{"width":"70px"},attrs:{"type":"number","dense":""},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]}},{key:"item.parameter_value",fn:function(ref){
    var item = ref.item;
return [(item.project_device_parameter.constant)?_c('span',[_vm._v(" "+_vm._s(item.parameter_value.value)+" ")]):_c('span',[_c('field-loader',{attrs:{"type":item.project_device_parameter.field_type,"default-value":item.parameter_value,"field-config":item.project_device_parameter.field_config,"field-label":_vm.$t('common.value'),"dense":""},on:{"input":function($event){return _vm.updateParameterValue(item.uuid, $event.value)}}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }