<template lang="html">
  <v-breadcrumbs :items="itemsBreadcrumbs" divider=">" class="pa-0">
    <template #item="{ item }">
      <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
        <v-hover v-slot="{ hover }">
          <span :class="item.disabled ? '' : 'font-weight-medium'">
            <span :class="!hover ? '' : 'secondary--text'">
              {{ item.text }}
            </span>
          </span>
        </v-hover>
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'HelperBreadcrumbs',
  props: {
    lastItem: {
      type: String,
      default: null,
    },
  },
  computed: {
    itemsBreadcrumbs() {
      return this.getBreadcrumbs()
    },
  },
  methods: {
    getBreadcrumbs() {
      const path = this.$route.path
      const pathSplit = path.substring(1).split('/')
      const items = []
      let keyToDisable = -1
      pathSplit.forEach((el, key) => {
        const elToDisable = el === 'add' || el === 'edit' || el === 'view'
        let disabled = true
        let href = ''
        if (pathSplit.length === 1) {
          disabled = false
          href = path
        } else {
          disabled = key === 0
          href = key === 0 ? '' : path
        }
        if (elToDisable) {
          keyToDisable = key + 1
        }
        if (!elToDisable && keyToDisable !== key) {
          items.push({
            disabled: disabled,
            href: href,
            text: this.$t('common.breadcrumbs.' + el),
          })
        }
      })
      if (this.lastItem) {
        items.push({
          disabled: true,
          text: this.lastItem,
        })
      }
      return items
    },
  },
}
</script>
