<template>
  <v-combobox
    v-model="tagsSelect"
    :filter="filter"
    :hide-no-data="!tagsSearch"
    :items="tagsItems"
    :label="label"
    :menu-props="{ closeOnContentClick: true, offsetY: true, top: false }"
    :placeholder="placeholder"
    :search-input.sync="tagsSearch"
    item-text="tag_value"
    item-value="id"
    chips
    clearable
    dense
    hide-selected
    multiple
    return-object
    class="tags-selector pt-1"
    @paste="tagsPasted"
    @change="selectItem"
  >
    <template #no-data>
      <v-list-item>
        <span class="subheading">
          {{ $t('common.create') }}
        </span>
        <v-chip small>
          {{ tagsSearch }}
        </v-chip>
      </v-list-item>
    </template>
    <template #selection="{ attrs, item, parent, selected }">
      <v-chip v-if="item === Object(item)" v-bind="attrs" :input-value="selected" label small>
        <span class="pr-2">
          {{ item.tag_value }}
        </span>
        <v-icon small @click="parent.selectItem(item)">$iconClose</v-icon>
      </v-chip>
    </template>
    <template v-if="itemEdit === 'on'" #item="{ index, item }">
      <v-text-field
        v-if="editing === item"
        v-model="editing.tag_value"
        autofocus
        flat
        background-color="transparent"
        hide-details
        @keyup.enter="edit(index, item)"
      />
      <v-chip v-else label small>
        {{ item.tag_value }}
      </v-chip>
      <v-spacer />
      <v-list-item-action class="d-flex flex-row" @click.stop>
        <v-btn icon small @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? '$iconEdit' : '$iconCheck' }}</v-icon>
        </v-btn>
        <v-btn v-if="editing !== item" icon small @click.stop.prevent="deleteTag(item)">
          <v-icon>$iconDelete</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
import { GET_LIB_TAGS, POST_LIB_TAG, PATCH_LIB_TAG, DELETE_LIB_TAG } from '@/graphql/LibTagQueries'

export default {
  props: {
    editItem: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    editing: null,
    firstload: true,
    loading: false,
    tagsItems: [],
    tagsSearch: '',
    tagsSelect: [],
  }),
  computed: {
    itemEdit() {
      return this.editItem === '' ? 'on' : this.editItem
    },
    label: function () {
      return this.$tc('common.tag', 2)
    },
    placeholder: function () {
      return this.$t('libDeviceParameter.filters.tag.placeholder')
    },
  },
  watch: {
    tagsSearch(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch()
      }
    },
    tagsSelect: {
      handler: 'addTag',
      immediate: true,
    },
    value: {
      handler: 'loadTag',
      immediate: true,
    },
  },
  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item
        this.index = index
      } else {
        this.editing = null
        this.index = -1
        this.updateTag(item)
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = (val) => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    },
    loadTag() {
      this.tagsSelect = this.value.map((v) => v.lib_tag)
    },
    selectItem() {
      const libSensorParameterTags = []
      this.tagsSelect.forEach(function (item) {
        libSensorParameterTags.push({ lib_tag: item })
      })
      this.$emit('input', libSensorParameterTags)
      this.tagsSearch = ''
    },
    tagsPasted() {
      this.$nextTick(() => {
        this.tagsSelect.push(...this.tagsSearch.split(','))
        this.$nextTick(() => {
          this.tagsSearch = ''
        })
      })
    },
    async addTag(val, prev) {
      if (!prev) return
      if (val.length === prev.length) return
      if (this.firstload) {
        this.firstload = false
        return
      }

      val.map(async (v) => {
        if (typeof v === 'string') {
          const rv = await this.$apollo
            .mutate({
              mutation: POST_LIB_TAG,
              variables: {
                objects: { tag_value: v },
              },
              refetchQueries: [
                {
                  query: GET_LIB_TAGS,
                },
              ],
            })
            .catch((error) => {
              throw error
            })

          if (rv) {
            this.tagsSelect.pop()
            this.tagsSelect.push(rv.data.insert_lib_tag.returning[0])
          }
        }
      })
    },
    async deleteTag(item) {
      await this.$apollo
        .mutate({
          mutation: DELETE_LIB_TAG,
          variables: {
            id: item.id,
          },
          refetchQueries: [
            {
              query: GET_LIB_TAGS,
            },
          ],
        })
        .catch((error) => {
          throw error
        })
      this.querySearch()
    },
    async querySearch() {
      this.loading = true
      const rv = await this.$apollo.query({
        query: GET_LIB_TAGS,
        fetchPolicy: 'no-cache',
        variables: {
          sorters: {
            tag_value: 'asc',
          },
        },
      })
      this.tagsItems = rv.data.lib_tag
      this.loading = false
    },
    async updateTag(item) {
      await this.$apollo
        .mutate({
          mutation: PATCH_LIB_TAG,
          variables: {
            id: parseInt(item.id),
            body: { tag_value: item.tag_value },
          },
        })
        .catch((error) => {
          throw error
        })
    },
  },
}
</script>

<style>
.subheading {
  padding: 0 10px 0 0;
}
.tags-selector .v-select__selections input {
  min-height: 32px;
}
</style>
