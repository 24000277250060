<template>
  <v-dialog
    v-model="show"
    :no-click-animation="isTrue"
    content-class="device-dialog-fullscreen"
    fullscreen
    outlined
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card v-if="project_device_by_pk" class="overflow-y-hidden" tile>
      <v-container class="pa-0" fluid>
        <v-toolbar id="device-dialog-toolbar" dense flat>
          <v-toolbar-title>
            <span v-if="editDevices & $store.getters['auth/isAuth'](['project_device', 'update'])">
              <v-text-field v-model="project_device_by_pk.name">
                <template slot="append">
                  <v-icon @click="editDevices = false">$iconClose</v-icon>
                  <v-icon @click="changeName()">$iconSave</v-icon>
                </template>
              </v-text-field>
            </span>
            <span v-else>
              <span class="text-h5" @click="editDevices = true">
                {{ project_device_by_pk.name }}
              </span>
              <span class="text-body-2">
                {{ $tc('project.' + getDeviceTypeData(project_device_by_pk.device_type).name, 1) }}
              </span>
              <span class="text-body-2">
                {{ deviceSubtitleDescription }}
              </span>
              <span
                v-if="$store.getters['auth/isAuth'](['project_device', 'update'])"
                class="text-caption text--disabled"
              >
                {{ project_device_by_pk.id }}
              </span>
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items class="pr-4">
            <v-btn
              v-if="$store.getters['auth/isAuth'](['project_device', 'delete'])"
              icon
              @click="deleteDevice"
            >
              <v-icon>$iconDelete</v-icon>
            </v-btn>
            <v-btn icon @click="closeDialog">
              <v-icon>$iconClose</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row align="center" class="no-gutters grey lighten-5" dense>
          <v-col cols="1" style="max-width: 90px" />
          <v-col cols="9">
            <device-link-status
              v-if="project_device_by_pk.device_type !== 'hub'"
              :device="project_device_by_pk"
            />
          </v-col>
          <v-spacer />
          <v-col cols="2" style="max-width: 105px">
            <span class="mr-2 mt-3 font-italic font-weight-medium text-capitalize">
              {{ $tc('common.state', 2) }}
            </span>
            <!--
            IF hub THEN device enable/disable ?
            ELSE
            IF sensor last value is no value THEN device enable/disable ?
            ELSE calc diff time and display color + icon
            -->
            <span v-if="project_device_by_pk.device_type === 'hub'">
              <v-icon :color="isDeviceEnable ? 'red darken-2' : ''">
                {{ isDeviceEnable ? '$iconHeart' : '$iconHeartOff' }}
              </v-icon>
            </span>
            <span v-else>
              <span v-if="sensorLastValueColor === 'text--black'">
                <v-icon :color="isDeviceEnable ? 'red darken-2' : ''">
                  {{ isDeviceEnable ? '$iconHeart' : '$iconHeartOff' }}
                </v-icon>
              </span>
              <v-tooltip v-else left>
                <template #activator="{ on }">
                  <v-icon :class="sensorLastValueColor" v-on="on">
                    {{ sensorLastValueColor === 'text--black' ? '$iconHeart' : '$iconHeartBroken' }}
                  </v-icon>
                </template>
                <span>
                  {{ GetSensorLastValueColorMsg(sensorLastValue, 'msg') }}
                </span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
        <v-row align="center" class="ml-1" justify="center" no-gutters>
          <v-tabs
            v-model="tab"
            background-color="grey lighten-5"
            color="quaternary"
            slider-size="4"
            vertical
          >
            <v-tab href="#graphics">
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon v-on="on">$iconChartBar</v-icon>
                </template>
                <span class="text-capitalize">
                  {{ $tc('project.graphic', 2) }}
                </span>
              </v-tooltip>
            </v-tab>
            <v-tab href="#documents">
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon v-on="on">$iconFile</v-icon>
                </template>
                <span class="text-capitalize">
                  {{ $tc('common.document', 2) }}
                </span>
              </v-tooltip>
            </v-tab>
            <v-tab href="#historical">
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon v-on="on">$iconCalendarClock</v-icon>
                </template>
                <span class="text-capitalize">
                  {{ $t('project.historical') }}
                </span>
              </v-tooltip>
            </v-tab>
            <v-tab v-if="$store.getters['auth/isAuth'](['alert_alert', 'update'])" href="#alert">
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon v-on="on"> $iconBell</v-icon>
                </template>
                <span class="text-capitalize">
                  {{ $tc('common.notification', 2) }}
                </span>
              </v-tooltip>
            </v-tab>

            <v-tab href="#settings">
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon v-on="on">$iconSettingsAppli</v-icon>
                </template>
                <span class="text-capitalize">
                  {{ $t('common.configuration') }}
                </span>
              </v-tooltip>
            </v-tab>
            <v-tab
              v-if="$store.getters['auth/isAuth'](['project_device_parameter', 'update'])"
              href="#settings-parameters"
            >
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-icon v-on="on">$iconSettings</v-icon>
                </template>
                <span>
                  {{ $tc('common.setting', 2) }}
                </span>
              </v-tooltip>
            </v-tab>

            <v-tab-item :style="{ minHeight: tabItemWrapper }" value="graphics">
              <v-container
                id="scroll-target-1"
                fluid
                class="overflow-y-auto px-0 pt-0 pb-6"
                :style="{ maxHeight: tabItemWrapper }"
              >
                <div v-scroll:#scroll-target-1="onScroll">
                  <div v-if="project_device_by_pk.device_type === 'hub'">
                    <device-dialog-tab-hub-graph :hub="project_device_by_pk" />
                  </div>
                  <div v-if="project_device_by_pk.device_type === 'sensor'">
                    <device-dialog-tab-sensor-graph :device="project_device_by_pk" />
                  </div>
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item :style="{ minHeight: tabItemWrapper }" value="documents">
              <v-container
                id="scroll-target-2"
                fluid
                class="overflow-y-auto pa-0"
                :style="{ maxHeight: tabItemWrapper }"
              >
                <div v-scroll:#scroll-target-2="onScroll">
                  <device-dialog-tab-document :device-uuid="id" />
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item :style="{ minHeight: tabItemWrapper }" value="historical">
              <v-container
                id="scroll-target-3"
                fluid
                class="overflow-y-auto pa-0"
                :style="{ maxHeight: tabItemWrapper }"
              >
                <div v-scroll:#scroll-target-3="onScroll">
                  <device-dialog-tab-logs :device="project_device_by_pk" />
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item :style="{ minHeight: tabItemWrapper }" value="settings">
              <v-container
                id="scroll-target-4"
                fluid
                class="overflow-y-auto"
                :style="{ maxHeight: tabItemWrapper }"
              >
                <div v-scroll:#scroll-target-4="onScroll">
                  <device-dialog-tab-sensor-conf :device="project_device_by_pk" />
                  <device-dialog-config-parameters
                    :device-uuid="project_device_by_pk.id"
                    :is-device-enable.sync="isDeviceEnable"
                    target="application"
                  />
                </div>
              </v-container>
            </v-tab-item>
            <v-tab-item :style="{ minHeight: tabItemWrapper }" value="alert">
              <v-container
                id="scroll-target-4"
                fluid
                class="overflow-y-auto"
                :style="{ maxHeight: tabItemWrapper }"
              >
                <div v-scroll:#scroll-target-4="onScroll">
                  <device-dialog-alert
                    :device-uuid="project_device_by_pk.id"
                    :is-device-enable.sync="isDeviceEnable"
                  />
                </div>
              </v-container>
            </v-tab-item>

            <v-tab-item :style="{ minHeight: tabItemWrapper }" value="settings-parameters">
              <v-container
                id="scroll-target-5"
                fluid
                class="overflow-y-auto"
                :style="{ maxHeight: tabItemWrapper }"
              >
                <div v-scroll:#scroll-target-5="onScroll">
                  <device-dialog-config-parameters
                    :device-uuid="project_device_by_pk.id"
                    :is-device-enable.sync="isDeviceEnable"
                  />
                </div>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import DeviceLinkStatus from '@/components/project/devices/dialog/DeviceDialogLinkStatus'
import DeviceDialogTabDocument from '@/components/project/devices/dialog/DeviceDialogTabDocument'
import DeviceDialogTabLogs from '@/components/project/devices/dialog/DeviceDialogTabLogs'
import DeviceDialogTabSensorGraph from '@/components/project/devices/dialog/DeviceDialogTabSensorGraph'
import DeviceDialogTabSensorConf from '@/components/project/devices/dialog/DeviceDialogTabSensorConf'
import DeviceDialogTabHubGraph from '@/components/project/devices/dialog/DeviceDialogTabHubGraph'
import DeviceType from '@/components/device_library/DeviceType'
import DeviceDialogConfigParameters from '@/components/project/devices/dialog/DeviceDialogConfigParameters'
import DeviceDialogAlert from '@/components/project/devices/dialog/DeviceDialogAlert'

import {
  GET_PROJECT_DEVICE,
  GET_PROJECT_DEVICES,
  PATCH_PROJECT_DEVICE,
  DELETE_PROJECT_DEVICE,
} from '@/graphql/ProjectDeviceQueries'
import { CommonMixin } from '@/mixins/CommonMixin'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import { SensorMixin } from '@/mixins/SensorMixin'

export default {
  name: 'DeviceDialog',
  components: {
    DeviceLinkStatus,
    DeviceDialogConfigParameters,
    DeviceDialogTabDocument,
    DeviceDialogTabLogs,
    DeviceDialogTabSensorGraph,
    DeviceDialogTabSensorConf,
    DeviceDialogTabHubGraph,
    DeviceDialogAlert,
  },
  mixins: [CommonMixin, ProjectMixin, SensorMixin],
  props: {
    id: {
      type: [Boolean, String],
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    project_device_by_pk: {
      query: GET_PROJECT_DEVICE,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          id: this.id,
        }
      },
      skip() {
        return !this.id
      },
    },
  },
  data: function () {
    return {
      clickRow: true,
      editDevices: false,
      isDeviceEnable: true,
      isTrue: true,
      localId: this.id,
      project_device_by_pk_default: {
        id: false,
        name: '',
        description: '',
        device_type: 'hub',
        config_fields: {},
      },
      tab: 'graphics',
    }
  },
  computed: {
    deviceSubtitleDescription() {
      return this.project_device_by_pk.description !== ''
        ? this.project_device_by_pk.description
        : this.project_device_by_pk.model
    },
    sensorLastValue() {
      this.GetSensorLastValue(null, this.id)
      const res = {
        device_name: this.SensorsLastValue[0]?.name,
        device_uuid: this.SensorsLastValue[0]?.id,
        hub_name: this.SensorsLastValue[0]?.project_device2_links[0]?.project_device1.name,
        hub_uuid: this.SensorsLastValue[0]?.project_device2_links[0]?.project_device1.id,
        metric: this.SensorsLastValue[0]?.devices_sensors[0]?.metric,
        metric_time: this.SensorsLastValue[0]?.devices_sensors[0]?.metric_time,
      }
      return res
    },
    sensorLastValueColor() {
      return this.GetSensorLastValueColorMsg(this.sensorLastValue, 'color')
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    tabItemWrapper() {
      /* AppBar: 48px ; Toolbar: 48px ; Row !== hub: 40.77px */
      return this.project_device_by_pk.device_type === 'hub'
        ? 'calc(100vh - (48px + 48px))'
        : 'calc(100vh - (48px + 48px + 41px))'
    },
  },
  watch: {
    localId: function (val, oldVal) {
      if (val || val !== oldVal) {
        this.isDeviceEnable = this.deviceEnable()
      }
    },
    tab: function (val) {
      if (this.$route.meta.isProjectDrawerDevice && this.$route.params.tab !== val) {
        this.$router.replace({ name: 'projectDevice', params: { tab: val } })
      }
    },
  },
  mounted() {
    this.tab = this.$route.params?.tab || 'graphics'
    setTimeout(() => {
      this.isDeviceEnable = this.deviceEnable()
    }, 500)
  },
  methods: {
    closeDialog() {
      if (this.$route.meta.isProjectDrawerDevice) {
        this.$route.meta.isProjectDrawerDeviceDialog = false
        this.$router.back()
      } else {
        this.$emit('input', false)
        this.editDevices = false
      }
    },
    deviceEnable() {
      const enable = this.GetProjectDeviceParameter(this.localId, 'enable')
      return enable === null ? true : enable
    },
    getDeviceTypeData(deviceType) {
      return DeviceType.find((x) => x.name === deviceType)
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop
    },
    async changeName() {
      const projectDevice = Object.keys(this.project_device_by_pk_default).reduce(
        (a, c) => ({ ...a, [c]: this.project_device_by_pk[c] }),
        {}
      )

      await this.$apollo
        .mutate({
          mutation: PATCH_PROJECT_DEVICE,
          variables: {
            id: this.id,
            body: projectDevice,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_DEVICES,
              variables: {
                where: {
                  project_id: {
                    _eq: this.$store.getters['project/currentProjectId'],
                  },
                },
              },
            },
          ],
        })
        .catch((error) => {
          throw error
        })
      this.editDevices = false
    },
    async deleteDevice() {
      const removal = this.$t('common.removal')
      const sureToDelete = this.$t('common.sureToDelete')
      if (await this.$root.$confirm(removal, sureToDelete, { color: 'red' })) {
        this.$root.$dialogLoader.start(
          removal + '...',
          {},
          () => {
            return new Promise((resolve, reject) => {
              try {
                const _this = this
                this.$apollo.mutate({
                  mutation: DELETE_PROJECT_DEVICE,
                  variables: {
                    id: _this.id,
                  },
                  refetchQueries: [
                    {
                      query: GET_PROJECT_DEVICES,
                      variables: {
                        where: {
                          project_id: {
                            _eq: this.$store.getters['project/currentProjectId'],
                          },
                        },
                      },
                    },
                  ],
                })
                this.localId = false
                this.ProjectDevicesUpdate = true
                this.closeDialog()
              } catch (e) {
                reject(e.toString())
              }
            })
          },
          true
        )
      }
    },
  },
}
</script>

<style lang="css">
#device-dialog-toolbar .v-toolbar__content {
  padding-right: 5px;
}
.device-dialog-fullscreen {
  top: 48px;
  height: calc(100% - 48px);
}
</style>
