<template>
  <div>
    <slot
      :attrs="{ errorMessages: activeErrorMessages, success: isValid }"
      :has-errors="hasErrors"
    />
  </div>
</template>
<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'
export default {
  extends: singleErrorExtractorMixin,
}
</script>
