<template>
  <v-container fluid class="mx-auto">
    <v-banner id="sensor-graph-banner" single-line elevation="2" rounded="4">
      <time-interval v-model="optionsTimeInterval" />
    </v-banner>
    <graph-single :options-params.sync="optionsParams" />
  </v-container>
</template>

<script>
import GraphSingle from '@/components/project/graphs/GraphSingle'
import TimeInterval from '@/components/helper/TimeInterval'

export default {
  name: 'DeviceDialogTabSensorGraph',
  components: {
    GraphSingle,
    TimeInterval,
  },
  props: {
    device: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    optionsParams: {},
    optionsTimeInterval: {},
  }),
  watch: {
    'device.id': function (val, oldVal) {
      if (oldVal !== val) {
        this.optionsParams = this.getOptionsParamsDefault()
        this.optionsTimeInterval = {}
        delete this.optionsParams.newOptions
      }
    },
    optionsTimeInterval: {
      handler: function (val) {
        this.optionsParams = Object.assign({}, this.optionsParams, val)
        this.updateGraph()
      },
      deep: true,
    },
  },
  mounted() {
    this.optionsParams = this.getOptionsParamsDefault()
  },
  methods: {
    getOptionsParamsDefault() {
      const options = {
        device_id: this.device.id,
        device_name: this.device.name,
        hub_name: this.device?.project_device2_links[0]?.project_device1?.name,
      }
      return options
    },
    updateGraph() {
      this.optionsParams.newOptions = true
    },
  },
}
</script>

<style>
#sensor-graph-banner > .v-banner__wrapper {
  padding: 6px 12px 0;
}
</style>
