var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"elevation-0 pa-0 fill-height flat",attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',[(_vm.showForm)?_vm._t("form",null,{"editedId":_vm.editedId,"listparams":_vm.params,"closeForm":_vm.closeForm,"transition":"scroll-x-reverse-transition"}):_vm._e(),_c('v-scroll-x-reverse-transition',[(!_vm.showForm)?_c('div',{attrs:{"fill-height":""}},[(_vm.showToolbar)?_c('v-toolbar',{staticClass:"grey lighten-5",attrs:{"flat":""}},[_c('helper-breadcrumbs'),_c('v-spacer'),_vm._t("toolbar-button"),(_vm.createButton)?_c('v-btn',{staticClass:"mr-1",on:{"click":_vm.createItem}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" $iconPlus ")]),_vm._v(_vm._s(_vm.$t('common.new'))+" ")],1):_vm._e(),(_vm.homeButton)?_c('v-btn',{staticClass:"mr-1",attrs:{"to":{ name: 'home' }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v(" $iconClose ")]),_vm._v(_vm._s(_vm.$t('common.close'))+" ")],1):_vm._e()],2):_vm._e(),_c('helper-crud-list-filter',{attrs:{"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},model:{value:(_vm.options.filter),callback:function ($$v) {_vm.$set(_vm.options, "filter", $$v)},expression:"options.filter"}}),(!_vm.showForm)?_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"item-class":_vm.rowClasses,"custom-filter":_vm.convertOptionsToGQL,"show-expand":_vm.showExpand,"footer-props":{
                'items-per-page-text': _vm.itemsPerPageText,
                'page-text': _vm.pageText,
                'items-per-page-options': [5, 10, 25, 50, -1],
              },"height":_vm.heightDataTable,"hide-default-footer":_vm.options.hideDefaultFooter,"loading":_vm.loading,"no-data-text":_vm.$t('common.noDataText'),"options":_vm.options,"server-items-length":_vm.totalCount,"fixed-header":"","sort-by":"name"},on:_vm._d({"update:options":function($event){_vm.options=$event}},[_vm.rowclickCondition,_vm.editItem]),scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":""}})]},proxy:true},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_vm._t("preAction",null,{"item":item,"listparams":_vm.params}),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.isEdit(item) ? '$iconEdit' : '$iconEye')+" ")]),(_vm.headersDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" $iconDelete ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.updated_at))+" ")]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mt-2 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('common.noDataText'))+" ")])]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)})],1):_vm._e()],1):_vm._e()])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }