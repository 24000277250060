<template>
  <v-container fluid class="pa-0">
    <v-card outlined class="pa-2">
      <v-text-field
        v-model="queryDeviceOptions.filter.filteredName"
        :label="$t('common.name')"
        clearable
        dense
      />
    </v-card>
    <v-data-table
      id="project-drawer-device-hub-calc-height"
      v-model="selectedDevices"
      :headers="headers"
      :items="projectDevicesFinal"
      :no-data-text="$t('common.noDataText')"
      :single-select="singleSelected"
      class="deviceDataTable pa-0 ma-0"
      dense
      disable-filtering
      disable-pagination
      fixed-header
      hide-default-footer
      must-sort
      style="overflow-y: scroll"
      @click:row="rowClick"
    >
      <template #[`item.name`]="{ item }">
        <v-tooltip left>
          <template #activator="{ on }">
            <span
              :class="[
                getPresent(item) ? 'text-decoration-none' : 'text-decoration-underline',
                'd-inline-block',
                'text-truncate',
              ]"
              style="max-width: 260px"
              v-on="on"
            >
              {{ item.name }}
            </span>
          </template>
          <span>
            {{ item.name }}
          </span>
          <span v-if="!getPresent(item)" class="pl-1">
            {{ $t('project.deviceNotPresentInView') }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-toolbar color="tertiary" height="32px">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            class="mr-3"
            :color="isSelectedToOpenDevice ? 'secondary' : 'primary'"
            small
            v-on="on"
            @click="clickIconToolbar('openDevice')"
          >
            $iconSettings
          </v-icon>
        </template>
        <span>{{ $t('project.deviceInfo') }}</span>
      </v-tooltip>
      <v-tooltip
        v-if="$store.getters['auth/isAuth'](['project_view_device_coordinate', 'update'])"
        top
      >
        <template #activator="{ on }">
          <v-icon
            class="mr-3"
            :color="isSelectedToAddDeviceMap ? 'secondary' : 'primary'"
            small
            v-on="on"
            @click="clickIconToolbar('addDeviceMap')"
          >
            $iconTarget
          </v-icon>
        </template>
        <span>{{ $t('project.addDeviceMap') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            small
            :color="isSelectedToZoomOn ? 'secondary' : 'primary'"
            v-on="on"
            @click="clickIconToolbar('zoomOn')"
          >
            $iconEye
          </v-icon>
        </template>
        <span>{{ $t('project.zoomOnDevice') }}</span>
      </v-tooltip>
      <v-spacer />
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            v-if="$store.getters['auth/isAuth'](['project_device', 'create'])"
            icon
            small
            @click="addDevice"
          >
            <v-icon v-on="on">$iconPlus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('project.addDevice') }}</span>
      </v-tooltip>
      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            v-if="$store.getters['auth/isAuth'](['project_device', 'delete'])"
            icon
            small
            :disabled="selectedDevices.length < 1"
            @click="multiDeleteDevice"
          >
            <v-icon v-on="on">$iconDelete</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('project.deleteDevice') }}</span>
      </v-tooltip>
    </v-toolbar>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { eventBus } from '@/plugins/eventBus'
import { GET_PROJECT_DEVICE_LINKS } from '@/graphql/ProjectDeviceLinkQueries'
import { GET_PROJECT_DEVICES, DELETE_PROJECT_DEVICE } from '@/graphql/ProjectDeviceQueries'
import { SUBSCRIPTION_PROJECT_VIEW_DEVICE_COORDINATES } from '@/graphql/ProjectViewDeviceCoordinateQueries'
import { ProjectMixin } from '@/mixins/ProjectMixin'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'

export default {
  name: 'ProjectDrawerDeviceHub',
  mixins: [ProjectMixin],
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Number,
      default: null,
    },
  },
  apollo: {
    $subscribe: {
      project_view_device_coordinate: {
        query: SUBSCRIPTION_PROJECT_VIEW_DEVICE_COORDINATES,
        variables() {
          let vars = {}
          if (this.currentView.type === '2d') {
            vars = {
              where: {
                project_uuid: {
                  _eq: this.currentView.project_uuid,
                },
                project_view_uuid: {
                  _eq: this.currentView.uuid,
                },
                project_view_coordinate_system_id: {
                  _eq: 1,
                },
              },
            }
          } else {
            vars = {
              where: {
                project_uuid: {
                  _eq: this.currentView.project_uuid,
                },
                project_view_coordinate_system_id: {
                  _eq: 2,
                },
              },
            }
          }
          return vars
        },
        result({ data }) {
          if (data) {
            this.projectViewDeviceCoordinate = data.project_view_device_coordinate
          }
        },
      },
    },
  },
  data: function () {
    return {
      deviceDialog: false,
      deviceSelector: false,
      isSelectedToAddDeviceMap: false,
      isSelectedToOpenDevice: true,
      isSelectedToZoomOn: false,
      localDevices: this.devices,
      projectDevices: [],
      projectDevicesFinal: [],
      projectViewDeviceCoordinate: [],
      queryDeviceOptions: {
        sortBy: ['name'],
        sortDesc: [true],
        filter: {
          project_id: this.currentProjectId,
          filteredName: '',
          device_type: 'hub',
          filteredHub: null,
        },
      },
      selectedDevice: false,
      selectedDevices: [],
      singleSelected: false,
    }
  },
  computed: {
    ...mapGetters({
      currentProjectId: 'project/currentProjectId',
      currentView: 'project/currentView',
    }),
    headers: function () {
      return [
        {
          text: this.$t('common.name'),
          value: 'name',
          align: 'left',
          cellClass: 'px-2 pt-2',
          class: 'px-2',
          sortable: true,
        },
      ]
    },
  },
  watch: {
    currentView: function (val, oldVal) {
      if (val.uuid !== oldVal.uuid) this.getQueryDeviceOptions()
    },
    currentProjectId: function (val, oldVal) {
      if (
        val !== oldVal &&
        (!this.queryDeviceOptions.filter?.filteredName ||
          !this.queryDeviceOptions.filter?.filteredHub)
      ) {
        this.queryDeviceOptions.filter.filteredName = ''
        this.queryDeviceOptions.filter.filteredHub = null
      }
    },
    devices: function (val, oldVal) {
      if (val.length !== oldVal.length) {
        this.localDevices = val
        this.initDefault()
      }
    },
    projectDevices: function (val, oldVal) {
      if (val.length > 0 || val !== oldVal) {
        this.getDevices()
      }
    },
    queryDeviceOptions: {
      handler() {
        this.getQueryDeviceOptions()
      },
      deep: true,
    },
    selectedDevices: {
      handler() {
        eventBus.$emit('selectDevice', this.selectedDevices)
      },
    },
    tab: function (val, oldVal) {
      if (val !== oldVal) this.initDefault()
    },
  },
  mounted() {
    this.initDefault()
  },
  methods: {
    ...mapActions({
      setTargetDevice: 'project/setTargetDevice',
      setViewTool: 'project/setViewTool',
      updateCurrentViewOptions: 'project/updateCurrentViewOptions',
    }),
    addDevice() {
      eventBus.$emit('addDevice', { type: 'hub' })
    },
    clickItem(item) {
      eventBus.$emit('editDevice', item)
    },
    editDevice(item) {
      this.selectedDevice = item.id
      this.deviceDialog = true
    },
    getDevices() {
      // init
      const listCurrentView = []
      const listOtherView = []
      let listFinal = []
      this.projectDevicesFinal = []

      if (this.currentView.type === '2d') {
        this.projectDevices?.forEach((item) => {
          if (item.project_view_device_coordinates.length > 0) {
            item.project_view_device_coordinates.forEach((coord) => {
              if (coord.project_view_uuid === this.currentView.uuid) {
                listCurrentView.push(item)
              }
              if (
                coord.project_view_uuid !== this.currentView.uuid &&
                coord.project_view_coordinate_system_id === 1
              ) {
                listOtherView.push(item)
              }
            })
          }
        })

        const listWithoutOtherView = this.projectDevices.filter((objFromA) => {
          return !listOtherView.find((objFromB) => {
            return objFromA.id === objFromB.id
          })
        })
        const listConcat = listCurrentView.concat(listWithoutOtherView)
        listFinal = uniqWith(listConcat, isEqual)

        this.projectDevicesFinal = listFinal
      } else {
        this.projectDevicesFinal = this.projectDevices
      }
    },
    getPresent(item) {
      return this.projectViewDeviceCoordinate.find((i) => i.project_device_uuid === item.id)
    },
    getQueryDeviceOptions() {
      const { sortBy, sortDesc, filter } = this.queryDeviceOptions
      const gqlWhere = {
        project_id: { _eq: this.currentProjectId },
        device_type: { _eq: 'hub' },
        ...(filter.filteredHub && {
          _or: [
            {
              project_device1_links: {
                project_device2_id: { _eq: filter.filteredHub },
              },
            },
            {
              project_device2_links: {
                project_device1_id: { _eq: filter.filteredHub },
              },
            },
          ],
        }),
        ...(filter.filteredName && {
          name: { _ilike: '%' + filter.filteredName + '%' },
        }),
      }
      this.params = {
        where: gqlWhere,
        order_by: sortBy.length ? { [sortBy]: sortDesc[0] ? 'asc' : 'desc' } : {},
      }
      this.getSearchDevices()
    },
    initDefault() {
      this.isSelectedToAddDeviceMap = false
      this.isSelectedToOpenDevice = true
      this.isSelectedToZoomOn = false
      this.projectDevices = this.localDevices
    },
    positionDevice(item) {
      eventBus.$emit('positionDevice', item)
    },
    async clickIconToolbar(action) {
      await this.setViewTool('select')
      this.selectedDevices = []
      if (action === 'openDevice') {
        this.isSelectedToAddDeviceMap = false
        this.isSelectedToOpenDevice = this.$store.getters['auth/isAuth']([
          'project_device',
          'delete',
        ])
          ? !this.isSelectedToOpenDevice
          : true
        this.isSelectedToZoomOn = false
      }
      if (action === 'addDeviceMap') {
        this.isSelectedToAddDeviceMap = !this.isSelectedToAddDeviceMap
        this.isSelectedToOpenDevice = !this.isSelectedToAddDeviceMap
        this.isSelectedToZoomOn = false
      }
      if (action === 'zoomOn') {
        // ! keep the order of execution of variables
        this.isSelectedToZoomOn = !this.isSelectedToZoomOn
        this.isSelectedToOpenDevice = !this.isSelectedToZoomOn
        this.isSelectedToAddDeviceMap = false
        if (!this.isSelectedToZoomOn) {
          this.updateCurrentViewOptions({
            center: this.ProjectView.options.center,
            zoom: this.ProjectView.options.zoom,
          })
          if (this.$route.name === 'ViewFocus') this.$router.push('/project')
        }
      }
    },
    async deleteDevice(device) {
      const removal = this.$t('common.removal')
      const removalFormatting = this.$t('common.removalFormatting', {
        attribute: device.name,
      })
      const sureToDeleteThis = this.$t('common.sureToDeleteThis', {
        attribute: device.name,
      })

      if (await this.$root.$confirm(removal, sureToDeleteThis, { color: 'red' })) {
        this.$root.$dialogLoader.start(
          removalFormatting + '...',
          {},
          () => {
            return new Promise((reject) => {
              try {
                this.$apollo.mutate({
                  mutation: DELETE_PROJECT_DEVICE,
                  variables: {
                    id: [device.id],
                  },
                })
                this.$root.$dialogLoader.showSnackbar(removal, {
                  color: 'success',
                })
              } catch (e) {
                reject(e.toString())
              }
            })
          },
          true
        )
      }
    },
    async getDevicesByHub(hubUuid) {
      const devicesUuid = []
      const vars = {
        where: {
          project_device1_id: {
            _eq: hubUuid,
          },
          project_device2: {
            project_view_device_coordinates: {
              project_uuid: {
                _eq: this.currentView.project_uuid,
              },
              project_view_uuid: {
                _eq: this.currentView.uuid,
              },
            },
          },
        },
      }
      const req = await this.$apollo.mutate({
        mutation: GET_PROJECT_DEVICE_LINKS,
        variables: vars,
      })
      req.data?.project_device_link.forEach((i) => {
        devicesUuid.push(i.project_device2_id)
      })
      return devicesUuid
    },
    async getSearchDevices() {
      let result = []

      await this.$apollo
        .query({
          query: GET_PROJECT_DEVICES,
          variables: this.params,
        })
        .then((res) => {
          result = res
        })
        .catch((err) => {
          throw err
        })

      this.projectDevices = result?.data?.project_device || []
    },
    async multiDeleteDevice() {
      if (this.selectedDevices.length !== 0) {
        const nameList = this.selectedDevices.map((e) => e.name).join(', ')
        const removal = this.$t('common.removal')
        const removalFormatting = this.$t('common.removalFormatting', {
          attribute: nameList,
        })
        const sureToDeleteThis = this.$t('common.sureToDeleteThis', {
          attribute: nameList,
        })

        if (await this.$root.$confirm(removal, sureToDeleteThis, { color: 'red' })) {
          this.$root.$dialogLoader.start(removalFormatting + '...', {}, () => {
            try {
              const idArray = this.selectedDevices.map((e) => e.id)
              return new Promise((reject) => {
                try {
                  this.$apollo.mutate({
                    mutation: DELETE_PROJECT_DEVICE,
                    variables: {
                      id: idArray,
                    },
                  })
                } catch (e) {
                  reject(e.toString())
                }
              })
            } catch (e) {
              console.log(e)
            }
          })
        }
      }
    },
    async rowClick(item, row) {
      row.select(!row.isSelected)
      this.singleSelected = false
      if (!row.isSelected) {
        if (this.isSelectedToOpenDevice) {
          this.singleSelected = true
          this.clickItem(item)
        }
        if (this.isSelectedToAddDeviceMap) {
          await this.setViewTool('device-pos')
          this.singleSelected = true
          this.positionDevice(item)
        }
        if (this.isSelectedToZoomOn && this.getPresent(item)) {
          this.singleSelected = true
          if (this.getPresent(item)) this.zoomOn(item.id)
        }
      } else {
        if (this.isSelectedToAddDeviceMap) {
          await this.setViewTool('select')
        }
        if (this.isSelectedToZoomOn) {
          this.updateCurrentViewOptions({
            center: this.ProjectView.options.center,
            zoom: this.ProjectView.options.zoom,
          })
        }
      }
    },
    async zoomOn(hubUuid) {
      const devicesByHub = await this.getDevicesByHub(hubUuid)
      const devices = devicesByHub.length > 0 ? devicesByHub : []
      devices.push(hubUuid)
      this.setTargetDevice(devices)
    },
  },
}
</script>

<style>
tr.v-data-table__selected {
  background: #64bdeb !important;
}
#project-drawer-device-hub-calc-height {
  max-height: calc(100vh - (48px + 226px)); /* AppBar: 48px + ...*/
  min-height: calc(100vh - (48px + 226px)); /* AppBar: 48px + ...*/
}
</style>
