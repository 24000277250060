<template>
  <v-card flat outlined>
    <v-row align="baseline" no-gutters class="px-4 pb-4">
      <v-col cols="auto">
        <span class="text-subtitle-1 font-weight-bold text-capitalize">
          {{ $tc('project.coordinates', 1) }}
        </span>
      </v-col>
      <v-col cols="3" md="2" class="px-2">
        <v-text-field
          v-model.number="deviceCoordinate.y"
          :label="$t('project.latitude')"
          hide-details
        />
      </v-col>
      <v-col cols="3" md="2" class="px-2">
        <v-text-field
          v-model.number="deviceCoordinate.x"
          :label="$t('project.longitude')"
          hide-details
        />
      </v-col>
      <v-col
        v-if="$store.getters['auth/isAuth'](['project_view_device_coordinate', 'update'])"
        cols="2"
        md="3"
      >
        <v-icon class="mr-3" :title="$t('common.save')" @click="saveCoordinate()">
          $iconSave
        </v-icon>
        <v-icon :title="$t('common.delete')" @click="deleteCoordinate()"> $iconDelete </v-icon>
      </v-col>
    </v-row>
    <v-divider v-if="device.device_type === 'hub'" />
    <v-row v-if="device.device_type === 'hub'" align="center" justify="center" no-gutters>
      <device-dialog-tab-sensor-conf-hub :device="device" />
    </v-row>
  </v-card>
</template>

<script>
import {
  GET_PROJECT_VIEW_DEVICE_COORDINATE_WITHOUT_VIEW,
  POST_PROJECT_VIEW_DEVICE_COORDINATE,
  DELETE_PROJECT_VIEW_DEVICE_COORDINATE,
} from '@/graphql/ProjectViewDeviceCoordinateQueries'
import DeviceDialogTabSensorConfHub from '@/components/project/devices/dialog/DeviceDialogTabSensorConfHub'
import { CommonMixin } from '@/mixins/CommonMixin'

export default {
  name: 'DeviceDialogTabSensorConf',
  components: {
    DeviceDialogTabSensorConfHub,
  },
  mixins: [CommonMixin],
  props: {
    device: {
      type: Object,
      default: undefined,
    },
  },
  apollo: {
    deviceCoordinate: {
      query: GET_PROJECT_VIEW_DEVICE_COORDINATE_WITHOUT_VIEW,
      variables() {
        return {
          project_uuid: this.$store.getters['project/currentProjectId'],
          project_device_uuid: this.device.id,
          coordinate_system_id: 2,
        }
      },
      update(data) {
        if (!data.project_view_device_coordinate[0]) {
          return {
            x: '',
            y: '',
          }
        }
        return data.project_view_device_coordinate[0]
      },
      skip() {
        return !this.$store.getters['project/currentProjectId']
      },
    },
  },
  data: () => ({
    deviceCoordinate: {
      x: '',
      y: '',
    },
  }),
  methods: {
    async deleteCoordinate() {
      const removal = this.$t('common.removal')
      const sureToDeleteThis = this.$t('common.sureToDeleteThis', {
        attribute: this.$tc('project.coordinates', 2),
      })
      const where = {
        where: {
          project_uuid: {
            _eq: this.device.project_id,
          },
          project_view_uuid: {
            _eq: this.$store.getters['project/currentView'].uuid,
          },
          project_view_coordinate_system_id: {
            _eq: 2,
          },
          project_device_uuid: {
            _eq: this.device.id,
          },
        },
      }
      if (await this.$root.$confirm(removal, sureToDeleteThis, { color: 'red' })) {
        await this.$apollo
          .mutate({
            mutation: DELETE_PROJECT_VIEW_DEVICE_COORDINATE,
            variables: where,
          })
          .then(() => {
            this.ShowSnackbarSuccess()
            this.deviceCoordinate = {
              x: '',
              y: '',
            }
          })
          .catch((error) => {
            this.ShowSnackbarError()
            throw error
          })
      }
    },
    async saveCoordinate() {
      const coordinate = {
        project_uuid: this.device.project_id,
        project_view_uuid: this.$store.getters['project/currentView'].uuid,
        project_view_coordinate_system_id: 2,
        project_device_uuid: this.device.id,
        x: parseFloat(this.deviceCoordinate.x),
        y: parseFloat(this.deviceCoordinate.y),
      }
      const vars = {
        project_uuid: this.device.project_id,
        project_device_uuid: this.device.id,
        coordinate_system_id: 2,
      }

      await this.$apollo
        .mutate({
          mutation: POST_PROJECT_VIEW_DEVICE_COORDINATE,
          variables: { coordinate: coordinate },
          refetchQueries: [
            {
              query: GET_PROJECT_VIEW_DEVICE_COORDINATE_WITHOUT_VIEW,
              variables: vars,
            },
          ],
        })
        .then(() => {
          this.ShowSnackbarSuccess()
        })
        .catch((error) => {
          this.ShowSnackbarError()
          throw error
        })
    },
  },
}
</script>
