import gql from 'graphql-tag'

// queries
export const GET_CRM_COMPANY = gql`
  query ($uuid: uuid!) {
    crm_company_by_pk(uuid: $uuid) {
      uuid
      name
      other_name
      address
      zip_code
      city
      profile
      created_at
      updated_at
    }
  }
`
export const GET_CRM_COMPANIES = gql`
  query crm_company(
    $where: crm_company_bool_exp
    $order_by: [crm_company_order_by!]
    $limit: Int
    $offset: Int
  ) {
    crm_company_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    crm_company(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      uuid
      name
      other_name
      address
      zip_code
      city
      profile
      created_at
      updated_at
    }
  }
`

// mutations
export const PATCH_CRM_COMPANY = gql`
  mutation ($uuid: uuid!, $body: crm_company_set_input!) {
    update_crm_company(where: { uuid: { _eq: $uuid } }, _set: $body) {
      affected_rows
      returning {
        uuid
        name
        other_name
        address
        zip_code
        city
        profile
        created_at
        updated_at
      }
    }
  }
`

export const POST_CRM_COMPANY = gql`
  mutation ($objects: [crm_company_insert_input!]!) {
    insert_crm_company(objects: $objects) {
      returning {
        uuid
        name
        other_name
        address
        zip_code
        city
        profile
        created_at
        updated_at
      }
    }
  }
`

export const DELETE_CRM_COMPANY = gql`
  mutation ($uuid: uuid!) {
    delete_crm_company(where: { uuid: { _eq: $uuid } }) {
      returning {
        uuid
        created_at
        updated_at
      }
    }
  }
`

// subscriptions
export const COMPANY_ADDED = gql`
  subscription {
    crm_company {
      uuid
      name
      other_name
      created_at
      updated_at
    }
  }
`

export const COMPANY_UPDATED = gql`
  subscription {
    crm_company {
      uuid
      name
      other_name
      created_at
      updated_at
    }
  }
`
