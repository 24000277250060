import gql from 'graphql-tag'

const eventFragment = gql`
  fragment Event on event {
    uuid
    category_id
    name
    description
    start
    end
    timed
    updated_at
    created_at
    event_category {
      id
      name
      color
    }
    event_crm_contacts {
      crm_contact {
        uuid
        firstname
        lastname
        address
        zip_code
        city
        job_title
        crm_company {
          uuid
          name
        }
        crm_phones {
          phone
          type
          priority
        }
        crm_emails {
          email
          type
        }
      }
    }
    event_devices {
      project_device {
        id
        name
        device_type
      }
    }
  }
`

export const GET_EVENT = gql`
  query ($uuid: uuid!) {
    event_by_pk(uuid: $uuid) {
      ...Event
    }
  }
  ${eventFragment}
`

export const GET_EVENTS = gql`
  query event($where: event_bool_exp, $order_by: [event_order_by!], $limit: Int, $offset: Int) {
    event_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    event(where: $where, offset: $offset, limit: $limit, order_by: $order_by) {
      ...Event
    }
  }
  ${eventFragment}
`

export const INSERT_EVENT_ONE = gql`
  mutation insert_event_one($object: event_insert_input!) {
    insert_event_one(object: $object) {
      ...Event
    }
  }
  ${eventFragment}
`

export const UPDATE_EVENT_BY_PK = gql`
  mutation update_event_by_pk(
    $uuid: uuid!
    $object: event_set_input!
    $crm_contacts: [event_crm_contact_insert_input!]!
    $devices: [event_device_insert_input!]!
  ) {
    delete_event_crm_contact(where: { event_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_event_crm_contact(objects: $crm_contacts) {
      affected_rows
    }
    delete_event_device(where: { event_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_event_device(objects: $devices) {
      affected_rows
    }
    update_event_by_pk(pk_columns: { uuid: $uuid }, _set: $object) {
      ...Event
    }
  }
  ${eventFragment}
`

export const UPDATE_EVENT_TIME_BY_PK = gql`
  mutation update_event_by_pk($uuid: uuid!, $start: timestamptz!, $end: timestamptz!) {
    update_event_by_pk(pk_columns: { uuid: $uuid }, _set: { start: $start, end: $end }) {
      ...Event
    }
  }
  ${eventFragment}
`

export const DELETE_EVENT_BY_PK = gql`
  mutation ($uuid: uuid!) {
    delete_event_by_pk(uuid: $uuid) {
      uuid
      created_at
      updated_at
    }
  }
`
