<template>
  <v-container fluid class="pt-2">
    <v-form v-if="hasRights">
      <v-row align="center" justify="center" class="px-1">
        <v-col>
          <v-combobox
            v-model="newType"
            :items="phoneTypeDefault"
            :search-input.sync="search"
            :error-messages="phoneTypeErrorMessage"
            :hint="$t('common.typeChoice')"
            :label="$t('common.selectOrAdd')"
            hide-selected
            persistent-hint
            small-chips
            dense
            @update:search-input="$v.newType.$touch()"
            @blur="$v.newType.$touch()"
          >
            <template #no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('common.noResultsMatching') }} "<strong>{{ search }}</strong
                    >". {{ $t('common.toCreateNewPress') }}
                    <kbd>{{ $t('common.enter') }}</kbd>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
        <v-col>
          <form-error :validator="$v.newPhone" :attribute="$t('common.phone')" :messages="messages">
            <v-text-field
              v-bind="attrs"
              v-model="newPhone"
              slot-scope="{ attrs }"
              :label="$t('common.phone')"
              :placeholder="$t('common.phoneNumber')"
              :error-messages="phoneErrorMessage"
              class="mt-0 pt-1"
              @input="$v.newPhone.$touch()"
              @blur="$v.newPhone.$touch()"
            />
          </form-error>
        </v-col>
        <v-col md="auto">
          <button v-if="hasRights" @click.prevent="addNewPhone()">
            <v-icon :title="$t('common.add')">$iconPlus</v-icon>
          </button>
        </v-col>
      </v-row>
    </v-form>
    <v-list>
      <template v-for="(item, index) in phonesList">
        <v-list-item :key="item.uuid" dense class="px-1">
          <v-list-item-content>
            <v-list-item-title>{{ item.type }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>{{ item.phone }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn v-if="hasRights" icon x-small @click.stop.prevent="deletePhone(index, item)">
              <v-icon :title="$t('common.delete')" @click="deletePhone(index, item)">
                $iconDelete
              </v-icon>
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
        <v-divider :key="index" />
      </template>
    </v-list>
  </v-container>
</template>

<script>
import { GET_CRM_PHONES, POST_CRM_PHONE, DELETE_CRM_PHONE } from '@/graphql/CrmPhoneQueries'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { phone } from '@/components/validators'
import upperFirst from 'lodash/upperFirst'

export default {
  name: 'PhoneSelector',
  mixins: [validationMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    crmName: {
      type: String,
      default: '', // contact || company
      required: true,
    },
  },
  data() {
    return {
      crmLocal: this.value,
      newPhone: '',
      newType: '',
      phonesList: [],
      phoneTypeDefault: [
        this.$t('common.typeWork'),
        this.$t('common.typeHome'),
        this.$t('common.typeNotification'),
        this.$t('common.phoneMobile'),
      ],
      search: null,
    }
  },
  validations: {
    newPhone: { required, phone },
    newType: { required },
    validationGroup: ['newType', 'newPhone'],
  },
  computed: {
    hasRights() {
      return (
        this.$store.getters['auth/isAuth'](['crm_contact', 'create']) ||
        this.$store.getters['auth/user'].crm_contact_uuid === this.crmLocal.uuid
      )
    },
    messages() {
      return this.$t('validationMessage')
    },
    phoneErrorMessage() {
      let msg = ''
      if (this.newType !== '' && this.$v.validationGroup.newPhone.$invalid) {
        const attr = this.$t('common.phone')
        if (this.$v.validationGroup.newPhone.required) {
          msg = this.$t('validationMessage.required', { attribute: attr })
        }
        if (this.$v.validationGroup.newPhone.phone) {
          msg = this.$t('validationMessage.phone', { attribute: attr })
        }
      }
      return msg
    },
    phoneTypeErrorMessage() {
      let msg = ''
      if (this.newPhone !== '' && this.$v.validationGroup.newType.$invalid) {
        const type = this.$t('common.type')
        msg = this.$t('validationMessage.required', { attribute: type })
      }
      return msg
    },
  },
  watch: {
    value: {
      handler: function (val) {
        if (val.uuid !== '') {
          this.crmLocal = val
          this.getPhone()
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPhone()
  },
  methods: {
    addNewPhone: function () {
      const newPhoneType = this.newType
      const newPhone = this.newPhone

      // Display message if phone field failed
      this.$v.$touch()
      if (this.$v.validationGroup.$invalid) {
        const phoneTxt = this.$t('common.phone')
        let errorForm = upperFirst(this.$t('common.errorOnForm')) + ' ' + phoneTxt + '.'
        if (this.$v.validationGroup.newType.$invalid && this.$v.validationGroup.newPhone.$invalid) {
          const attr = this.$t('common.type') + ' + ' + phoneTxt
          const msg = this.$t('validationMessage.required', {
            attribute: attr,
          })
          errorForm = errorForm + ' ' + msg
        }
        this.$root.$dialogLoader.showSnackbar(errorForm, { color: 'error' })
        return
      } else {
        if (this.crmLocal.uuid !== '') {
          // Add new phone to db with crmUuid
          this.addPhoneWithCrmUuid(newPhone, newPhoneType)
        }
      }

      // Clear
      this.newType = ''
      this.newPhone = ''
    },
    getQueryVariables() {
      // Condition where for db to get, post query
      let vars = {}
      if (this.crmName === 'contact') {
        vars = {
          where: { crm_contact_uuid: { _eq: this.crmLocal.uuid } },
        }
      }
      if (this.crmName === 'company') {
        vars = {
          where: { crm_company_uuid: { _eq: this.crmLocal.uuid } },
        }
      }
      return vars
    },
    async addPhoneWithCrmUuid(newPhone, newType) {
      let phones = {}

      // Create object phone by crmName to post query
      if (this.crmName === 'contact') {
        phones = {
          crm_contact_uuid: this.crmLocal.uuid,
          phone: newPhone,
          type: newType,
        }
      }

      if (this.crmName === 'company') {
        phones = {
          crm_company_uuid: this.crmLocal.uuid,
          phone: newPhone,
          type: newType,
        }
      }

      await this.$apollo
        .mutate({
          mutation: POST_CRM_PHONE,
          variables: {
            objects: phones,
          },
          // eslint-disable-next-line
          update: (cache, { data: { insert_crm_phone } }) => {
            const data = cache.readQuery({
              query: GET_CRM_PHONES,
              variables: this.getQueryVariables(),
            })
            // eslint-disable-next-line
            data.crm_phone.push(insert_crm_phone.returning[0])
            data.crm_phone_aggregate.aggregate.totalCount++

            // Update the list for display
            this.phonesList = data.crm_phone

            cache.writeQuery({
              query: GET_CRM_PHONES,
              data: data,
              variables: this.getQueryVariables(),
            })
          },
        })
        .catch((error) => {
          throw error
        })
    },
    async getPhone() {
      // Get list phone by user
      if (this.crmLocal.uuid !== '') {
        const result = await this.$apollo.query({
          query: GET_CRM_PHONES,
          variables: this.getQueryVariables(),
        })
        this.phonesList = result?.data?.crm_phone || []
      }
    },
    async deletePhone(index, item) {
      const removal = this.$t('common.removal')
      const sureToDelete = this.$t('common.sureToDelete')
      const res = await this.$root.$confirm(removal, sureToDelete, {
        color: 'red',
      })

      if (res) {
        // Delete on DB if user exist in DB
        if (typeof item.uuid !== 'undefined') {
          await this.$apollo
            .mutate({
              mutation: DELETE_CRM_PHONE,
              variables: {
                uuid: item.uuid,
              },
              refetchQueries: [
                {
                  // Update cache Phones
                  query: GET_CRM_PHONES,
                  variables: this.getQueryVariables(),
                },
              ],
            })
            .then((res) => {
              // Delete phone to phonesList (only for display)
              if (res) this.phonesList.splice(index, 1)
            })
            .catch((error) => {
              throw error
            })
        }
      }
    },
  },
}
</script>
