<template>
  <v-card>
    <v-card-title class="text-body-1 white--text secondary py-1">
      {{ $tc('common.communication', 1) }}
      <v-tooltip v-if="!disabled" right>
        <template #activator="{ on }">
          <v-icon class="pl-2" color="white" v-on="on">$iconInfo</v-icon>
        </template>
        <span>{{ $t('contact.info') }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12" lg="6">
          <v-card outlined>
            <v-card-title class="pt-1 pb-0 text-subtitle-2">
              {{ $t('common.phoneColon') }}
            </v-card-title>
            <phone-selector v-model="crmContactLocal" :crm-name="crmName" />
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card outlined>
            <v-card-title class="pt-1 pb-0 text-subtitle-2">
              {{ $t('common.emailColon') }}
            </v-card-title>
            <email-selector v-model="crmContactLocal" :crm-name="crmName" />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import EmailSelector from '@/components/helper/EmailSelector'
import PhoneSelector from '@/components/helper/PhoneSelector'

export default {
  name: 'CrmCommunicationEditor',
  components: {
    EmailSelector,
    PhoneSelector,
  },
  props: {
    crmName: {
      type: String,
      default: '', // contact || company
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {
          uuid: '',
        }
      },
    },
  },
  computed: {
    crmContactLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>