<template>
  <v-row>
    <v-col>
      <v-textarea
        v-model="settingsLocal.description"
        :label="$t('common.description')"
        :placeholder="$t('common.description')"
        rows="2"
        auto-grow
        clearable
        hide-details
        outlined
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DashboardWidgetSettingsText',
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    settingsLocal: {},
  }),
  mounted() {
    this.settingsLocal = this.settings
  },
}
</script>
