<template>
  <v-container class="pa-0">
    <v-card class="ma-2 project-drawer-view-panel" flat tile>
      <v-expansion-panels accordion focusable mandatory>
        <v-expansion-panel class="ma-0 pa-0">
          <v-expansion-panel-header class="px-2 py-0">
            <span v-if="currentView.type === 'net'" class="font-weight-bold">
              <v-icon small>$iconTools</v-icon>
              {{ $t('project.toolbox') }}
            </span>
            <span v-else class="font-weight-bold">
              <v-icon small width="10">$iconLayers</v-icon>
              {{ $tc('project.layer', 2) }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="ma-0 pa-0">
            <view-layers-list v-if="currentView.type !== 'net'" type="map" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="ma-0 pa-0">
          <v-expansion-panel-header class="px-2 py-0">
            <span class="font-weight-bold">
              <v-icon small>$iconSettings</v-icon>
              {{ $t('common.configuration') }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-3">
            <view-configuration v-if="currentView.type !== 'net'" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ViewConfiguration from '@/components/project/views/drawer/ProjectDrawerViewConfiguration'
import ViewLayersList from '@/components/project/views/drawer/ProjectDrawerViewLayers'

export default {
  name: 'ProjectDrawerViewPanel',
  components: {
    ViewConfiguration,
    ViewLayersList,
  },
  computed: {
    ...mapGetters({
      currentView: 'project/currentView',
    }),
  },
}
</script>

<style lang="css">
.project-drawer-view-panel {
  height: calc(100vh - (48px + 188px));
}
.project-drawer-view-panel .v-expansion-panel-header {
  min-height: 48px !important;
}
.project-drawer-view-panel .v-expansion-panel-content {
  height: calc(100vh - (48px + 285px));
  overflow-y: auto;
}
.project-drawer-view-panel .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
