import * as moment from 'moment/moment'

export const dateTimeFormats = {
  fr: {
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    long: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'en-US': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'en-GB': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
}

export const formatDateTimeIso8601 = 'yyyy-MM-DDTHH:mm:ss.SSSZ'

export const formatDate = {
  fr: {
    manual: 'jj/mm/aaaa',
    momentjs: 'DD/MM/yyyy',
    picker: 'dd/MM/yyyy',
  },
  en: {
    manual: 'yyyy-mm-dd',
    momentjs: 'yyyy-MM-DD',
    picker: 'yyyy-MM-dd',
  },
}

/**
 * Returns formatted date
 * @param {Object|String} dateTime
 *  Object, eg.:
 *    Wed Feb 17 2021 09:47:06 GMT+0100 (heure normale d’Europe centrale)
 *  String: yyyy-MM-DDTHH:mm:ss.SSS+HH:mm
 * @returns {Object|String}
 */
export const convertDateTime = (dateTime) => {
  let newDateTime = null
  // Convert Date Object to Date ISO 8601 format
  if (typeof dateTime === 'object' && dateTime !== null) {
    newDateTime = moment.utc(dateTime).format(formatDateTimeIso8601)
  }
  // Convert Date ISO 8601 format to Date Object
  if (typeof dateTime === 'string' && dateTime !== null) {
    newDateTime = new Date(dateTime)
  }
  return newDateTime
}

export const dateIso8601ToDate = (dateIso8601) => {
  return dateIso8601
    ? moment(dateIso8601, formatDateTimeIso8601).format(formatDate.en.momentjs)
    : null
}

export const dateIso8601ToHour = (dateIso8601) => {
  return dateIso8601 ? moment(dateIso8601, formatDateTimeIso8601).format('HH:mm') : null
}

export const dateTimeHumanFormatFromTo = (dateTimeFrom, dateTimeTo, langAndTranslate) => {
  let format = langAndTranslate.lang === 'fr' ? formatDate.fr.momentjs : formatDate.en.momentjs
  format = format + ' HH:mm'
  return (
    moment(dateTimeFrom).format(format) +
    ' ' +
    langAndTranslate.to +
    ' ' +
    moment(dateTimeTo).format(format)
  )
}

export const dateTimeToIso8601 = (dateTime) => {
  return moment(dateTime).utc().format(formatDateTimeIso8601)
}

/**
 * Return date iso with format timezone
 * Eg. date = 2021-06-30T13:20:38+00:00 (with GMT +02:00 - can't see it there)
 * return = 2021-06-30T15:20:38+02:00
 * @link https://stackoverflow.com/a/17415677
 * @param {Object|String} date
 * @returns {String}
 */
export const toIsoString = (date) => {
  const tzo = -moment(date)._d.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = (num) => {
    return (num < 10 ? '0' : '') + num
  }

  return (
    moment(date)._d.getFullYear() +
    '-' +
    pad(moment(date)._d.getMonth() + 1) +
    '-' +
    pad(moment(date)._d.getDate()) +
    'T' +
    pad(moment(date)._d.getHours()) +
    ':' +
    pad(moment(date)._d.getMinutes()) +
    ':' +
    pad(moment(date)._d.getSeconds()) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' +
    pad(Math.abs(tzo) % 60)
  )
}
