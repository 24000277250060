<template>
  <v-dialog v-model="show" persistent max-width="700">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t('project.layerAttributes') }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-if="selectedLayer"
                v-model="selectedLayer.name"
                :label="$t('project.layerName')"
                required
              />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-if="selectedLayer"
                v-model="selectedLayer.options.color"
                hide-details
                :label="$t('project.colorOutline')"
                outlined
              >
                <template #append>
                  <v-menu
                    v-model="menu"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on }">
                      <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="selectedLayer.options.color" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-if="selectedLayer && selectedLayer.type === 'geojson'"
                v-model="selectedLayer.options.bgcolor"
                hide-details
                :label="$t('project.colorSurface')"
                outlined
              >
                <template #append>
                  <v-menu
                    v-model="menuBg"
                    top
                    nudge-bottom="105"
                    nudge-left="16"
                    :close-on-content-click="false"
                  >
                    <template #activator="{ on }">
                      <div :style="swatchStyleBg" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="selectedLayer.options.bgcolor" flat />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-slider
                v-if="selectedLayer"
                v-model="selectedLayer.options.opacity"
                :label="$t('project.transparency')"
                class="align-center"
                max="100"
                min="0"
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-checkbox
                v-if="selectedLayer && selectedLayer.type == 'geojson'"
                v-model="selectedLayer.options.showlabel"
                :label="$t('project.showlabels')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn color="primary darken-1" text @click="SaveLayerDialog">
          {{ $t('common.save') }}
        </v-btn>
        <v-btn color="grey" text @click="UndoLayerDialog">
          {{ $t('common.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  GET_PROJECT_VIEW_LAYERS,
  PATCH_PROJECT_VIEW_LAYER,
} from '@/graphql/ProjectViewLayerQueries'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'LayerOptionsDialog',
  props: {
    layer: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedLayer: undefined,
    oldLayer: undefined,
    menu: false,
    menuBg: false,
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    swatchStyle() {
      const { menu } = this
      return {
        backgroundColor: this.selectedLayer.options.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
    swatchStyleBg() {
      const { menuBg } = this
      return {
        backgroundColor: this.selectedLayer.options.bgcolor,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menuBg ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
  },
  watch: {
    show(visible) {
      if (visible) {
        this.selectedLayer = cloneDeep(this.layer)
        this.oldLayer = cloneDeep(this.layer)
      }
    },
  },
  methods: {
    UndoLayerDialog() {
      this.selectedLayer.name = this.oldLayer.name
      this.selectedLayer.options = this.oldLayer.options
      this.show = false
    },
    async SaveLayerDialog() {
      const layer = cloneDeep(this.selectedLayer)
      delete layer.__typename
      delete layer.features

      await this.$apollo
        .mutate({
          mutation: PATCH_PROJECT_VIEW_LAYER,
          variables: {
            uuid: this.selectedLayer.uuid,
            body: layer,
          },
          refetchQueries: [
            {
              query: GET_PROJECT_VIEW_LAYERS,
              variables: {
                where: {
                  project_view_uuid: {
                    _eq: this.$store.getters['project/currentView'].uuid,
                  },
                },
                order_by: { vorder: 'asc' },
              },
            },
          ],
        })
        .catch((error) => {
          const errorChangeLayer = this.$t('project.errorChangeLayer')
          this.$root.$dialogLoader.showSnackbar(
            errorChangeLayer + `  ${this.selectedLayer.name} (${this.selectedLayer.uuid})`,
            { color: 'error' }
          )
          throw error
        })
      this.show = false
    },
  },
}
</script>
<style lang="scss" scoped></style>
